import ActionTypes from '../../../constants/ActionTypes';
import {
  IAdministrativeBanState,
  IFetchAdministrativeDataAction,
  ISetAdministrativeDataAction
} from './types';

export function fetchAdministrativeBanDataAction(): IFetchAdministrativeDataAction {
  return {
    type: ActionTypes.ADMINISTRATIVE_BAN.FETCH_ADMINISTRATIVE_BAN_DATA
  };
}

export function setAdministrativeBanDataAction(
  administrativeBanData: IAdministrativeBanState
): ISetAdministrativeDataAction {
  return {
    type: ActionTypes.ADMINISTRATIVE_BAN.SET_ADMINISTRATIVE_BAN_DATA,
    payload: administrativeBanData
  };
}

export function validateForeignBookDataAction(
  administrativeBanData: IAdministrativeBanState
) {
  return {
    type: ActionTypes.ADMINISTRATIVE_BAN.VALIDATE_ADMINISTRATIVE_BAN_DATA,
    payload: administrativeBanData
  };
}

export function enrichForeignBookDataAction(foreignBookData: any) {
  return {
    type: ActionTypes.FOREIGN_BOOK.ENRICH_FOREIGN_BOOK_DATA,
    payload: foreignBookData
  };
}

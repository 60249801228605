import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import { setSubscribeNewsLetterDataAction } from './actions';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import { ErrorCodes } from '../../../constants/ErrorCodes';

const subscribeNewsLetterMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWSLETTER.SUBSCRIBE_NEWSLETTER:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.NEWSLETTER_SUBSCRIBE,
            null,
            action.payload.newsLetterSubscribeRequest,
            MiddlewareEntities.NEWSLETTER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.NEWSLETTER));
        break;
      case `${MiddlewareEntities.NEWSLETTER} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER));
        next(
          setSubscribeNewsLetterDataAction(
            'success',
            'Uskoro će na vašu email adresu stići link za potvrdu prijave.'
          )
        );
        break;
      case `${MiddlewareEntities.NEWSLETTER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER));

        if (
          action.payload.data &&
          action.payload.data.data &&
          action.payload.data.data.code === ErrorCodes.ALREADY_SUBSCRIBED
        ) {
          return next(
            setSubscribeNewsLetterDataAction(
              'error',
              ErrorMessages.ALREADY_SUBSCRIBED
            )
          );
        }

        next(setSubscribeNewsLetterDataAction('error', ErrorMessages.DEFAULT));
        break;
    }
  };

export default [subscribeNewsLetterMiddleware];

import React, { FC, useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import useNavItemsOpen from '../../layout/header/hooks/useNavItemsOpen';
import { getSliceArray } from '../../../utilites';
import useSectionExpand from '../../hooks/useSectionExpand';
import { getCategoryByName } from '../../../selectors/getCategoryByName';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { keyId } from '../../layout/header/hooks/types';
import { Categories } from '../../../constants/Categories';
import categoryMap from '../../../utilites/categoryMap';
import reverseCategoryMap from '../../../utilites/reverseCategoryMap';
import { IGenre } from '../../../entities/product/types';
import InPageParams from '../../../constants/InPageParams';
import MobileGenres from './mobile';
import { IGenresByCategory } from '../../../app/store/filters-data/types';

interface IProps {
  genres: any;
  ebook?: boolean;
  isRenderForMobileMenu?: boolean;
}

const Genres: FC<IProps> = ({
  genres,
  ebook,
  isRenderForMobileMenu
}: IProps) => {
  const location = useLocation();
  const { category, genreName } = useParams();
  const { navItemsOpen, handleOnToggleNavDropdown, setNavItemsOpenToFalse } =
    useNavItemsOpen();
  const [searchParams] = useSearchParams();
  const { setExpand, findItemByKey, expandMore } = useSectionExpand([]);

  const mustHaveGenreParam = searchParams.get('mustHaveGenre');
  const inPageParam = searchParams.get('inPage');

  const [isFilterOpen, setIsFilterOpen] = useState(
    category ||
      inPageParam === Categories.KIDS_BOOK ||
      inPageParam === InPageParams.authorsList ||
      ebook
  );

  const MIN_GENRES_TO_DISPLAY_ALL = 8;
  const renderGenresInPage = () => {
    let genresByCategories: any[] = [...genres.genresByCategories];

    genresByCategories = genresByCategories.filter(
      (genresByCategory: any) =>
        genresByCategory.category !== 'Video igra' &&
        genresByCategory.category !== 'Udžbenik' &&
        genresByCategory.category !== 'Film'
    );

    genresByCategories = genresByCategories.map((genre: any) => {
      const category = categoryMap.get(genre.category) || genre.category;
      if (genre.category === 'Knjiga' || genre.category === 'Strana knjiga') {
        genre.genres = genre.genres.filter((gen: any) => {
          return gen.genreName !== 'E-knjige';
        });
      }
      return { ...genre, category: category };
    });

    const desireGenresOrder = [
      'Knjige',
      'Strane knjige',
      'Gift',
      'Muzika',
      'Dečja knjiga'
    ];

    const reorderCategories = (order: any) => {
      if (
        !Array.isArray(genresByCategories) ||
        genresByCategories.length === 0
      ) {
        return [];
      }
      const categoryMap = genresByCategories.reduce(
        (acc: any, category: IGenresByCategory) => {
          acc[category.category] = category;
          return acc;
        },
        {}
      );

      return order.map((categoryName: string) => categoryMap[categoryName]);
    };

    const reorderedCategories = reorderCategories(desireGenresOrder);
    if (reorderedCategories.length > 0) {
      if (ebook) {
        genresByCategories = reorderedCategories.filter(
          (genre: any) =>
            genre.category === 'Knjige' || genre.category === 'Strane knjige'
        );
      }
      if (inPageParam === Categories.KIDS_BOOK) {
        const bookForKidsGenres = genres.booksForKids.filter(
          (genre: IGenre) => genre.genreName !== 'Knjige za decu'
        );
        return [
          {
            category: 'Knjige za decu',
            genres: [...bookForKidsGenres]
          }
        ];
      }
      return reorderedCategories.filter(
        (genre: any) => genre.category !== 'Dečja knjiga'
      );
    }

    return [];
  };

  const genresByCategories = renderGenresInPage();

  const prepareInitialState = () => {
    let initialState: any = [];
    genresByCategories.forEach((gen: any) => {
      initialState.push({
        key: gen.category,
        genres: gen.genres,
        isShow:
          inPageParam === Categories.KIDS_BOOK || ebook
            ? true
            : getCategoryByName(
                genresByCategories,
                categoryMap.get(category || '') || category || ''
              )?.category === gen.category,
        showMore: false
      });
    });

    return initialState;
  };

  useEffect(() => {
    if (genres && genres.genresByCategories.length > 0) {
      setExpand(prepareInitialState());
    }
  }, [genres, location, ebook]);

  const getGenresByKey = (key: string) => {
    const item = findItemByKey(key);

    if (typeof item !== 'undefined') {
      return item;
    }

    return null;
  };

  const checkIsCategoryOpen = (key: string) => {
    const item = findItemByKey(key);

    if (typeof item !== 'undefined' && typeof item.isShow !== 'undefined') {
      return item.isShow;
    }

    return false;
  };

  const handleOnClickToggle = (type: keyId) => {
    if (isFilterOpen) {
      // @ts-ignore
      setIsFilterOpen(false);
      return setNavItemsOpenToFalse(type);
    }
    handleOnToggleNavDropdown(type);
  };

  const getGenreForUrl = () => {
    let genres = genreName ? genreName.split(',') : '';

    if (Array.isArray(genres)) {
      genres = genres.map((genre: string) => {
        return genre.replaceAll('_ss_cmma_', ',');
      });

      return genres;
    }

    return [genres];
  };

  const foundGenreInGenres = (genreName: string) => {
    return getGenreForUrl().some(
      (element) => element.toLowerCase() === genreName.toLowerCase()
    );
  };

  const createGenreUrl = (genreName: string, category: string) => {
    const genreNameWithSsComma = genreName.includes(',')
      ? genreName.replace(/,/g, '_ss_cmma_')
      : genreName;
    const mustHaveGenreUrlParam = mustHaveGenreParam
      ? `&mustHaveGenreParam=${encodeURIComponent(mustHaveGenreParam)}`
      : '';

    const genreUrlParam = encodeURIComponent(genreNameWithSsComma);

    return `/${
      reverseCategoryMap.get(category) || category
    }/zanr/${genreUrlParam}${mustHaveGenreUrlParam}${
      ebook ? `?eBook=${ebook}` : ''
    }${
      inPageParam === Categories.KIDS_BOOK
        ? `&inPage=${inPageParam}&genre=${genreUrlParam},Knjige za decu${mustHaveGenreUrlParam}`
        : ''
    }`;
  };

  const handleOnClickToggleNavDropdown = (
    event: React.MouseEvent<HTMLDivElement>,
    keyId: keyId
  ) => {
    event.stopPropagation();
    handleOnToggleNavDropdown(keyId);
  };

  const renderGenres = () => {
    if (isRenderForMobileMenu) {
      return (
        <MobileGenres
          genres={genresByCategories}
          handleOnClickToggleNavDropdown={handleOnClickToggleNavDropdown}
          navItemsOpen={navItemsOpen}
          createGenreUrl={createGenreUrl}
          foundGenreInGenres={foundGenreInGenres}
        />
      );
    }

    return (
      <div className="row justify-content-start text-start align-items-center">
        <div className="background-snuff-gray p-3">
          <div>
            <div
              className="col-md-12 d-flex cursor-pointer"
              onClick={() => handleOnClickToggle('genres')}
            >
              <h6 className="me-2">ŽANROVI</h6>
              {isFilterOpen || navItemsOpen.genres ? (
                <MdKeyboardArrowUp
                  size="1.1em"
                  className="font-color-default"
                />
              ) : (
                <MdKeyboardArrowDown
                  size="1.1em"
                  className="font-color-default"
                />
              )}
            </div>
            <div className="border-bottom-blue-haze-2 mt-2 mb-2"></div>
          </div>
          {isFilterOpen || navItemsOpen.genres ? (
            <>
              <div className="p-2">
                <div>
                  {genresByCategories.map(
                    (genre: IGenresByCategory, index: number) => {
                      return (
                        <div key={index}>
                          <div className="col-12 cursor-pointer">
                            <div
                              className="col-12 d-flex"
                              onClick={() =>
                                expandMore(genre.category, 'isShow')
                              }
                            >
                              <h6 className="me-2">{genre.category}</h6>
                              {checkIsCategoryOpen(genre.category) ? (
                                <MdKeyboardArrowUp
                                  size="1.1em"
                                  className="font-color-default"
                                />
                              ) : (
                                <MdKeyboardArrowDown
                                  size="1.1em"
                                  className="font-color-default"
                                />
                              )}
                            </div>
                            <div
                              className={`${
                                checkIsCategoryOpen(genre.category)
                                  ? 'col-12 d-block'
                                  : 'd-none'
                              }`}
                            >
                              {getSliceArray(
                                0,
                                MIN_GENRES_TO_DISPLAY_ALL,
                                getGenresByKey(genre.category)
                                  ? getGenresByKey(genre.category).genres
                                  : []
                              ).map((genres: any, index: number) => {
                                return (
                                  <div key={index} className="col-12">
                                    <Link
                                      onClick={(event) => {
                                        return (
                                          foundGenreInGenres(
                                            genres.genreName
                                          ) && event.preventDefault()
                                        );
                                      }}
                                      to={createGenreUrl(
                                        genres.genreName,
                                        genre.category
                                      )}
                                      className="text-decoration-none"
                                    >
                                      <h6
                                        className={`text-14 text-decoration-none ${
                                          !foundGenreInGenres(genres.genreName)
                                            ? ''
                                            : 'color-active-item cursor-default'
                                        }`}
                                      >
                                        {genres.genreName}
                                      </h6>
                                    </Link>
                                  </div>
                                );
                              })}
                              {getGenresByKey(genre.category) &&
                                getGenresByKey(genre.category).showMore && (
                                  <div className="col-12">
                                    {getSliceArray(
                                      MIN_GENRES_TO_DISPLAY_ALL,
                                      getGenresByKey(genre.category)
                                        ? getGenresByKey(genre.category).genres
                                            .length
                                        : 0,
                                      getGenresByKey(genre.category)
                                        ? getGenresByKey(genre.category).genres
                                        : []
                                    ).map((genres, index) => {
                                      return (
                                        <div key={index} className="col-12">
                                          <Link
                                            onClick={(event) => {
                                              return (
                                                foundGenreInGenres(
                                                  genres.genreName
                                                ) && event.preventDefault()
                                              );
                                            }}
                                            to={createGenreUrl(
                                              genres.genreName,
                                              genre.category
                                            )}
                                            className="text-decoration-none"
                                          >
                                            <h6
                                              className={`text-14 text-decoration-none ${
                                                !foundGenreInGenres(
                                                  genres.genreName
                                                )
                                                  ? ''
                                                  : 'color-active-item cursor-default'
                                              }`}
                                            >
                                              {genres.genreName}
                                            </h6>
                                          </Link>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                              {getGenresByKey(genre.category)
                                ? getGenresByKey(genre.category).genres.length >
                                    MIN_GENRES_TO_DISPLAY_ALL && (
                                    <div
                                      className="col text-start cursor-pointer"
                                      onClick={() =>
                                        expandMore(genre.category, 'showMore')
                                      }
                                    >
                                      <span className="text-color-blue">
                                        Prikaži{' '}
                                        {getGenresByKey(genre.category).showMore
                                          ? 'manje'
                                          : 'više'}
                                      </span>
                                    </div>
                                  )
                                : null}
                            </div>
                            <div className="border-bottom-blue-haze-2 mt-2 mb-2"></div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  return genresByCategories.length > 0 ? renderGenres() : null;
};

export default Genres;

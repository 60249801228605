import { ISetTopListsPageDataAction, ITopListsPageState } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: ITopListsPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const topListsPageDataReducer = (
  state = initialState,
  action: ISetTopListsPageDataAction
): ITopListsPageState => {
  switch (action.type) {
    case ActionTypes.TOP_LISTS_PAGE.SET_TOP_LISTS_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default topListsPageDataReducer;

import React, { ReactElement } from 'react';
import IPropsCarousel from './types';
import { MdOutlineKeyboardArrowLeft } from 'react-icons/md';

const PrevArrow: React.FC<IPropsCarousel> = (
  props: IPropsCarousel
): ReactElement => {
  const { onClick, styleType } = props;
  return (
    <div
      className="custom-arrows prev cursor-pointer d-flex justify-content-center align-items-center"
      onClick={onClick}
      style={styleType}
    >
      <MdOutlineKeyboardArrowLeft size="1.2em" />
    </div>
  );
};

export default PrevArrow;

import React, { FC, useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import {
  composeValidators,
  email,
  minValue,
  required
} from '../forms/validators';
import Error from '../forms/Error';
import Spinner from '../spinner/Spinner';
import ButtonWitIcon from '../button-with-icon';
import { FaCheck } from 'react-icons/fa';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../../config';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { ILoginRequest } from './types';

interface IProps {
  handleOnSubmit: (values: ILoginRequest) => void;
  ui: any;
  middlewareEntity: string;
  errorMessage?: string;
}

const LoginForm: FC<IProps> = ({
  handleOnSubmit,
  ui,
  errorMessage,
  middlewareEntity
}: IProps) => {
  const refRecaptcha = useRef(null);
  const inputPassRef = useRef<null | HTMLInputElement>(null);
  const [localErrorMessage, setErrorMessage] = useState<any>(
    errorMessage ? errorMessage : ''
  );

  const handleOnSubmitLocal = async (values: ILoginRequest) => {
    //@ts-ignore
    values.recaptcha = await refRecaptcha.current.executeAsync();

    handleOnSubmit(values);
  };

  const handleOnChange = (e: any) => {
    setErrorMessage('');
  };

  useEffect(() => {
    setErrorMessage(errorMessage);
  }, [errorMessage, middlewareEntity, ui]);

  return (
    <Form
      onSubmit={handleOnSubmitLocal}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="row ms-0 me-0">
            <div className="col-12">
              <Field
                name="username"
                type="text"
                validate={composeValidators(
                  required,
                  minValue(3),
                  MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER ===
                    middlewareEntity
                    ? email
                    : () => {}
                )}
              >
                {({ input, meta }) => (
                  <>
                    <input
                      className="input-group"
                      placeholder="E-mail:"
                      {...input}
                      id="username"
                      onChange={(e) => {
                        input.onChange(e);
                        handleOnChange(e);
                      }}
                    />
                    <Field name="username">
                      {({ meta: { active, dirty, error, touched } }) => (
                        <Error
                          active={active}
                          dirty={dirty}
                          error={error}
                          touched={touched}
                        />
                      )}
                    </Field>
                  </>
                )}
              </Field>
            </div>
            <div className="col-12 mt-3">
              <Field
                name="password"
                type="password"
                validate={composeValidators(required, minValue(2))}
              >
                {({ input, meta }) => (
                  <>
                    <input
                      className="input-group"
                      placeholder="Lozinka:"
                      {...input}
                      id="password"
                      ref={inputPassRef}
                      onChange={(e) => {
                        input.onChange(e);
                        handleOnChange(e);
                      }}
                    />
                    <Field name="password">
                      {({ meta: { active, dirty, error, touched } }) => (
                        <Error
                          active={active}
                          dirty={dirty}
                          error={error}
                          touched={touched}
                        />
                      )}
                    </Field>
                  </>
                )}
              </Field>
            </div>
            {ui.loaders.includes(middlewareEntity) ? (
              <Spinner classes="mt-4" color="white" />
            ) : (
              <>
                {localErrorMessage ? (
                  <div className="col-12 mt-3 mb-3">
                    <span className="alert-danger">{errorMessage}</span>
                  </div>
                ) : null}
                <div className="col-12 mt-3">
                  <ButtonWitIcon
                    label="Prijava"
                    iconDirection="right"
                    type="submit"
                    icon={
                      <FaCheck size="1.4em" className="align-items-center" />
                    }
                    iconClasses="ms-3"
                    componentClasses="background-orange justify-content-center text-center w-100 p-3 text-uppercase"
                    textClasses="text-14 jost-extra-bold font-color-default"
                  />
                </div>
              </>
            )}
          </div>
          <ReCAPTCHA
            ref={refRecaptcha}
            sitekey={Config.recaptchaPublicKey}
            size="invisible"
          />
        </form>
      )}
    ></Form>
  );
};

export default LoginForm;

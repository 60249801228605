import { IFactory } from '../types';
import { IBookMakerPage } from '../../app/store/bookmaker-magazine/types';
import BookmakerPageData from '../../entities/bookmaker/BookmakerPageData';
import { replaceDotWithUrl } from '../../utilites/product';
import { getFullYear, getMonthName } from '../../utilites/date';

const BookmakerPageDataFactory: IFactory = class BookmakerPageDataFactory {
  static create(bookmakerPageData: IBookMakerPage): BookmakerPageData {
    return new BookmakerPageData(
      bookmakerPageData._id,
      replaceDotWithUrl(bookmakerPageData.img),
      bookmakerPageData.prominentText,
      bookmakerPageData.regularText,
      bookmakerPageData.bodyText,
      bookmakerPageData.issueNumber,
      bookmakerPageData.no,
      bookmakerPageData.publishedAt,
      `${getFullYear(bookmakerPageData.publishedAt)}. ${getMonthName(
        bookmakerPageData.publishedAt,
        'sr'
      )}`
    );
  }
};

export default BookmakerPageDataFactory;

import { IBookPageState, ISetBookPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IBookPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const bookPageDataReducer = (
  state = initialState,
  action: ISetBookPageDataAction
): IBookPageState => {
  switch (action.type) {
    case ActionTypes.BOOK_PAGE.SET_BOOK_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default bookPageDataReducer;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchProductPageAction,
  ISetProductPageAction,
  IValidateProductPageAction,
  IEnrichProductPageAction,
  IProductPageState
} from './types';

export function fetchProductOverviewPageAction(
  id: number
): IFetchProductPageAction {
  return {
    type: ActionTypes.PRODUCT_PAGE.FETCH_PRODUCT_PAGE,
    payload: {
      id
    }
  };
}

export function setProductPageAction(
  productPage: IProductPageState
): ISetProductPageAction {
  return {
    type: ActionTypes.PRODUCT_PAGE.SET_PRODUCT_PAGE,
    payload: productPage
  };
}

export function validateProductPageDataAction(
  productPageData: any
): IValidateProductPageAction {
  return {
    type: ActionTypes.PRODUCT_PAGE.VALIDATE_PRODUCT_PAGE,
    payload: productPageData
  };
}

export function enrichProductPageDataAction(
  productPageData: any
): IEnrichProductPageAction {
  return {
    type: ActionTypes.PRODUCT_PAGE.ENRICH_PRODUCT_PAGE,
    payload: productPageData
  };
}

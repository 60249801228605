import { cloneElement, FC, ReactElement } from 'react';
import { AppState } from '../../../app/store/rootReducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { requestFetchHomePage } from '../../../app/store/homepage/actions';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

interface IProps {
  children: ReactElement;
}

type Props = ReturnType<typeof mapStateToProps> &
  IProps &
  ReturnType<typeof mapDispatchToProps>;

const RequireAuth: FC<Props> = ({ children, user }: Props) => {
  if (!user) {
    return <Navigate to="/" replace />;
  }

  return cloneElement(children, { user });
};

const mapStateToProps = (state: AppState) => ({
  user: state.user.member.user
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ requestFetchHomePage }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RequireAuth);

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchMusicPageDataAction,
  IMusicPageState,
  ISetMusicPageDataAction
} from './types';

export function fetchMusicPageDataAction(): IFetchMusicPageDataAction {
  return {
    type: ActionTypes.MUSIC_PAGE.FETCH_MUSIC_PAGE_DATA
  };
}

export function setMusicPageDataAction(
  musicPageData: IMusicPageState
): ISetMusicPageDataAction {
  return {
    type: ActionTypes.MUSIC_PAGE.SET_MUSIC_PAGE_DATA,
    payload: musicPageData
  };
}

export function validateMusicPageDataAction(musicPageData: IMusicPageState) {
  return {
    type: ActionTypes.MUSIC_PAGE.VALIDATE_MUSIC_PAGE_DATA,
    payload: musicPageData
  };
}

export function enrichMusicPageDataAction(musicPageData: any) {
  return {
    type: ActionTypes.MUSIC_PAGE.ENRICH_MUSIC_PAGE_DATA,
    payload: musicPageData
  };
}

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setAskAboutProductMessageStatusAction } from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import { generateErrorMessage } from '../../../utilites/error';

const askAboutProductMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.ASK_ABOUT_PRODUCT.ASK_ABOUT_PRODUCT_POST_REQUEST:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.ASK_ABOUT_PRODUCT,
            null,
            action.payload.values,
            MiddlewareEntities.ASK_ABOUT_PRODUCT,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.ASK_ABOUT_PRODUCT));
        break;
      case `${MiddlewareEntities.ASK_ABOUT_PRODUCT} ${ActionTypes.API_SUCCESS}`:
        next(
          setAskAboutProductMessageStatusAction(
            200,
            'Uspešno ste poslali poruku'
          )
        );
        next(setLoader(false, MiddlewareEntities.ASK_ABOUT_PRODUCT));
        break;
      case `${MiddlewareEntities.ASK_ABOUT_PRODUCT} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.ASK_ABOUT_PRODUCT));
        next(
          setAskAboutProductMessageStatusAction(
            action.payload.status,
            generateErrorMessage(
              action.payload.data.data && action.payload.data.data.userMessage
                ? action.payload.data.data.userMessage
                : ''
            )
          )
        );
        break;
    }
  };

export default askAboutProductMiddleware;

import ActionTypes from '../../../constants/ActionTypes';
import { ISetApiResponseInCacheActions } from './types';

export const setApiResponseInCache = (
  cacheKey: string,
  data: any,
  time: number
): ISetApiResponseInCacheActions => ({
  type: ActionTypes.SET_API_RESPONSE_IN_CACHE,
  payload: { cacheKey, data, time }
});

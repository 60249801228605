/* eslint-disable @typescript-eslint/brace-style */

import {
  BenefitStatesEnum,
  IVisaPremiumBenefitReport,
  IVisaPremiumBenefitPayload
} from '../cart/types';
import Benefit from './Benefit';

export default class VisaPremiumBenefitReport
  extends Benefit
  implements IVisaPremiumBenefitReport
{
  state: BenefitStatesEnum;

  payload: IVisaPremiumBenefitPayload;

  constructor(state: BenefitStatesEnum, payload: IVisaPremiumBenefitPayload) {
    super(state);
    this.state = state;
    this.payload = payload;
  }
}

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import { setTextSliderItems } from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';

const textSlidersMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.TEXT_SLIDERS.FETCH_TEXT_SLIDERS:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.TEXT_SLIDERS,
            null,
            null,
            MiddlewareEntities.TEXT_SLIDERS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.TEXT_SLIDERS));
        break;
      case `${MiddlewareEntities.TEXT_SLIDERS} ${ActionTypes.API_SUCCESS}`:
        next(setTextSliderItems(action.payload.data.textSliders));
        next(setLoader(false, MiddlewareEntities.TEXT_SLIDERS));
        break;
      case `${MiddlewareEntities.TEXT_SLIDERS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.TEXT_SLIDERS));
        break;
    }
  };

export default textSlidersMiddleware;

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';

export const updateNotFoundUrlMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.NOT_FOUND_URL.UPDATE_NOT_FOUND_URL:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.UPDATE_NOT_FOUND_URL,
            null,
            action.payload,
            MiddlewareEntities.NOT_FOUND_URL,
            {}
          )
        );
        break;
      case `${MiddlewareEntities.NOT_FOUND_URL} ${ActionTypes.API_SUCCESS}`:
        break;
      case `${MiddlewareEntities.NOT_FOUND_URL} ${ActionTypes.API_ERROR}`:
        break;
    }
  };

export default [updateNotFoundUrlMiddleware];

import React, { ReactChildren, ReactNode } from 'react';

interface IProps {
  children: JSX.Element | ReactNode | ReactChildren | null;
}

const LayoutBody: React.FC<IProps> = ({ children }: IProps) => (
  <main role="main" className="main">
    <div className="container-fluid px-0 background-gray" style={{ maxWidth: '1920px' }}>
      {children}
    </div>
  </main>
);

export default LayoutBody;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IAvailableBookstoresState,
  ISetAvailableBookstoresAction
} from './types';

const initialState: IAvailableBookstoresState = {
  items: []
};

const availableBookstoresReducer = (
  state = initialState,
  action: ISetAvailableBookstoresAction
): IAvailableBookstoresState => {
  switch (action.type) {
    case ActionTypes.PRODUCT_PAGE.SET_AVAILABLE_BOOKSTORES:
      return {
        ...state,
        items: action.payload.bookstores
      };
  }

  return state;
};

export default availableBookstoresReducer;

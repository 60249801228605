import { IInfoIconsContent, IInfoIconsItem } from '../types';

export default class InfoIconsContent implements IInfoIconsContent {
  _id: string;

  title: string;

  startAt: string | null;

  endAt: string | null;

  items: IInfoIconsItem[];

  constructor(
    id: string,
    title: string,
    startAt: string | null,
    endAt: string | null,
    items: IInfoIconsItem[]
  ) {
    this._id = id;
    this.title = title;
    this.startAt = startAt;
    this.endAt = endAt;
    this.items = items;
  }
}

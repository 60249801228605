import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { createNotification } from '../notification/actions';

const errorHandlingMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => async (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.ERROR) {
      let url = '';

      if (action.payload.status === HttpStatusCodes.HTTP_SERVICE_UNAVAILABLE) {
        url = '/maintenance';
      }

      if (url) {
        return window.location.replace(url);
      }

      next(
        createNotification(
          action.payload.entity,
          action.payload.errorNotificationType,
          undefined,
          action.payload.data
        )
      );
    }
  };

export default errorHandlingMiddleware;

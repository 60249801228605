import { IFactory } from '../types';
import { IOrder, IOrderItem } from '../../entities/order/types';
import Order from '../../entities/order/Order';
import OrderItemFactory from './OrderItemFactory';

const OrderFactory: IFactory = class OrderFactory {
  static getFinalPrice(order: IOrder): number {
    if (order.finalPrice && order.finalPrice > 0) {
      return order.finalPrice;
    }

    return order.amount - order.pttPrice;
  }

  static create(order: IOrder) {
    const orderItems = order.order_items.map((orderItem: IOrderItem) => {
      return OrderItemFactory.create(orderItem);
    });

    return new Order(
      order.id,
      order.amount,
      order.created_at,
      order.customer_detail,
      order.customer_detail_id,
      order.delivery_type,
      orderItems,
      order.payment_detail,
      order.payment_detail_id,
      order.secret,
      order.status,
      order.pttPrice,
      this.getFinalPrice(order),
      order.pc_validation_response
    );
  }
};

export default OrderFactory;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichRussianBooksPageDataAction,
  IFetchRussianBooksPageDataAction,
  IRussianBooksPageState,
  ISetRussianBooksPageDataAction,
  IValidateRussianBooksDataAction
} from './types';

export function fetchRussianBooksPageDataAction(): IFetchRussianBooksPageDataAction {
  return {
    type: ActionTypes.RUSSIAN_BOOKS_PAGE.FETCH_RUSSIAN_BOOKS_PAGE_DATA
  };
}

export function setRussianBooksPageDataAction(
  russianBooksPageData: IRussianBooksPageState
): ISetRussianBooksPageDataAction {
  return {
    type: ActionTypes.RUSSIAN_BOOKS_PAGE.SET_RUSSIAN_BOOKS_PAGE_DATA,
    payload: russianBooksPageData
  };
}

export function validateRussianBooksDataAction(
  russianBooksPageData: IRussianBooksPageState
): IValidateRussianBooksDataAction {
  return {
    type: ActionTypes.RUSSIAN_BOOKS_PAGE.VALIDATE_RUSSIAN_BOOKS_PAGE_DATA,
    payload: russianBooksPageData
  };
}

export function enrichRussianBooksPageDataAction(
  russianBooksPageData: any
): IEnrichRussianBooksPageDataAction {
  return {
    type: ActionTypes.RUSSIAN_BOOKS_PAGE.ENRICH_RUSSIAN_BOOKS_PAGE_DATA,
    payload: russianBooksPageData
  };
}

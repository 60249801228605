export const productOverviewSchema: any = {
  type: 'object',
  properties: {
    product: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        oldProductId: { type: 'number' },
        publisher: { type: ['string', 'null'] },
        status: { type: 'boolean' },
        isAvailable: { type: 'boolean' },
        description: { type: ['string', 'null'] },
        title: { type: 'string' },
        category: { type: 'string' },
        images: {
          type: 'object',
          properties: {
            s: { type: 'string' },
            m: { type: 'string' },
            l: { type: 'string' },
            xl: { type: 'string' },
            xxl: { type: 'string' },
            fb: { type: 'string' }
          }
        },
        priceList: {
          type: 'object',
          properties: {
            fullPrice: { type: ['string', 'number'] },
            // discount: { type: ['string', 'number'] },
            // quantityDiscount: { type: ['string', 'number'] },
            // action: { type: ['string', 'number'] },
            eBookPrice: { type: ['string', 'number'] }
          },
          required: ['fullPrice']
        }
      },
      required: [
        '_id',
        'oldProductId',
        'isAvailable',
        'status',
        'title',
        'category',
        'priceList'
      ]
    }
  },
  required: ['product']
};

import ActionTypes from '../../../constants/ActionTypes';
import {
  IActionsState,
  IFetchActionsDataAction,
  ISetActionsDataAction
} from './types';

export function fetchActionsDataAction(): IFetchActionsDataAction {
  return {
    type: ActionTypes.ACTIONS_PAGE.FETCH_ACTIONS_PAGE_DATA
  };
}

export function setActionsDataAction(
  actionsData: IActionsState
): ISetActionsDataAction {
  return {
    type: ActionTypes.ACTIONS_PAGE.SET_ACTIONS_PAGE_DATA,
    payload: actionsData
  };
}

export function validateActionsDataAction(actionsData: IActionsState) {
  return {
    type: ActionTypes.ACTIONS_PAGE.VALIDATE_ACTIONS_PAGE_DATA,
    payload: actionsData
  };
}

export function enrichActionsDataAction(actionsData: any) {
  return {
    type: ActionTypes.ACTIONS_PAGE.ENRICH_ACTIONS_PAGE_DATA,
    payload: actionsData
  };
}

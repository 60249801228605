import {
  IEnrichAvailableBookstoresAction,
  IFetchAvailableBookstoresByNavIdAction,
  ISetAvailableBookstoresAction,
  IValidateAvailableBookstoresAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { IBookstore } from '../../../entities/bookstore/types';

export const fetchAvailableBookstoresByProductNavIdAction = (
  navId: string
): IFetchAvailableBookstoresByNavIdAction => ({
  type: ActionTypes.PRODUCT_PAGE.FETCH_AVAILABLE_BOOKSTORES,
  payload: {
    navId
  }
});

export const validateAvailableBookstoresAction = (
  bookstores: IBookstore[]
): IValidateAvailableBookstoresAction => ({
  type: ActionTypes.PRODUCT_PAGE.VALIDATE_AVAILABLE_BOOKSTORES,
  payload: {
    bookstores
  }
});

export const enrichAvailableBookstoresAction = (
  bookstores: IBookstore[]
): IEnrichAvailableBookstoresAction => ({
  type: ActionTypes.PRODUCT_PAGE.ENRICH_AVAILABLE_BOOKSTORES,
  payload: {
    bookstores
  }
});

export const setAvailableBookstoresAction = (
  bookstores: any[]
): ISetAvailableBookstoresAction => ({
  type: ActionTypes.PRODUCT_PAGE.SET_AVAILABLE_BOOKSTORES,
  payload: {
    bookstores
  }
});

import Notification from '../../entities/notification/Notification';
import { IFactory } from '../types';
import { ErrorMessages } from '../../constants/ErrorMessages';
import NotificationTypes from '../../constants/NotificationTypes';

export default class NotificationFactory {
  static create(message: string, type: string) {
    return new Notification(
      new Date().valueOf(),
      type,
      message,
      this.getCssClassByType(type)
    );
  }

  public static getCssClassByType(type: string) {
    switch (type) {
      case NotificationTypes.INFO:
        return 'alert-info';
      case NotificationTypes.WARNING:
        return 'alert-warning';
      case NotificationTypes.ERROR:
      case NotificationTypes.CRITICAL:
      case NotificationTypes.DELAY_NOTIFICATION_DISMISS:
        return 'alert-danger';
    }

    return 'alert-info';
  }

  public static createFromErrorResponse(userMessage: string) {
    const message = userMessage ? userMessage : ErrorMessages.DEFAULT;

    return new Notification(
      new Date().valueOf(),
      'critical',
      message,
      this.getCssClassByType('critical')
    );
  }
}

import {
  IConfirmNewsLetterAction,
  ISetNewsLetterConfirmationMessageAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const confirmNewsLetter = (
  date: Date,
  token: string
): IConfirmNewsLetterAction => ({
  type: ActionTypes.NEWSLETTER.CONFIRM_NEWSLETTER,
  payload: {
    date,
    token
  }
});

export const setNewsLetterConfirmationMessageAction = (
  message: string
): ISetNewsLetterConfirmationMessageAction => ({
  type: ActionTypes.NEWSLETTER.SET_NEWSLETTER_CONFIRMATION_MESSAGE,
  payload: {
    message
  }
});

import { IFactory } from '../../types';
import { IThreePartCarouselItem } from '../../../entities/section/types';
import ThreePartCarousels from '../../../entities/section/three-part-carousels/ThreePartCarousels';
import { replaceDotWithUrl } from '../../../utilites/product';
import ProductFactory from '../../product';

const ThreePartCarouselsFactory: IFactory = class ThreePartCarouselsFactory {
  static create(threePartCarousels: IThreePartCarouselItem) {
    return new ThreePartCarousels(
      threePartCarousels.description,
      replaceDotWithUrl(threePartCarousels.img),
      ProductFactory.create(threePartCarousels.product),
      threePartCarousels.prominentText,
      threePartCarousels.signature
    );
  }
};

export default ThreePartCarouselsFactory;

import ActionTypes from '../../../constants/ActionTypes';
import { IPublisherPageDataState, ISetPublisherPageDataAction } from './types';

const initialState: IPublisherPageDataState = {
  publisher: {
    _id: '',
    publisherName: '',
    status: false
  },
  recordsTotal: 0,
  products: []
};
const publisherPageDataReducer = (
  state = initialState,
  action: ISetPublisherPageDataAction
): IPublisherPageDataState => {
  switch (action.type) {
    case ActionTypes.PUBLISHER_PAGE.SET_PUBLISHER_PAGE_DATA:
      return {
        ...state,
        publisher: action.payload.publisher,
        recordsTotal: action.payload.recordsTotal,
        products: action.payload.products
      };
  }
  return state;
};

export default publisherPageDataReducer;

import { IProduct } from '../product/types';
import {
  IRegularDiscountPriceReport,
  IActionPriceReport,
  IQuantityDiscountPriceReport
} from '../../app/store/cart/types';

export default class OrderItemPrice {
  product: IProduct;

  fullPrice: Number | string;

  quantity: number;

  finalPrice: Number | string;

  totalProductPriceByQuantity: string;

  quantityDiscountPriceReport?: IQuantityDiscountPriceReport | null;

  actionPriceReport?: IActionPriceReport | null;

  regularDiscountPriceReport?: IRegularDiscountPriceReport | null;

  constructor(
    product: IProduct,
    fullPrice: Number | string,
    quantity: number,
    finalPrice: Number | string,
    totalProductPriceByQuantity: string,
    quantityDiscountPriceReport?: IQuantityDiscountPriceReport | null,
    actionPriceReport?: IActionPriceReport | null,
    regularDiscountPriceReport?: IRegularDiscountPriceReport | null
  ) {
    this.product = product;
    this.fullPrice = fullPrice;
    this.quantityDiscountPriceReport = quantityDiscountPriceReport;
    this.actionPriceReport = actionPriceReport;
    this.regularDiscountPriceReport = regularDiscountPriceReport;
    this.quantity = quantity;
    this.finalPrice = finalPrice;
    this.totalProductPriceByQuantity = totalProductPriceByQuantity;
  }
}

export const bookmakerSchema: any = {
  type: 'object',
  properties: {
    bookmakerPage: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        img: { type: 'string' },
        prominentText: { type: 'string' },
        regularText: { type: 'string' },
        bodyText: { type: 'string' },
        publishedAt: { type: 'string' },
        issueNumber: { type: 'number' },
        no: { type: 'number' }
      },
      required: ['_id', 'img', 'prominentText', 'regularText', 'publishedAt']
    },
    bookmakerItems: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          _id: { type: 'string' },
          img: { type: 'string' },
          issueNumber: { type: 'number' },
          issuuUrl: { type: 'string' },
          no: { type: 'number' },
          publishedAt: { type: 'string' }
        },
        required: ['_id', 'img']
      }
    }
  },
  required: ['bookmakerPage', 'bookmakerItems']
};

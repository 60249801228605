import ActionTypes from '../../../constants/ActionTypes';
import {
  IActionsListState,
  IFetchActionsListPageDataAction,
  IValidateActionsListPageDataAction,
  IEnrichActionsListPageDataAction,
  ISetActionsListPageDataAction
} from './types';

export function fetchActionsListDataAction(
  actionId?: string
): IFetchActionsListPageDataAction {
  if (actionId) {
    return {
      type: ActionTypes.ACTIONS_LIST_PAGE.FETCH_ACTIONS_LIST_PAGE_DATA,
      payload: actionId
    };
  }
}

export function setActionsListPageDataAction(
  actionsData: IActionsListState
): ISetActionsListPageDataAction {
  return {
    type: ActionTypes.ACTIONS_LIST_PAGE.SET_ACTIONS_LIST_PAGE_DATA,
    payload: actionsData
  };
}

export function validateActionsListPageDataAction(
  actionsData: IActionsListState
): IValidateActionsListPageDataAction {
  return {
    type: ActionTypes.ACTIONS_LIST_PAGE.VALIDATE_ACTIONS_LIST_PAGE_DATA,
    payload: actionsData
  };
}

export function enrichActionsListPageDataAction(
  actionsData: any
): IEnrichActionsListPageDataAction {
  return {
    type: ActionTypes.ACTIONS_LIST_PAGE.ENRICH_ACTIONS_LIST_PAGE_DATA,
    payload: actionsData
  };
}

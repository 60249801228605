export const simpleCarouselsSchema: any = {
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        title: { type: 'string' },
        viewAllUrl: { type: ['string', 'null'] },
        isTopList: { type: 'boolean' },
        type: { type: 'string' },
        products: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              _id: { type: 'string' },
              publisher: { type: ['string', 'null'] },
              authors: {
                type: ['array', 'string']
              },
              images: {
                type: 'object',
                properties: {
                  s: { type: 'string' },
                  m: { type: 'string' },
                  l: { type: 'string' },
                  xl: { type: 'string' },
                  xxl: { type: 'string' },
                  fb: { type: 'string' }
                },
                required: ['s', 'm', 'l', 'xl', 'xxl', 'fb']
              },
              priceList: {
                type: 'object',
                properties: {
                  fullPrice: { type: ['string', 'number'] },
                  // discount: { type: ['string', 'number'] },
                  // quantityDiscount: { type: ['string', 'number'] },
                  // action: { type: ['string', 'number'] },
                  eBookPrice: { type: ['string', 'number'] }
                }
              },
              status: { type: 'boolean' },
              isAvailable: { type: 'boolean' },
              title: { type: 'string' },
              category: { type: 'string' }
            },
            required: [
              '_id',
              'status',
              'isAvailable',
              'title',
              'category',
              'images',
              'priceList'
            ]
          }
        }
      },
      required: ['_id', 'title', 'products']
    }
  },
  required: ['type', 'content']
};

import { IFactory } from '../types';
import {
  IVisaPremiumBenefitPayload,
  IVisaPremiumBenefitPayloadCart
} from '../../entities/cart/types';
import VisaPremiumBenefitPayload from '../../entities/benefits/VisaPremiumBenefitPayload';
import Config from '../../config';
import VisaPremiumBenefitPayloadCartFactory from './VisaPremiumBenefitPayloadCartFactory';
const VisaPremiumBenefitPayloadFactory: IFactory = class VisaPremiumBenefitPayloadFactory {
  static create(visaPremiumBenefitPayload: IVisaPremiumBenefitPayload) {
    const dateNow = new Date();
    const benefitCarts = visaPremiumBenefitPayload.benefitCarts
      .filter((benefitCart: IVisaPremiumBenefitPayloadCart) => {
        return (
          benefitCart.cartStatus &&
          dateNow >= new Date(benefitCart.startAt) &&
          dateNow <= new Date(benefitCart.endAt)
        );
      })
      .map((benefitCart: IVisaPremiumBenefitPayloadCart) =>
        VisaPremiumBenefitPayloadCartFactory.create(benefitCart)
      );
    return new VisaPremiumBenefitPayload(
      `${Config.api.mediaUrl}${visaPremiumBenefitPayload.bannerUrl}`,
      visaPremiumBenefitPayload.benefitAmountLimit,
      visaPremiumBenefitPayload.benefitAvailableContent,
      benefitCarts,
      visaPremiumBenefitPayload.benefitUnavailableContent,
      visaPremiumBenefitPayload.benefitSubtext
    );
  }
};

export default VisaPremiumBenefitPayloadFactory;

import { ICart } from '../entities/cart/types';
import { ICartOrderItem, IGtmQuantityChangeData } from '../app/store/cart/types';
import { GtmQuantityChangeProductData } from '../app/store/gtm/types';

export const getDataLayerDataBasedOnCartChange = (
  oldCart: ICart,
  newCart: ICart,
  gtmQuantityChangeData?: IGtmQuantityChangeData,
): any => {
  let addedProduct: GtmQuantityChangeProductData | null = null;
  let removedProduct: GtmQuantityChangeProductData | null = null;

  if (!newCart.orderItemsPriceReport) {
    // console.log('There is no products in new order. Check potential delete.');
  } else {
    // console.log('There are products in new order. Check if old exists.');
    if (oldCart.orderItemsPriceReport) {
      // console.log('Old order items exists. Check it all...');
      // For New Cart
      newCart.orderItemsPriceReport.forEach((oneOrderItemsPriceReport) => {
        const product = oneOrderItemsPriceReport.product;
        const resultInOldOrder = oldCart.orderItemsPriceReport.find((oneOldOrderItemsPriceReport) => {
          return oneOldOrderItemsPriceReport.product.oldProductId === product.oldProductId;
        });
        if (resultInOldOrder) {
          // console.log(`Product ${product.title} already exists in order. Nothing happen.`);
        } else {
          // console.log(`Product ${product.title} is newly added to the order. Append it to the addedProducts array.`);
          addedProduct = {
            product,
            quantity: 1
          };
        }
      });

      // For Old Cart
      oldCart.orderItemsPriceReport.forEach((oneOrderItemsPriceReport) => {
        const product = oneOrderItemsPriceReport.product;
        const resultInNewOrder = newCart.orderItemsPriceReport.find((oneNewOrderItemsPriceReport) => {
          return oneNewOrderItemsPriceReport.product.oldProductId === product.oldProductId;
        });
        if (resultInNewOrder) {
          // console.log(`Product ${product.title} already exists in new order. Nothing happen.`);
        } else {
          // console.log(`Product ${product.title} is just deleted from the order. Append it to the removedProducts array.`);
          removedProduct = {
            product,
            quantity: oneOrderItemsPriceReport.quantity
          };
        }
      });
    } else {
      // console.log('There is no old order items. Just add new ones.');
    }
  }

  if (gtmQuantityChangeData && gtmQuantityChangeData.productId) {
    // console.log('CHECK ITEMS FROM gtmQuantityChangeData');
    const productInNewOrder = newCart.orderItemsPriceReport.find((oneOrderItemsPriceReport) => {
      return oneOrderItemsPriceReport.product.id === gtmQuantityChangeData.productId;
    });

    if (productInNewOrder && productInNewOrder.product && gtmQuantityChangeData.quantity && gtmQuantityChangeData.direction === 1) {
      // console.log('Product added based on gtmQuantityChangeData');
      addedProduct = {
        product: productInNewOrder.product,
        quantity: gtmQuantityChangeData.quantity,
      };
    }

    if (productInNewOrder && productInNewOrder.product && gtmQuantityChangeData.quantity && gtmQuantityChangeData.direction === -1) {
      // console.log('Product removed based on gtmQuantityChangeData');
      // console.log(gtmQuantityChangeData);
      removedProduct = {
        product: productInNewOrder.product,
        quantity: gtmQuantityChangeData.quantity
      };
    }

  }

  // console.log('oldOrder', oldCart);
  // console.log('newOrder', newCart);
  // console.log('addedProduct', addedProduct);
  // console.log('removedProduct', removedProduct);

  return {
    addedProduct,
    removedProduct
  };
};


export const getItemDataForBeginCheckoutEvent = (cartOrderItem: ICartOrderItem) => {
  return {
    item_id: cartOrderItem.product.oldProductId,
    item_name: cartOrderItem.product.title,
    item_brand: cartOrderItem.product.publisher,
    item_category: cartOrderItem.product.category,
    price: cartOrderItem.finalPrice,
    quantity: cartOrderItem.quantity,
  };
};
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { AppState } from '../../../app/store/rootReducers';
import React, { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import Loader from '../../components/loader';
import { Helmet } from 'react-helmet-async';
import { confirmNewsletterUnsubscribeAction } from '../../../app/store/newsletter-unsubscribe/actions';
import Footer from '../../layout/footer';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const ConfirmNewsletterUnsubscribeContainerPage: FC<Props> = ({
  confirmNewsletterUnsubscribeAction,
  ui,
  newsletterUnsubscribeData
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const date = searchParams.get('date');
  const token = searchParams.get('token');

  useEffect(() => {
    if (date && token) {
      confirmNewsletterUnsubscribeAction(new Date(date), token);
    }
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <title> Potvrda prijave na newsletter | Delfi knjižare </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <div className="container mt-5">
        {!date || !token ? (
          <div className="row justify-content-center text-center">
            <div className="col-md-12">
              <h4>Nije validan token ili datum</h4>
            </div>
          </div>
        ) : ui.loaders.includes(MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE) ? (
          <Loader />
        ) : (
          <div className="row justify-content-center text-center">
            <div className="col-md-12">
              <h4>{newsletterUnsubscribeData.confirmUnsubscribeMessage}</h4>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ui: state.ui,
  newsletterUnsubscribeData: state.newsletterUnsubscribeData
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      confirmNewsletterUnsubscribeAction
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmNewsletterUnsubscribeContainerPage);

import { IFactory } from '../types';
import { IAuthor } from '../../entities/author/types';
import Author from '../../entities/author/Author';
import Config from '../../config';

const AuthorFactory: IFactory = class AuthorFactory {
  private static addMediaUrlToImage(imageSrc: string | null | undefined) {
    return imageSrc ? `${Config.api.mediaUrl}${imageSrc}` : '';
  }

  static create(author: IAuthor) {
    return new Author(
      author._id,
      author.oldId,
      author.authorName,
      author.status,
      author.authorRefDetails,
      this.addMediaUrlToImage(author.authorImg),
      author.description,
      author.authorType,
      author.metaTitle || '',
      author.metaDescription || '',
      author.authorSrRef,
      author.authorEnRef,
      author.authorRuRef,
      author.authorHuRef,
      author.defaultLanguage
    );
  }
};

export default AuthorFactory;

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import {
  getFromCacheByKey,
  setInCacheByKey
} from '../../../utilites/memoryCache';

const cacheMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.SET_API_RESPONSE_IN_CACHE)) {
      const { cacheKey, data, time } = action.payload;
      if (!getFromCacheByKey(action.payload.cacheKey)) {
        setInCacheByKey(cacheKey, data, time);
      }
    }
  };

export default cacheMiddleware;

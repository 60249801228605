import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichOrderAction,
  ISetOrderAction,
  IValidateOrderAction
} from './types';
import { IOrder } from '../../../entities/order/types';

export const fetchOrderAction = (secret: string) => ({
  type: ActionTypes.ORDER_STATUS.FETCH_ORDER,
  payload: { secret }
});

export const validateOrderAction = (order: IOrder): IValidateOrderAction => ({
  type: ActionTypes.ORDER_STATUS.VALIDATE_ORDER,
  payload: { order }
});

export const enrichOrderAction = (order: IOrder): IEnrichOrderAction => ({
  type: ActionTypes.ORDER_STATUS.ENRICH_ORDER,
  payload: { order }
});

export const setOrderAction = (order: IOrder): ISetOrderAction => ({
  type: ActionTypes.ORDER_STATUS.SET_ORDER,
  payload: { order }
});

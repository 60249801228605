import { ITwinBanner, ITwinBannersContent } from '../types';

export default class TwinBannersContent implements ITwinBannersContent {
  _id: string;

  title: string;

  banner1: ITwinBanner;

  banner2: ITwinBanner;

  startAt: string | null;

  endAt: string | null;

  constructor(
    _id: string,
    title: string,
    banner1: ITwinBanner,
    banner2: ITwinBanner,
    startAt: string | null,
    endAt: string | null
  ) {
    this._id = _id;
    this.title = title;
    this.banner1 = banner1;
    this.banner2 = banner2;
    this.startAt = startAt;
    this.endAt = endAt;
  }
}

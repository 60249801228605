import { IOrderItem } from './types';
import { IProduct } from '../product/types';

export default class OrderItem implements IOrderItem {
  id: number;

  order_id: number;

  product: IProduct;

  final_price?: number;

  quantity?: number;

  constructor(id: number, order_id: number, product: IProduct, final_price: number | undefined, quantity: number | undefined) {
    this.id = id;
    this.order_id = order_id;
    this.product = product;
    this.final_price = final_price;
    this.quantity = quantity;
  }
}

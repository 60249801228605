export const actionSchema: any = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    title: { type: 'string' },
    type: { type: 'string' },
    description: { type: 'string' },
    actionProductSelector: {
      type: 'object',
      properties: {
        includes: {
          type: 'object',
          properties: {
            authors: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            articles: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            categories: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            genres: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            publishers: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  publisher: { type: 'string' },
                  publisherName: { type: 'string' },
                  includedCategories: {
                    type: 'array',
                    items: {
                      type: 'string'
                    }
                  }
                }
              }
            }
          }
        },
        excludes: {
          type: 'object',
          properties: {
            authors: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            articles: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            categories: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            genres: {
              type: 'array',
              items: {
                type: 'string'
              }
            },
            publishers: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  publisher: { type: 'string' },
                  publisherName: { type: 'string' },
                  includedCategories: {
                    type: 'array',
                    items: {
                      type: 'string'
                    }
                  }
                }
              }
            }
          }
        }
      },
      required: ['includes', 'excludes']
    },
    startAt: { type: 'string' },
    endAt: { type: 'string' }
  },
  required: ['_id', 'title', 'type', 'description']
};

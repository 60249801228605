import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { goToPreviousStepAction, setCheckoutDataAction } from './actions';
import { TransitionSteps } from '../../../constants/TransitionSteps';
import { ICheckoutState } from './types';
import { ICartState } from '../cart/types';
import {
  changeCartCountryAndZipCodeAction,
  fetchCartItemsFromApiAction,
  removeAllItemsFromCartAction,
  resetCartItemsAction
} from '../cart/actions';
import { Checkout } from '../../../constants/Checkout';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { createNotification } from '../notification/actions';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import NotificationTypes from '../../../constants/NotificationTypes';
import { formatPhoneNumber } from '../../../utilites';
import { sendGtmEventBeginCheckoutAction } from '../gtm/actions';
import { get } from 'lodash';

export const submitForNextStepMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.CHECKOUT.SUBMIT_ORDER_DATA_STEP) {
      const checkoutData: ICheckoutState = store.getState().checkoutData;
      const cart: ICartState = store.getState().cart;
      switch (action.payload.stepName) {
        case TransitionSteps.INITIAL_STATE:
          checkoutData.checkoutData.steps.current = {
            name: TransitionSteps.PERSONAL_DATA_STATE
          };

          checkoutData.checkoutData.steps.previous.push(
            TransitionSteps.INITIAL_STATE
          );

          checkoutData.checkoutData.steps.next.push(
            TransitionSteps.PERSONAL_DATA_STATE
          );

          checkoutData.checkoutData.orderData.selectedProducts =
            cart.order.orderItemsPriceReport.map((item: any) => {
              return {
                id: item.product.id,
                quantity: item.quantity,
                price: item.finalPrice
              };
            });

          next(sendGtmEventBeginCheckoutAction(cart));
          next(setCheckoutDataAction(checkoutData));
          break;

        case TransitionSteps.PERSONAL_DATA_STATE:
          checkoutData.checkoutData.steps.current = {
            name: TransitionSteps.PAYMENT_OPTION_STATE
          };

          checkoutData.checkoutData.steps.previous.push(
            TransitionSteps.PERSONAL_DATA_STATE
          );

          checkoutData.checkoutData.steps.next.push(
            TransitionSteps.PAYMENT_OPTION_STATE
          );

          checkoutData.checkoutData.orderData.customer = action.payload.data;

          const homeAddress = action.payload.data.addresses.find(
            (address: any) => address.type === Checkout.HOME_ADDRESS
          );

          const shippingAddress = action.payload.data.addresses.find(
            (address: any) => address.type === Checkout.SHIPPING_ADDRESS
          );

          let countryOldId = homeAddress.country;
          let pakAndCity = homeAddress.pakAndCity;

          if (shippingAddress) {
            countryOldId = shippingAddress.country;
            pakAndCity = shippingAddress.pakAndCity;
          }
          store.dispatch(
            changeCartCountryAndZipCodeAction(countryOldId, pakAndCity)
          );
          store.dispatch(
            fetchCartItemsFromApiAction(
              {
                options: {
                  validateVisaPremiumBenefit: true,
                  validateVisaMassBenefit: true,
                  validateYandex: true
                }
              },
              undefined,
              undefined,
              true
            )
          );
          next(setCheckoutDataAction(checkoutData));
          break;
        case TransitionSteps.PAYMENT_OPTION_STATE:
          checkoutData.checkoutData.steps.current = {
            name: TransitionSteps.CONFIRM_ORDER_STATE
          };

          checkoutData.checkoutData.steps.previous.push(
            TransitionSteps.PAYMENT_OPTION_STATE
          );

          checkoutData.checkoutData.steps.next.push(
            TransitionSteps.CONFIRM_ORDER_STATE
          );

          const selectedBenefits = action.payload.data.benefits;

          if (
            selectedBenefits &&
            selectedBenefits[Checkout.YANDEX_DELIVERY_SERVICE.DELIVERY_TYPE]
          ) {
            if (
              cart.order.benefitsReport[
                Checkout.YANDEX_DELIVERY_SERVICE.DELIVERY_TYPE
              ] &&
              !cart.order.benefitsReport[
                Checkout.YANDEX_DELIVERY_SERVICE.DELIVERY_TYPE
              ].forToday
            ) {
              selectedBenefits[Checkout.YANDEX_DELIVERY_SERVICE.DELIVERY_TYPE] =
                Checkout.YANDEX_DELIVERY_SERVICE.YANDEX_TOMORROW;
            }
          }

          checkoutData.checkoutData.orderData.benefits = selectedBenefits;

          delete action.payload.data.benefits;

          checkoutData.checkoutData.orderData.paymentDetails =
            action.payload.data;

          next(setCheckoutDataAction(checkoutData));

          const homeAddr =
            checkoutData.checkoutData.orderData.customer.addresses.find(
              (address: any) => address.type === Checkout.HOME_ADDRESS
            );

          store.dispatch(
            fetchCartItemsFromApiAction(
              {
                options: {
                  validateVisaPremiumBenefit: true,
                  validateVisaMassBenefit: true,
                  validateYandex: true
                }
              },
              checkoutData.checkoutData.orderData.paymentDetails.type,
              undefined,
              undefined,
              undefined,
              undefined,
              get(homeAddr, 'recipientEmail', undefined)
            )
          );

          break;

        case TransitionSteps.CONFIRM_ORDER_STATE:
          const user = store.getState().user;

          checkoutData.checkoutData.steps.current = {
            name: TransitionSteps.CONFIRM_ORDER_STATE
          };

          checkoutData.checkoutData.steps.next.push(
            TransitionSteps.CONFIRM_ORDER_STATE
          );

          const updatedAddresses =
            checkoutData.checkoutData.orderData.customer.addresses.map(
              (address: any) => {
                if (address.type === Checkout.HOME_ADDRESS) {
                  return {
                    ...address,
                    recipientPhone: `${
                      !user.member.user ? '+' + address.countryCallingCode : ''
                    }${formatPhoneNumber(address.recipientPhone, '')}`
                  };
                }

                if (address.type === Checkout.SHIPPING_ADDRESS) {
                  return {
                    ...address,
                    recipientPhone: `${
                      '+' + address.shippingCountryCallingCode
                    }${formatPhoneNumber(address.recipientPhone, '')}`
                  };
                }
              }
            );

          const sAddress = updatedAddresses.find(
            (address: any) => address.type === Checkout.SHIPPING_ADDRESS
          );

          const hAddress = updatedAddresses.find(
            (address: any) => address.type === Checkout.HOME_ADDRESS
          );

          if (!sAddress && hAddress) {
            const updatedHomeAddress = {
              ...hAddress,
              type: Checkout.SHIPPING_ADDRESS
            };
            updatedAddresses.push(updatedHomeAddress);
          }

          const requestCreateOrder = {
            selectedProducts:
              checkoutData.checkoutData.orderData.selectedProducts,
            customer: checkoutData.checkoutData.orderData.customer.customer,
            addresses: updatedAddresses,
            paymentType: checkoutData.checkoutData.orderData.paymentDetails,
            totalPrice: cart.order.totalAmount,
            recaptcha: action.payload.data.recaptcha,
            benefits: checkoutData.checkoutData.orderData.benefits
              ? checkoutData.checkoutData.orderData.benefits
              : undefined
          };

          next(
            apiRequest(
              null,
              'POST',
              ApiUrls.CREATE_ORDER,
              null,
              requestCreateOrder,
              MiddlewareEntities.CREATE_ORDER,
              {}
            )
          );
          next(setLoader(true, MiddlewareEntities.CREATE_ORDER));
          break;
      }
    }

    if (
      action.type ===
      `${MiddlewareEntities.CREATE_ORDER} ${ActionTypes.API_SUCCESS}`
    ) {
      next(setLoader(false, MiddlewareEntities.CREATE_ORDER));

      if (action.payload.data.orderStatus === 'readyForOnlinePayment') {
        return window.location.replace(
          `/placanje-s1?id=${action.payload.data.orderSecret}`
        );
      }

      window.location.replace(
        `/status-porudzbine/${action.payload.data.orderSecret}`
      );

      next(removeAllItemsFromCartAction(true));
    }

    if (
      action.type ===
      `${MiddlewareEntities.CREATE_ORDER} ${ActionTypes.API_ERROR}`
    ) {
      next(setLoader(false, MiddlewareEntities.CREATE_ORDER));
      if (
        action.payload.data &&
        action.payload.data.data &&
        action.payload.data.data.metadata &&
        action.payload.data.data.metadata.meta
      ) {
        if (
          action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.CHANGE_PURCHASE_PRICE ||
          action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.CART_ITEM_IS_NOT_VALID
        ) {
          next(
            createNotification(
              MiddlewareEntities.CREATE_ORDER,
              NotificationTypes.WARNING,
              undefined,
              action.payload.data.data
            )
          );

          // return store.dispatch(
          //   fetchCartItemsFromApiAction(
          //     {
          //       options: {
          //         validateVisaPremiumBenefit: true,
          //         validateVisaMassBenefit: true,
          //         validateYandex: true
          //       }
          //     },
          //     undefined,
          //     undefined,
          //     true
          //   )
          // );

          return store.dispatch(
            goToPreviousStepAction(TransitionSteps.INITIAL_STATE)
          );
        }

        next(
          createNotification(
            MiddlewareEntities.CREATE_ORDER,
            NotificationTypes.ERROR,
            undefined,
            action.payload.data.data
          )
        );

        if (
          action.payload.data.data.metadata.meta.errorCode ===
          ErrorCodes.EMPTY_CART_ITEMS
        ) {
          return store.dispatch(resetCartItemsAction(true));
        }

        if (
          action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.ADMINISTRATIVE_BAN_SELECTED_COMPANY_NOT_EXISTS ||
          action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.ADMINISTRATIVE_BAN_NUMBER_OF_INSTALLMENTS_NOT_AVAILABLE ||
          action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.ADMINISTRATIVE_BAN_NOT_AVAILABLE
        ) {
          return store.dispatch(
            goToPreviousStepAction(TransitionSteps.PAYMENT_OPTION_STATE)
          );
        }
      }
    }
  };

const transitionMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.CHECKOUT.GO_TO_NEXT_STEP:
        const data: ICheckoutState = store.getState().checkoutData;
        switch (action.payload.stepName) {
          case TransitionSteps.PERSONAL_DATA_STATE:
            data.checkoutData.steps.current = {
              name: TransitionSteps.PERSONAL_DATA_STATE
            };
            next(setCheckoutDataAction(data));
            break;
          case TransitionSteps.PAYMENT_OPTION_STATE:
            data.checkoutData.steps.current = {
              name: TransitionSteps.PAYMENT_OPTION_STATE
            };
            next(setCheckoutDataAction(data));
            break;
          case TransitionSteps.CONFIRM_ORDER_STATE:
            data.checkoutData.steps.current = {
              name: TransitionSteps.CONFIRM_ORDER_STATE
            };
            next(setCheckoutDataAction(data));
            break;
        }
        break;
      case ActionTypes.CHECKOUT.GO_TO_PREVIOUS_STEP:
        const checkoutData: ICheckoutState = store.getState().checkoutData;
        switch (action.payload.stepName) {
          case TransitionSteps.INITIAL_STATE:
            store.dispatch(
              fetchCartItemsFromApiAction({
                options: {
                  validateVisaPremiumBenefit: true,
                  validateVisaMassBenefit: true
                }
              })
            );
            checkoutData.checkoutData.steps.current = {
              name: TransitionSteps.INITIAL_STATE
            };
            checkoutData.checkoutData.steps.previous = [];
            next(setCheckoutDataAction(checkoutData));
            break;
          case TransitionSteps.PERSONAL_DATA_STATE:
            checkoutData.checkoutData.steps.current = {
              name: TransitionSteps.PERSONAL_DATA_STATE
            };
            checkoutData.checkoutData.steps.previous = [
              TransitionSteps.INITIAL_STATE
            ];
            next(setCheckoutDataAction(checkoutData));
            break;
          case TransitionSteps.PAYMENT_OPTION_STATE:
            store.dispatch(
              fetchCartItemsFromApiAction(
                {
                  options: {
                    validateVisaPremiumBenefit: true,
                    validateVisaMassBenefit: true,
                    validateYandex: true
                  }
                },
                undefined,
                undefined,
                true
              )
            );
            checkoutData.checkoutData.steps.current = {
              name: TransitionSteps.PAYMENT_OPTION_STATE
            };
            checkoutData.checkoutData.steps.previous = [
              TransitionSteps.INITIAL_STATE,
              TransitionSteps.PERSONAL_DATA_STATE
            ];
            next(setCheckoutDataAction(checkoutData));
            break;
          case TransitionSteps.CONFIRM_ORDER_STATE:
            checkoutData.checkoutData.steps.current = {
              name: TransitionSteps.CONFIRM_ORDER_STATE
            };
            checkoutData.checkoutData.steps.previous = [
              TransitionSteps.INITIAL_STATE,
              TransitionSteps.PERSONAL_DATA_STATE,
              TransitionSteps.PAYMENT_OPTION_STATE
            ];
            next(setCheckoutDataAction(checkoutData));
            break;
        }
    }
  };

const resetCheckoutDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.CHECKOUT.RESET_CHECKOUT_DATA) {
      next(
        setCheckoutDataAction({
          checkoutData: {
            steps: {
              current: {
                name: TransitionSteps.INITIAL_STATE
              },
              next: [],
              previous: []
            },
            orderData: {}
          }
        })
      );
    }
  };

export default [
  submitForNextStepMiddleware,
  transitionMiddleware,
  resetCheckoutDataMiddleware
];

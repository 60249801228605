import React, { FC, useEffect } from 'react';
import { AppState } from '../../../app/store/rootReducers';
import { Dispatch, bindActionCreators, AnyAction } from 'redux';
import { connect } from 'react-redux';
import {
  removeAllNotifications,
  removeNotification
} from '../../../app/store/notification/actions';
import './scss/style.scss';
import NotificationTypes from '../../../constants/NotificationTypes';
import { useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const NotificationComponent: FC<Props> = ({
  notifications,
  removeNotification,
  removeAllNotifications
}: Props) => {
  const { pathname } = useLocation();
  const handleOnClick = (id: string | number) => {
    removeNotification(id);
  };

  const isTablet = useMediaQuery({ query: '(max-width: 768px)' });

  useEffect(() => {
    if (notifications.length > 0) {
      removeAllNotifications();
    }
  }, [pathname]);

  const renderNotification = () => {
    window.scrollTo(0, 0);
    return notifications.map((notification) => {
      return (
        <div
          key={notification.id}
          className={`alert ${notification.cssClass} alert-dismissible fade show`}
          role="alert"
          style={{ zIndex: isTablet ? '1060' : '10' }}
        >
          <strong>{notification.message}</strong>
          <button
            type="button"
            className="btn-close shadow-none border-0"
            data-bs-dismiss="alert"
            aria-label="Close"
            onClick={() => {
              handleOnClick(notification.id);
            }}
          ></button>
          {/*{notification.type ===*/}
          {/*NotificationTypes.DELAY_NOTIFICATION_DISMISS ? (*/}
          {/*  <div className="delay-progress-bar mt-2"></div>*/}
          {/*) : null}*/}
          <div
            className={`delay-progress-bar ${
              notification.type === NotificationTypes.SUCCESS
                ? 'delay-progress-bar-bg-success-color'
                : notification.type === NotificationTypes.WARNING
                ? 'delay-progress-bar-bg-warning-color'
                : 'delay-progress-bar-bg-error-color'
            } mt-2`}
          ></div>
        </div>
      );
    });
  };

  return notifications.length ? (
    <div className="row justify-content-center text-center mt-5 gx-0">
      <div className="col-md-10">{renderNotification()}</div>
    </div>
  ) : null;
};

const mapStateToProps = (state: AppState) => {
  return {
    notifications: state.notifications
  };
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      removeNotification,
      removeAllNotifications
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationComponent);

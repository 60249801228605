export const widePromoBannersSchema: any = {
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        buttonLabel: { type: 'string' },
        description: { type: 'string' },
        img: { type: 'string' },
        mobileImg: { type: 'string' },
        title: { type: 'string' },
        url: { type: ['null', 'string'] },
        _id: { type: 'string' }
      },
      required: [
        'buttonLabel',
        'description',
        'img',
        'mobileImg',
        'title',
        '_id'
      ]
    }
  },
  required: ['type', 'content']
};

export const twinBannersSchema: any = {
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        banner1: {
          type: 'object',
          properties: {
            img: { type: 'string' },
            link: { type: 'string' }
          },
          required: ['img', 'link']
        },
        banner2: {
          type: 'object',
          properties: {
            img: { type: 'string' },
            link: { type: 'string' }
          },
          required: ['img', 'link']
        }
      },
      required: ['banner1', 'banner2']
    }
  },
  required: ['type', 'content']
};

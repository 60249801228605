import { IForeignBookState, ISetForeignBookDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IForeignBookState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const foreignBookDataReducer = (
  state = initialState,
  action: ISetForeignBookDataAction
): IForeignBookState => {
  switch (action.type) {
    case ActionTypes.FOREIGN_BOOK.SET_FOREIGN_BOOK_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default foreignBookDataReducer;

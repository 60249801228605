import ActionTypes from '../../../constants/ActionTypes';
import {
  IBoardGamesPageState,
  IEnrichBoardGamesPageDataAction,
  IFetchBoardGamesPageDataAction,
  ISetBoardGamesPageDataAction,
  IValidateBoardGamesPageDataAction
} from './types';

export function fetchBoardGamesPageDataAction(): IFetchBoardGamesPageDataAction {
  return {
    type: ActionTypes.BOARD_GAMES_PAGE.FETCH_BOARD_GAMES_PAGE_DATA
  };
}

export function setBoardGamesPageDataAction(
  boardGamesPageData: IBoardGamesPageState
): ISetBoardGamesPageDataAction {
  return {
    type: ActionTypes.BOARD_GAMES_PAGE.SET_BOARD_GAMES_PAGE_DATA,
    payload: boardGamesPageData
  };
}

export function validateBoardGamesPageDataAction(
  boardGamesPageData: IBoardGamesPageState
): IValidateBoardGamesPageDataAction {
  return {
    type: ActionTypes.BOARD_GAMES_PAGE.VALIDATE_BOARD_GAMES_PAGE_DATA,
    payload: boardGamesPageData
  };
}

export function enrichBoardGamesPageDataAction(
  boardGamesPageData: any
): IEnrichBoardGamesPageDataAction {
  return {
    type: ActionTypes.BOARD_GAMES_PAGE.ENRICH_BOARD_GAMES_PAGE_DATA,
    payload: boardGamesPageData
  };
}

/* eslint-disable @typescript-eslint/brace-style */

import { IVisaPremiumBenefitPayloadCart } from '../cart/types';
import { IProduct } from '../product/types';

export default class VisaPremiumBenefitPayloadCart
  implements IVisaPremiumBenefitPayloadCart
{
  cartId: number;

  cartImageUrl: string;

  cartProducts: IProduct[];

  cartStatus: boolean;

  cartTitle: string;

  startAt: string;

  endAt: string;

  constructor(
    cartId: number,
    cartImageUrl: string,
    cartProducts: IProduct[],
    cartStatus: boolean,
    cartTitle: string,
    startAt: string,
    endAt: string
  ) {
    this.cartId = cartId;
    this.cartImageUrl = cartImageUrl;
    this.cartProducts = cartProducts;
    this.cartStatus = cartStatus;
    this.cartTitle = cartTitle;
    this.startAt = startAt;
    this.endAt = endAt;
  }
}

import React, { forwardRef, ReactElement } from 'react';
import './scss/style.scss';
import delfiPremiumLongImage from '../../../assets/images/delfi-premium-long.svg';
import TopNavbar from '../top-navbar';
import { Link } from 'react-router-dom';
import { textSliderSettings } from '../../../components/sliders/carousel/settings';
import TextSlider from '../../../components/sliders/text-slider';
import { ITextSlider } from '../../../components/sliders/text-slider/types';

import { useMediaQuery } from 'react-responsive';

interface IProps {
  textSliders: ITextSlider[];
  ui: any;
}

const DesktopHeader = forwardRef<any, IProps>(
  ({ textSliders }: IProps, ref): ReactElement => {
    const isLaptop = useMediaQuery({ query: '(max-width: 1600px)' });

    return (
      <header>
        <div className="position-relative">
          {/*<VerticalIcons />*/}
          <div
            className={`row justify-content-center text-center ms-0 me-0 gx-0 align-items-center ${
              isLaptop ? '' : 'p-2'
            } bg-header-slider`}
          >
            <div
              className="col-md-6 text-14 jost-semi-bold"
              style={{ height: '20px' }}
            >
              {Array.isArray(textSliders) && textSliders.length > 0 ? (
                <TextSlider
                  textSliders={textSliders}
                  settings={textSliderSettings}
                />
              ) : null}
            </div>
          </div>
          <div className="row ms-0 me-0 gx-0 align-items-center border-bottom">
            <div
              className={`col-md-6 text-start ${
                isLaptop ? '' : 'p-2'
              } text-11 jost-semi-bold`}
            >
              <TopNavbar />
            </div>
            <div className="col-6 pb-2 pe-2 align-items-center d-flex justify-content-end align-items-center ">
              <Link className="mt-2 text-decoration-none" to="/vesti">
                <span className="me-lg-3 jost-semi-bold text-16 font-color-default">
                  #Delfi kutak
                </span>
              </Link>
              <Link className="mt-2 text-decoration-none" to="/knjizare">
                <span className="me-lg-3 jost-semi-bold text-16 font-color-default">
                  Knjižare
                </span>
              </Link>
              <Link
                className="me-3 cursor-pointer d-flex align-items-center"
                to="/delfi-premium"
              >
                <img
                  width="105"
                  height="22"
                  src={delfiPremiumLongImage}
                  alt="Delfi Premium"
                />
              </Link>
            </div>
          </div>
        </div>
      </header>
    );
  }
);

export default DesktopHeader;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IConfirmationNewsLetterState,
  ISetNewsLetterConfirmationMessageAction
} from './types';

const initialState: IConfirmationNewsLetterState = {
  message: ''
};

const newsLetterConfirmationReducer = (
  state = initialState,
  action: ISetNewsLetterConfirmationMessageAction
): IConfirmationNewsLetterState => {
  switch (action.type) {
    case ActionTypes.NEWSLETTER.SET_NEWSLETTER_CONFIRMATION_MESSAGE:
      return {
        ...state,
        message: action.payload.message
      };
  }

  return state;
};

export default newsLetterConfirmationReducer;

import React, { FC } from 'react';
import FullPrice from './FullPrice';
import { formatPrice } from '../../../utilites/product';

interface IProps {
  fullPrice: Number;
  collectionPrice: Number;
  classes?: string;
}

const CollectionPrice: FC<IProps> = ({
  fullPrice,
  collectionPrice,
  classes
}: IProps) => {
  if (collectionPrice && collectionPrice > 0 && fullPrice > collectionPrice) {
    return (
      <>
        <span className="card-price jost-semi-bold text-16 d-block">
          {formatPrice(collectionPrice, 2)} RSD
        </span>
        <span
          className={`text-14 text-muted text-decoration-line-through font-color-default ${classes}`}
        >
          {formatPrice(fullPrice, 2)} RSD
        </span>
      </>
    );
  }

  return <FullPrice fullPrice={fullPrice} />;
};

export default CollectionPrice;

import { IGenrePageDataState, ISetGenrePageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IGenrePageDataState = {
  genre: {
    _id: '',
    genreName: '',
    status: false,
    oldId: 0
  },
  recordsTotal: 0,
  products: []
};
const genrePageDataReducer = (
  state = initialState,
  action: ISetGenrePageDataAction
): IGenrePageDataState => {
  switch (action.type) {
    case ActionTypes.GENRE_PAGE.SET_GENRE_PAGE_DATA:
      return {
        ...state,
        genre: action.payload.genre,
        recordsTotal: action.payload.recordsTotal,
        products: action.payload.products
      };
  }
  return state;
};

export default genrePageDataReducer;

import React, { FC } from 'react';
import { openWindowPopup } from '../../../../../utilites';
import { Link, useNavigate } from 'react-router-dom';

const BottomBar: FC = () => {
  const navigate = useNavigate();
  return (
    <div className="container horizontal-scrollable mt-3">
      <div className="row text-center align-items-center p-2 text-11 jost-semi-bold">
        <div className="col-auto">
          <a
            className="cursor-pointer font-color-default text-decoration-none"
            onClick={() => openWindowPopup('https://edenbooks.rs/', '_blank')}
          >
            EDEN BOOKS
          </a>
        </div>
        {/*<div className="col-auto">*/}
        {/*  <Link*/}
        {/*    className="cursor-pointer font-color-default text-decoration-none"*/}
        {/*    to="/lista-proizvoda?category=Gift&genre=Vaučeri"*/}
        {/*  >*/}
        {/*    GIFT CARDS*/}
        {/*  </Link>*/}
        {/*</div>*/}
        <div className="col-auto">
          <Link
            className="cursor-pointer font-color-default text-decoration-none"
            to="/ulaznice"
          >
            ULAZNICE
          </Link>
        </div>
        <div className="col-auto">
          <Link
            className="cursor-pointer font-color-default text-decoration-none"
            to="/delfi_magazin"
          >
            BUKMARKER
          </Link>
        </div>
        <div className="col-auto">
          <Link
            className="cursor-pointer font-color-default text-decoration-none"
            to="/delfi-premium"
          >
            DELFI PREMIUM
          </Link>
        </div>
        {/*<div className="col-auto">*/}
        {/*  <a*/}
        {/*    className="cursor-pointer font-color-default text-decoration-none"*/}
        {/*    onClick={() => navigate('/decja-knjiga')}*/}
        {/*  >*/}
        {/*    DEČJA BIBLIOTEKA*/}
        {/*  </a>*/}
        {/*</div>*/}
        {/*<div className="col-auto">*/}
        {/*  <span>RADIO LAGUNA</span>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default BottomBar;

import { IBoardGamesPageState, ISetBoardGamesPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IBoardGamesPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const boardGamesPageDataReducer = (
  state = initialState,
  action: ISetBoardGamesPageDataAction
): IBoardGamesPageState => {
  switch (action.type) {
    case ActionTypes.BOARD_GAMES_PAGE.SET_BOARD_GAMES_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default boardGamesPageDataReducer;

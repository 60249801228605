import { AppState } from '../../../app/store/rootReducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { unsubscribeNewsletterAction } from '../../../app/store/newsletter-unsubscribe/actions';
import { connect } from 'react-redux';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import Headline from '../../components/headline';
import Breadcrumbs from '../../components/breadcrumbs';
import Footer from '../../layout/footer';
import { Field, Form } from 'react-final-form';
import {
  composeValidators,
  email,
  required
} from '../../components/forms/validators';
import Error from '../../components/forms/Error';
import Spinner from '../../components/spinner/Spinner';
import Button from '../../components/button';
import ReCAPTCHA from 'react-google-recaptcha';
import Config from '../../../config';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const UnsubscribeNewsletterContainerPage: FC<Props> = ({
  ui,
  newsletterUnsubscribeData,
  unsubscribeNewsletterAction
}: Props) => {
  const refRecaptcha = React.useRef(null);
  const handleOnSubmit = async (values: any) => {
    //@ts-ignore
    values.recaptcha = await refRecaptcha.current.executeAsync();
    unsubscribeNewsletterAction(values);
  };

  const [unsubscribeNewsLetterMessage, setNewsLetterMessage] = useState(
    newsletterUnsubscribeData && newsletterUnsubscribeData.unsubscribeMessage
      ? newsletterUnsubscribeData.unsubscribeMessage
      : ''
  );

  useEffect(() => {
    setNewsLetterMessage(newsletterUnsubscribeData.unsubscribeMessage);
  }, [newsletterUnsubscribeData]);

  useEffect(() => {
    setNewsLetterMessage('');
  }, [location]);

  return (
    <>
      <Helmet>
        <meta property="og:url" content={window.location.href} />
        <title> Odjava sa newslettera | Delfi knjižare </title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <div className="mt-5 container">
        <div className="row">
          <div className="col-md-12">
            <Breadcrumbs
              items={[
                {
                  name: 'Početna',
                  url: '/'
                },
                {
                  name: 'Odjava sa newslettera',
                  isActive: true
                }
              ]}
            />
          </div>
        </div>
        <>
          <Headline
            title="Za odjavu sa newslettera, unesite  email adresu."
            classes="justify-content-center text-center mt-4"
            border={
              <div className="col-1 border-bottom-blue-5 border-width-5" />
            }
          />
          {unsubscribeNewsLetterMessage ? (
            <div className="row justify-content-center text-center mt-5">
              <div
                className={`col-md-8 p-2 ${
                  newsletterUnsubscribeData.status === HttpStatusCodes.OK
                    ? 'alert-success'
                    : 'alert-danger'
                }`}
              >
                {newsletterUnsubscribeData.unsubscribeMessage}
              </div>
            </div>
          ) : (
            <div className="row justify-content-center text-center mt-5">
              <div className="col-8 col-md-6 col-lg-4 col-xl-3">
                <Form
                  onSubmit={handleOnSubmit}
                  render={({
                    handleSubmit,
                    form,
                    submitting,
                    pristine,
                    values
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row ms-0 me-0">
                        <div className="col-md-12">
                          <Field
                            name="email"
                            type="text"
                            validate={composeValidators(required, email)}
                          >
                            {({ input, meta }) => (
                              <>
                                <input
                                  className="form-control"
                                  placeholder="Unesite email"
                                  {...input}
                                />
                                <Field name="email">
                                  {({
                                    meta: { active, dirty, error, touched }
                                  }) => (
                                    <Error
                                      active={active}
                                      dirty={dirty}
                                      error={error}
                                      touched={touched}
                                    />
                                  )}
                                </Field>
                              </>
                            )}
                          </Field>
                        </div>
                        <div className="col-12 mt-3">
                          {ui.loaders.includes(
                            MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE
                          ) ? (
                            <Spinner color="white" />
                          ) : (
                            <Button
                              classes="jost-extra-bold btn background-orange rounded-0 text-14 w-100 font-color-default shadow-none"
                              type="submit"
                            >
                              POŠALJI
                            </Button>
                          )}
                        </div>
                      </div>
                      <ReCAPTCHA
                        ref={refRecaptcha}
                        sitekey={Config.recaptchaPublicKey}
                        size="invisible"
                      />
                    </form>
                  )}
                ></Form>
              </div>
            </div>
          )}
        </>
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ui: state.ui,
  newsletterUnsubscribeData: state.newsletterUnsubscribeData
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ unsubscribeNewsletterAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeNewsletterContainerPage);

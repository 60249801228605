import { IFactory } from '../types';
import { IBookMakerItem } from '../../app/store/bookmaker-magazine/types';
import BookmakerItem from '../../entities/bookmaker/BookmakerItem';
import { replaceDotWithUrl } from '../../utilites/product';
import { getFullYear, getMonthName } from '../../utilites/date';

const BookmakerItemFactory: IFactory = class BookmakerItemFactory {
  static create(bookmakerItem: IBookMakerItem): BookmakerItem {
    return new BookmakerItem(
      bookmakerItem._id,
      replaceDotWithUrl(bookmakerItem.img),
      bookmakerItem.issueNumber,
      bookmakerItem.issuuUrl,
      bookmakerItem.no,
      bookmakerItem.publishedAt,
      `${getFullYear(bookmakerItem.publishedAt)}. ${getMonthName(
        bookmakerItem.publishedAt,
        'sr'
      )}`
    );
  }
};

export default BookmakerItemFactory;

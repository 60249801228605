import { FC } from 'react';
import { formatPrice } from '../../../utilites/product';
import { useStore } from 'react-redux';

interface IProps {
  fullPrice: Number;
  bestPrice: Number;
  productType: string;
  isOnCart?: boolean;
}

const DiscountPrice: FC<IProps> = ({
  fullPrice,
  bestPrice,
  productType,
  isOnCart
}: IProps) => {
  const store = useStore();
  const state: any = store.getState();
  return (
    <>
      <span className="card-price jost-semi-bold text-16 d-block">
        {formatPrice(bestPrice, 2)} RSD
      </span>
      <span
        className={`text-14 text-muted ${
          state.user.member.user || isOnCart
            ? 'text-decoration-line-through'
            : ''
        } font-color-default`}
      >
        {formatPrice(fullPrice, 2)} RSD
      </span>
    </>
  );
};

export default DiscountPrice;

import { IProduct } from '../../product/types';
import { ISimpleCarouselContent, PriceDisplayTypes } from '../types';

export default class SimpleCarouselContent implements ISimpleCarouselContent {
  _id: string;

  title: string;

  priceDisplayType: PriceDisplayTypes;

  products: IProduct[];

  type: string;

  startAt: string | null;

  endAt: string | null;

  isTopList?: boolean;

  viewAllUrl?: string;

  constructor(
    _id: string,
    title: string,
    priceDisplayType: PriceDisplayTypes,
    products: IProduct[],
    type: string,
    startAt: string | null,
    endAt: string | null,
    isTopList?: boolean,
    viewAllUrl?: string
  ) {
    this._id = _id;
    this.title = title;
    this.priceDisplayType = priceDisplayType;
    this.products = products;
    this.type = type;
    this.startAt = startAt;
    this.endAt = endAt;
    this.isTopList = isTopList;
    this.viewAllUrl = viewAllUrl;
  }
}

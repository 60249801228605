import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { createNotification } from '../notification/actions';
import { setResetPasswordAction } from './actions';
import { isTokenExpired } from '../../../utilites/token';
import NotificationTypes from '../../../constants/NotificationTypes';

export const sendResetPasswordMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.RESET_USER_PASSWORD.SEND_RESET_USER_PASSWORD_REQUEST:
        if (!isTokenExpired(action.payload.sendResetPasswordReq.date)) {
          next(
            apiRequest(
              null,
              'POST',
              ApiUrls.SEND_RESET_USER_PASSWORD,
              null,
              action.payload.sendResetPasswordReq,
              MiddlewareEntities.RESET_USER_PASSWORD,
              {}
            )
          );
          return next(setLoader(true, MiddlewareEntities.RESET_USER_PASSWORD));
        }
        next(
          createNotification(
            MiddlewareEntities.RESET_USER_PASSWORD,
            NotificationTypes.ERROR,
            'Token je istekao!'
          )
        );
        break;
      case `${MiddlewareEntities.RESET_USER_PASSWORD} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.RESET_USER_PASSWORD));
        next(setResetPasswordAction(true));
        next(
          createNotification(
            MiddlewareEntities.RESET_USER_PASSWORD,
            NotificationTypes.SUCCESS,
            'Uspešno ste izmenili lozinku'
          )
        );
        break;
      case `${MiddlewareEntities.RESET_USER_PASSWORD} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.RESET_USER_PASSWORD));
        next(setResetPasswordAction(false));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.RESET_USER_PASSWORD,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export default [sendResetPasswordMiddleware];

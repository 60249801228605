import ActionTypes from '../../../constants/ActionTypes';
import {
  ICheckoutState,
  IGoToNextStepAction,
  IGoToPreviousStepAction
} from './types';

export const setCheckoutDataAction = (data: ICheckoutState) => ({
  type: ActionTypes.CHECKOUT.SET_CHECKOUT_DATA,
  payload: {
    data
  }
});

export const submitDataForNextStepAction = (stepName: string, data: any) => ({
  type: ActionTypes.CHECKOUT.SUBMIT_ORDER_DATA_STEP,
  payload: {
    stepName,
    data
  }
});

export const goToNextStepAction = (stepName: string): IGoToNextStepAction => ({
  type: ActionTypes.CHECKOUT.GO_TO_NEXT_STEP,
  payload: {
    stepName
  }
});

export const goToPreviousStepAction = (
  stepName: string
): IGoToPreviousStepAction => ({
  type: ActionTypes.CHECKOUT.GO_TO_PREVIOUS_STEP,
  payload: {
    stepName
  }
});

export const resetCheckoutData = () => ({
  type: ActionTypes.CHECKOUT.RESET_CHECKOUT_DATA
});

import { IFactory } from '../types';
import { IProduct } from '../../entities/product/types';
import ProductFactory from './index';

const ProductsFactory: IFactory = class ProductsFactory {
  static create(products: IProduct[]) {
    return products.map((product: IProduct) => {
      return ProductFactory.create(product);
    });
  }
};

export default ProductsFactory;

import { ILoaderState, ISetLoaderAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: ILoaderState = {
  loaders: []
};

const uiReducer = (
  store = initialState,
  action: ISetLoaderAction
): ILoaderState => {
  if (action.type.includes(ActionTypes.SET_LOADER)) {
    const newLoaders = [...store.loaders];
    const loaderIndex = store.loaders.findIndex((loader: string) => {
      return loader === action.meta.entity;
    });

    if (action.payload && !newLoaders.includes(action.meta.entity)) {
      newLoaders.push(action.meta.entity);
    } else {
      newLoaders.splice(loaderIndex, 1);
    }

    return {
      ...store,
      loaders: newLoaders
    };
  }

  return store;
};

export default uiReducer;

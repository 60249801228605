import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import {
  enrichMenuItemsAction,
  setMenuItemsAction,
  validateMenuItemsAction
} from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ProminentProductFactory from '../../../factories/menu-items/ProminentProductFactory';
import Ajv from 'ajv';
import { productSchema } from '../../../validators/product/productSchema';
import { headerSchema } from '../../../validators/menu-items/headerSchema';
import { prominentProductSchema } from '../../../validators/menu-items/prominentProductSchema';

const fetchMenuItemsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.MENU_ITEMS.FETCH_MENU_ITEMS:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_MENU_ITEMS,
            null,
            null,
            MiddlewareEntities.MENU_ITEMS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.MENU_ITEMS));
        break;
      case `${MiddlewareEntities.MENU_ITEMS} ${ActionTypes.API_SUCCESS}`:
        next(validateMenuItemsAction(action.payload.data.data));
        next(setLoader(false, MiddlewareEntities.MENU_ITEMS));
        break;
      case `${MiddlewareEntities.MENU_ITEMS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.MENU_ITEMS));
        break;
    }
  };

export const validateMenuItemsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.MENU_ITEMS.VALIDATE_MENU_ITEMS)) {
      next(enrichMenuItemsAction(action.payload));
    }
  };

export const enrichMenuItemsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.MENU_ITEMS.ENRICH_MENU_ITEMS) {
      const ajv = new Ajv();
      let menuItems = null;
      let prominentProduct = null;
      if (ajv.validate(headerSchema, action.payload.menuItems)) {
        menuItems = action.payload.menuItems;
      }

      if (
        ajv.validate(prominentProductSchema, action.payload.prominentProduct)
      ) {
        prominentProduct = ProminentProductFactory.create(
          action.payload.prominentProduct
        );
      }

      next(setMenuItemsAction({ menuItems, prominentProduct }));
    }
  };

export default [
  fetchMenuItemsMiddleware,
  validateMenuItemsMiddleware,
  enrichMenuItemsMiddleware
];

import {
  IConfirmRegistrationAction,
  ISetUserConfirmationMessageAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const confirmUserRegistration = (
  date: Date,
  token: string
): IConfirmRegistrationAction => ({
  type: ActionTypes.CONFIRM_USER_REGISTRATION,
  payload: {
    date,
    token
  }
});

export const setUserConfirmationMessageAction = (
  message: string,
  status: string
): ISetUserConfirmationMessageAction => ({
  type: ActionTypes.SET_USER_CONFIRMATION_MESSAGE,
  payload: {
    message,
    status
  }
});

export const bookstoreSchema: any = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    stockStatus: { type: 'boolean' },
    status: { type: 'boolean' },
    imgGallery: { type: 'array' },
    img: { type: 'string' },
    location: {
      type: 'object',
      properties: { lat: { type: 'string' }, long: { type: 'string' } }
    },
    email: { type: 'string' },
    tel: { type: 'string' },
    embedMap: { type: 'string' },
    address: {
      type: 'object',
      properties: {
        streetAndNumber: { type: 'string' },
        city: { type: 'string' },
        postalCode: { type: 'string' }
      }
    },
    bookstoreName: { type: 'string' }
  },
  required: ['_id', 'bookstoreName', 'address', 'embedMap']
};

import ActionTypes from '../../../constants/ActionTypes';
import { IBookstoresState, ISetBookstoresAction } from './types';

const initialState: IBookstoresState = {
  items: []
};

const bookstoresReducer = (
  state = initialState,
  action: ISetBookstoresAction
): IBookstoresState => {
  switch (action.type) {
    case ActionTypes.BOOKSTORES_PAGE.SET_BOOKSTORES_PAGE:
      return {
        ...state,
        items: action.payload.bookstores
      };
  }

  return state;
};

export default bookstoresReducer;

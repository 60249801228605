import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import Ajv from 'ajv';
import { orderSchema } from '../../../validators/order/orderSchema';
import {
  enrichOrderAction,
  setOrderAction,
  validateOrderAction
} from './actions';
import OrderFactory from '../../../factories/order/OrderFactory';
import NotificationTypes from '../../../constants/NotificationTypes';
import { fetchCartItemsFromApiAction } from '../cart/actions';
import { removeCookie } from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';
import OrderStatuses from '../../../constants/OrderStatuses';

const fetchOrderStatusMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.ORDER_STATUS.FETCH_ORDER:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_ORDER_STATUS}/${action.payload.secret}`,
            null,
            null,
            MiddlewareEntities.ORDER_STATUS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.ORDER_STATUS));
        break;
      case `${MiddlewareEntities.ORDER_STATUS} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.ORDER_STATUS));
        if (
          action.payload.data.status ===
          OrderStatuses.WAITING_FOR_FINAL_ONLINE_PAYMENT
        ) {
          removeCookie(CookieNames.CART);
        }
        store.dispatch(fetchCartItemsFromApiAction());
        next(validateOrderAction(action.payload.data));
        break;
      case `${MiddlewareEntities.ORDER_STATUS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.ORDER_STATUS));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.ORDER_STATUS,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

const validateOrderMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.ORDER_STATUS.VALIDATE_ORDER)) {
      const ajv = new Ajv();
      const order = action.payload.order;
      if (ajv.validate(orderSchema, order)) {
        next(enrichOrderAction(order));
      }
    }
  };

const enrichOrderMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.ORDER_STATUS.ENRICH_ORDER)) {
      const order = OrderFactory.create(action.payload.order);
      next(setOrderAction(order));
    }
  };

export default [
  fetchOrderStatusMiddleware,
  validateOrderMiddleware,
  enrichOrderMiddleware
];

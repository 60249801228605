import {
  ITopListsContent,
  ITopListsList
} from '../../../entities/section/types';
import { IFactory } from '../../types';
import TopListsContent from '../../../entities/section/top-lists/TopListsContent';
import { getAvailableProduct } from '../../../utilites/product';

const TopListsContentFactory: IFactory = class TopListsContentFactory {
  static create(content: ITopListsContent[]) {
    const tabs: any = [];

    content.forEach((item: ITopListsContent | any) => {
      item.list.length = 5;

      const sortLists = item.list.sort(
        (listA: ITopListsList, listB: ITopListsList) => {
          return listB.orderNumber - listA.orderNumber;
        }
      );

      const products = sortLists.map((sortList: ITopListsList) => {
        return getAvailableProduct(sortList.product);
      });

      tabs.push({
        name: item.category,
        products: products
      });
    });

    return new TopListsContent(tabs);
  }
};

export default TopListsContentFactory;

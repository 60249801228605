import React, { FC, ReactChildren, ReactElement, ReactNode } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';
import { keyId } from '../hooks/types';

interface IProps {
  id: keyId;
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>, key: keyId) => void;
  showDropdown: boolean;
  label: string;
  textHeadlineClasses: string;
  textClassesChildren: string;
  children: ReactChildren | ReactElement | ReactNode;
  classes?: string;
  borderClasses?: string;
  icon?: ReactElement;
}

const DropdownMenu: FC<IProps> = ({
  id,
  handleOnClick,
  showDropdown,
  label,
  children,
  classes,
  borderClasses,
  textHeadlineClasses,
  textClassesChildren,
  icon
}: IProps): ReactElement => (
  <>
    <div
      className={`row mt-2 cursor-pointer align-items-center ${classes}`}
      onClick={(event: React.MouseEvent<HTMLDivElement>) =>
        handleOnClick(event, id)
      }
    >
      <div className="col-6">
        <div className="row align-items-center">
          {icon && <div className="col-auto pe-0">{icon}</div>}
          <div className="col">
            <h4 className={textHeadlineClasses}>{label}</h4>
          </div>
        </div>
      </div>
      <div className="col-6 text-end">
        {showDropdown ? (
          <FaMinus
            size="1em"
            style={{ strokeWidth: '1' }}
            className="mt-minus-10"
          />
        ) : (
          <FaPlus
            size="1em"
            style={{ strokeWidth: '1' }}
            className="mt-minus-10"
          />
        )}
      </div>
      {!showDropdown ? (
        <div className={`${borderClasses} mt-2 mb-2`}></div>
      ) : null}
    </div>
    {showDropdown ? (
      <div className="row text-start justify-content-start mt-3">
        <div className="col-12">
          <ul className={`footer-links ${textClassesChildren}`}>{children}</ul>
        </div>
        <div className={`${borderClasses}`}></div>
      </div>
    ) : null}
  </>
);

export default DropdownMenu;

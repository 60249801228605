export const Checkout = {
  LEGAL_PERSON: 'legal_person',
  NATURAL_PERSON: 'natural_person',
  HOME_ADDRESS: 'home',
  SHIPPING_ADDRESS: 'shipping',
  PAYMENT_TYPE_ANY_CREDIT_CARD: 'ANY_CREDIT_CARD',
  PAYMENT_TYPE_VISA_PREMIUM_CREDIT_CARD: 'VISA_PREMIUM_CREDIT_CARD',
  PAYMENT_TYPE_VISA_CREDIT_CARD: 'VISA_CREDIT_CARD',
  PAYMENT_TYPE_ON_DELIVERY: 'ON_DELIVERY',
  PAYMENT_TYPE_PAYMENT_SLIP: 'PAYMENT_SLIP',
  PAYMENT_TYPE_ADMINISTRATIVE_BAN: 'ADMINISTRATIVE_BAN',
  FREE_DELIVERY: 'Besplatna dostava na teritoriji republike Srbije',
  DELIVERY_TYPE_POST_DELIVERY: 'post_deliver',
  DELIVERY_TYPE_PERSONAL_ACCEPTANCE: 'personal_acceptance',
  DELIVERY_SERVICE_DEFAULT: 'DEFAULT',
  DELIVERY_SERVICE_DHL: 'DHL',
  DELIVERY_SERVICE_YANDEX: 'YANDEX',

  YANDEX_DELIVERY_SERVICE: {
    DELIVERY_TYPE: 'yandexDelivery',
    YANDEX_TODAY: 'forToday',
    YANDEX_TOMORROW: 'forTomorrow',
    YANDEX_FOR_MONDAY: 'forMonday'
  }
};

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchHomePageAction,
  ISetHomePageAction,
  IHomePageState
} from './types';

export function requestFetchHomePage(): IFetchHomePageAction {
  return {
    type: ActionTypes.HOMEPAGE.FETCH_HOMEPAGE
  };
}

export function setHomePageAction(
  homePage: IHomePageState
): ISetHomePageAction {
  return {
    type: ActionTypes.HOMEPAGE.SET_HOMEPAGE,
    payload: homePage
  };
}

export function validateHomePageDataAction(homePageData: any) {
  return {
    type: ActionTypes.HOMEPAGE.VALIDATE_HOMEPAGE,
    payload: homePageData
  };
}

export function enrichHomePageDataAction(homePageData: any) {
  return {
    type: ActionTypes.HOMEPAGE.ENRICH_HOMEPAGE,
    payload: homePageData
  };
}

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { getJsonFromCookie, setCookie } from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';
import CookieExpiredByDays from '../../../constants/CookieExpiredByDays';
import { setCookieConsentAction } from './actions';

const cookieConsentMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.COOKIE_CONSENT.CREATE_COOKIE_CONSENT) {
      const cookieConsent = getJsonFromCookie(CookieNames.COOKIE_CONSENT, '{}');

      if (!cookieConsent || Object.keys(cookieConsent).length === 0) {
        setCookie(
          'cookieConsent',
          JSON.stringify(action.payload.cookieConsent),
          CookieExpiredByDays.COOKIE_CONSENT
        );

        next(setCookieConsentAction(action.payload));
      }
    }

    if (action.type === ActionTypes.COOKIE_CONSENT.FETCH_COOKIE_CONSENT) {
      const cookieConsent = getJsonFromCookie(CookieNames.COOKIE_CONSENT, '{}');

      if (cookieConsent && Object.keys(cookieConsent).length > 0) {
        next(setCookieConsentAction({ cookieConsent: cookieConsent }));
      }
    }
  };

export default cookieConsentMiddleware;

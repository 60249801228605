import { IProductByNavIdState, ISetProductByNavIdAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IProductByNavIdState | null = {
  data: null
};
const productByNavIdReducer = (
  state: IProductByNavIdState = initialState,
  action: ISetProductByNavIdAction
): IProductByNavIdState => {
  switch (action.type) {
    case ActionTypes.PRODUCT_BY_NAV_ID.SET_PRODUCT_BY_NAV_ID:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default productByNavIdReducer;

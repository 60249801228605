import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichMangePageDataAction,
  IFetchMangePageDataAction,
  IMangePageState,
  ISetMangePageDataAction,
  IValidateMangePageDataAction
} from './types';

export function fetchMangePageDataAction(): IFetchMangePageDataAction {
  return {
    type: ActionTypes.MANGE_PAGE.FETCH_MANGE_PAGE_DATA
  };
}

export function setMangePageDataAction(
  mangePageData: IMangePageState
): ISetMangePageDataAction {
  return {
    type: ActionTypes.MANGE_PAGE.SET_MANGE_PAGE_DATA,
    payload: mangePageData
  };
}

export function validateMangePageDataAction(
  mangePageData: IMangePageState
): IValidateMangePageDataAction {
  return {
    type: ActionTypes.MANGE_PAGE.VALIDATE_MANGE_PAGE_DATA,
    payload: mangePageData
  };
}

export function enrichMangePageDataAction(
  mangePageData: any
): IEnrichMangePageDataAction {
  return {
    type: ActionTypes.MANGE_PAGE.ENRICH_MANGE_PAGE_DATA,
    payload: mangePageData
  };
}

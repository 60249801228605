import {
  IAuthor,
  ICharacteristic,
  IGenre,
  IImage,
  IProduct,
  IProductGalleryItem
} from '../../entities/product/types';
import Product from '../../entities/product/Product';
import { IFactory } from '../types';
import { replaceDotWithUrl } from '../../utilites/product';
import PriceRulesFacade from '../../rules/PriceRulesFacade';
import noProductDelfiImageL from '../../views/assets/images/no-images/no-image-delfi-l.jpg';
import noProductDelfiImageXl from '../../views/assets/images/no-images/no-image-delfi-xl.jpg';
import URL_SUFFIX from '../../constants/UrlSuffix';
import { replaceStringWith } from '../../utilites';
import { Categories } from '../../constants/Categories';

const ProductFactory: IFactory = class ProductFactory {
  private static mapCategoryUrl = (category: string) => {
    switch (category) {
      case 'Knjiga':
        return 'knjige';
      case 'Strana knjiga':
        return 'strane_knjige';
      case 'Film':
        return 'filmovi';
      case 'Gift':
        return 'gift';
      case 'Muzika':
        return 'muzika';
      case 'Udžbenik':
        return 'udzbenici';
      case 'Video igra':
        return 'video_igre';
      default:
        return '';
    }
  };

  private static formatAuthors(product: IProduct): {
    authorLabel: string;
    publisherLabel: string;
    authors: IAuthor[] | string;
  } {
    let authorLabel = 'Autor';
    let publisherLabel = 'Izdavač';
    switch (product.category) {
      case 'Gift':
        authorLabel = 'Naziv kolekcije';
        publisherLabel = 'Dobavljač';
        break;
      case 'Muzika':
        authorLabel = 'Izvodjač';
        publisherLabel = 'Distributer';
        break;
      case 'Video igra':
        authorLabel = 'Proizvodjač';
        publisherLabel = 'Distributer';
        break;
      case 'Knjiga':
      case 'Strana knjiga':
      case 'Udžbenik':
      case 'Dečja knjiga':
        authorLabel = product.authors.length ? 'Autori' : 'Autor';
    }

    return {
      authorLabel,
      publisherLabel,
      authors: product.authors
    };
  }

  private static formatAttr(attr: string): string[] {
    const attr5Array = attr.split(':');
    return attr5Array.filter(function (element: string) {
      return element !== '';
    });
  }

  private static getCharacteristics(product: IProduct): ICharacteristic[] {
    let characteristics: ICharacteristic[] = [];
    const attrItemsLength = 2;

    if (product.category) {
      characteristics.push({
        name: 'Kategorija:',
        value: product.category
      });
    }

    if (product.publisher) {
      let publisherName = 'Dobavljač:';
      if (product.category === Categories.BOOK) {
        publisherName = 'Izdavač:';
      }

      if (product.category === Categories.GIFT) {
        publisherName = 'Proizvođač';
      }

      characteristics.push({
        name: publisherName,
        value: product.publisher
      });
    }

    if (product.navId) {
      characteristics.push({
        name: 'Nav-id:',
        value: product.navId
      });
    }

    if (product.cover) {
      characteristics.push({
        name: 'Povez:',
        value: product.cover
      });
    }

    if (product.releaseDate) {
      characteristics.push({
        name: 'Godina izdanja:',
        value: product.releaseDate
      });
    }

    if (
      product.alphabets &&
      Array.isArray(product.alphabets) &&
      product.alphabets.length > 0
    ) {
      const alphabetsMap: any = {
        cyrillic: 'Ćirilica',
        latin: 'Latinica'
      };
      const alphabets = product.alphabets.map(
        (alphabet: string) => alphabetsMap[alphabet] || alphabet
      );

      characteristics.push({
        name: 'Pismo:',
        value: alphabets.join('/')
      });
    }

    product.attributes.forEach((attribute: any) => {
      if (attribute.k === 'ISBN' && attribute.v) {
        characteristics.push({ name: 'ISBN:', value: attribute.v });
      }

      if (attribute.k === 'dimensions' && attribute.v) {
        characteristics.push({ name: 'Dimenzije:', value: attribute.v });
      }

      if (attribute.k === 'numberOfPages' && attribute.v) {
        characteristics.push({ name: 'Broj strana:', value: attribute.v });
      }

      if (attribute.k === 'material') {
        characteristics.push({ name: 'Materijal:', value: attribute.v });
      }

      if (attribute.k === 'format') {
        characteristics.push({ name: 'Format:', value: attribute.v });
      }

      if (attribute.k === 'numberOfSheets') {
        characteristics.push({ name: 'Broj listova:', value: attribute.v });
      }

      if (attribute.k === 'weight' && attribute.v) {
        characteristics.push({ name: 'Težina (kg):', value: attribute.v });
      }

      if (attribute.k === 'numberOfPlayers' && attribute.v) {
        characteristics.push({ name: 'Broj igrača:', value: attribute.v });
      }

      if (attribute.k === 'numberOfPieces' && attribute.v) {
        characteristics.push({ name: 'Broj delova:', value: attribute.v });
      }

      if (attribute.k === 'volume' && attribute.v) {
        characteristics.push({ name: 'Zapremina:', value: attribute.v });
      }

      if (attribute.k === 'pack' && attribute.v) {
        characteristics.push({ name: 'Pakovanje:', value: attribute.v });
      }

      if (attribute.k === 'gender' && attribute.v) {
        characteristics.push({ name: 'Pol:', value: attribute.v });
      }

      if (attribute.k === 'madeIn' && attribute.v) {
        characteristics.push({ name: 'Zemlja porekla:', value: attribute.v });
      }

      if (attribute.k === 'importedFrom' && attribute.v) {
        characteristics.push({ name: 'Zemlja uvoza:', value: attribute.v });
      }

      if (attribute.k === 'yearOfImport' && attribute.v) {
        characteristics.push({ name: 'Godina uvoza:', value: attribute.v });
      }

      if (attribute.k === 'unit' && attribute.v) {
        characteristics.push({ name: 'Jedinica mere:', value: attribute.v });
      }

      if (attribute.k === 'importer' && attribute.v) {
        characteristics.push({ name: 'Uvoznik:', value: attribute.v });
      }

      if (attribute.k === 'kind' && attribute.v) {
        characteristics.push({ name: 'Vrsta artikla:', value: attribute.v });
      }

      if (attribute.k === 'gameMechanics' && attribute.v) {
        characteristics.push({ name: 'Mehanika igre:', value: attribute.v });
      }

      if (attribute.k === 'linguisticDependency' && attribute.v) {
        characteristics.push({
          name: 'Jezička zavisnost:',
          value: attribute.v
        });
      }
      if (attribute.k === 'languageOfEdition' && attribute.v) {
        characteristics.push({ name: 'Jezik izdanja:', value: attribute.v });
      }

      if (attribute.k === 'complexity' && attribute.v) {
        characteristics.push({ name: 'Kompleksnost:', value: attribute.v });
      }

      if (attribute.k === 'attr1' && attribute.v) {
        const attr1Array = this.formatAttr(attribute.v);

        if (attr1Array.length === attrItemsLength) {
          characteristics.push({
            name: `${attr1Array[0]}:`,
            value: attr1Array[1]
          });
        }
      }

      if (attribute.k === 'attr2' && attribute.v) {
        const attr2Array = this.formatAttr(attribute.v);

        if (attr2Array.length === attrItemsLength) {
          characteristics.push({
            name: `${attr2Array[0]}:`,
            value: attr2Array[1]
          });
        }
      }

      if (attribute.k === 'attr3' && attribute.v) {
        const attr3Array = this.formatAttr(attribute.v);

        if (attr3Array.length === attrItemsLength) {
          characteristics.push({
            name: `${attr3Array[0]}:`,
            value: attr3Array[1]
          });
        }
      }

      if (attribute.k === 'attr4' && attribute.v) {
        const attr4Array = this.formatAttr(attribute.v);

        if (attr4Array.length === attrItemsLength) {
          characteristics.push({
            name: `${attr4Array[0]}:`,
            value: attr4Array[1]
          });
        }
      }

      if (attribute.k === 'attr5' && attribute.v) {
        const attr5Array = this.formatAttr(attribute.v);

        if (attr5Array.length === attrItemsLength) {
          characteristics.push({
            name: `${attr5Array[0]}:`,
            value: attr5Array[1]
          });
        }
      }
    });

    if (product.pgRating) {
      characteristics.push({
        name: 'Uzrast:',
        value: product.pgRating
      });
    }

    if (product.barcode) {
      characteristics.push({
        name: 'Bar-kod:',
        value: product.barcode
      });
    }

    return characteristics;
  }

  private static createNavigateToUrl = (product: IProduct) => {
    const titleNoSpecialChars = replaceStringWith(
      product.title,
      /[^a-zA-Z0-9 ]/g,
      ''
    );
    const category = replaceStringWith(product.category, / /g, '-');
    return `/${this.mapCategoryUrl(product.category)}/${
      product.oldProductId
    }-${titleNoSpecialChars.replace(/ /g, '-')}-${category}-${URL_SUFFIX}`;
  };

  private static replaceImgDotWithUrl(product: IProduct) {
    let images: IImage = {
      s: product.images.s
        ? replaceDotWithUrl(product.images.s)
        : noProductDelfiImageL,
      m: product.images.m
        ? replaceDotWithUrl(product.images.m)
        : noProductDelfiImageL,
      l: product.images.l
        ? replaceDotWithUrl(product.images.l)
        : noProductDelfiImageL,
      xl: product.images.xl
        ? replaceDotWithUrl(product.images.xl)
        : noProductDelfiImageXl,
      xxl: product.images.xxl
        ? replaceDotWithUrl(product.images.xxl)
        : product.images.xl
        ? replaceDotWithUrl(product.images.xl)
        : noProductDelfiImageXl,
      fb: product.images.fb
        ? replaceDotWithUrl(product.images.fb)
        : noProductDelfiImageL
    };
    return images;
  }

  private static getUniqueGenres(genres: IGenre[]) {
    return genres.filter((genre, index, arr) => {
      return (
        arr.findIndex((elem) => elem.genreName === genre.genreName) === index
      );
    });
  }

  private static formatImgGallery(
    product: IProduct,
    imgGallery?: IProductGalleryItem[]
  ) {
    if (Array.isArray(imgGallery) && imgGallery.length > 0) {
      const imgGalleryCopy = [...imgGallery];
      const imgG = imgGalleryCopy.map(
        (item: IProductGalleryItem, index: number) => {
          return {
            thumbnail: replaceDotWithUrl(item.thumbnailImg),
            original: replaceDotWithUrl(item.originalImg),
            fullscreen: replaceDotWithUrl(item.fullScreenImg),
            originalAlt: `${product.title}-slika${index}`,
            thumbnailAlt: `${product.title}-thumbnail${index}`
          };
        }
      );

      imgG.unshift({
        original: this.replaceImgDotWithUrl(product).xl,
        thumbnail: this.replaceImgDotWithUrl(product).xl,
        fullscreen: this.replaceImgDotWithUrl(product).xxl,
        originalAlt: `image-${product.title}`,
        thumbnailAlt: `thumbnail-${product.title}`
      });

      return imgG;
    }

    return [];
  }

  private static formatDefaultReleaseDate(
    defaultReleaseDate?: Date
  ): string | Date {
    if (defaultReleaseDate) {
      const date = new Date(defaultReleaseDate);
      return date.toISOString().split('T')[0];
    }

    return new Date('Y-m-d');
  }

  public static create(product: IProduct | any): IProduct {
    const priceRulesFacade = PriceRulesFacade.getInstance();
    priceRulesFacade.init(product);
    const displayStickers = priceRulesFacade.getStickers();
    const displayPrices = priceRulesFacade.getBestPrice();
    const action = priceRulesFacade.getAction();

    return new Product(
      product._id,
      product.actions,
      this.replaceImgDotWithUrl(product),
      product.priceList,
      displayPrices,
      displayStickers,
      product.attributes,
      product.publisher,
      this.formatAuthors(product).authors,
      product.ean,
      product.quantity,
      product.isLaguna,
      product.genreAnalytics,
      product.pgRating,
      product.cover,
      product.pdv,
      product.order,
      product.isNewProduct,
      product.isFree,
      product.status,
      product.isAvailable,
      product.isLiveLager,
      product.shortDescription ? product.shortDescription : '',
      product.description ? product.description : '',
      product.title,
      product.subgenre,
      product.category,
      product.barcode,
      product.navId,
      product.alphabets,
      product.topComments,
      product.averageRank,
      product.totalNumberOfComments,
      product.rankList,
      this.formatAuthors(product).authorLabel,
      this.formatAuthors(product).publisherLabel,
      product.metaData,
      product.eBook,
      product.productType,
      product.oldProductId,
      this.getCharacteristics(product),
      this.createNavigateToUrl(product),
      product.releaseDate,
      this.getUniqueGenres(product.genres),
      product.excerptPath ? replaceDotWithUrl(product.excerptPath) : '',
      `/${this.mapCategoryUrl(product.category)}`,
      action && typeof action !== 'undefined' && Object.keys(action).length > 0,
      this.formatImgGallery(product, product.imgGallery),
      product.metaTitle,
      this.formatDefaultReleaseDate(product.defaultReleaseDate)
    );
  }
};

export default ProductFactory;

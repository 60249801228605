import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchLastNewsAction,
  ISetNewsAction,
  IFetchNewsSectionAction,
  IEnrichNewsSectionsAction,
  ISetNewsSectionsAction
} from './types';
import News from '../../../entities/news/News';
import { ISection } from '../../../entities/section/types';

export const fetchLastNews = (
  skip: number,
  limit: number,
  isOverrideNews: boolean
): IFetchLastNewsAction => ({
  type: ActionTypes.NEWS.FETCH_LAST_NEWS,
  payload: {
    skip,
    limit,
    isOverrideNews
  }
});

export const setNews = (
  news: News[],
  isOverrideNews: boolean
): ISetNewsAction => ({
  type: ActionTypes.NEWS.SET_LAST_NEWS,
  payload: {
    news,
    isOverrideNews
  }
});

export const validateNews = (news: News[], isOverrideNews: boolean) => ({
  type: ActionTypes.NEWS.VALIDATE_NEWS,
  payload: {
    news,
    isOverrideNews
  }
});

export const enrichNews = (news: News[], isOverrideNews: boolean) => ({
  type: ActionTypes.NEWS.ENRICH_NEWS,
  payload: {
    news,
    isOverrideNews
  }
});

export const fetchNewsSectionsAction = (): IFetchNewsSectionAction => ({
  type: ActionTypes.NEWS.FETCH_NEWS_SECTIONS
});

export const enrichNewsSectionsAction = (
  sections: ISection[]
): IEnrichNewsSectionsAction => ({
  type: ActionTypes.NEWS.ENRICH_NEWS_SECTIONS,
  payload: {
    sections
  }
});

export const setNewsSectionsAction = (
  sections: ISection[],
  metaDescription: string
): ISetNewsSectionsAction => ({
  type: ActionTypes.NEWS.SET_NEWS_SECTIONS,
  payload: {
    sections,
    metaDescription
  }
});

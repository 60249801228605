export const orderSchema: any = {
  type: 'object',
  properties: {
    id: { type: 'number' },
    payment_detail_id: { type: 'number' },
    customer_detail_id: { type: 'number' },
    amount: { type: ['number', 'string'] },
    delivery_type: { type: 'string' },
    payment_detail: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        payment_type: { type: 'string' }
      }
    },
    order_items: { type: 'array' },
    customer_detail: {
      type: 'object',
      properties: {
        id: { type: 'number' },
        type: { type: 'string' },
        addresses: { type: 'array' }
      }
    },
    created_at: { type: 'string' },
    status: { type: 'string' },
    type: { type: 'string' }
  },
  required: ['id', 'type', 'status']
};

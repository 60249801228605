import { IAuthor, IProduct } from '../product/types';
import { INews } from './types';

export default class News implements INews {
  _id: string;

  actions: boolean;

  authors: IAuthor[];

  products: IProduct[];

  content: string;

  img1: string;

  img2: string;

  img3: string;

  createdAt: string;

  dateRangeStart: string;

  dateRangeEnd: string;

  rb: number;

  status: string;

  title: string;

  oldId: number;

  imgGallery?: string[] | null;

  metaTitle?: string | null;

  metaDescription?: string | null;

  constructor(
    id: string,
    actions: boolean,
    authors: IAuthor[],
    products: IProduct[],
    content: string,
    img1: string,
    img2: string,
    img3: string,
    createdAt: string,
    dateRangeStart: string,
    dateRangeEnd: string,
    rb: number,
    status: string,
    title: string,
    oldId: number,
    imgGallery?: string[] | null,
    metaTitle?: string | null,
    metaDescription?: string | null
  ) {
    this._id = id;
    this.actions = actions;
    this.authors = authors;
    this.products = products;
    this.content = content;
    this.img1 = img1;
    this.img2 = img2;
    this.img3 = img3;
    this.createdAt = createdAt;
    this.dateRangeStart = dateRangeStart;
    this.dateRangeEnd = dateRangeEnd;
    this.rb = rb;
    this.status = status;
    this.title = title;
    this.oldId = oldId;
    this.imgGallery = imgGallery;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
  }
}

import { cloneElement, FC, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { AppState } from '../../../app/store/rootReducers';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

interface IProps {
  children: ReactElement;
}

type Props = ReturnType<typeof mapStateToProps> & IProps;

const RequireNonAuth: FC<Props> = ({ children, user }: Props) => {
  let location = useLocation();

  if (user && user.username) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return cloneElement(children, { user });
};

const mapStateToProps = (state: AppState) => ({
  user: state.user.member.user
});

export default connect(mapStateToProps, {})(RequireNonAuth);

import ActionTypes from '../../../constants/ActionTypes';
import {
  ICookieConsentState,
  ICreateCookieConsentAction,
  IFetchCookiesConsentAction,
  ISetCookieConsentAction
} from './types';

export const createCookieConsentAction = (
  payload: ICookieConsentState
): ICreateCookieConsentAction => ({
  type: ActionTypes.COOKIE_CONSENT.CREATE_COOKIE_CONSENT,
  payload: payload
});

export const setCookieConsentAction = (
  payload: ICookieConsentState
): ISetCookieConsentAction => ({
  type: ActionTypes.COOKIE_CONSENT.SET_COOKIE_CONSENT,
  payload: payload
});

export const fetchCookieConsentAction = (): IFetchCookiesConsentAction => ({
  type: ActionTypes.COOKIE_CONSENT.FETCH_COOKIE_CONSENT
});

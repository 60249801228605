import { IMangePageState, ISetMangePageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IMangePageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const mangePageDataReducer = (
  state = initialState,
  action: ISetMangePageDataAction
): IMangePageState => {
  switch (action.type) {
    case ActionTypes.MANGE_PAGE.SET_MANGE_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default mangePageDataReducer;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import {
  removeAllNotifications,
  removeNotification,
  setNotification
} from './actions';
import NotificationFactory from '../../../factories/notification/NotificationFactory';
import { generateErrorMessage } from '../../../utilites/error';
import { INotification } from './types';
import NotificationTypes from '../../../constants/NotificationTypes';

export const notificationMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    if (action.type.includes(ActionTypes.NOTIFICATION.CREATE_NOTIFICATION)) {
      const { message, data, meta } = action.payload;
      const notification: INotification = NotificationFactory.create(
        message
          ? message
          : generateErrorMessage(
              data && data.userMessage ? data.userMessage : ''
            ),
        meta.type
      );

      next(setNotification(notification, meta.entity));

      setTimeout(() => {
        next(removeNotification(notification.id));
      }, 10000);

      // if (meta.type === NotificationTypes.DELAY_NOTIFICATION_DISMISS) {
      //   setTimeout(() => {
      //     next(removeNotification(notification.id));
      //   }, 10000);
      // }

      if (action.type.includes(ActionTypes.NOTIFICATION.REMOVE_NOTIFICATION)) {
        next(removeNotification(action.payload.notificationId));
      }
    }

    if (action.type === ActionTypes.NOTIFICATION.REMOVE_ALL_NOTIFICATIONS) {
      next(removeAllNotifications());
    }
  };

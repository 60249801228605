import React, {
  ReactElement,
  ReactNode,
  ReactNodeArray,
  useCallback,
  useState
} from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './scss/style.scss';
import Slider, { Settings } from 'react-slick';

export interface IProps {
  settings: Settings;
  children: ReactElement | ReactNode | ReactNodeArray | null;
}

export const CarouselSlider = (props: IProps) => {
  const { settings, children } = props;

  const [dragging, setDragging] = useState(false);

  const handleBeforeChange = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const handleAfterChange = useCallback(() => {
    setDragging(false);
  }, [setDragging]);

  const handleOnItemClick = useCallback(
    (e) => {
      if (dragging) {
        e.preventDefault();
      }
    },
    [dragging]
  );

  return (
    <Slider
      beforeChange={handleBeforeChange}
      afterChange={handleAfterChange}
      {...settings}
    >
      {React.Children.map(children, (child) => (
        <div className="h-100" onClickCapture={handleOnItemClick}>
          {child}
        </div>
      ))}
    </Slider>
  );
};

export default CarouselSlider;

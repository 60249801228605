import { IFactory } from '../types';
import { ICartOrderItem } from '../../app/store/cart/types';
import OrderItemPrice from '../../entities/cart/OrderItemPrice';
import ProductFactory from '../product';
import { formatPrice } from '../../utilites/product';

const CartOrderItemPriceFactory: IFactory = class CartOrderItemPriceFactory {
  static create(orderItemsPriceReport: ICartOrderItem) {
    return new OrderItemPrice(
      ProductFactory.create(orderItemsPriceReport.product),
      orderItemsPriceReport.fullPrice,
      orderItemsPriceReport.quantity,
      orderItemsPriceReport.finalPrice,
      orderItemsPriceReport.finalPrice
        ? `${orderItemsPriceReport.quantity} x ${formatPrice(
            orderItemsPriceReport.finalPrice,
            2
          )}`
        : '/',
      orderItemsPriceReport.quantityDiscountPriceReport,
      orderItemsPriceReport.actionPriceReport,
      orderItemsPriceReport.regularDiscountPriceReport
    );
  }
};

export default CartOrderItemPriceFactory;

/* eslint-disable @typescript-eslint/brace-style */

import { IProminentCarouselContent, PriceDisplayTypes } from '../types';
import { IProduct } from '../../product/types';

export default class ProminentCarouselContent
  implements IProminentCarouselContent
{
  _id: string;

  title: string;

  priceDisplayType: PriceDisplayTypes;

  prominentTitle: string;

  prominentSubtitle: string;

  prominentDescription: string;

  prominentImg: string;

  prominentProduct: IProduct | null;

  products: IProduct[];

  startAt: string | null;

  endAt: string | null;

  isTopList?: boolean;

  viewAllUrl?: string;

  constructor(
    id: string,
    title: string,
    priceDisplayType: PriceDisplayTypes,
    prominentTitle: string,
    prominentSubtitle: string,
    prominentDescription: string,
    prominentImg: string,
    product: IProduct | null,
    products: IProduct[],
    startAt: string | null,
    endAt: string | null,
    isTopList?: boolean,
    viewAllUrl?: string
  ) {
    this._id = id;
    this.title = title;
    this.priceDisplayType = priceDisplayType;
    this.prominentTitle = prominentTitle;
    this.prominentSubtitle = prominentSubtitle;
    this.prominentDescription = prominentDescription;
    this.prominentImg = prominentImg;
    this.prominentProduct = product;
    this.products = products;
    this.startAt = startAt;
    this.endAt = endAt;
    this.isTopList = isTopList;
    this.viewAllUrl = viewAllUrl;
  }
}

export const Categories = {
  BOOK: 'Knjiga',
  GIFT: 'Gift',
  MUSIC: 'Muzika',
  FOREIGN_BOOK: 'Strana knjiga',
  VIDEO_GAME: 'Video igra',
  FILM: 'Film',
  CLASS_BOOK: 'Udžbenik',
  KIDS_BOOK: 'decja-knjiga'
};

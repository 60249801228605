import { IFactory } from '../../types';
import { IInfoIconsItem } from '../../../entities/section/types';
import InfoIconsItem from '../../../entities/section/info-icons/InfoIconsItem';
import Config from '../../../config';

const InfoIconsItemFactory: IFactory = class InfoIconsItemFactory {
  static create(infoIconsItem: IInfoIconsItem) {
    return new InfoIconsItem(
      infoIconsItem.boldText,
      `${Config.api.mediaUrl}${infoIconsItem.icon}`,
      infoIconsItem.regularText,
      infoIconsItem.url
    );
  }
};

export default InfoIconsItemFactory;

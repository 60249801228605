import React, { FC, useEffect, useState } from 'react';
import { HiOutlineArrowSmUp } from 'react-icons/hi';
import Button from '../button';
import './scss/style.scss';

interface IProps {
  classes?: string;
}

const BackToTopBtn: FC<IProps> = ({ classes }: IProps) => {
  const [showButton, setShowButton] = useState(false);

  const setShowButtonAfterPosition = () => {
    if (window.scrollY > 200) {
      return setShowButton(true);
    }
    return setShowButton(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', setShowButtonAfterPosition);

    return () => {
      window.removeEventListener('scroll', setShowButtonAfterPosition);
    };
  }, []);

  return showButton ? (
    <div className={`${classes} position-fixed back-to-top-wrapper`}>
      <Button
        classes="btn-back-to-top background-orange d-flex justify-content-center align-items-center text-center"
        onClick={() => window.scrollTo(0, 0)}
      >
        <HiOutlineArrowSmUp size="1.6em" stroke="#000" />
      </Button>
    </div>
  ) : null;
};

export default BackToTopBtn;

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Config from '../config';
import { detectIE } from '../utilites';

class HttpService {
  public static async request(
    options: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    if (!options.url) {
      throw new Error('url is required');
    }

    options.baseURL = Config.api.baseUrl;
    options.method = options.method || 'get';

    options.headers = options.headers || {};

    if (options.method === 'post' || options.method === 'put') {
      options.headers['Content-Type'] = 'application/json';
    }

    options.withCredentials = true;

    // Deal with IE aggressive caching
    // http://stackoverflow.com/questions/2848945/prevent-ie-caching
    if (detectIE() && options.method === 'get') {
      options.headers['Cache-Control'] = 'no-cache, no-store, must-revalidate';
      options.headers.Pragma = 'no-cache';
      options.headers.Expires = '0';
    }
    try {
      const response: AxiosResponse<any> = await axios(options);
      return response.data;
    } catch (error: any) {
      let err = {
        message: 'Server unavailable!',
        status: 500,
        meta: {}
      };
      const { response } = error;
      if (response && response.data) {
        err = response;
      }

      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw err;
    }
  }
}

export default HttpService;

import { IBookMakerItem } from '../../app/store/bookmaker-magazine/types';

export default class BookmakerItem implements IBookMakerItem {
  _id: string;

  img: string;

  issueNumber: number;

  issuuUrl: string;

  no: number;

  publishedAt: Date;

  displayDate: string;

  constructor(
    id: string,
    img: string,
    issueNumber: number,
    issuuUrl: string,
    no: number,
    publishedAt: Date,
    displayDate: string
  ) {
    this._id = id;
    this.img = img;
    this.issueNumber = issueNumber;
    this.issuuUrl = issuuUrl;
    this.no = no;
    this.publishedAt = publishedAt;
    this.displayDate = displayDate;
  }
}

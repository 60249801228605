import ActionTypes from '../../../constants/ActionTypes';
import { ISearchItemsState, ISetSearchItemsAction } from './types';

const initialState: ISearchItemsState = {
  totalNumberOfResults: 0,
  results: [],
  prominentProduct: null
};

const searchItemsReducer = (
  state = initialState,
  action: ISetSearchItemsAction
): ISearchItemsState => {
  switch (action.type) {
    case ActionTypes.SEARCH_ITEMS.SET_ITEMS_BY_KEY:
      return {
        ...state,
        totalNumberOfResults: action.payload.searchItems.totalNumberOfResults,
        results: action.payload.searchItems.results,
        prominentProduct: action.payload.searchItems.prominentProduct
      };
    case ActionTypes.SEARCH_ITEMS.SET_INIT_SEARCH_ITEMS:
      return {
        ...state,
        ...initialState
      };
  }

  return state;
};

export default searchItemsReducer;

import { ICartOrderItem } from '../../app/store/cart/types';
import { IProduct } from '../product/types';
import exp from 'constants';

export interface ICart {
  calculatedPremiumPrice: Number | string;
  benefitsReport: any;
  availablePaymentTypes: string[];
  administrativeBanReport: IAdministrativeBanReport | null;
  deliveryService: string;
  orderItemsPriceReport: ICartOrderItem[];
  allOrderItemsAmount: Number | string;
  pttPrice: number;
  totalAmount: Number;
  amountUpToFreeShipping: Number | string;
  totalItemQuantity: number;
  validatePrice: () => null | IOrderValidatePrice;
  discountPrice: Number | string;
  fullItemsPrice: number;
}

export interface IOrderValidatePrice {
  blockFlag: boolean;
  orderValidationUserMessage: string;
}

export interface IQuantityDiscountPriceReport {
  discountPercentage: number;
  discountPrice: Number | string;
  countLimit: number;
}

export interface IActionPriceReport {
  actionId: string;
  actionTitle: string;
  actionType: string;
  actionPrice: Number | string;
}

export interface IRegularDiscountPriceReport {}

export interface IBenefit {
  state: BenefitStatesEnum;
}

export interface IVisaPremiumBenefitReport extends IBenefit {
  payload: IVisaPremiumBenefitPayload;
}

export interface IVisaMassBenefit extends IBenefit {
  freeProducts: IProduct[];
}

export interface IYandexBenefit extends IBenefit {
  forToday: boolean;
  forTomorrow: boolean;
  forMonday: boolean;
}

export interface IAdministrativeBanReport {
  availableInstallments: IAvailableInstallment[];
  isApplicable: boolean;
}

export interface IAvailableInstallment {
  numberOfInstallments: number;
  installmentPrice: number | string;
}

export enum BenefitStatesEnum {
  killed = 'killed',
  not_applicable = 'not_applicable',
  applicable = 'applicable',
  applied = 'applied'
}

export enum BenefitsReportEnum {
  VISA_PREMIUM = 'visaPremiumBenefitReport',
  VISA_MASS = 'visaMassBenefitReport',
  YANDEX = 'yandexBenefitReport'
}

export interface IVisaPremiumBenefitPayload {
  bannerUrl: string;
  benefitAmountLimit: number;
  benefitAvailableContent: string;
  benefitCarts: IVisaPremiumBenefitPayloadCart[];
  benefitUnavailableContent: string;
  benefitSubtext: string;
}

export interface IVisaPremiumBenefitPayloadCart {
  cartId: number;
  cartImageUrl: string;
  cartProducts: IProduct[];
  cartStatus: boolean;
  cartTitle: string;
  startAt: string;
  endAt: string;
}

export interface IBenefitsReport {
  visaMassBenefitReport: IVisaMassBenefit;
  visaPremiumBenefitReport: IVisaPremiumBenefitReport;
  yandexBenefitReport: IYandexBenefit;
  calculatedPremiumPrice: number;
  deliveryService: string;
}

import ActionTypes from '../../../constants/ActionTypes';
import { IMunicipalitiesState, ISetMunicipalitiesAction } from './types';

const initialState: IMunicipalitiesState = {
  items: []
};

const municipalitiesReducer = (
  state = initialState,
  action: ISetMunicipalitiesAction
): IMunicipalitiesState => {
  switch (action.type) {
    case ActionTypes.MUNICIPALITIES.SET_MUNICIPALITIES:
      return {
        ...state,
        items: action.payload.municipalities
      };
  }

  return state;
};

export default municipalitiesReducer;

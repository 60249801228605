import { useState } from 'react';

const useDropdownShow = () => {
  const [showDropdownCategory, setDropdownCategory] = useState(false);

  return {
    showDropdownCategory,
    setDropdownCategory
  };
};

export default useDropdownShow;

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import {
  setUserAction,
  setUserMessageAction,
  userLogoutAction
} from './actions';
import { parseJwt } from '../../../utilites/jwt-token';
import { getCookie, removeCookie } from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { createNotification } from '../notification/actions';
import NotificationTypes from '../../../constants/NotificationTypes';
import { getItem } from '../../../utilites/localStorage';
import { setInitialWishlistAction } from '../wishlist/actions';

const userLoginMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.USER.USER_LOGIN:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.USER_LOGIN,
            null,
            {
              ...action.payload.userLoginRequest,
              ...{ browserWishlist: getItem(CookieNames.WISHLIST) }
            },
            MiddlewareEntities.USER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.USER));
        break;
      case `${MiddlewareEntities.USER} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.USER));
        let user = null;

        if (action.payload.data.username) {
          user = action.payload.data.username;
          next(setInitialWishlistAction(action.payload.data.wishlist));
        }
        next(setUserAction(user));
        window.location.reload();
        break;
      case `${MiddlewareEntities.USER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.USER));
        if (action.payload.data.status === 401) {
          return next(
            setUserAction({
              username: '',
              email: '',
              isDelfiClub: false,
              message: 'Nepravilno ste uneli e-mail ili lozinku'
            })
          );
        }

        next(
          createNotification(
            MiddlewareEntities.USER,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );

        break;
    }
  };

const logoutMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.USER.USER_LOGOUT) {
      next(
        apiRequest(
          null,
          'GET',
          ApiUrls.USER_LOGOUT,
          null,
          null,
          MiddlewareEntities.USER,
          {}
        )
      );
      next(setLoader(true, MiddlewareEntities.USER));
    }
  };

const fetchUserFromCookieMiddleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.USER.FETCH_USER) {
      const jwtCookie = getCookie(CookieNames.MEMBER);
      let jwt = null;

      if (jwtCookie) {
        jwt = parseJwt(jwtCookie);
      }

      if (jwt) {
        next(setUserAction(jwt));
      }
    }
  };

const createUserMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.USER.CREATE_USER:
        next(
          apiRequest(
            action.payload.user,
            'POST',
            ApiUrls.USER_CREATE,
            null,
            action.payload.user,
            MiddlewareEntities.CREATE_USER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.CREATE_USER));
        break;
      case `${MiddlewareEntities.CREATE_USER} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.CREATE_USER));
        next(setUserMessageAction('Korisnik je uspešno kreiran', 'success'));
        break;
      case `${MiddlewareEntities.CREATE_USER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.CREATE_USER));
        if (
          action.payload.data &&
          action.payload.data.data &&
          action.payload.data.data.status ===
            HttpStatusCodes.HTTP_BAD_REQUEST &&
          action.payload.data.data.code.includes('009')
        ) {
          return next(
            setUserMessageAction(
              'Korisnik sa ovim emailom je već registrovan',
              'error'
            )
          );
        }

        // next(
        //   setNotification(
        //     [
        //       NotificationFactory.create(
        //         generateErrorMessage(
        //           action.payload.data.data &&
        //             action.payload.data.data.userMessage
        //             ? action.payload.data.data.userMessage
        //             : ''
        //         ),
        //         'error'
        //       )
        //     ],
        //     MiddlewareEntities.CREATE_USER
        //   )
        // );

        break;
    }
  };

const removeUserCookieMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.USER.REMOVE_USER_COOKIE) {
      removeCookie(CookieNames.MEMBER);
      next(userLogoutAction());
    }
  };

export default [
  userLoginMiddleware,
  fetchUserFromCookieMiddleware,
  createUserMiddleware,
  removeUserCookieMiddleware,
  logoutMiddleware
];

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichBookMakerMagazineDataAction,
  setBookMakerMagazineDataAction,
  validateBookMakerMagazineDataAction
} from './actions';
import BookmakerValidator from '../../../validators/bookmaker';
import BookmakerPageDataFactory from '../../../factories/bookmaker/BookmakerPageDataFactory';
import { IBookMakerItem, IBookMakerMagazineState } from './types';
import BookmakerItemFactory from '../../../factories/bookmaker/BookmakerItemFactory';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchBookMakerMagazineMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.BOOKMAKER_MAGAZINE.FETCH_BOOKMAKER_MAGAZINE_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_BOOKMAKER_MAGAZINE_PAGE_DATA,
            { ...action.params },
            null,
            MiddlewareEntities.BOOKMAKER_MAGAZINE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.BOOKMAKER_MAGAZINE));
        break;
      case `${MiddlewareEntities.BOOKMAKER_MAGAZINE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.BOOKMAKER_MAGAZINE));
        next(validateBookMakerMagazineDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.BOOKMAKER_MAGAZINE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.BOOKMAKER_MAGAZINE));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.BOOKMAKER_MAGAZINE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateBookMakerMagazineDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.BOOKMAKER_MAGAZINE.VALIDATE_BOOKMAKER_MAGAZINE_PAGE_DATA
      )
    ) {
      if (BookmakerValidator.validate(action.payload)) {
        return next(enrichBookMakerMagazineDataAction(action.payload));
      }
    }
  };

export const enrichBookMakerMagazineDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type ===
      ActionTypes.BOOKMAKER_MAGAZINE.ENRICH_BOOKMAKER_MAGAZINE_PAGE_DATA
    ) {
      const bookmakerPage = BookmakerPageDataFactory.create(
        action.payload.bookmakerPage
      );
      const bookmakerItems = action.payload.bookmakerItems.map(
        (bookmakerItem: IBookMakerItem) => {
          return BookmakerItemFactory.create(bookmakerItem);
        }
      );

      const bookmakerMagazineData: IBookMakerMagazineState = {
        bookmakerPage,
        bookmakerItems
      };

      next(setBookMakerMagazineDataAction(bookmakerMagazineData));
    }
  };

export default [
  fetchBookMakerMagazineMiddleware,
  validateBookMakerMagazineDataMiddleware,
  enrichBookMakerMagazineDataMiddleware
];

import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';

interface IProps {
  item: any;
}

const BreadCrumbItem: FC<IProps> = ({ item }: IProps): ReactElement => {
  return (
    <li className={`breadcrumb-item ${item.isActive ? 'color-blue' : ''}`}>
      {!item.isActive ? (
        <Link className="text-decoration-none font-color-default" to={item.url}>
          {item.name}
        </Link>
      ) : (
        item.name
      )}
    </li>
  );
};

export default BreadCrumbItem;

import ActionTypes from '../../../constants/ActionTypes';
import { ICheckoutState, ISetCheckoutDataAction } from './types';
import { TransitionSteps } from '../../../constants/TransitionSteps';

const initialState: ICheckoutState = {
  checkoutData: {
    steps: {
      current: {
        name: TransitionSteps.INITIAL_STATE
      },
      next: [],
      previous: []
    },
    orderData: {}
  }
};

const checkoutReducer = (
  state = initialState,
  action: ISetCheckoutDataAction
): ICheckoutState => {
  switch (action.type) {
    case ActionTypes.CHECKOUT.SET_CHECKOUT_DATA:
      return {
        ...state,
        checkoutData: action.payload.data.checkoutData
      };
  }

  return state;
};

export default checkoutReducer;

export const Discounts = {
  QUANTITY_DISCOUNT_PRICE: 'quantityDiscountPrice',
  REGULAR_DISCOUNT_PRICE: 'regularDiscountPrice',
  QUANTITY_DISCOUNT_PREMIUM_PRICE: 'quantityDiscountPremiumPrice',
  REGULAR_DISCOUNT_PREMIUM_PRICE: 'regularDiscountPremiumPrice',
  QUANTITY_DISCOUNT_PERCENTAGE: 'quantityDiscountPercentage',
  REGULAR_DISCOUNT_PERCENTAGE: 'regularDiscountPercentage',
  QUANTITY_DISCOUNT_PREMIUM_PERCENTAGE: 'quantityDiscountPremiumPercentage',
  REGULAR_DISCOUNT_PREMIUM_PERCENTAGE: 'regularDiscountPremiumPercentage'
};

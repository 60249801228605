import { IFactory } from '../../types';
import { IThreeCollectionCarouselsGroup } from '../../../entities/section/types';
import ThreeCollectionCarouselsGroup from '../../../entities/section/threecollectioncarousels/ThreeCollectionCarouselsGroup';
import ProductsFactory from '../../product/ProductsFactory';
import Config from '../../../config';

const ThreeCollectionCarouselsGroupFactory: IFactory = class ThreeCollectionCarouselsGroupFactory {
  static create(
    group: IThreeCollectionCarouselsGroup
  ): ThreeCollectionCarouselsGroup {
    return new ThreeCollectionCarouselsGroup(
      group.groupTitle,
      `${Config.api.mediaUrl}${group.image}`,
      ProductsFactory.create(group.products)
    );
  }
};

export default ThreeCollectionCarouselsGroupFactory;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import CookieNames from '../../../constants/CookieNames';
import { getJsonFromCookie, setCookie } from '../../../utilites/cookies';
import CookieExpiredByDays from '../../../constants/CookieExpiredByDays';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { enrichLastViewedAction, setLastViewedProductsAction } from './actions';
import { getAvailableProducts } from '../../../utilites/product';
import _ from 'lodash';

const LAST_VIEWED_LENGTH = 30;

const lastViewedCookieMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.LAST_VIEWED.ADD_TO_LAST_VIEWED:
        let productIds = getJsonFromCookie(CookieNames.LAST_VIEWED, '[]');

        if (!productIds || productIds.length === 0) {
          setCookie(
            CookieNames.LAST_VIEWED,
            JSON.stringify([action.payload.productId]),
            CookieExpiredByDays.LAST_VIEWED
          );
          return;
        }

        if (Array.isArray(productIds)) {
          if (
            !productIds.includes(action.payload.productId) &&
            productIds.length < LAST_VIEWED_LENGTH
          ) {
            productIds.unshift(action.payload.productId);
          }

          if (
            !productIds.includes(action.payload.productId) &&
            productIds.length >= LAST_VIEWED_LENGTH
          ) {
            productIds.pop();
            productIds.unshift(action.payload.productId);
          }

          if (productIds.includes(action.payload.productId)) {
            const indexOfItem = productIds.indexOf(action.payload.productId);
            productIds.splice(indexOfItem, 1);
            productIds.unshift(action.payload.productId);
          }

          setCookie(
            CookieNames.LAST_VIEWED,
            JSON.stringify(productIds),
            CookieExpiredByDays.LAST_VIEWED
          );
        }

        break;
    }
  };

const fetchLastViewedProductsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.LAST_VIEWED.FETCH_LAST_VIEWED_PRODUCTS_FROM_API:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_LAST_VIEWED_PRODUCTS,
            null,
            null,
            MiddlewareEntities.LAST_VIEWED,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.LAST_VIEWED));
        break;
      case `${MiddlewareEntities.LAST_VIEWED} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.LAST_VIEWED));
        next(enrichLastViewedAction(action.payload.data.data));
        break;
      case `${MiddlewareEntities.LAST_VIEWED} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.LAST_VIEWED));
        break;
    }
  };

export const enrichLastViewedMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.LAST_VIEWED.ENRICH_LAST_VIEWED_PRODUCTS) {
      let products = getAvailableProducts(action.payload.products);
      let productIds = getJsonFromCookie(CookieNames.LAST_VIEWED, '[]');

      if (Array.isArray(productIds) && productIds.length > 0) {
        products = productIds.map((id) => {
          return products.find((product) => product.id === id);
        });
        products = _.compact(products);
      }

      let newProductIds = products.map((product) => product.id);
      setCookie(
        CookieNames.LAST_VIEWED,
        JSON.stringify(newProductIds),
        CookieExpiredByDays.LAST_VIEWED
      );
      next(setLastViewedProductsAction(products));
    }
  };

export default [
  lastViewedCookieMiddleware,
  fetchLastViewedProductsMiddleware,
  enrichLastViewedMiddleware
];

import { IProductListPageState } from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { AnyAction } from 'redux';

const initialState: IProductListPageState | any = {
  products: [],
  totalRecords: 0
};
const productListPageReducer = (
  state = initialState,
  action: AnyAction
): IProductListPageState => {
  switch (action.type) {
    case ActionTypes.PRODUCTS_LIST_PAGE.SET_PRODUCTS_LIST_PAGE_PRODUCTS:
      return {
        ...state,
        products: action.payload.products,
        totalRecords: action.payload.totalRecords
      };
  }
  return state;
};

export default productListPageReducer;

import Validator from '../Validator';
import Ajv from 'ajv';
import { newsSchema } from './newsSchema';
import News from '../../entities/news/News';

export default class NewsValidator extends Validator {
  public static validate(news: News) {
    const ajv = new Ajv();
    return ajv.validate(newsSchema, news);
  }
}

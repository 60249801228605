import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchGamePageDataAction,
  IGamePageState,
  ISetGamePageDataAction
} from './types';

export function fetchGamePageDataAction(): IFetchGamePageDataAction {
  return {
    type: ActionTypes.GAME_PAGE.FETCH_GAME_PAGE_DATA
  };
}

export function setGamePageDataAction(
  gamePageData: IGamePageState
): ISetGamePageDataAction {
  return {
    type: ActionTypes.GAME_PAGE.SET_GAME_PAGE_DATA,
    payload: gamePageData
  };
}

export function validateGamePageDataAction(gamePageData: IGamePageState) {
  return {
    type: ActionTypes.GAME_PAGE.VALIDATE_GAME_PAGE_DATA,
    payload: gamePageData
  };
}

export function enrichGamePageDataAction(gamePageData: any) {
  return {
    type: ActionTypes.GAME_PAGE.ENRICH_GAME_PAGE_DATA,
    payload: gamePageData
  };
}

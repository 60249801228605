import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichTopListsPageDataAction,
  IFetchTopListsPageDataAction,
  ISetTopListsPageDataAction,
  ITopListsPageState,
  IValidateTopListsPageDataAction
} from './types';

export function fetchTopListsPageDataAction(): IFetchTopListsPageDataAction {
  return {
    type: ActionTypes.TOP_LISTS_PAGE.FETCH_TOP_LISTS_PAGE_DATA
  };
}

export function setTopListsPageDataAction(
  topListsPageData: ITopListsPageState
): ISetTopListsPageDataAction {
  return {
    type: ActionTypes.TOP_LISTS_PAGE.SET_TOP_LISTS_PAGE_DATA,
    payload: topListsPageData
  };
}

export function validateTopListsPageDataAction(
  topListsPageData: ITopListsPageState
): IValidateTopListsPageDataAction {
  return {
    type: ActionTypes.TOP_LISTS_PAGE.VALIDATE_TOP_LISTS_PAGE_DATA,
    payload: topListsPageData
  };
}

export function enrichTopListsPageDataAction(
  topListsPageData: any
): IEnrichTopListsPageDataAction {
  return {
    type: ActionTypes.TOP_LISTS_PAGE.ENRICH_TOP_LISTS_PAGE_DATA,
    payload: topListsPageData
  };
}

import { IFactory } from '../types';
import { IOrderItem } from '../../entities/order/types';
import ProductFactory from '../product';
import OrderItem from '../../entities/order/OrderItem';

const OrderItemFactory: IFactory = class OrderItemFactory {
  static create(orderItem: IOrderItem) {
    return new OrderItem(
      orderItem.id,
      orderItem.order_id,
      ProductFactory.create(orderItem.product),
      orderItem.final_price,
      orderItem.quantity,
    );
  }
};

export default OrderItemFactory;

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import { setAddCommentStatusAction, setCommentsAction } from './actions';

export const fetchAllCommentsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.COMMENTS.FETCH_ALL_COMMENTS:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.PC_FRONTEND_API +
              '/' +
              action.payload.productId +
              '/comments',
            null,
            null,
            MiddlewareEntities.COMMENTS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.COMMENTS));
        break;
      case `${MiddlewareEntities.COMMENTS} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.COMMENTS));
        next(setCommentsAction(action.payload.data));
        break;
      case `${MiddlewareEntities.COMMENTS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.COMMENTS));
        next(setAddCommentStatusAction('error'));
        break;
      case ActionTypes.COMMENTS.ADD_COMMENT:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.ADD_COMMENT + '/' + action.payload.productId,
            null,
            action.payload.addCommentBody,
            MiddlewareEntities.COMMENT,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.COMMENT));
        break;
      case `${MiddlewareEntities.COMMENT} ${ActionTypes.API_SUCCESS}`:
        next(setAddCommentStatusAction('success'));
        next(setLoader(false, MiddlewareEntities.COMMENT));
        break;
      case `${MiddlewareEntities.COMMENT} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.COMMENT));
        next(setAddCommentStatusAction('error'));
        break;
    }
  };

export default [fetchAllCommentsMiddleware];

import React, { ReactElement } from 'react';

interface IProps {
  active?: boolean;
  dirty?: boolean;
  error?: string;
  touched?: boolean;
  classes?: string;
}

const Error: React.FC<IProps> = (props: IProps): ReactElement | null => {
  if (props.touched && props.error) {
    return (
      <div className={`${props.classes} mt-1 ml-1 text-14`}>
        <span className="alert-danger">{props.error}</span>
      </div>
    );
  }

  return null;
};

export default Error;

export const NotificationTypes = {
  DELAY_NOTIFICATION_DISMISS: 'delayNotificationDismiss',
  INFO: 'info',
  WARNING: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
  CRITICAL: 'critical'
};

export default NotificationTypes;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichProductByNavIdAction,
  setProductByNavIdAction,
  validateProductByNavIdAction
} from './actions';
import ProductValidator from '../../../validators/product';
import ProductFactory from '../../../factories/product';

export const fetchProductPageByNavIdMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.PRODUCT_BY_NAV_ID.FETCH_PRODUCT_BY_NAV_ID:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.PRODUCT_BY_NAV_ID + '/' + action.payload.navId,
            null,
            null,
            MiddlewareEntities.PRODUCT_BY_NAV_ID,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PRODUCT_BY_NAV_ID));
        break;
      case `${MiddlewareEntities.PRODUCT_BY_NAV_ID} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.PRODUCT_BY_NAV_ID));
        next(validateProductByNavIdAction(action.payload.data.data));
        break;
      case `${MiddlewareEntities.PRODUCT_BY_NAV_ID} ${ActionTypes.API_ERROR}`:
        break;
      case `${ActionTypes.PRODUCT_BY_NAV_ID.RESET_PRODUCT_BY_NAV_ID}`:
        next(setProductByNavIdAction(null));
        break;
    }
  };

export const validateProductByNavIdMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.PRODUCT_BY_NAV_ID.VALIDATE_PRODUCT_BY_NAV_ID
      )
    ) {
      if (ProductValidator.validate({ product: action.payload })) {
        next(enrichProductByNavIdAction(action.payload));
      }
    }
  };

export const enrichProductByNavIdMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type === ActionTypes.PRODUCT_BY_NAV_ID.ENRICH_PRODUCT_BY_NAV_ID
    ) {
      const product = ProductFactory.create(action.payload);
      next(setProductByNavIdAction(product));
    }
  };

export default [
  fetchProductPageByNavIdMiddleware,
  validateProductByNavIdMiddleware,
  enrichProductByNavIdMiddleware
];

import React, { ReactElement } from 'react';
import Header from './header';
import LayoutBody from './LayoutBody';
import { Outlet } from 'react-router-dom';
import Notification from '../components/notification';
import DelfiScrollManager from './DelfiScrollManager';

const Layout: React.FC = (): ReactElement => (
  <DelfiScrollManager>
    <LayoutBody>
      <Header />
      <Notification />
      <Outlet />
    </LayoutBody>
  </DelfiScrollManager>
);

export default Layout;

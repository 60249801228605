import { IAuthor } from '../entities/product/types';

/**
 *
 * @returns {*}
 */
export const detectIE = (): number | boolean => {
  const { userAgent } = window.navigator;
  const msie = userAgent.indexOf('MSIE ');

  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(
      userAgent.substring(msie + 5, userAgent.indexOf('.', msie)),
      10
    );
  }
  const trident = userAgent.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = userAgent.indexOf('rv:');
    return parseInt(
      userAgent.substring(rv + 3, userAgent.indexOf('.', rv)),
      10
    );
  }
  const edge = userAgent.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(
      userAgent.substring(edge + 5, userAgent.indexOf('.', edge)),
      10
    );
  }
  // other browser
  return false;
};

export const capitalizeFirstLetter = (text?: string): string => {
  if (text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return '';
};

export const createMarkup = (text: string) => {
  return { __html: text };
};

export const formatDateWithDots = (date: Date): string => {
  const year = date.getFullYear();
  let month: string | number = date.getMonth() + 1;
  let day: string | number = date.getDate();

  if (day < 10) {
    day = `0${day}`;
  }

  if (month < 10) {
    month = `0${month}`;
  }

  return `${day}.${month}.${year}.`;
};

export const stripStringFromHtml = (html: string): string => {
  const dom = new DOMParser().parseFromString(html, 'text/html');
  const textContent = dom.body.textContent;

  if (textContent && textContent.length > 1) {
    return textContent;
  }

  return '';
};

export const checkIfNumberExists = (number: string): boolean => {
  return !!(number && parseInt(number) > 0);
};

export const getSliceArray = (from: number, to: number, arr: any[]) => {
  const sliceArr = [...arr];

  return sliceArr.slice(from, to);
};

export const isIncluded = (arr: string[], includeStr: string) => {
  return arr.includes(includeStr);
};

export const openWindowPopup = (
  url: string,
  target: string,
  features?: string
) => {
  window.open(url, target, features);
};

export const getShortDescription = (
  text: string,
  start: number,
  end: number,
  isRenderDots?: boolean
) => {
  return stripStringFromHtml(text) !== ''
    ? `${stripStringFromHtml(text).substring(start, end)}${
        isRenderDots ? '...' : ''
      }`
    : '';
};

export const replaceStringWith = (
  str: string,
  regularExpression: RegExp | string,
  stringWillReplace: string
) => {
  return str
    .toLowerCase()
    .replace(/[šđčćž]/g, (match: string): string => {
      switch (match) {
        case 'š':
          return 's';
        case 'đ':
          return 'dj';
        case 'č':
          return 'c';
        case 'ć':
          return 'c';
        case 'ž':
          return 'z';
        case 'dž':
          return 'dz';
        default:
          return 's';
      }
    })
    .replace(regularExpression, stringWillReplace);
};

export const formatPhoneNumber = (str: string, replaceWith: string) => {
  if (str.startsWith('+')) {
    return `+${str.replace(/\D/g, replaceWith)}`;
  }
  return str.replace(/\D/g, replaceWith);
};

export const getAuthorsId = (authors: IAuthor[] | string) => {
  let authorsId = '';
  if (Array.isArray(authors)) {
    authors.map((author: any, index: number, authors) => {
      authorsId += author._id;
      if (index + 1 !== authors.length) {
        authorsId += ',';
      }
    });
  }

  return authorsId;
};

export const checkIsIphone = () => {
  return /iPhone/i.test(navigator.userAgent);
};

export const validDateFormat = (value: any): boolean => {
  try {
    if (value) {
      let date: Date = new Date(value);
      return !isNaN(date.getTime());
    }
  } catch (e) {
    return false;
  }

  return false;
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const firstLetterSmall = (string: string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

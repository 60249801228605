import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  validateSchoolBookDataAction,
  enrichSchoolBookDataAction,
  setSchoolBookDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchSchoolBookDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.SCHOOL_BOOK.FETCH_SCHOOL_BOOK_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_SCHOOL_BOOK,
            { ...action.params },
            null,
            MiddlewareEntities.SCHOOL_BOOK,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.SCHOOL_BOOK));
        break;
      case `${MiddlewareEntities.SCHOOL_BOOK} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.SCHOOL_BOOK));
        next(validateSchoolBookDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.SCHOOL_BOOK} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.SCHOOL_BOOK));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.SCHOOL_BOOK,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateSchoolBookDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.SCHOOL_BOOK.VALIDATE_SCHOOL_BOOK_DATA)
    ) {
      next(
        enrichSchoolBookDataAction({
          sections: SectionDataFacade.generate(action.payload.sections),
          metaDescription: action.payload.metaDescription
        })
      );
    }
  };

export const enrichSchoolBookDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.SCHOOL_BOOK.ENRICH_SCHOOL_BOOK_DATA) {
      next(setSchoolBookDataAction(action.payload));
    }
  };

export default [
  fetchSchoolBookDataMiddleware,
  validateSchoolBookDataMiddleware,
  enrichSchoolBookDataMiddleware
];

import { IFactory } from '../types';
import { IVisaPremiumBenefitReport } from '../../entities/cart/types';
import VisaPremiumBenefitReport from '../../entities/benefits/VisaPremiumBenefit';
import VisaPremiumBenefitPayloadFactory from './VisaPremiumPayloadFactory';
const VisaPremiumBenefitFactory: IFactory = class VisaPremiumBenefitFactory {
  static create(visaPremiumBenefitReport: IVisaPremiumBenefitReport) {
    return new VisaPremiumBenefitReport(
      visaPremiumBenefitReport.state,
      VisaPremiumBenefitPayloadFactory.create(visaPremiumBenefitReport.payload)
    );
  }
};

export default VisaPremiumBenefitFactory;

import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect, useStore } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { AppState } from '../../app/store/rootReducers';
import { useLocation } from 'react-router-dom';

const DelfiScrollManager = ({ children }: any) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [positionMap, setPositionMap]: any = useState(new Map());

  const store: any = useStore();

  const location = useLocation();

  useEffect(() => {
    // console.log('Location changed!', location.pathname);

    const keysInMap = Array.from(positionMap.keys());
    const lastKeyInMap = keysInMap[keysInMap.length - 1];
    const beforeLastKeyInMap = keysInMap[keysInMap.length - 2];

    if (['/vesti'].indexOf(location.pathname) === 0) {
      return;
    }

    if (['/uslovi_koriscenja'].indexOf(location.pathname) === 0) {
      return;
    }

    // Scenario 1
    if (
      !positionMap.get(location.pathname + location.search) ||
      ['/kupovina'].indexOf(location.pathname + location.search) !== -1
    ) {
      // console.log('Scenario 1 - do not scroll');
      // setPositionMap(new Map(positionMap.set(location.pathname + location.search, 0)));
      let pageLoadObserver = window.setInterval(() => {
        if (store.getState().ui.loaders.length === 0) {
          clearInterval(pageLoadObserver);
          setTimeout(() => {
            // console.log('Page is ready now.');
            // console.log('POS:', 0);
            window.scrollTo({
              left: 0,
              top: 0,
              // @ts-ignore
              behavior: 'instant'
            });
            setScrollPosition(window.pageYOffset);
            setPositionMap(
              new Map(positionMap.set(location.pathname + location.search, 0))
            );
            // console.log('SCROLL to TOP for Scenario 1');
          }, 500);
        } else {
          // console.log('We will wait a little bit...');
        }
      }, 50);
    } else if (beforeLastKeyInMap === location.pathname + location.search) {
      // console.log('Scenario 2 - SCROLL to BEST');
      // console.log('positionMap dump:', positionMap);
      positionMap.delete(lastKeyInMap);
      setPositionMap(new Map(positionMap));
      let pageLoadObserver = window.setInterval(() => {
        if (store.getState().ui.loaders.length === 0) {
          clearInterval(pageLoadObserver);
          // console.log('Page is ready now.');
          let pageHeightCheckerClock = setInterval(() => {
            // console.log('HEIGHT: ', document.body.scrollHeight);
            if (
              document.body.scrollHeight >=
              positionMap.get(location.pathname + location.search)
            ) {
              clearInterval(pageHeightCheckerClock);
              // console.log('X POS:', positionMap.get(location.pathname + location.search));
              window.scrollTo({
                left: 0,
                top: positionMap.get(location.pathname + location.search),
                // @ts-ignore
                behavior: 'instant'
              });
              // console.log('X SCROLL 2');
            }
          }, 300);
        } else {
          // console.log('We will wait a little bit...');
        }
      }, 100);
    } else if (positionMap.get(location.pathname + location.search)) {
      positionMap.delete(location.pathname + location.search);
      // console.log('Scenario 3 - do not scroll down');
      // console.log('positionMap dump:', positionMap);
      // setPositionMap(new Map(positionMap.set(location.pathname + location.search, 0)));
      // window.scrollTo({
      //               left: 0,
      //               top: 0,
      //               // @ts-ignore
      //               behavior: 'instant'
      //             });
      let pageLoadObserver = window.setTimeout(() => {
        if (store.getState().ui.loaders.length === 0) {
          setTimeout(() => {
            clearInterval(pageLoadObserver);
            // console.log('Page is ready now.');
            // console.log('POS:', 0);
            window.scrollTo({
              left: 0,
              top: 0,
              // @ts-ignore
              behavior: 'instant'
            });
            setScrollPosition(window.pageYOffset);
            setPositionMap(
              new Map(positionMap.set(location.pathname + location.search, 0))
            );
            // console.log('SCROLL to TOP for Scenario 3');
          }, 500);
        } else {
          // console.log('We will wait a little bit...');
        }
      }, 50);
    }
    // console.log(positionMap);
  }, [location]);

  useEffect(() => {
    // console.log('Delfi Scroll Manager Loaded');
    const readyString =
      store.getState().ui.loaders.length > 0
        ? 'Page is ready.'
        : 'Page isn`t ready!';
    // console.log('Initial status:', readyString);

    return () => {
      // console.log('Delfi Scroll Manager will be unmounted!');
    };
  }, []);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;
    // function to run on scroll
    const updateScrollPosition = () => {
      // @ts-ignore
      setScrollPosition(window.pageYOffset);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      // @ts-ignore
      positionMap.set(location.pathname + location.search, scrollPosition);
      // console.log(positionMap);
    };
    window.addEventListener('scroll', updateScrollPosition); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollPosition); // clean up
    };
  }, [scrollPosition]); // run when scroll direction changes

  return <>{children}</>;
};

const mapStateToProps = (state: AppState) => ({
  starWayData: state.starWayData.data
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DelfiScrollManager);

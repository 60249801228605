import { ISchoolBookState, ISetSchoolBookDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: ISchoolBookState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const schoolBookDataReducer = (
  state = initialState,
  action: ISetSchoolBookDataAction
): ISchoolBookState => {
  switch (action.type) {
    case ActionTypes.SCHOOL_BOOK.SET_SCHOOL_BOOK_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default schoolBookDataReducer;

import {
  IAddToLastViewedAction,
  IFetchLastViewedFromCookieAction,
  IFetchLastViewedProductsFromApiAction,
  ISetLastViewedProductsAction,
  ISetLastViewedToCookieAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { IProduct } from '../../../entities/product/types';

export const addToLastViewedAction = (
  productId: string
): IAddToLastViewedAction => ({
  type: ActionTypes.LAST_VIEWED.ADD_TO_LAST_VIEWED,
  payload: {
    productId
  }
});

export const setToLastViewedCookieAction = (
  productIds: string[]
): ISetLastViewedToCookieAction => ({
  type: ActionTypes.LAST_VIEWED.SET_LAST_VIEWED_TO_COOKIE,
  payload: {
    productIds
  }
});

export const fetchLastViewedFromCookieAction =
  (): IFetchLastViewedFromCookieAction => ({
    type: ActionTypes.LAST_VIEWED.FETCH_LAST_VIEWED_FROM_COOKIE
  });

export const fetchLastViewedProductsFromApiAction =
  (): IFetchLastViewedProductsFromApiAction => ({
    type: ActionTypes.LAST_VIEWED.FETCH_LAST_VIEWED_PRODUCTS_FROM_API
  });

export const enrichLastViewedAction = (products: IProduct[]) => ({
  type: ActionTypes.LAST_VIEWED.ENRICH_LAST_VIEWED_PRODUCTS,
  payload: {
    products
  }
});

export const setLastViewedProductsAction = (
  products: IProduct[]
): ISetLastViewedProductsAction => ({
  type: ActionTypes.LAST_VIEWED.SET_LAST_VIEWED_PRODUCTS,
  payload: {
    products
  }
});

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichMangePageDataAction,
  setMangePageDataAction,
  validateMangePageDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchMangePageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.MANGE_PAGE.FETCH_MANGE_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_MANGE_PAGE_DATA,
            { ...action.params },
            null,
            MiddlewareEntities.MANGE_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.MANGE_PAGE));
        break;
      case `${MiddlewareEntities.MANGE_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.MANGE_PAGE));
        next(validateMangePageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.MANGE_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.MANGE_PAGE));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.MANGE_PAGE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateMangePageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.MANGE_PAGE.VALIDATE_MANGE_PAGE_DATA)) {
      next(
        enrichMangePageDataAction({
          sections: SectionDataFacade.generate(action.payload.sections),
          metaDescription: action.payload.metaDescription
        })
      );
    }
  };

export const enrichMangePageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.MANGE_PAGE.ENRICH_MANGE_PAGE_DATA) {
      next(setMangePageDataAction(action.payload));
    }
  };

export default [
  fetchMangePageDataMiddleware,
  validateMangePageDataMiddleware,
  enrichMangePageDataMiddleware
];

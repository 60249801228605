import ActionTypes from '../../../constants/ActionTypes';
import {
  IAdvancedSearchState,
  IFetchAdvancedSearchPageDataAction,
  ISetAdvancedSearchPageDataAction
} from './types';

export function fetchAdvancedSearchPageDataAction(
  // isOverride: boolean,
  queryString: string | null
): IFetchAdvancedSearchPageDataAction {
  return {
    type: ActionTypes.ADVANCED_SEARCH.FETCH_ADVANCED_SEARCH_DATA,
    payload: {
      // isOverride,
      queryString
    }
  };
}

export function setAdvancedSearchPageDataAction(
  advancedSearchPageData: IAdvancedSearchState
): ISetAdvancedSearchPageDataAction {
  return {
    type: ActionTypes.ADVANCED_SEARCH.SET_ADVANCED_SEARCH_DATA,
    payload: advancedSearchPageData
  };
}

export function validateAdvancedSearchPageDataAction(
  advancedSearchPageData: IAdvancedSearchState
) {
  return {
    type: ActionTypes.ADVANCED_SEARCH.VALIDATE_ADVANCED_SEARCH_DATA,
    payload: advancedSearchPageData
  };
}

export function enrichAdvancedSearchPageDataAction(
  advancedSearchPageData: IAdvancedSearchState
) {
  return {
    type: ActionTypes.ADVANCED_SEARCH.ENRICH_ADVANCED_SEARCH_DATA,
    payload: advancedSearchPageData
  };
}

import { IBookMakerMagazineState, ISetBookMakerMagazineAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IBookMakerMagazineState = {
  bookmakerPage: null,
  bookmakerItems: []
};
const bookMakerMagazinePageDataReducer = (
  state = initialState,
  action: ISetBookMakerMagazineAction
): IBookMakerMagazineState => {
  switch (action.type) {
    case ActionTypes.BOOKMAKER_MAGAZINE.SET_BOOKMAKER_MAGAZINE_PAGE_DATA:
      return {
        ...state,
        bookmakerPage: action.payload.bookmakerPage,
        bookmakerItems: action.payload.bookmakerItems
      };
  }
  return state;
};

export default bookMakerMagazinePageDataReducer;

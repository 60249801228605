import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { setAdministrativeBanDataAction } from './actions';
import { createNotification } from '../notification/actions';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchAdministrativeBanMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.ADMINISTRATIVE_BAN.FETCH_ADMINISTRATIVE_BAN_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_ADMINISTRATIVE_BAN,
            { ...action.params },
            null,
            MiddlewareEntities.ADMINISTRATIVE_BAN,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.ADMINISTRATIVE_BAN));
        break;
      case `${MiddlewareEntities.ADMINISTRATIVE_BAN} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.ADMINISTRATIVE_BAN));
        next(setAdministrativeBanDataAction(action.payload));
        break;
      case `${MiddlewareEntities.ADMINISTRATIVE_BAN} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.ADMINISTRATIVE_BAN));
        next(
          createNotification(
            MiddlewareEntities.ADMINISTRATIVE_BAN,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );
        break;
    }
  };

export default [fetchAdministrativeBanMiddleware];

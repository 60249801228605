import ActionTypes from '../../../constants/ActionTypes';
import {
  IComplaintPageDataState,
  IFetchComplaintPageDataAction,
  ISetComplaintPageDataAction,
  ISubmitComplaintPageData,
  ISubmitComplaintPageDataAction
} from './types';

export function fetchComplaintPageDataAction(): IFetchComplaintPageDataAction {
  return {
    type: ActionTypes.COMPLAINT_PAGE.FETCH_COMPLAINT_PAGE_DATA
  };
}

export function setComplaintPageDataAction(
  compliantPageData: IComplaintPageDataState
): ISetComplaintPageDataAction {
  return {
    type: ActionTypes.COMPLAINT_PAGE.SET_COMPLAINT_PAGE_DATA,
    payload: compliantPageData
  };
}

export function submitComplaintDataAction(
  complaintData: FormData
): ISubmitComplaintPageDataAction {
  return {
    type: ActionTypes.COMPLAINT_PAGE.SUBMIT_COMPLAINT_DATA,
    payload: {
      data: complaintData
    }
  };
}

import {
  ICreateUserRequestAction,
  ISetUserAction,
  ISetUserMessageAction,
  IUserLoginAction,
  IUserLogoutAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { ICreateUserDto, IUser } from '../../../entities/user/types';
import { ILoginRequest } from '../../../views/components/login-form/types';

export const userLoginAction = (
  userLoginRequest: ILoginRequest
): IUserLoginAction => ({
  type: ActionTypes.USER.USER_LOGIN,
  payload: {
    userLoginRequest
  }
});

export const userLogoutAction = (): IUserLogoutAction => ({
  type: ActionTypes.USER.USER_LOGOUT
});

export const fetchUserAction = (): any => ({
  type: ActionTypes.USER.FETCH_USER
});

export const setUserAction = (user: IUser): ISetUserAction => ({
  type: ActionTypes.USER.SET_USER,
  payload: {
    user
  }
});

export const createUserRequestAction = (
  user: ICreateUserDto
): ICreateUserRequestAction => ({
  type: ActionTypes.USER.CREATE_USER,
  payload: {
    user
  }
});

export const removeUserCookieAction = (): any => ({
  type: ActionTypes.USER.REMOVE_USER_COOKIE
});

export const setUserMessageAction = (
  message: string,
  status: string
): ISetUserMessageAction => ({
  type: ActionTypes.USER.SET_USER_MESSAGE,
  payload: {
    message,
    status
  }
});

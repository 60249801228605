import ActionTypes from '../../../constants/ActionTypes';
import { ISubscribeNewsLetterState } from './types';

const initialState: ISubscribeNewsLetterState = {};

const newsletterReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case ActionTypes.NEWSLETTER.SET_SUBSCRIBE_NEWSLETTER_MESSAGE:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      };
  }

  return state;
};

export default newsletterReducer;

import { IFactory } from '../types';
import Cart from '../../entities/cart/Cart';
import { ICart } from '../../entities/cart/types';
import CartOrderItemPriceFactory from './CartOrderItemPriceFactory';
import { ICartOrderItem } from '../../app/store/cart/types';
import BenefitReportFactory from '../benefits/BenfitReportFactory';

const CartFactory: IFactory = class CartItemFactory {
  static create(cart: ICart) {
    let sum = 0;
    let fullItemsPrice = 0;
    const orderItemsPriceReport = cart.orderItemsPriceReport.map(
      (item: ICartOrderItem) => {
        sum += item.quantity;
        fullItemsPrice += Number(item.fullPrice) * item.quantity;
        return CartOrderItemPriceFactory.create(item);
      }
    );

    return new Cart(
      orderItemsPriceReport,
      cart.benefitsReport && Object.keys(cart.benefitsReport).length > 0
        ? BenefitReportFactory.create(cart.benefitsReport)
        : null,
      cart.availablePaymentTypes,
      cart.administrativeBanReport &&
      Object.keys(cart.administrativeBanReport).length > 0
        ? cart.administrativeBanReport
        : null,
      cart.deliveryService,
      cart.allOrderItemsAmount,
      cart.pttPrice,
      cart.totalAmount,
      Math.ceil(Number(cart.amountUpToFreeShipping)),
      sum,
      Number(fullItemsPrice) - Number(cart.allOrderItemsAmount),
      cart.calculatedPremiumPrice,
      fullItemsPrice
    );
  }
};

export default CartFactory;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IBookPageState,
  IFetchBookPageDataAction,
  ISetBookPageDataAction
} from './types';

export function fetchBookPageDataAction(): IFetchBookPageDataAction {
  return {
    type: ActionTypes.BOOK_PAGE.FETCH_BOOK_PAGE_DATA
  };
}

export function setBookPageDataAction(
  bookPageData: IBookPageState
): ISetBookPageDataAction {
  return {
    type: ActionTypes.BOOK_PAGE.SET_BOOK_PAGE_DATA,
    payload: bookPageData
  };
}

export function validateBookPageDataAction(bookPageData: IBookPageState) {
  return {
    type: ActionTypes.BOOK_PAGE.VALIDATE_BOOK_PAGE_DATA,
    payload: bookPageData
  };
}

export function enrichBookPageDataAction(bookPageData: any) {
  return {
    type: ActionTypes.BOOK_PAGE.ENRICH_BOOK_PAGE_DATA,
    payload: bookPageData
  };
}

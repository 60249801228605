import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { createNotification } from '../notification/actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import {
  setEdenBooksErrorUserMessageAction,
  setForgotUserEdenPasswordAction,
  setResetEdenBooksPasswordAction
} from './actions';
import { isTokenExpired } from '../../../utilites/token';
import OrderStatuses from '../../../constants/OrderStatuses';
import { fetchProductOverviewPageAction } from '../product-overview/actions';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import { ErrorMessages } from '../../../constants/ErrorMessages';
import NotificationTypes from '../../../constants/NotificationTypes';

export const createEdenBooksOrderMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.EDEN_BOOKS.EDEN_BOOKS_CREATE_ORDER:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.EDEN_BOOKS_CREATE_ORDER,
            null,
            action.payload,
            MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER));
        break;
      case `${MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER));
        if (
          action.payload.data.orderStatus &&
          action.payload.data.orderStatus ===
            OrderStatuses.READY_FOR_ONLINE_PAYMENT
        ) {
          return (window.location.href = `/placanje-s1?id=${action.payload.data.orderSecret}`);
        }

        next(
          createNotification(
            MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER,
            NotificationTypes.ERROR,
            ErrorMessages.DEFAULT
          )
        );

        break;
      case `${MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER));

        const data = action.payload.data;

        if (
          data &&
          data.data &&
          data.data.metadata &&
          data.data.metadata.meta &&
          data.data.metadata.meta.errorCode &&
          data.data.metadata.meta.errorCode.includes(
            ErrorCodes.CHANGE_PURCHASE_TERMS
          ) &&
          data.config &&
          data.config.data
        ) {
          try {
            const requestData = JSON.parse(action.payload.data.config.data);
            next(
              createNotification(
                MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER,
                NotificationTypes.DELAY_NOTIFICATION_DISMISS,
                undefined,
                action.payload.data.data
              )
            );
            return store.dispatch(
              fetchProductOverviewPageAction(requestData.oldProductId)
            );
          } catch (e) {}
        }

        if (
          data &&
          data.data &&
          data.data.code &&
          (ErrorCodes.WRONG_CREDENTIALS.includes(data.data.code) ||
            data.data.code === ErrorCodes.BAD_FORMAT)
        ) {
          return next(
            setEdenBooksErrorUserMessageAction(
              data.userMessage
                ? data.userMessage
                : 'Vaši pristupni podaci nisu ispravni. Molimo unesite validnu email adresu i lozinku za Eden nalog'
            )
          );
        }

        if (
          data &&
          data.data &&
          ErrorCodes.EBOOK_ALREADY_EXISTS.includes(data.data.code) &&
          data.data.metadata &&
          data.data.metadata.meta &&
          data.data.metadata.meta.errorCode &&
          ErrorCodes.EBOOK_ALREADY_EXISTS.includes(
            data.data.metadata.meta.errorCode
          )
        ) {
          return next(
            setEdenBooksErrorUserMessageAction(
              data.userMessage
                ? data.userMessage
                : 'Knjiga koju želite da kupite se već nalazi među Vašim ranije kupljenim knjigama na Eden nalogu'
            )
          );
        }

        next(
          createNotification(
            MiddlewareEntities.EDEN_BOOKS_CREATE_ORDER,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );

        break;
    }
  };

export const sendForgotUserEdenPasswordMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.EDEN_BOOKS.SEND_FORGOT_USER_EDEN_PASSWORD_REQUEST:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.EDEN_BOOKS_SEND_FORGOT_PASSWORD_EMAIL,
            null,
            action.payload.sendForgotUserEdenPasswordReq,
            MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD,
            {}
          )
        );
        next(
          setLoader(true, MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD)
        );
        break;
      case `${MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD} ${ActionTypes.API_SUCCESS}`:
        next(
          setLoader(false, MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD)
        );
        next(setForgotUserEdenPasswordAction(true));
        next(
          createNotification(
            MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD,
            NotificationTypes.SUCCESS,
            'Uspešno ste poslali podatke. Uskoro će Vam stići email'
          )
        );
        break;
      case `${MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD} ${ActionTypes.API_ERROR}`:
        next(
          setLoader(false, MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD)
        );
        next(setForgotUserEdenPasswordAction(false));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.EDEN_BOOKS_USER_FORGOT_PASSWORD,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const sendResetEdenBooksPasswordMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.EDEN_BOOKS.SEND_RESET_USER_EDEN_PASSWORD_REQUEST:
        if (
          !isTokenExpired(action.payload.sendResetEdenBooksPasswordReq.date)
        ) {
          next(
            apiRequest(
              null,
              'POST',
              ApiUrls.EDEN_BOOKS_RESET_USER_PASSWORD,
              null,
              action.payload.sendResetEdenBooksPasswordReq,
              MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD,
              {}
            )
          );

          return next(
            setLoader(true, MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD)
          );
        }
        next(
          createNotification(
            MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD,
            NotificationTypes.ERROR,
            'Token je istekao!'
          )
        );
        break;
      case `${MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD} ${ActionTypes.API_SUCCESS}`:
        next(
          setLoader(false, MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD)
        );
        next(setResetEdenBooksPasswordAction(true));
        next(
          createNotification(
            MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD,
            NotificationTypes.SUCCESS,
            'Vaša pristupna lozinka za Eden nalog je uspešno izmenjena.'
          )
        );
        break;
      case `${MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD} ${ActionTypes.API_ERROR}`:
        next(
          setLoader(false, MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD)
        );
        next(setResetEdenBooksPasswordAction(false));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.EDEN_BOOKS_USER_RESET_PASSWORD,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export default [
  createEdenBooksOrderMiddleware,
  sendForgotUserEdenPasswordMiddleware,
  sendResetEdenBooksPasswordMiddleware
];

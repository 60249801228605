import {
  IAddToWishListAction,
  IRemoveFromWishListAction,
  IFetchWishListFromCookieAction,
  IFetchWishListRequestAction,
  ISetWishListAction,
  IWishListSetInitialStateAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { IProduct } from '../../../entities/product/types';

export const addToWishListAction = (
  productId: string
): IAddToWishListAction => ({
  type: ActionTypes.WISHLIST.ADD_TO_WISHLIST,
  payload: {
    productId
  }
});

export const removeFromWishListAction = (
  productId: string
): IRemoveFromWishListAction => ({
  type: ActionTypes.WISHLIST.REMOVE_FROM_WISHLIST,
  payload: {
    productId
  }
});

export const setToWishListAction = (
  productIds: string[]
): ISetWishListAction => ({
  type: ActionTypes.WISHLIST.SET_WISHLIST,
  payload: {
    productIds
  }
});

export const fetchWishListFromCookieAction =
  (): IFetchWishListFromCookieAction => ({
    type: ActionTypes.WISHLIST.FETCH_WISHLIST_FROM_COOKIE
  });

export const fetchWishListRequestAction = (): IFetchWishListRequestAction => ({
  type: ActionTypes.WISHLIST.FETCH_WISHLIST_REQUEST
});

export const validateWishListAction = (products: IProduct[]) => ({
  type: ActionTypes.WISHLIST.VALIDATE_WISHLIST,
  payload: {
    products
  }
});

export const enrichWishlistAction = (products: IProduct[]) => ({
  type: ActionTypes.WISHLIST.ENRICH_WISHLIST,
  payload: {
    products
  }
});

export const setWishlistFromApiAction = (products: IProduct[]) => ({
  type: ActionTypes.WISHLIST.SET_WISHLIST_FROM_API,
  payload: {
    products
  }
});

export const saveWishlistRequestAction = () => ({
  type: ActionTypes.WISHLIST.SAVE_TO_WISHLIST_REQUEST
});

export const setInitialWishlistAction = (
  productIds: string[]
): IWishListSetInitialStateAction => ({
  type: ActionTypes.WISHLIST.SET_INITIAL_WISHLIST,
  payload: productIds
});

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import { setCountriesAction } from './actions';
import { createNotification, setNotification } from '../notification/actions';
import NotificationFactory from '../../../factories/notification/NotificationFactory';
import { generateErrorMessage } from '../../../utilites/error';
import NotificationTypes from '../../../constants/NotificationTypes';

const countriesMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.COUNTRIES.FETCH_COUNTRIES:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_COUNTRIES,
            null,
            null,
            MiddlewareEntities.COUNTRY,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.COUNTRY));
        break;
      case `${MiddlewareEntities.COUNTRY} ${ActionTypes.API_SUCCESS}`:
        next(setCountriesAction(action.payload.data.data));
        next(setLoader(false, MiddlewareEntities.COUNTRY));
        break;
      case `${MiddlewareEntities.COUNTRY} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.COUNTRY));
        next(
          createNotification(
            MiddlewareEntities.COUNTRY,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );
        break;
    }
  };

export default countriesMiddleware;

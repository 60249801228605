import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichPublisherPageDataAction,
  IFetchPublisherPageDataAction,
  IPublisherPageDataState,
  ISetPublisherPageDataAction,
  IValidatePublisherPageDataAction
} from './types';

export function fetchPublisherPageDataAction(
  publisherName: string
): IFetchPublisherPageDataAction {
  return {
    type: ActionTypes.PUBLISHER_PAGE.FETCH_PUBLISHER_PAGE_DATA,
    payload: { publisherName }
  };
}

export function setPublisherPageDataAction(
  publisherPageData: IPublisherPageDataState
): ISetPublisherPageDataAction {
  return {
    type: ActionTypes.PUBLISHER_PAGE.SET_PUBLISHER_PAGE_DATA,
    payload: publisherPageData
  };
}

export function validatePublisherPageDataAction(
  publisherPageData: IPublisherPageDataState
): IValidatePublisherPageDataAction {
  return {
    type: ActionTypes.PUBLISHER_PAGE.VALIDATE_PUBLISHER_PAGE_DATA,
    payload: publisherPageData
  };
}

export function enrichPublisherPageDataAction(
  publisherPageData: IPublisherPageDataState
): IEnrichPublisherPageDataAction {
  return {
    type: ActionTypes.PUBLISHER_PAGE.ENRICH_PUBLISHER_PAGE_DATA,
    payload: publisherPageData
  };
}

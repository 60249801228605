import ProminentCarouselContent from '../../../entities/section/prominent-carousel/ProminentCarouselContent';
import { IProminentCarouselContent } from '../../../entities/section/types';
import { IFactory } from '../../types';
import {
  getAvailableProduct,
  getAvailableProducts,
  replaceDotWithUrl
} from '../../../utilites/product';

const ProminentCarouselContentFactory: IFactory = class ProminentCarouselContentFactory {
  static create(content: IProminentCarouselContent) {
    return new ProminentCarouselContent(
      content._id,
      content.title,
      content.priceDisplayType,
      content.prominentTitle,
      content.prominentSubtitle,
      content.prominentDescription,
      replaceDotWithUrl(content.prominentImg),
      getAvailableProduct(content.prominentProduct),
      getAvailableProducts(content.products),
      content.startAt,
      content.endAt,
      content.isTopList,
      content.viewAllUrl
    );
  }
};

export default ProminentCarouselContentFactory;

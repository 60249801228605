import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import {
  enrichAvailableBookstoresAction,
  setAvailableBookstoresAction,
  validateAvailableBookstoresAction
} from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import BookstoreFactory from '../../../factories/bookstore/BookstoreFactory';
import BookstoreValidator from '../../../validators/bookstore';

const fetchAvailableBookstoresMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PRODUCT_PAGE.FETCH_AVAILABLE_BOOKSTORES:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.FETCH_AVAILABLE_BOOKSTORES}/${action.payload.navId}`,
            null,
            null,
            MiddlewareEntities.BOOKSTORES,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.BOOKSTORES));
        break;
      case `${MiddlewareEntities.BOOKSTORES} ${ActionTypes.API_SUCCESS}`:
        next(validateAvailableBookstoresAction(action.payload.data));
        next(setLoader(false, MiddlewareEntities.BOOKSTORES));
        break;
      case `${MiddlewareEntities.BOOKSTORES} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.BOOKSTORES));
        break;
    }
  };

const validateAvailableBookstoresMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.PRODUCT_PAGE.VALIDATE_AVAILABLE_BOOKSTORES
      )
    ) {
      const validateBookstores = action.payload.bookstores.filter((item: any) =>
        BookstoreValidator.validate(item.bookstore)
      );

      next(enrichAvailableBookstoresAction(validateBookstores));
    }
  };

const enrichAvailableBookstoresMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.PRODUCT_PAGE.ENRICH_AVAILABLE_BOOKSTORES) {
      const bookstores = action.payload.bookstores.map((item: any) => {
        return BookstoreFactory.create(item.bookstore);
      });
      next(setAvailableBookstoresAction(bookstores));
    }
  };

export default [
  fetchAvailableBookstoresMiddleware,
  validateAvailableBookstoresMiddleware,
  enrichAvailableBookstoresMiddleware
];

import { ISearchParamsState, ISetSearchParamsActions } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: ISearchParamsState = {
  items: {}
};

const searchParamsReducer = (
  state = initialState,
  action: ISetSearchParamsActions
): ISearchParamsState => {
  switch (action.type) {
    case ActionTypes.SEARCH_PARAMS.SET_SEARCH_PARAMS:
      return {
        ...state,
        items: action.payload.items
      };
    case ActionTypes.SEARCH_PARAMS.CLEAR_SEARCH_PARAMS:
      return {
        ...state,
        items: {}
      };
  }
  return state;
};

export default searchParamsReducer;

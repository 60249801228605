/* eslint-disable @typescript-eslint/brace-style */

import {
  IThreeCollectionCarouselsContent,
  IThreeCollectionCarouselsGroup,
  PriceDisplayTypes
} from '../types';

export default class ThreeCollectionCarouselsContent
  implements IThreeCollectionCarouselsContent
{
  _id: string;

  title: string;

  priceDisplayType: PriceDisplayTypes;

  startAt: string | null;

  endAt: string | null;

  group1: IThreeCollectionCarouselsGroup;

  group2: IThreeCollectionCarouselsGroup;

  group3: IThreeCollectionCarouselsGroup;

  constructor(
    id: string,
    title: string,
    priceDisplayType: PriceDisplayTypes,
    startAt: string | null,
    endAt: string | null,
    group1: IThreeCollectionCarouselsGroup,
    group2: IThreeCollectionCarouselsGroup,
    group3: IThreeCollectionCarouselsGroup
  ) {
    this._id = id;
    this.title = title;
    this.priceDisplayType = priceDisplayType;
    this.startAt = startAt;
    this.endAt = endAt;
    this.group1 = group1;
    this.group2 = group2;
    this.group3 = group3;
  }
}

import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface IProps {
  classes?: string;
}

const TopNavbar: FC<IProps> = ({ classes }: IProps) => {
  const navigate = useNavigate();
  return (
    <ul className={`nav ${classes}`}>
      <li className="nav-item border-end">
        <a
          className="text-decoration-none font-color-default"
          href="https://edenbooks.rs/"
          target="_blank"
        >
          <span className="nav-link">EDEN BOOKS</span>
        </a>
      </li>
      {/*<li className="nav-item border-end">*/}
      {/*  <a*/}
      {/*    className="text-decoration-none cursor-pointer font-color-default"*/}
      {/*    href="/lista-proizvoda?category=Gift&genre=Vau%C4%8Deri"*/}
      {/*  >*/}
      {/*    <span className="nav-link">GIFT CARDS</span>*/}
      {/*  </a>*/}
      {/*</li>*/}
      <li className="nav-item border-end">
        <a
          className="text-decoration-none cursor-pointer font-color-default"
          onClick={() => navigate('/ulaznice')}
        >
          <span className="nav-link">ULAZNICE</span>
        </a>
      </li>
      <li className="nav-item border-end">
        <a
          className="text-decoration-none cursor-pointer font-color-default"
          href="/lista-proizvoda?eBook=true"
        >
          <span className="nav-link">E-KNJIGE</span>
        </a>
      </li>
    </ul>
  );
};

export default TopNavbar;

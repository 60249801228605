import React, { ForwardedRef, forwardRef, useEffect, useState } from 'react';
import delfiPremiumImage from '../../assets/images/delfi-premium.png';
import './scss/style.scss';
import { FiUser } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { BsCardChecklist } from 'react-icons/bs';
import { FaCheck } from 'react-icons/fa';
import ButtonWitIcon from '../button-with-icon';
import Tab from '../tab';
import { Link, useNavigate } from 'react-router-dom';
import { VscHistory } from 'react-icons/vsc';
import LoginForm from '../login-form';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import Spinner from '../spinner/Spinner';
import { ILoginRequest } from '../login-form/types';
// import { usePreventOutsideScroll } from '../../../hooks/usePreventScrollOutside';

interface IProps {
  user: any;
  handleLogin: (values: ILoginRequest) => void;
  handleLogout: () => void;
  handleOnClickShowUserPopup: (event: React.MouseEvent<HTMLElement>) => void;
  ui: any;
  isMobile?: boolean;
  isLaptop?: boolean;
}

const UserPopup = forwardRef<HTMLDivElement, IProps>(
  (
    {
      user,
      handleLogin,
      handleLogout,
      handleOnClickShowUserPopup,
      ui,
      isMobile,
      isLaptop
    }: IProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const handleOnSubmit = (values: ILoginRequest) => {
      handleLogin(values);
    };

    // usePreventOutsideScroll(ref);

    const navigate = useNavigate();

    const handleOnClickNavigateToProfile = (
      event: React.MouseEvent<HTMLElement>,
      partToNavigate: string
    ) => {
      navigate(partToNavigate);
      handleOnClickShowUserPopup(event);
    };

    return (
      <div
        className={`${
          isMobile ? 'mobile-callout' : 'callout'
        } bg-white position-absolute text-start p-3`}
        ref={ref}
      >
        {user && user.username !== '' ? (
          <>
            <div className="row">
              <div className="col-6">
                <h4 className="jost-extra-bold">{user.username}</h4>
              </div>
            </div>
            <div className="row justify-content-start align-items-center mt-2 ps-1">
              <div className="col-auto">
                <img
                  className="img-fluid"
                  src={delfiPremiumImage}
                  alt="delfi preimum"
                />
              </div>
              <div className="col">
                <div className="text-11 jost-semi-bold">
                  <span className="text-uppercase">Član kluba</span>
                </div>
                <div className="text-11 jost-semi-bold">
                  <span className="text-14 jost-extra-bold">Delfi Premium</span>
                </div>
              </div>
            </div>
            <div className="row p-2">
              <div className="col-12 border-2 border-top p-2"></div>
              <div
                className="tab-wrapper"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleOnClickNavigateToProfile(event, 'profile')
                }
              >
                <Tab
                  label="MOJ PROFIL"
                  icon={<FiUser size="2em" />}
                  roundedIcon={
                    user &&
                    typeof user.isDelfiClub !== 'undefined' &&
                    !user.isDelfiClub
                  }
                >
                  <div className="col text-end icon">
                    <MdKeyboardArrowRight size="1.2em" className="mt-minus-5" />
                  </div>
                </Tab>
              </div>
              <div
                className="tab-wrapper"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleOnClickNavigateToProfile(
                    event,
                    'profile/istorija-kupovine'
                  )
                }
              >
                <Tab label="ISTORIJA KUPOVINE" icon={<VscHistory size="2em" />}>
                  <div className="col text-end icon">
                    <MdKeyboardArrowRight size="1.2em" className="mt-minus-5" />
                  </div>
                </Tab>
              </div>
              <div
                className="tab-wrapper"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleOnClickNavigateToProfile(
                    event,
                    'profile/istorija-aktivnosti'
                  )
                }
              >
                <Tab
                  label="ISTORIJA AKTIVNOSTI"
                  icon={<BsCardChecklist size="2em" />}
                >
                  <div className="col text-end icon">
                    <MdKeyboardArrowRight size="1.2em" className="mt-minus-5" />
                  </div>
                </Tab>
              </div>
              <div className="col-12 border-2 border-bottom p-2"></div>
            </div>
            {ui.loaders.includes(MiddlewareEntities.USER) ? (
              <Spinner classes="mt-4" color="white" />
            ) : (
              <div className="row ps-3 pe-3 pt-2">
                <ButtonWitIcon
                  label="ODJAVI SE"
                  iconDirection="right"
                  icon={<FaCheck size="1.4em" className="mt-minus-4" />}
                  componentClasses="background-orange justify-content-center text-center w-100 p-3"
                  iconClasses="ms-3"
                  textClasses="text-14 jost-extra-bold"
                  handleOnClick={handleLogout}
                />
              </div>
            )}
          </>
        ) : (
          <div className="loginWrapper">
            <div className="row">
              <div className="col-6">
                <h6 className="text-32 jost-extra-bold text-uppercase headline">
                  Prijava
                </h6>
              </div>
            </div>
            <div className="row justify-content-start text-start mt-2">
              <div>
                <p className="text-16 jost-regular font-color-default description">
                  Ukoliko imate Premium korisničke podatke, prijavi se klikom na
                  dugme ispod. Ukoliko niste Premium član, registrujte se i
                  ostvarite pogodnosti za online kupovine.
                </p>
              </div>
            </div>
            <LoginForm
              handleOnSubmit={handleOnSubmit}
              ui={ui}
              middlewareEntity={MiddlewareEntities.USER}
              errorMessage={user?.message ? user.message : ''}
            />
            <div className="row justify-content-center text-center mt-3">
              <div className="col-md-12">
                <Link
                  className="text-decoration-none cursor-pointer"
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    handleOnClickShowUserPopup(event);
                  }}
                  to="/zaboravljena_lozinka"
                >
                  Zaboravljena lozinka
                </Link>
              </div>
            </div>
            <div className="row justify-content-center text-center mt-2">
              <div className="col-md-12">
                <Link
                  className="text-decoration-none cursor-pointer"
                  onClick={(event: React.MouseEvent<HTMLElement>) => {
                    handleOnClickShowUserPopup(event);
                  }}
                  to="delfi-premium"
                >
                  Nemate nalog? Registrujte se
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default UserPopup;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiError, apiSuccess } from './actions';
import HttpService from '../../../services/HttpService';

const apiMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => async (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.API_REQUEST) {
      const { entity } = action.payload.meta;
      try {
        const response = await HttpService.request(action.payload.meta);
        store.dispatch(
          apiSuccess(
            response.data,
            entity,
            action.payload.meta.params,
            action.payload.meta.data,
            action.payload.meta.metaData
          )
        );
      } catch (error: any) {
        store.dispatch(apiError(error, entity));
      }
    }
  };

export default apiMiddleware;

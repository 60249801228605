import React, { ReactElement } from 'react';
import IPropsCarousel from './types';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import './scss/style.scss';

const NextArrow: React.FC<IPropsCarousel> = (
  props: IPropsCarousel
): ReactElement => {
  const { onClick, styleType } = props;
  return (
    <div
      className="custom-arrows next cursor-pointer d-flex justify-content-center align-items-center"
      onClick={onClick}
      style={styleType}
    >
      <MdOutlineKeyboardArrowRight size="1.2em" />
    </div>
  );
};

export default NextArrow;

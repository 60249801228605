import { IProductPageState, ISetProductPageAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IProductPageState | any = {
  data: {
    product: {},
    fromTheSameSource: {},
    pageSections: []
  }
};
const productPageReducer = (
  state = initialState,
  action: ISetProductPageAction
): IProductPageState => {
  switch (action.type) {
    case ActionTypes.PRODUCT_PAGE.SET_PRODUCT_PAGE:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default productPageReducer;

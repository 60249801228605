import { IPublisher } from './types';

export default class Publisher implements IPublisher {
  _id: string;

  publisherName: string;

  status: boolean;

  description?: string;

  shortDescription?: string;

  metaTitle?: string;

  metaDescription?: string;

  constructor(
    id: string,
    publisherName: string,
    status: boolean,
    description?: string,
    shortDescription?: string,
    metaTitle?: string,
    metaDescription?: string
  ) {
    this._id = id;
    this.publisherName = publisherName;
    this.status = status;
    this.description = description;
    this.shortDescription = shortDescription;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
  }
}

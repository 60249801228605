import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichMagyarPageDataAction,
  setMagyarPageDataAction,
  validateMagyarPageDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchMagyarPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.MAGYAR_PAGE.FETCH_MAGYAR_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_MAGYAR_PAGE_DATA,
            { ...action.params },
            null,
            MiddlewareEntities.MAGYAR_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.MAGYAR_PAGE));
        break;
      case `${MiddlewareEntities.MAGYAR_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.MAGYAR_PAGE));
        next(validateMagyarPageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.MAGYAR_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.MAGYAR_PAGE));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.MAGYAR_PAGE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateMagyarPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.MAGYAR_PAGE.VALIDATE_MAGYAR_PAGE_DATA)
    ) {
      next(
        enrichMagyarPageDataAction({
          sections: SectionDataFacade.generate(action.payload.sections),
          metaDescription: action.payload.metaDescription
        })
      );
    }
  };

export const enrichMagyarPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.MAGYAR_PAGE.ENRICH_MAGYAR_PAGE_DATA) {
      next(setMagyarPageDataAction(action.payload));
    }
  };

export default [
  fetchMagyarPageDataMiddleware,
  validateMagyarPageDataMiddleware,
  enrichMagyarPageDataMiddleware
];

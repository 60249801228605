import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { setAvailableNotificationData } from './actions';
import GeneralStatuses from '../../../constants/GeneralStatuses';

export const submitAvailableNotificationDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.AVAILABLE_NOTIFICATION.SUBMIT_AVAILABLE_NOTIFICATION:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.SUBMIT_AVAILABLE_NOTIFICATION_DATA,
            null,
            action.payload.data,
            MiddlewareEntities.AVAILABLE_NOTIFICATION,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.AVAILABLE_NOTIFICATION));
        break;
      case `${MiddlewareEntities.AVAILABLE_NOTIFICATION} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.AVAILABLE_NOTIFICATION));
        next(
          setAvailableNotificationData({
            status: GeneralStatuses.SUCCESS_STATUS,
            message: 'Uspešno ste prosledili podatke.'
          })
        );
        break;
      case `${MiddlewareEntities.AVAILABLE_NOTIFICATION} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.AVAILABLE_NOTIFICATION));
        next(
          setAvailableNotificationData({
            status: GeneralStatuses.ERROR_STATUS,
            message:
              'Došlo je do greške prilikom slanja podataka. Molimo Vas pokušajte ponovo'
          })
        );
        break;
      case ActionTypes.AVAILABLE_NOTIFICATION.CLEAR_AVAILABLE_NOTIFICATION:
        next(
          setAvailableNotificationData({
            status: '',
            message: ''
          })
        );
    }
  };

export default submitAvailableNotificationDataMiddleware;

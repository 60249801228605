/* eslint-disable @typescript-eslint/brace-style */

import { IThreeCollectionCarouselsGroup } from '../types';
import { IProduct } from '../../product/types';

export default class ThreeCollectionCarouselsGroup
  implements IThreeCollectionCarouselsGroup
{
  groupTitle: string;

  image: string;

  products: IProduct[];

  constructor(groupTitle: string, image: string, products: IProduct[]) {
    this.groupTitle = groupTitle;
    this.image = image;
    this.products = products;
  }
}

import React, { FC } from 'react';
import './scss/style.scss';

interface IProps {
  numberOfItems: number;
  classes?: string;
}

const RoundedCircleNumber: FC<IProps> = ({
  numberOfItems,
  classes
}: IProps) => {
  return (
    <div
      className={`rounded-circle jost-semi-bold text-11 text-center position-absolute rounded-circle-number-wrapper d-flex justify-content-center align-items-center ${classes}`}
    >
      <span>{numberOfItems}</span>
    </div>
  );
};

export default RoundedCircleNumber;

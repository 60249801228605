import { ISetForgotPasswordAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState = {
    sendEmailStatus: false,
};
const forgotPasswordReducer = (
    state = initialState,
    action: ISetForgotPasswordAction
) => {
    switch (action.type) {
        case ActionTypes.FORGOT_USER_PASSWORD.SET_FORGOT_USER_PASSWORD:
            return {
                ...state,
                sendEmailStatus: action.payload.sendEmailStatus
            };
    }
    return state;
};

export default forgotPasswordReducer;

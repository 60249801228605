import { ICountry, IFetchCountriesAction, ISetCountriesAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const fetchCountriesAction = (): IFetchCountriesAction => ({
  type: ActionTypes.COUNTRIES.FETCH_COUNTRIES
});
export const setCountriesAction = (
  countries: ICountry[]
): ISetCountriesAction => ({
  type: ActionTypes.COUNTRIES.SET_COUNTRIES,
  payload: {
    countries
  }
});

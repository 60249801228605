import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchPremiumActionsPageDataAction,
  IValidatePremiumActionsPageDataAction,
  IEnrichPremiumActionsPageDataAction,
  ISetPremiumActionsPageDataAction,
  IPremiumActionsPageState
} from './types';

export function fetchPremiumActionsPageDataAction(): IFetchPremiumActionsPageDataAction {
  return {
    type: ActionTypes.PREMIUM_ACTIONS_PAGE.FETCH_PREMIUM_ACTIONS_PAGE_DATA
  };
}

export function setPremiumActionsPageDataAction(
  premiumActionsPageData: IPremiumActionsPageState
): ISetPremiumActionsPageDataAction {
  return {
    type: ActionTypes.PREMIUM_ACTIONS_PAGE.SET_PREMIUM_ACTIONS_PAGE_DATA,
    payload: premiumActionsPageData
  };
}

export function validatePremiumActionsPageDataAction(
  premiumActionsPageData: IPremiumActionsPageState
): IValidatePremiumActionsPageDataAction {
  return {
    type: ActionTypes.PREMIUM_ACTIONS_PAGE.VALIDATE_PREMIUM_ACTIONS_PAGE_DATA,
    payload: premiumActionsPageData
  };
}

export function enrichPremiumActionsPageDataAction(
  premiumActionsPageData: any
): IEnrichPremiumActionsPageDataAction {
  return {
    type: ActionTypes.PREMIUM_ACTIONS_PAGE.ENRICH_PREMIUM_ACTIONS_PAGE_DATA,
    payload: premiumActionsPageData
  };
}

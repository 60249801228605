import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { setComplaintPageDataAction } from './actions';
import { createNotification } from '../notification/actions';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchCompliantPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.COMPLAINT_PAGE.FETCH_COMPLAINT_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_STATIC_PAGE_DATA}/reklamacije`,
            null,
            null,
            MiddlewareEntities.COMPLAINT,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.COMPLAINT));
        break;
      case `${MiddlewareEntities.COMPLAINT} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.COMPLAINT));
        next(
          setComplaintPageDataAction({
            compliantPage: action.payload.data.staticPage
          })
        );
        break;
      case `${MiddlewareEntities.COMPLAINT} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.COMPLAINT));
        break;
    }
  };

export const submitCompliantDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.COMPLAINT_PAGE.SUBMIT_COMPLAINT_DATA:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.SEND_COMPLAINT,
            null,
            action.payload.data,
            MiddlewareEntities.CREATE_COMPLAINT,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.CREATE_COMPLAINT));
        break;
      case `${MiddlewareEntities.CREATE_COMPLAINT} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.CREATE_COMPLAINT));
        next(
          createNotification(
            MiddlewareEntities.CREATE_COMPLAINT,
            NotificationTypes.SUCCESS,
            'Vaša zahtev za reklamaciju je uspešno sačuvan i biće obrađen u najkraćem mogućem roku.'
          )
        );
        break;
      case `${MiddlewareEntities.CREATE_COMPLAINT} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.CREATE_COMPLAINT));
        next(
          createNotification(
            MiddlewareEntities.CREATE_COMPLAINT,
            NotificationTypes.ERROR,
            'Vaš zahtev za reklamaciju nije uspešno sačuvan. ' +
              'Molimo proverite unete podatke i pokušajte ponovo ili nas kontaktirajte telefonom na: 011/7155-042'
          )
        );
        break;
    }
  };

export default [
  fetchCompliantPageDataMiddleware,
  submitCompliantDataMiddleware
];

import { IKidsBookState, ISetKidsBookAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IKidsBookState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const kidsBookPageReducer = (
  state = initialState,
  action: ISetKidsBookAction
): IKidsBookState => {
  switch (action.type) {
    case ActionTypes.KIDS_BOOK.SET_KIDS_BOOK_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default kidsBookPageReducer;

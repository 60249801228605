import { IMagyarPageState, ISetMagyarPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IMagyarPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const magyarPageDataReducer = (
  state = initialState,
  action: ISetMagyarPageDataAction
): IMagyarPageState => {
  switch (action.type) {
    case ActionTypes.MAGYAR_PAGE.SET_MAGYAR_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default magyarPageDataReducer;

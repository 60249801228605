import ActionTypes from '../../../constants/ActionTypes';
import { ICartState, ISetProductToCartAction } from './types';
import { AnyAction } from 'redux';
import { Checkout } from '../../../constants/Checkout';
import { PaymentTypes } from '../../../constants/PaymentTypes';

const initialCart = {
  allOrderItemsAmount: 0,
  benefitsReport: {},
  availablePaymentTypes: [PaymentTypes.PAYMENT_TYPE_ANY_CREDIT_CARD],
  administrativeBanReport: null,
  deliveryService: Checkout.DELIVERY_SERVICE_DEFAULT,
  orderItemsPriceReport: [],
  pttPrice: 0,
  totalAmount: 0,
  amountUpToFreeShipping: 0,
  totalItemQuantity: 0,
  discountPrice: 0,
  calculatedPremiumPrice: 0,
  validatePrice: () => null,
  fullItemsPrice: 0
};

const initialState: ICartState = {
  order: initialCart,
  cartForPayment: initialCart,
  isLastGetCartError: true
};

const cartReducer = (
  state = initialState,
  action: ISetProductToCartAction | AnyAction
): ICartState => {
  switch (action.type) {
    case ActionTypes.CART.SET_PRODUCT_TO_CART:
      return {
        ...state,
        order: action.payload.order
      };

    case ActionTypes.CART.RESET_CART:
      return state;
    case ActionTypes.CART.SET_CART_FOR_PAYMENT:
      return {
        ...state,
        cartForPayment: action.payload.order
      };

    case ActionTypes.CART.SET_IS_LAST_GET_CART_ERROR:
      return {
        ...state,
        isLastGetCartError: action.payload
      };
  }

  return state;
};

export default cartReducer;

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import { setWsPayRedirectParamsAction } from './actions';

const fetchWsPayRedirectParamsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.WSPAY.FETCH_WSPAY_REDIRECT_PARAMS_FROM_API:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_WSPAY_REDIRECT_PARAMS + '/' + action.payload,
            null,
            null,
            MiddlewareEntities.FETCH_WSPAY_REDIRECT_PARAMS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.FETCH_WSPAY_REDIRECT_PARAMS));
        break;
      case `${MiddlewareEntities.FETCH_WSPAY_REDIRECT_PARAMS} ${ActionTypes.API_SUCCESS}`:
        next(setWsPayRedirectParamsAction(action.payload.data.redirectParams));
        next(setLoader(false, MiddlewareEntities.FETCH_WSPAY_REDIRECT_PARAMS));
        break;
    }
  };

export default [
  fetchWsPayRedirectParamsMiddleware
];

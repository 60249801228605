import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { fetchUserAction } from '../user/actions';
import { fetchCookieConsentAction } from '../cookie-consent/actions';

const initMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.INIT:
        next(fetchUserAction());
        next(fetchCookieConsentAction());
        break;
    }
  };

export default initMiddleware;

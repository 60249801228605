import { BenefitStatesEnum, IBenefit } from '../cart/types';
import BenefitStates from '../../constants/BenefitStates';

export default class Benefit implements IBenefit {
  state: BenefitStatesEnum;

  constructor(state: BenefitStatesEnum) {
    this.state = state;
  }

  // public isBenefitApplicable() {
  //   if (this.state === BenefitStates.APPLICABLE) {
  //     return true;
  //   }
  //
  //   return false;
  // }
  //
  // public isBenefitPossible() {
  //   if (this.state !== BenefitStates.KILLED) {
  //     return true;
  //   }
  //
  //   return false;
  // }
}

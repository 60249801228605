import { useState } from 'react';

interface IShowMoreItem {
  key: number;
  isShow: boolean;
  showMore: boolean;
}

const useSectionExpand = (initialStateWithKeys: any[]) => {
  const [expandSections, setExpand] = useState(initialStateWithKeys);

  const findItemByKey = (key: number | string) => {
    return expandSections.find((item: any) => item.key === key);
  };

  const updateExpandSection = (
    key: number | string,
    updateProperty: 'isShow' | 'showMore'
  ) => {
    setExpand((current: IShowMoreItem[]) =>
      current.map((item: IShowMoreItem) => {
        if (item.key === key) {
          return { ...item, key: key, [updateProperty]: !item[updateProperty] };
        }

        return item;
      })
    );
  };

  const expandMore = (
    key: number | string,
    updateProperty: 'isShow' | 'showMore'
  ) => {
    return updateExpandSection(key, updateProperty);
  };

  return {
    expandSections,
    setExpand,
    findItemByKey,
    expandMore
  };
};

export default useSectionExpand;

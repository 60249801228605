import { IFactory } from '../types';
import ProminentProduct from '../../entities/menu-items/ProminentProduct';
import ProductFactory from '../product';
import Config from '../../config';

const ProminentProductFactory: IFactory = class ProminentProductFactory {
  public static create(prominentProduct: ProminentProduct) {
    return new ProminentProduct(
      prominentProduct._id,
      prominentProduct.type,
      `${Config.api.mediaUrl}${prominentProduct.image}`,
      ProductFactory.create(prominentProduct.product)
    );
  }
};

export default ProminentProductFactory;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IConfirmationUserState,
  ISetUserConfirmationMessageAction
} from './types';

const initialState: IConfirmationUserState = {
  message: '',
  status: 'error'
};

const userConfirmationReducer = (
  state = initialState,
  action: ISetUserConfirmationMessageAction
): IConfirmationUserState => {
  switch (action.type) {
    case ActionTypes.SET_USER_CONFIRMATION_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
        status: action.payload.status
      };
  }

  return state;
};

export default userConfirmationReducer;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IEdenBookCreateOrderAction,
  IEdenBooksCreateOrderDto,
  ISendForgotUserEdenPasswordAction,
  ISendResetEdenBooksPasswordReqAction,
  ISetEdenBooksErrorUserMessageAction,
  ISetEdenBooksResetPasswordStatusAction,
  ISetForgotUserEdenPasswordAction
} from './types';
import { ISendResetPasswordReqAction, ISetResetPasswordAction } from '../reset-user-password/types';

export function submitCreateEdenBooksOrderAction(submitCreateEdenBooksOrderReq: IEdenBooksCreateOrderDto): IEdenBookCreateOrderAction {
  return {
    type: ActionTypes.EDEN_BOOKS.EDEN_BOOKS_CREATE_ORDER,
    payload:submitCreateEdenBooksOrderReq
  };
}

export function sendForgotUserEdenPasswordAction(sendForgotUserEdenPasswordReq: { email: string }): ISendForgotUserEdenPasswordAction {
  return {
    type: ActionTypes.EDEN_BOOKS.SEND_FORGOT_USER_EDEN_PASSWORD_REQUEST,
    payload: {
      sendForgotUserEdenPasswordReq
    }
  };
}

export function setForgotUserEdenPasswordAction(sendEmailStatus: boolean): ISetForgotUserEdenPasswordAction {
  return {
    type: ActionTypes.EDEN_BOOKS.SET_FORGOT_USER_EDEN_PASSWORD,
    payload: {
      sendEmailStatus
    }
  };
}

export function sendResetEdenBooksPasswordAction(sendResetEdenBooksPasswordReq: { token: string, date: Date | string, password: string }): ISendResetEdenBooksPasswordReqAction {
  return {
    type: ActionTypes.EDEN_BOOKS.SEND_RESET_USER_EDEN_PASSWORD_REQUEST,
    payload: {
      sendResetEdenBooksPasswordReq
    }
  };
}

export function setResetEdenBooksPasswordAction(resetPasswordStatus: boolean): ISetEdenBooksResetPasswordStatusAction {
  return {
    type: ActionTypes.EDEN_BOOKS.SET_RESET_USER_EDEN_PASSWORD_STATUS,
    payload: {
      resetPasswordStatus
    }
  };
}

export function setEdenBooksErrorUserMessageAction(message: string): ISetEdenBooksErrorUserMessageAction {
  return {
    type: ActionTypes.EDEN_BOOKS.SET_EDEN_ERROR_MESSAGE,
    payload: {
      message
    }
  };
}
import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import TagManager from 'react-gtm-module';
import { ICartOrderItem } from '../cart/types';
import { getItemDataForBeginCheckoutEvent } from '../../../utilites/gtm';

const gtmMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.GTM.SEND_EVENT_ADD_PRODUCT) {
      if (
        store.getState().cookieConsentData.cookieConsent &&
        store.getState().cookieConsentData.cookieConsent.analytics
      ) {
        // console.log('GTM SEND EVENT add_to_cart FIRED!');
        // console.log(action.payload.gtmQuantityChangeProductData.product);
        const gtmDataLayerAddItem = {
          dataLayer: {
            event: 'add_to_cart',
            currency: 'RSD',
            value:
              action.payload.gtmQuantityChangeProductData.product.priceList.fullPrice.toFixed(
                2
              ),
            items: [
              {
                item_id:
                  action.payload.gtmQuantityChangeProductData.product
                    .oldProductId,
                item_name:
                  action.payload.gtmQuantityChangeProductData.product.title,
                item_brand:
                  action.payload.gtmQuantityChangeProductData.product.publisher,
                item_category:
                  action.payload.gtmQuantityChangeProductData.product.category,
                price:
                  action.payload.gtmQuantityChangeProductData.product.priceList.fullPrice.toFixed(
                    2
                  ),
                quantity: action.payload.gtmQuantityChangeProductData.quantity
              }
            ]
          }
        };

        TagManager.dataLayer(gtmDataLayerAddItem);

        // console.log(gtmDataLayerAddItem);
      }
    }
    if (action.type === ActionTypes.GTM.SEND_EVENT_REMOVE_PRODUCT) {
      // console.log('GTM SEND EVENT remove_from_cart FIRED!');
      // console.log(action.payload.product);
      const gtmDataLayerRemoveItem = {
        dataLayer: {
          event: 'remove_from_cart',
          currency: 'RSD',
          value:
            action.payload.gtmQuantityChangeProductData.product.priceList.fullPrice.toFixed(
              2
            ),
          items: [
            {
              item_id:
                action.payload.gtmQuantityChangeProductData.product
                  .oldProductId,
              item_name:
                action.payload.gtmQuantityChangeProductData.product.title,
              item_brand:
                action.payload.gtmQuantityChangeProductData.product.publisher,
              item_category:
                action.payload.gtmQuantityChangeProductData.product.category,
              price:
                action.payload.gtmQuantityChangeProductData.product.priceList.fullPrice.toFixed(
                  2
                ),
              quantity: action.payload.gtmQuantityChangeProductData.quantity
            }
          ]
        }
      };

      TagManager.dataLayer(gtmDataLayerRemoveItem);

      // console.log(gtmDataLayerRemoveItem);
    }

    if (action.type === ActionTypes.GTM.SEND_EVENT_BEGIN_CHECKOUT) {
      // console.log('GTM SEND EVENT begin_checkout FIRED!');
      // console.log(action.payload.cartState);
      const gtmDataLayerBeginCheckout = {
        dataLayer: {
          event: 'begin_checkout',
          currency: 'RSD',
          value: action.payload.cartState.order.allOrderItemsAmount.toFixed(2),
          items: action.payload.cartState.order.orderItemsPriceReport.map(
            (priceReport: ICartOrderItem) => {
              return getItemDataForBeginCheckoutEvent(priceReport);
            }
          )
        }
      };

      TagManager.dataLayer(gtmDataLayerBeginCheckout);

      // console.log(gtmDataLayerBeginCheckout);
    }
  };

export default gtmMiddleware;

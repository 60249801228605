import ActionTypes from '../../../constants/ActionTypes';
import { IErrorForbiddenAction, INotFoundAction, IErrorAction } from './types';

export function errorAction(
  data: any,
  entity: string,
  errorNotificationType: string,
  status: number,
  code?: string
): IErrorAction {
  return {
    type: ActionTypes.ERROR,
    payload: {
      data,
      entity,
      errorNotificationType,
      status,
      code
    }
  };
}

import { useState } from 'react';

const useDropdownLogoMenuShow = () => {
  const [showLogoDropdownMenu, setLogoDropdownMenu] = useState(false);

  return {
    showLogoDropdownMenu,
    setLogoDropdownMenu
  };
};

export default useDropdownLogoMenuShow;

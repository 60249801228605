import { IBookMakerPage } from '../../app/store/bookmaker-magazine/types';

export default class BookmakerPageData implements IBookMakerPage {
  _id: string;

  img: string;

  prominentText: string;

  regularText: string;

  bodyText: string;

  issueNumber: number;

  no: number;

  publishedAt: Date | string;

  displayDate: string;

  constructor(
    id: string,
    img: string,
    prominentText: string,
    regularText: string,
    bodyText: string,
    issueNumber: number,
    no: number,
    publishedAt: Date | string,
    displayDate: string
  ) {
    this._id = id;
    this.img = img;
    this.prominentText = prominentText;
    this.regularText = regularText;
    this.bodyText = bodyText;
    this.issueNumber = issueNumber;
    this.no = no;
    this.publishedAt = publishedAt;
    this.displayDate = displayDate;
  }
}

import ActionTypes from '../../../constants/ActionTypes';
import { ISendForgotPasswordAction, ISetForgotPasswordAction } from './types';

export function sendForgotPasswordAction(sendForgotPasswordReq: { email: string }): ISendForgotPasswordAction {
  return {
    type: ActionTypes.FORGOT_USER_PASSWORD.SEND_FORGOT_USER_PASSWORD_REQUEST,
    payload: {
      sendForgotPasswordReq
    }
  };
}

export function setForgotUserPasswordAction(sendEmailStatus: boolean): ISetForgotPasswordAction {
  return {
    type: ActionTypes.FORGOT_USER_PASSWORD.SET_FORGOT_USER_PASSWORD,
    payload: {
      sendEmailStatus
    }
  };
}

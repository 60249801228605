import React from 'react';
import './style.scss';

interface IProps {
  isDisplaySpinner?: boolean;
}

const Loader: React.FC<IProps> = ({ isDisplaySpinner }: IProps) => (
  <div className="d-flex justify-content-center">
    <div
      className={`loader ${isDisplaySpinner ? 'loader-bg-image' : ''}`}
      role="status"
    ></div>
  </div>
);

export default Loader;

import { IThreePartCarouselItem } from '../types';
import { IProduct } from '../../product/types';

export default class ThreePartCarousel implements IThreePartCarouselItem {
  description: string;

  img: string;

  product: IProduct;

  prominentText: string;

  signature: string;

  constructor(
    description: string,
    img: string,
    product: IProduct,
    prominentText: string,
    signature: string
  ) {
    this.description = description;
    this.img = img;
    this.product = product;
    this.prominentText = prominentText;
    this.signature = signature;
  }
}

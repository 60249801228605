import ActionTypes from '../../../../constants/ActionTypes';
import { ISetHomepageTopCarouselAction, ITextSlidersState } from './types';

const initialState: ITextSlidersState = {
  slides: []
};

const homepageCarouselReducer = (
  state = initialState,
  action: ISetHomepageTopCarouselAction,
): ITextSlidersState => {
  switch (action.type) {
    case ActionTypes.HOMEPAGE_TOP_CAROUSEL.SET_HOMEPAGE_TOP_CAROUSEL:
      return {
        ...state,
        slides: action.payload.slides
      };
  }

  return state;
};

export default homepageCarouselReducer;

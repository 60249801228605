import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import {
  enrichBookstoreAction,
  setBookstoreAction,
  validateBookstoreAction
} from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import BookstoreFactory from '../../../factories/bookstore/BookstoreFactory';
import BookstoreValidator from '../../../validators/bookstore';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

const fetchBookstoreMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.BOOKSTORE_OVERVIEW_PAGE.FETCH_BOOKSTORE_OVERVIEW_PAGE:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.FETCH_BOOKSTORE_BY_OLD_ID + '/' + action.payload.withOldId,
            null,
            null,
            MiddlewareEntities.BOOKSTORE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.BOOKSTORE));
        break;
      case `${MiddlewareEntities.BOOKSTORE} ${ActionTypes.API_SUCCESS}`:
        next(validateBookstoreAction(action.payload.data));
        next(setLoader(false, MiddlewareEntities.BOOKSTORE));
        break;
      case `${MiddlewareEntities.BOOKSTORE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.BOOKSTORE));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.BOOKSTORE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

const validateBookstoreMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.BOOKSTORE_OVERVIEW_PAGE.VALIDATE_BOOKSTORE_OVERVIEW_PAGE
      )
    ) {
      if (BookstoreValidator.validate(action.payload.bookstore.data)) {
        return next(enrichBookstoreAction(action.payload.bookstore.data));
      }
    }
  };

const enrichBookstoreMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type ===
      ActionTypes.BOOKSTORE_OVERVIEW_PAGE.ENRICH_BOOKSTORE_OVERVIEW_PAGE
    ) {
      next(
        setBookstoreAction(BookstoreFactory.create(action.payload.bookstore))
      );
    }
  };

export default [
  fetchBookstoreMiddleware,
  validateBookstoreMiddleware,
  enrichBookstoreMiddleware
];

export const required = (value: string) =>
  value ? undefined : 'Obavezno polje';
export const maxLength = (max: number) => (value: string) =>
  value && value.length > max
    ? `Nije dozvoljeno više od ${max} karaktera`
    : undefined;
export const maxLength15 = maxLength(15);
export const mustBeNumber = (value: string) =>
  value && !/^\d*$/.test(value) ? 'Mora biti broj' : undefined;
export const minValue = (min: number) => (value: string) =>
  value.length < min ? `Polje mora imati bar ${min} karaktera` : undefined;
export const minValue18 = minValue(18);
// export const email = (value: string) =>
//   value && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
//     ? 'Email nije u validnom formatu'
//     : undefined;
export const email = (value: string) =>
  value &&
  !/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i.test(
    value
  )
    ? 'Email nije u validnom formatu'
    : undefined;
export const onlyNumbersAndLetters = (value: string) =>
  value && !/^[a-zA-Z0-9_.-]*$/.test(value)
    ? 'Samo su brojevi i slova dozvoljeni'
    : undefined;
export const onlyLetter = (value: string) =>
  value && !/[A-Za-zŽžČčĆćŠšĐđ]+$/.test(value)
    ? 'Samo su slova dozvoljena'
    : undefined;
export const phoneNumber = (value: string) =>
  value && !/^[1-9][0-9\s]{7,}$/.test(value)
    ? 'Broj nije u dozvoljenom formatu'
    : undefined;

export const phoneNumberForLogin = (value: string) =>
  value && !/^[0-9\s+]{7,}$/.test(value)
    ? 'Broj nije u dozvoljenom formatu'
    : undefined;

export const jmbg = (value: string | number) =>
  value.toString().length !== 13
    ? 'Matični broj nije u dozvoljenom formatu'
    : undefined;

export const password = (value: string) =>
  value && !/^(?=.[a-zA-Z])|(?=.\d).{6,}$/.test(value)
    ? 'Minimum šest karaktera'
    : undefined;

export const verifyTheSamePassword = (value: string, values: any) =>
  value !== values.password ? 'Niste dobro uneli lozinku' : undefined;

export const verifyTheSameNewPassword = (value: string, values: any) =>
  value !== values.newPassword ? 'Niste dobro uneli lozinku' : undefined;

export const zipCodeSerbia = (value: string) =>
  value && !/^\d{5}\s[A-Ža-ž\s]+$/.test(value)
    ? 'Molimo unesite poštanski broj i mesto u formatu: 11000 Beograd'
    : undefined;

export const zipCodeForeign = (value: string) =>
  value && !/^.{1,}\s.{2,}$/.test(value)
    ? 'Molimo unesite poštanski broj i mesto u formatu: 11000 Beograd'
    : undefined;

export const composeValidators =
  (...validators: any) =>
  (value: string, allValues: any) =>
    validators.reduce(
      (error: string, validator: (value: string, allValues: any) => {}) => {
        return error || validator(value, allValues);
      },
      undefined
    );

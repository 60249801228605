import {
  IEnrichSearchItemsAction,
  IFetchSearchItemsAction,
  ISearchItemsState,
  ISetInitSearchItemsAction,
  ISetSearchItemsAction,
  IValidateSearchItemsAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const fetchSearchItems = (
  category: string,
  queryString: string
): IFetchSearchItemsAction => ({
  type: ActionTypes.SEARCH_ITEMS.FETCH_ITEMS_BY_KEY,
  payload: {
    category,
    queryString
  }
});

export const setSearchItems = (
  searchItems: ISearchItemsState
): ISetSearchItemsAction => ({
  type: ActionTypes.SEARCH_ITEMS.SET_ITEMS_BY_KEY,
  payload: {
    searchItems
  }
});

export const setInitSearchItems = (): ISetInitSearchItemsAction => ({
  type: ActionTypes.SEARCH_ITEMS.SET_INIT_SEARCH_ITEMS
});

export function validateSearchItemsAction(
  searchItems: ISearchItemsState
): IValidateSearchItemsAction {
  return {
    type: ActionTypes.SEARCH_ITEMS.VALIDATE_SEARCH_ITEMS,
    payload: searchItems
  };
}

export function enrichSearchItemsAction(
  searchItems: ISearchItemsState
): IEnrichSearchItemsAction {
  return {
    type: ActionTypes.SEARCH_ITEMS.ENRICH_SEARCH_ITEMS,
    payload: searchItems
  };
}

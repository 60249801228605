import { IFlatCarouselContent, PriceDisplayTypes } from '../types';
import { IProduct } from '../../product/types';

export default class FlatCarouselsContent implements IFlatCarouselContent {
  _id: string;

  products: IProduct[];

  title: string;

  priceDisplayType: PriceDisplayTypes;

  startAt: string | null;

  endAt: string | null;

  isTopList?: boolean;

  viewAllUrl?: string;

  constructor(
    id: string,
    title: string,
    priceDisplayType: PriceDisplayTypes,
    startAt: string | null,
    endAt: string | null,
    products: IProduct[],
    isTopList?: boolean,
    viewAllUrl?: string
  ) {
    this._id = id;
    this.title = title;
    this.priceDisplayType = priceDisplayType;
    this.startAt = startAt;
    this.endAt = endAt;
    this.products = products;
    this.isTopList = isTopList;
    this.viewAllUrl = viewAllUrl;
  }
}

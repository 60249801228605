import React, { MutableRefObject, useEffect, useRef, useState } from 'react';
import './scss/style.scss';
import MediaQuery from 'react-responsive';
import DesktopHeader from './desktop';
import MobileHeader from './mobile';
import { AppState } from '../../../app/store/rootReducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { fetchTextSliders } from '../../../app/store/text-sliders/actions';
import { fetchWishListFromCookieAction } from '../../../app/store/wishlist/actions';
import {
  userLoginAction,
  userLogoutAction
} from '../../../app/store/user/actions';
import {
  changeCartQuantityAction,
  fetchCartItemsFromApiAction,
  removeFromCartAction
} from '../../../app/store/cart/actions';
import useDropdownShow from './hooks/useDropdownShow';
import useDropdownLogoMenuShow from './hooks/useDropdownLogoMenuShow';
import { fetchMenuItemsAction } from '../../../app/store/menu-items/actions';
import DesktopHeaderStickyPart from './desktop2';
import { ILoginRequest } from '../../components/login-form/types';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const Header: React.FC<Props> = ({
  fetchTextSliders,
  fetchWishListFromCookieAction,
  fetchCartItemsFromApiAction,
  changeCartQuantityAction,
  removeFromCartAction,
  fetchMenuItemsAction,
  userLoginAction,
  userLogoutAction,
  textSliders,
  menuItems,
  user,
  isChanged,
  wishlist,
  cart,
  ui
}: Props) => {
  const { showDropdownCategory, setDropdownCategory } = useDropdownShow();
  const { showLogoDropdownMenu, setLogoDropdownMenu } =
    useDropdownLogoMenuShow();
  const [showUserPopup, setUserShowPopup] = useState(false);
  const [showCartPopup, setShowCartPopup] = useState(false);
  const [isShowSearchItems, setIsShowSearchItems] = useState(false);
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const userPopupRef: MutableRefObject<any> = useRef(null);
  const cartPopupRef: MutableRefObject<any> = useRef(null);
  const categoryDropdownRef: MutableRefObject<any> = useRef(null);
  const searchWrapperRef: MutableRefObject<any> = useRef(null);
  const searchCategoryDropdownRef: MutableRefObject<any> = useRef(null);
  // const isMobile = useMediaQuery({ query: '(max-width: 999px)' });
  const [showCartAnimation, setShowCartAnimation] = useState(false);

  const refs = useRef({
    userPopupRef,
    cartPopupRef,
    categoryDropdownRef,
    searchWrapperRef,
    searchCategoryDropdownRef
  });

  useEffect(() => {
    if (textSliders.items.length === 0) {
      fetchTextSliders();
      fetchWishListFromCookieAction();
      fetchCartItemsFromApiAction(
        undefined,
        undefined,
        undefined,
        undefined,
        true
      );
      fetchMenuItemsAction();
    }
  }, []);

  const handleLogin = (values: ILoginRequest) => {
    userLoginAction(values);
  };

  const handleLogout = () => {
    userLogoutAction();
  };

  const handleOnChangeQuantity = (productId: string, quantity: number) => {
    changeCartQuantityAction(productId, quantity);
  };

  const handleOnRemoveFromItem = (productId: string) => {
    removeFromCartAction(productId);
  };

  const handleClickOutside = (event: any) => {
    if (userPopupRef.current && !userPopupRef.current.contains(event.target)) {
      setUserShowPopup(false);
    }

    if (
      searchCategoryDropdownRef.current &&
      !searchCategoryDropdownRef.current.contains(event.target)
    ) {
      setIsCategoryDropdownOpen(false);
    }

    if (cartPopupRef.current && !cartPopupRef.current.contains(event.target)) {
      setShowCartPopup(false);
    }

    if (
      searchWrapperRef.current &&
      !searchWrapperRef.current.contains(event.target) &&
      event.target.getAttribute('name') !== 'search-input'
    ) {
      setIsShowSearchItems(false);
    }

    if (
      categoryDropdownRef.current &&
      !categoryDropdownRef.current.contains(event.target)
    ) {
      setDropdownCategory(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside, {
        capture: true
      });
    };
  });

  useEffect(() => {
    if (cart.order.totalItemQuantity > 0) {
      setShowCartAnimation(true);
    }
    setTimeout(() => setShowCartAnimation(false), 500);
  }, [cart.order.totalItemQuantity]);

  const handleOnClickShowCategoryMenu = () => {
    setDropdownCategory(!showDropdownCategory);
  };

  const handleOnClickShowDropdownMenu = () => {
    setLogoDropdownMenu(!showLogoDropdownMenu);
  };

  const handleOnClickShowCartPopup = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setShowCartPopup(!showCartPopup);
    setUserShowPopup(false);
    setIsShowSearchItems(false);

    // if (isMobile && !showCartPopup) {
    //   preventBodyToScroll(true);
    // }
  };

  const handleOnClickShowUserDropDownMenu = (
    e: React.MouseEvent<HTMLElement>
  ) => {
    e.stopPropagation();
    setUserShowPopup(!showUserPopup);
    setShowCartPopup(false);
    setIsShowSearchItems(false);

    // if (isMobile && !showUserPopup) {
    //   preventBodyToScroll(true);
    // }
  };

  return (
    <>
      <MediaQuery query="(min-width: 1000px)">
        <DesktopHeader textSliders={textSliders.items} ui={ui} />
        <DesktopHeaderStickyPart
          numberOfWishProductItems={wishlist.productIds.length}
          handleOnRemoveFromItem={handleOnRemoveFromItem}
          cart={cart}
          handleOnChangeQuantity={handleOnChangeQuantity}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          user={user.user}
          isChanged={isChanged}
          handleOnClickShowCartPopup={handleOnClickShowCartPopup}
          handleOnClickShowCategoryMenu={handleOnClickShowCategoryMenu}
          handleOnClickShowDropdownMenu={handleOnClickShowDropdownMenu}
          handleOnClickShowUserDropDownMenu={handleOnClickShowUserDropDownMenu}
          showCartPopup={showCartPopup}
          showDropdownCategory={showDropdownCategory}
          showLogoDropdownMenu={showLogoDropdownMenu}
          showUserPopup={showUserPopup}
          showCartAnimation={showCartAnimation}
          isShowSearchItems={isShowSearchItems}
          setIsShowSearchItems={setIsShowSearchItems}
          isCategoryDropdownOpen={isCategoryDropdownOpen}
          setIsCategoryDropdownOpen={setIsCategoryDropdownOpen}
          ui={ui}
          ref={refs}
          menuItems={menuItems}
        />
      </MediaQuery>
      <MediaQuery query="(max-width: 999px)">
        <MobileHeader
          textSliders={textSliders.items}
          numberOfWishProductItems={wishlist.productIds.length}
          cart={cart}
          user={user.user}
          isChanged={isChanged}
          handleOnChangeQuantity={handleOnChangeQuantity}
          handleOnClickShowCartPopup={handleOnClickShowCartPopup}
          handleOnRemoveFromItem={handleOnRemoveFromItem}
          showCartPopup={showCartPopup}
          handleOnClickShowUserPopup={handleOnClickShowUserDropDownMenu}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
          showUserPopup={showUserPopup}
          showCartAnimation={showCartAnimation}
          isShowSearchItems={isShowSearchItems}
          setIsShowSearchItems={setIsShowSearchItems}
          ui={ui}
          ref={refs}
        />
      </MediaQuery>
    </>
  );
};

const mapStateToProps = (state: AppState) => ({
  ui: state.ui,
  textSliders: state.textSliders,
  user: state.user.member,
  wishlist: state.wishlist.productIds,
  cart: state.cart,
  isChanged: state.toggleChangeCartData.isChanged,
  menuItems: state.menuItems
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      fetchTextSliders,
      fetchWishListFromCookieAction,
      fetchCartItemsFromApiAction,
      fetchMenuItemsAction,
      changeCartQuantityAction,
      removeFromCartAction,
      userLoginAction,
      userLogoutAction
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);

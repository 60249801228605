import ActionTypes from '../../../constants/ActionTypes';
import { IOrderState, ISetOrderAction } from './types';

const initialState: IOrderState = {
  order: null
};

export default (state = initialState, action: ISetOrderAction): IOrderState => {
  if (action.type === ActionTypes.ORDER_STATUS.SET_ORDER) {
    return {
      ...state,
      order: action.payload.order
    };
  }

  return state;
};

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { setCareerPageDataAction } from './actions';
import { createNotification } from '../notification/actions';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchCareerPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.CAREER.FETCH_CAREER_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_CAREER_PAGE_DATA,
            null,
            null,
            MiddlewareEntities.CAREER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.CAREER));
        break;
      case `${MiddlewareEntities.CAREER} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.CAREER));
        next(
          setCareerPageDataAction({
            careerPage: action.payload.data.data.careerPage,
            careerPositions: action.payload.data.data.careerPositions
          })
        );
        break;
      case `${MiddlewareEntities.CAREER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.CAREER));
        break;
    }
  };

export const submitUserCareerDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.CAREER.SUBMIT_USER_CAREER_DATA:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.GET_CAREER_PAGE_DATA,
            null,
            action.payload.data,
            MiddlewareEntities.CAREER_USER_SUBMIT_DATA,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.CAREER_USER_SUBMIT_DATA));
        break;
      case `${MiddlewareEntities.CAREER_USER_SUBMIT_DATA} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.CAREER_USER_SUBMIT_DATA));
        next(
          createNotification(
            MiddlewareEntities.CAREER_USER_SUBMIT_DATA,
            NotificationTypes.SUCCESS,
            'Uspešno ste poslali podatke'
          )
        );
        break;
      case `${MiddlewareEntities.CAREER_USER_SUBMIT_DATA} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.CAREER_USER_SUBMIT_DATA));
        next(
          createNotification(
            MiddlewareEntities.CAREER_USER_SUBMIT_DATA,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );
        break;
    }
  };

export default [fetchCareerPageDataMiddleware, submitUserCareerDataMiddleware];

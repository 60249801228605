import {
  IFetchMunicipalitiesAction,
  IMunicipality,
  ISetMunicipalitiesAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const fetchMunicipalitiesAction = (): IFetchMunicipalitiesAction => ({
  type: ActionTypes.MUNICIPALITIES.FETCH_MUNICIPALITIES
});
export const setMunicipalitiesAction = (
  municipalities: IMunicipality[]
): ISetMunicipalitiesAction => ({
  type: ActionTypes.MUNICIPALITIES.SET_MUNICIPALITIES,
  payload: {
    municipalities
  }
});

import {
  IAskAboutProductPostRequestAction,
  IAskAboutProductBody,
  ISetAskAboutProductMessageStatusAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const askAboutProductPostRequestAction = (
  values: IAskAboutProductBody
): IAskAboutProductPostRequestAction => ({
  type: ActionTypes.ASK_ABOUT_PRODUCT.ASK_ABOUT_PRODUCT_POST_REQUEST,
  payload: {
    values
  }
});

export const setAskAboutProductMessageStatusAction = (
  status: number,
  message: string
): ISetAskAboutProductMessageStatusAction => ({
  type: ActionTypes.ASK_ABOUT_PRODUCT
    .SET_ASK_ABOUT_PRODUCT_MESSAGE_STATUS_ACTION,
  payload: {
    status,
    message
  }
});

import ActionTypes from '../../../constants/ActionTypes';
import {
  IAdvancedSearchState,
  ISetAdvancedSearchPageDataAction
} from './types';

const initialState: IAdvancedSearchState = {
  products: [],
  totalRecords: 0
};

const advancedSearchPageDataReducer = (
  state = initialState,
  action: ISetAdvancedSearchPageDataAction
): IAdvancedSearchState => {
  switch (action.type) {
    case ActionTypes.ADVANCED_SEARCH.SET_ADVANCED_SEARCH_DATA:
      return {
        ...state,
        // products: [...state.products, ...action.payload.products],
        products: action.payload.products,
        totalRecords: action.payload.totalRecords
      };
  }

  return state;
};

export default advancedSearchPageDataReducer;

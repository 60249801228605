import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichKidsBookDataAction,
  setKidsBookAction,
  validateKidsBookDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { setNotification } from '../notification/actions';
import NotificationFactory from '../../../factories/notification/NotificationFactory';
import { generateErrorMessage } from '../../../utilites/error';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchKidsBookDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.KIDS_BOOK.FETCH_KIDS_BOOK_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_KIDS_BOOK,
            { ...action.params },
            null,
            MiddlewareEntities.KIDS_BOOK,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.KIDS_BOOK));
        break;
      case `${MiddlewareEntities.KIDS_BOOK} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.KIDS_BOOK));
        next(validateKidsBookDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.KIDS_BOOK} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.KIDS_BOOK));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.KIDS_BOOK,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateKidsBookDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.KIDS_BOOK.VALIDATE_KIDS_BOOK_DATA)) {
      next(
        enrichKidsBookDataAction({
          sections: SectionDataFacade.generate(action.payload.sections),
          metaDescription: action.payload.metaDescription
        })
      );
    }
  };

export const enrichKidsBookDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.KIDS_BOOK.ENRICH_KIDS_BOOK_DATA) {
      next(setKidsBookAction(action.payload));
    }
  };

export default [
  fetchKidsBookDataMiddleware,
  validateKidsBookDataMiddleware,
  enrichKidsBookDataMiddleware
];

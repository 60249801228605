import { IFactory } from '../../types';
import { ILogoWidgetCarousel } from '../../../entities/section/types';
import { LogoWidgetCarouselItem } from '../../../entities/section/logo-widget-carousels/LogoWidgetCarouselItem';
import LogoWidgetCarousel from '../../../entities/section/logo-widget-carousels/LogoWidgetCarousel';
import Config from '../../../config';

const LogoWidgetCarouselsFactory: IFactory = class LogoWidgetCarouselsFactory {
  static create(logoWidgetCarousel: ILogoWidgetCarousel) {
    const items = logoWidgetCarousel.items.map((item) => {
      return new LogoWidgetCarouselItem(
        item.url,
        `${Config.api.mediaUrl}/${item.img}`,
        item.altImg
      );
    });

    return new LogoWidgetCarousel(
      logoWidgetCarousel._id,
      logoWidgetCarousel.title,
      logoWidgetCarousel.status,
      items
    );
  }
};

export default LogoWidgetCarouselsFactory;

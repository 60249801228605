import ActionTypes from '../../../constants/ActionTypes';
import { IProfilePageState } from './types';
import { AnyAction } from 'redux';

const initialState: IProfilePageState = {
  user: null,
  orderHistory: [],
  activityHistory: []
};

const profilePageReducer = (state = initialState, action: AnyAction): any => {
  switch (action.type) {
    case ActionTypes.PROFILE_PAGE.SET_PROFILE_USER:
      return {
        ...state,
        user: action.payload.user
      };
    case ActionTypes.PROFILE_PAGE.SET_PROFILE_ORDER_HISTORY:
      return {
        ...state,
        orderHistory: action.payload.orders
      };
    case ActionTypes.PROFILE_PAGE.SET_PROFILE_ACTIVITY:
      return {
        ...state,
        activityHistory: action.payload.activityHistory
      };
  }

  return state;
};

export default profilePageReducer;

import { IProduct } from '../../../entities/product/types';
import SimpleCarouselContent from '../../../entities/section/simple-carousel/SimpleCarouselContent';
import { ISimpleCarouselContent } from '../../../entities/section/types';
import { IFactory } from '../../types';
import ProductFactory from '../../product';
import { getAvailableProducts } from '../../../utilites/product';

const SimpleCarouselContentFactory: IFactory = class SimpleCarouselContentFactory {
  static create(content: ISimpleCarouselContent) {
    return new SimpleCarouselContent(
      content._id,
      content.title,
      content.priceDisplayType,
      getAvailableProducts(content.products),
      content.type,
      content.startAt,
      content.endAt,
      content.isTopList,
      content.viewAllUrl
    );
  }
};

export default SimpleCarouselContentFactory;

import ActionTypes from '../../../constants/ActionTypes';
import { ICommentsState } from './types';
import { AnyAction } from 'redux';

const initialState: ICommentsState = {
  items: [],
  status: ''
};

const commentsReducer = (
  state = initialState,
  action: AnyAction
): ICommentsState => {
  switch (action.type) {
    case ActionTypes.COMMENTS.SET_ALL_COMMENTS:
      return {
        ...state,
        items: action.payload.comments
      };
    case ActionTypes.COMMENTS.SET_ADD_COMMENT_STATUS:
      return {
        ...state,
        status: action.payload.status
      };
  }

  return state;
};

export default commentsReducer;

import ActionTypes from '../../../constants/ActionTypes';
import { IBookstoreState, ISetBookstoreAction } from './types';

const initialState: IBookstoreState = {
  item: null
};

const bookstoreOverviewReducer = (
  state = initialState,
  action: ISetBookstoreAction
): IBookstoreState => {
  switch (action.type) {
    case ActionTypes.BOOKSTORE_OVERVIEW_PAGE.SET_BOOKSTORE_OVERVIEW_PAGE:
      return {
        ...state,
        item: action.payload.bookstore
      };
  }

  return state;
};

export default bookstoreOverviewReducer;

import { createGroupSchema } from '../../utilites/schema';

export const threeCollectionCarouselsSchema: any = {
  $id: 'threeCollectionCarouselsSchema',
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        title: { type: 'string' },
        startAt: { type: ['string', 'null'] },
        endAt: { type: ['string', 'null'] },
        group1: createGroupSchema(),
        group2: createGroupSchema(),
        group3: createGroupSchema()
      },
      required: ['_id', 'title', 'group1', 'group2', 'group3']
    }
  },
  required: ['type', 'content']
};

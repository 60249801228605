export const ErrorCodes = {
  CHANGE_PURCHASE_TERMS: 901,
  CHANGE_PURCHASE_PRICE: '400.125.101',
  EMPTY_CART_ITEMS: '400.123.101',
  CART_ITEM_IS_NOT_VALID: '400.123.102',
  ADMINISTRATIVE_BAN_NOT_AVAILABLE: '400.125.102',
  ADMINISTRATIVE_BAN_SELECTED_COMPANY_NOT_EXISTS: '400.125.103',
  ADMINISTRATIVE_BAN_NUMBER_OF_INSTALLMENTS_NOT_AVAILABLE: '400.125.104',
  ALREADY_SUBSCRIBED: '400.123.004',
  WRONG_CREDENTIALS: '401.123.005, 403.123.005',
  BAD_FORMAT: '400.123.100',
  EBOOK_ALREADY_EXISTS: '400.123.005, 400.125.902'
};

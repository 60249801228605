import { useState } from 'react';
import { keyId } from './types';

const useNavItemsOpen = (itemKey?: keyId, value?: boolean) => {
  const state: any = {
    books: false,
    gifts: false,
    topLists: false,
    musics: false,
    film: false,
    schoolBooks: false,
    eBooks: false,
    foreignBooks: false,
    childrenBook: false,
    starWay: false,
    tech: false,
    delfiCorner: false,
    actions: false,
    services: false,
    genres: false,
    recommend: false,
    popularGenres: false,
    customerFavorites: false,
    videoGames: false,
    classBook: false,
    category: true,
    isAvailable: true,
    averageRank: false,
    priceRange: false,
    city: true,
    publishers: false
  };

  if (typeof itemKey !== 'undefined' && typeof value !== 'undefined') {
    state[itemKey] = value;
  }

  const [navItemsOpen, setNavOpened] = useState(state);

  const setNavItemsOpenToFalse = (key: keyId) => {
    setNavOpened({ ...navItemsOpen, [key]: false });
  };

  const setNavItemsOpenToTrue = (key: keyId) => {
    setNavOpened({ ...navItemsOpen, [key]: true });
  };

  const handleOnToggleNavDropdown = (key: keyId) => {
    setNavOpened({ ...navItemsOpen, [key]: !navItemsOpen[key] });
  };

  return {
    navItemsOpen,
    setNavItemsOpenToFalse,
    setNavItemsOpenToTrue,
    handleOnToggleNavDropdown
  };
};

export default useNavItemsOpen;

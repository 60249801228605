import { IHomePageState, ISetHomePageAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IHomePageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const homePageReducer = (
  state = initialState,
  action: ISetHomePageAction
): IHomePageState => {
  switch (action.type) {
    case ActionTypes.HOMEPAGE.SET_HOMEPAGE:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default homePageReducer;

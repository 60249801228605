import { createProductSchema } from '../../utilites/schema';

export const prominentProductSchema = {
  type: 'object',
  properties: {
    image: { type: 'string' },
    product: createProductSchema(),
    type: { type: 'string' },
    _id: { type: 'string' }
  },
  required: ['image', 'product', '_id']
};

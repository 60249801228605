import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichAdvancedSearchPageDataAction,
  setAdvancedSearchPageDataAction,
  validateAdvancedSearchPageDataAction
} from './actions';
import { IProduct } from '../../../entities/product/types';
import ProductFactory from '../../../factories/product';
import { productSchema } from '../../../validators/product/productSchema';
import Ajv from 'ajv';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { setNotification } from '../notification/actions';
import NotificationFactory from '../../../factories/notification/NotificationFactory';
import { generateErrorMessage } from '../../../utilites/error';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchAdvancedSearchPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.ADVANCED_SEARCH.FETCH_ADVANCED_SEARCH_DATA:
        const storeSearchParams = { ...store.getState().searchParams.items };

        if (storeSearchParams.q) {
          delete storeSearchParams.q;
        }

        if (storeSearchParams.page > 1) {
          storeSearchParams.skip =
            Number(storeSearchParams.limit) * Number(storeSearchParams.page) -
            Number(storeSearchParams.limit);
        }

        if (storeSearchParams.page === 1) {
          storeSearchParams.skip = 0;
        }

        if (storeSearchParams.isAvailable === 'all') {
          delete storeSearchParams.isAvailable;
        }

        if (storeSearchParams.category === 'all') {
          delete storeSearchParams.category;
        }

        if (storeSearchParams.page) {
          delete storeSearchParams.page;
        }

        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.FETCH_ADVANCED_SEARCH_ITEMS +
              '/' +
              action.payload.queryString,
            storeSearchParams,
            null,
            MiddlewareEntities.ADVANCED_SEARCH,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.ADVANCED_SEARCH));
        break;
      case `${MiddlewareEntities.ADVANCED_SEARCH} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.ADVANCED_SEARCH));
        next(validateAdvancedSearchPageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.ADVANCED_SEARCH} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.ADVANCED_SEARCH));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.ADVANCED_SEARCH,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateAdvancedSearchPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.ADVANCED_SEARCH.VALIDATE_ADVANCED_SEARCH_DATA
      )
    ) {
      const ajv = new Ajv();
      const products = action.payload.products.filter((product: IProduct) => {
        if (ajv.validate(productSchema, product)) {
          return product;
        }
      });

      next(
        enrichAdvancedSearchPageDataAction({
          products,
          totalRecords: action.payload.recordsTotal
        })
      );
    }
  };

export const enrichAdvancedSearchPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type === ActionTypes.ADVANCED_SEARCH.ENRICH_ADVANCED_SEARCH_DATA
    ) {
      const products = action.payload.products.map((product: IProduct) =>
        ProductFactory.create(product)
      );

      next(
        setAdvancedSearchPageDataAction({
          products,
          totalRecords: action.payload.totalRecords
        })
      );
    }
  };

export default [
  fetchAdvancedSearchPageDataMiddleware,
  validateAdvancedSearchPageDataMiddleware,
  enrichAdvancedSearchPageDataMiddleware
];

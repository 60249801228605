import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { setSearchParamsAction } from './actions';
import ActionTypes from '../../../constants/ActionTypes';

const searchParamsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.SEARCH_PARAMS.CREATE_SEARCH_PARAMS:
        next(setSearchParamsAction(action.payload));
        break;
      case ActionTypes.SEARCH_PARAMS.CLEAR_SEARCH_PARAMS:
        break;
    }
  };

export default searchParamsMiddleware;

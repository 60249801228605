import ActionTypes from '../../../constants/ActionTypes';

const initialState: any = {
  sendEmailStatus: false,
  resetPasswordStatus: false,
  message: ''
};
const edenBooksReducer = (
  state = initialState,
  action: any
): any => {
  switch (action.type) {
    case ActionTypes.EDEN_BOOKS.SET_FORGOT_USER_EDEN_PASSWORD:
      return {
        ...state,
        sendEmailStatus: action.payload.sendEmailStatus
      };
    case ActionTypes.EDEN_BOOKS.SET_RESET_USER_EDEN_PASSWORD_STATUS:
      return {
        ...state,
        resetPasswordStatus: action.payload.resetPasswordStatus
      };
    case ActionTypes.EDEN_BOOKS.SET_EDEN_ERROR_MESSAGE:
      return {
        ...state,
        message: action.payload.message
      };
  }
  return state;
};

export default edenBooksReducer;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import {
  enrichProfileActivityAction,
  enrichProfileOrderHistoryAction,
  fetchProfileUserAction,
  setProfileActivityAction,
  setProfileOrderHistoryAction,
  setProfileUserAction,
  validateProfileActivityAction,
  validateProfileOrderHistoryAction
} from './actions';
import { IProfileActivityHistory } from './types';
import ProfileActivityFactory from '../../../factories/profile/ProfileActivityFactory';
import { uniqBy } from 'lodash';
import { sortByDate } from '../../../selectors/sortByDate';
import { getFromCacheByKey } from '../../../utilites/memoryCache';
import { setApiResponseInCache } from '../cache/actions';
import { removeCookie } from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';
import CacheTime from '../../../constants/CacheTime';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { fetchUserAction } from '../user/actions';
import OrderFactory from '../../../factories/order/OrderFactory';
import { IOrder } from '../../../entities/order/types';
import NotificationTypes from '../../../constants/NotificationTypes';
import { createNotification } from '../notification/actions';
import { sleep } from '../../../utilites';

const fetchProfileActivityMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PROFILE_PAGE.FETCH_PROFILE_ACTIVITY:
        const profileHistoryActivityFromCache = getFromCacheByKey(
          MiddlewareEntities.PROFILE_ACTIVITY
        );

        if (profileHistoryActivityFromCache) {
          return next(
            setProfileOrderHistoryAction(profileHistoryActivityFromCache)
          );
        }

        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.FETCH_PROFILE_ACTIVITY,
            null,
            null,
            MiddlewareEntities.PROFILE_ACTIVITY,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PROFILE_ACTIVITY));
        break;
      case `${MiddlewareEntities.PROFILE_ACTIVITY} ${ActionTypes.API_SUCCESS}`:
        next(validateProfileActivityAction(action.payload.data));
        next(setLoader(false, MiddlewareEntities.PROFILE_ACTIVITY));
        break;

      case `${MiddlewareEntities.PROFILE_ACTIVITY} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_ACTIVITY));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.PROFILE_ACTIVITY,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

const fetchProfileUserMiddleware: Middleware =
  () => (next: Dispatch) => async (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PROFILE_PAGE.FETCH_PROFILE_USER:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.FETCH_PROFILE_USER,
            null,
            null,
            MiddlewareEntities.PROFILE_USER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PROFILE_USER));
        break;
      case `${MiddlewareEntities.PROFILE_USER} ${ActionTypes.API_SUCCESS}`:
        next(setProfileUserAction(action.payload.data.user));
        next(setLoader(false, MiddlewareEntities.PROFILE_USER));
        break;

      case `${MiddlewareEntities.PROFILE_USER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_USER));
        removeCookie(CookieNames.MEMBER);
        if (
          action.payload.data.status === HttpStatusCodes.HTTP_FORBIDDEN ||
          (action.payload.data.data &&
            action.payload.data.data.code === '500.123.000')
        ) {
          next(
            createNotification(
              action.payload.meta.entity,
              'warning',
              'Vaša sesija je istekla. Molimo Vas ponovo se ulogujte'
            )
          );
        } else {
          next(
            createNotification(
              MiddlewareEntities.PROFILE_USER,
              NotificationTypes.DELAY_NOTIFICATION_DISMISS,
              undefined,
              action.payload.data.data
            )
          );
        }

        await sleep(3000);

        window.location.href = '/';

        break;
    }
  };

const deleteProfileUserMiddleware: Middleware =
  () => (next: Dispatch) => async (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PROFILE_PAGE.DELETE_PROFILE_USER:
        next(
          apiRequest(
            null,
            'DELETE',
            ApiUrls.DELETE_PROFILE_USER,
            null,
            null,
            MiddlewareEntities.DELETE_PROFILE_USER,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.DELETE_PROFILE_USER));
        break;
      case `${MiddlewareEntities.DELETE_PROFILE_USER} ${ActionTypes.API_SUCCESS}`:
        next(setProfileUserAction(action.payload.data.user));
        next(setLoader(false, MiddlewareEntities.DELETE_PROFILE_USER));
        next(
          createNotification(
            MiddlewareEntities.DELETE_PROFILE_USER,
            NotificationTypes.SUCCESS,
            'Uspešno ste obrisali Vaš profil'
          )
        );
        removeCookie(CookieNames.MEMBER);

        await sleep(3000);

        window.location.href = '/';
        break;

      case `${MiddlewareEntities.DELETE_PROFILE_USER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.DELETE_PROFILE_USER));
        break;
    }
  };

const fetchProfileOrderHistoryMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PROFILE_PAGE.FETCH_PROFILE_ORDER_HISTORY:
        const profileHistoryOrderFromCache = getFromCacheByKey(
          MiddlewareEntities.PROFILE_ORDER_HISTORY
        );

        if (profileHistoryOrderFromCache) {
          return next(
            setProfileOrderHistoryAction(profileHistoryOrderFromCache)
          );
        }

        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.FETCH_PROFILE_ORDER_HISTORY,
            null,
            null,
            MiddlewareEntities.PROFILE_ORDER_HISTORY,
            {}
          )
        );
        break;
      case `${MiddlewareEntities.PROFILE_ORDER_HISTORY} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_ORDER_HISTORY));
        next(validateProfileOrderHistoryAction(action.payload.data));
        break;

      case `${MiddlewareEntities.PROFILE_ORDER_HISTORY} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_ORDER_HISTORY));
        break;
    }
  };

const validateProfileOrderHistoryMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.PROFILE_PAGE.VALIDATE_PROFILE_ORDER_HISTORY
      )
    ) {
      next(enrichProfileOrderHistoryAction(action.payload.orderHistory));
    }
  };

const enrichProfileOrderHistoryMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.PROFILE_PAGE.ENRICH_PROFILE_ORDER_HISTORY) {
      // const orders = action.payload.orderHistory.map((item: any) => {
      //   return ProfileOrderFactory.create(item);
      // });
      const orderHistory = action.payload.orderHistory.map((order: IOrder) => {
        return OrderFactory.create(order);
      });
      next(setProfileOrderHistoryAction(orderHistory));
      next(
        setApiResponseInCache(
          MiddlewareEntities.PROFILE_ORDER_HISTORY,
          orderHistory,
          CacheTime.PROFILE_USER
        )
      );
    }
  };

const validateProfileActivityHistoryMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.PROFILE_PAGE.VALIDATE_PROFILE_ACTIVITY)
    ) {
      next(enrichProfileActivityAction(action.payload.activityHistory));
    }
  };

const enrichProfileActivityHistoryMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.PROFILE_PAGE.ENRICH_PROFILE_ACTIVITY) {
      const activityHistory = [...action.payload.activityHistory];
      let comments = [];
      const uniqueProducts = uniqBy(activityHistory, (item: any) => {
        return item.product.title;
      });

      for (let i = 0; i < uniqueProducts.length; i++) {
        for (let j = 0; j < activityHistory.length; j++) {
          if (
            uniqueProducts[i].product.title === activityHistory[j].product.title
          ) {
            comments.push({
              comment: activityHistory[j].comment,
              createdAt: activityHistory[j].createdAt,
              email: activityHistory[j].email,
              ipAddress: activityHistory[j].ipAddress,
              memberId: activityHistory[j].memberId,
              memberName: activityHistory[j].memberName,
              title: activityHistory[i].title ? activityHistory[j].title : '',
              productRank: activityHistory[i].productRank
            });
          }
        }

        uniqueProducts[i].comments = sortByDate(comments, 'desc');
        comments = [];
      }

      const entityActivityHistory = uniqueProducts.map(
        (activityHistory: IProfileActivityHistory) => {
          return ProfileActivityFactory.create(activityHistory);
        }
      );

      next(setProfileActivityAction(entityActivityHistory));
      next(
        setApiResponseInCache(
          MiddlewareEntities.PROFILE_ACTIVITY,
          entityActivityHistory,
          CacheTime.PROFILE_USER
        )
      );
    }
  };

const updateProfileDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => async (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PROFILE_PAGE.PROFILE_USER_UPDATE:
        next(
          apiRequest(
            null,
            'PATCH',
            ApiUrls.PROFILE_USER_UPDATE,
            null,
            action.payload.newUserProfileData,
            MiddlewareEntities.PROFILE_USER_UPDATE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PROFILE_USER_UPDATE));
        break;
      case `${MiddlewareEntities.PROFILE_USER_UPDATE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_USER_UPDATE));
        next(
          createNotification(
            MiddlewareEntities.PROFILE_USER_UPDATE,
            NotificationTypes.SUCCESS,
            'Uspešno ste izmenili podatke'
          )
        );
        await sleep(500);
        store.dispatch(fetchProfileUserAction());
        break;

      case `${MiddlewareEntities.PROFILE_USER_UPDATE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_USER_UPDATE));
        next(
          createNotification(
            MiddlewareEntities.PROFILE_USER_UPDATE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );
        break;
    }
  };

const changeProfilePasswordMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PROFILE_PAGE.PROFILE_USER_PASSWORD_CHANGE:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.PROFILE_USER_PASSWORD_CHANGE,
            null,
            action.payload.changePasswordData,
            MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE));
        break;
      case `${MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE));
        next(
          createNotification(
            MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE,
            NotificationTypes.SUCCESS,
            'Uspešno ste izmenili podatke'
          )
        );
        store.dispatch(fetchProfileUserAction());
        break;

      case `${MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE));
        next(
          createNotification(
            MiddlewareEntities.PROFILE_USER_PASSWORD_CHANGE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );
        break;
    }
  };

const joinToDelfiPremiumMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.PROFILE_PAGE.JOIN_TO_DELFI_PREMIUM:
        next(
          apiRequest(
            null,
            'PATCH',
            ApiUrls.JOIN_TO_DELFI_PREMIUM,
            null,
            { id: store.getState().profileData.user.id },
            MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM));
        break;
      case `${MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM} ${ActionTypes.API_SUCCESS}`:
        store.dispatch(fetchUserAction());
        next(setLoader(false, MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM));
        next(
          createNotification(
            MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM,
            NotificationTypes.SUCCESS,
            'Uspešno ste se prijavili na Delfi Premium'
          )
        );
        break;

      case `${MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM));
        next(
          createNotification(
            MiddlewareEntities.PROFILE_USER_DELFI_PREMIUM,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            undefined,
            action.payload.data.data
          )
        );
        break;
    }
  };

export default [
  fetchProfileUserMiddleware,
  deleteProfileUserMiddleware,
  fetchProfileOrderHistoryMiddleware,
  validateProfileOrderHistoryMiddleware,
  enrichProfileOrderHistoryMiddleware,
  fetchProfileActivityMiddleware,
  validateProfileActivityHistoryMiddleware,
  enrichProfileActivityHistoryMiddleware,
  updateProfileDataMiddleware,
  changeProfilePasswordMiddleware,
  joinToDelfiPremiumMiddleware
];

import Validator from '../Validator';
import Ajv from 'ajv';
import { actionSchema } from './actionSchema';

export default class ActionValidator extends Validator {
  public static validate(action: any) {
    const ajv = new Ajv();
    return ajv.validate(actionSchema, action);
  }
}

import { ICustomerDetail, IOrder, IOrderItem, IPaymentDetail } from './types';

export default class Order implements IOrder {
  id: number;

  amount: number;

  created_at: Date | string;

  customer_detail: ICustomerDetail;

  customer_detail_id: number;

  delivery_type: string;

  order_items: IOrderItem[];

  payment_detail: IPaymentDetail;

  payment_detail_id: number;

  secret: string;

  status: string;

  pttPrice: number;

  finalPrice: number | null;

  pc_validation_response?: any;

  constructor(
    id: number,
    amount: number,
    created_at: Date | string,
    customer_detail: ICustomerDetail,
    customer_detail_id: number,
    delivery_type: string,
    order_items: IOrderItem[],
    payment_detail: IPaymentDetail,
    payment_detail_id: number,
    secret: string,
    status: string,
    pttPrice: number,
    finalPrice: number | null,
    pc_validation_response = null
  ) {
    this.id = id;
    this.amount = amount;
    this.created_at = created_at;
    this.customer_detail = customer_detail;
    this.customer_detail_id = customer_detail_id;
    this.delivery_type = delivery_type;
    this.order_items = order_items;
    this.payment_detail = payment_detail;
    this.payment_detail_id = payment_detail_id;
    this.secret = secret;
    this.status = status;
    this.pttPrice = pttPrice;
    this.finalPrice = finalPrice;
    this.pc_validation_response = pc_validation_response;
  }
}

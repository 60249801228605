export default class Validator {

  public static isUndefined(item: any): boolean {
    return typeof item === 'undefined';
  }

  public static isObject(item: any): boolean {
    return typeof item === 'object' && item !== null;
  }

  public static isArray(item: any): boolean {
    return Array.isArray(item);
  }

  public static isArrayHasItems(items: Array<any>): number {
    return items.length;
  }

  public static isObjectHasItems(item: object): number {
    return Object.keys(item).length;
  }

}

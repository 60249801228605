import ActionTypes from '../../../constants/ActionTypes';
import {
  ICareerPageDataState,
  IFetchCareerPageDataAction,
  ISetCareerPageDataAction,
  ISubmitUserCareerData
} from './types';

export function fetchCareerPageDataAction(): IFetchCareerPageDataAction {
  return {
    type: ActionTypes.CAREER.FETCH_CAREER_PAGE_DATA
  };
}

export function setCareerPageDataAction(
  careerPageData: ICareerPageDataState
): ISetCareerPageDataAction {
  return {
    type: ActionTypes.CAREER.SET_CAREER_PAGE_DATA,
    payload: careerPageData
  };
}

export function validateCareerPageDataAction(
  careerPageData: ICareerPageDataState
) {
  return {
    type: ActionTypes.CAREER.VALIDATE_CAREER_PAGE_DATA,
    payload: careerPageData
  };
}

export function enrichCareerPageDataAction(
  careerPageData: ICareerPageDataState
) {
  return {
    type: ActionTypes.CAREER.ENRICH_CAREER_PAGE_DATA,
    payload: careerPageData
  };
}

export function submitUserCareerDataAction(
  userCareerData: FormData
) {
  return {
    type: ActionTypes.CAREER.SUBMIT_USER_CAREER_DATA,
    payload: {
      data: userCareerData
    }
  };
}

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichProductPageDataAction,
  setProductPageAction,
  validateProductPageDataAction
} from './actions';
import ProductValidator from '../../../validators/product';
import ProductOverviewFactory from '../../../factories/product-overview';
import { setCommentsAction } from '../comments/actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchProductPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.PRODUCT_PAGE.FETCH_PRODUCT_PAGE:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.PRODUCT_PAGE + '/' + action.payload.id,
            { ...action.params },
            null,
            MiddlewareEntities.PRODUCT_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PRODUCT_PAGE));
        break;
      case `${MiddlewareEntities.PRODUCT_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.PRODUCT_PAGE));
        next(validateProductPageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.PRODUCT_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PRODUCT_PAGE));

        if (
          action.payload.data &&
          action.payload.data.status &&
          action.payload.data.status === HttpStatusCodes.HTTP_NOT_FOUND
        ) {
          return (window.location.href = '/404');
        }

        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.PRODUCT_PAGE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateProductPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.PRODUCT_PAGE.VALIDATE_PRODUCT_PAGE)) {
      if (ProductValidator.validate(action.payload)) {
        return next(enrichProductPageDataAction(action.payload));
      }
    }
  };

export const enrichProductPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.PRODUCT_PAGE.ENRICH_PRODUCT_PAGE) {
      const productOverviewData = ProductOverviewFactory.create(action.payload);
      next(setProductPageAction(productOverviewData));
      next(setCommentsAction(productOverviewData.product.topComments));
    }
  };

export default [
  fetchProductPageDataMiddleware,
  validateProductPageDataMiddleware,
  enrichProductPageDataMiddleware
];

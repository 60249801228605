import Validator from '../Validator';
import Ajv from 'ajv';
import { bookstoresSchema } from './bookstoresSchema';
import { IBookstore } from '../../entities/bookstore/types';

export default class BookstoresValidator extends Validator {
  public static validate(bookstores: IBookstore[]) {
    const ajv = new Ajv();
    return ajv.validate(bookstoresSchema, bookstores);
  }
}

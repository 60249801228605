import ActionTypes from '../../../constants/ActionTypes';
import { ISetUserAction, ISetUserMessageAction, IUserState } from './types';
import { AnyAction, combineReducers } from 'redux';

const initialState: IUserState = {
  user: null
};

const userLoginReducer = (
  state = initialState,
  action: ISetUserAction
): IUserState => {
  switch (action.type) {
    case ActionTypes.USER.SET_USER:
      return {
        ...state,
        user: action.payload.user
      };
  }

  return state;
};

const initialUserStatusMessageState = {
  userMessage: '',
  status: ''
};

const userStatusMessageReducer = (
  state = initialUserStatusMessageState,
  action: ISetUserMessageAction
): any => {
  switch (action.type) {
    case ActionTypes.USER.SET_USER_MESSAGE:
      return {
        ...state,
        userMessage: action.payload.message,
        status: action.payload.status
      };
  }

  return state;
};

const userReducer = combineReducers({
  member: userLoginReducer,
  userStatusMessage: userStatusMessageReducer
});

export default userReducer;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IAskAboutProductState,
  ISetAskAboutProductMessageStatusAction
} from './types';

const initialState: IAskAboutProductState = {
  status: null,
  message: null
};

const askAboutProductReducer = (
  state = initialState,
  action: ISetAskAboutProductMessageStatusAction
): IAskAboutProductState => {
  switch (action.type) {
    case ActionTypes.ASK_ABOUT_PRODUCT
      .SET_ASK_ABOUT_PRODUCT_MESSAGE_STATUS_ACTION:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      };
  }

  return state;
};

export default askAboutProductReducer;

const OrderStatuses = {
  ACTIVE: 'active',
  FINISHED: 'finished',
  READY_FOR_ONLINE_PAYMENT: 'readyForOnlinePayment',
  READY_FOR_ZEAS_API: 'readyForZeasApi',
  WAITING_FOR_FINAL_ONLINE_PAYMENT: 'waitingForFinalOnlinePaymentStatus',
  E_BOOK_SUCCESSFULLY_ADDED: 'ebookSuccessfullyAdded',
  CANCELED: 'canceled',
  WAITING_FOR_FINAL_PAYMENT_SLIP_STATUS: 'waitingForFinalPaymentSlipStatus'
};

export default OrderStatuses;

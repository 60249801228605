/* eslint-disable @typescript-eslint/brace-style */

import { BenefitStatesEnum, IVisaMassBenefit } from '../cart/types';
import { IProduct } from '../product/types';
import Benefit from './Benefit';

export default class VisaMassBenefit
  extends Benefit
  implements IVisaMassBenefit
{
  state: BenefitStatesEnum;

  freeProducts: IProduct[];

  constructor(state: BenefitStatesEnum, freeProducts: IProduct[]) {
    super(state);
    this.state = state;
    this.freeProducts = freeProducts;
  }
}

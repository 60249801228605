import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import reducers from './rootReducers';
import { appProviderMiddleware } from './provider-middleware/app';
import { coreProviderMiddleware } from './provider-middleware/core';

const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === 'development',
});

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(
      loggerMiddleware,
      ...appProviderMiddleware,
      ...coreProviderMiddleware
    ),
  ),
);

export default store;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IAvailableNotificationData,
  IAvailableNotificationState,
  ISetAvailableNotificationDataAction,
  ISubmitAvailableNotificationDataAction
} from './types';

export function setAvailableNotificationData(
  data: IAvailableNotificationState
): ISetAvailableNotificationDataAction {
  return {
    type: ActionTypes.AVAILABLE_NOTIFICATION.SET_AVAILABLE_NOTIFICATION,
    payload: data
  };
}

export function submitAvailableNotificationDataAction(
  availableNotificationData: IAvailableNotificationData
): ISubmitAvailableNotificationDataAction {
  return {
    type: ActionTypes.AVAILABLE_NOTIFICATION.SUBMIT_AVAILABLE_NOTIFICATION,
    payload: {
      data: availableNotificationData
    }
  };
}

export function clearAvailableNotificationDataAction() {
  return {
    type: ActionTypes.AVAILABLE_NOTIFICATION.CLEAR_AVAILABLE_NOTIFICATION
  };
}

import {
  IFetchMenuItemsAction,
  IMenuItems,
  ISetMenuItemsAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const fetchMenuItemsAction = (): IFetchMenuItemsAction => ({
  type: ActionTypes.MENU_ITEMS.FETCH_MENU_ITEMS
});

export const validateMenuItemsAction = (
  menuItems: IMenuItems
): ISetMenuItemsAction => ({
  type: ActionTypes.MENU_ITEMS.VALIDATE_MENU_ITEMS,
  payload: menuItems
});

export const enrichMenuItemsAction = (
  menuItems: IMenuItems
): ISetMenuItemsAction => ({
  type: ActionTypes.MENU_ITEMS.ENRICH_MENU_ITEMS,
  payload: menuItems
});

export const setMenuItemsAction = (
  menuItems: IMenuItems
): ISetMenuItemsAction => ({
  type: ActionTypes.MENU_ITEMS.SET_MENU_ITEMS,
  payload: menuItems
});

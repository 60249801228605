import { AnyAction } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { INewsState } from './types';

const initialState: INewsState = {
  items: [],
  pageSection: {
    sections: [],
    metaDescription: ''
  }
};

const newsReducer = (state = initialState, action: AnyAction): INewsState => {
  switch (action.type) {
    case ActionTypes.NEWS.SET_LAST_NEWS:
      return {
        ...state,
        items: action.payload.isOverrideNews
          ? action.payload.news
          : [...state.items, ...action.payload.news]
      };
    case ActionTypes.NEWS.SET_NEWS_SECTIONS:
      return {
        ...state,
        pageSection: {
          sections: action.payload.sections,
          metaDescription: action.payload.metaDescription
        }
      };
  }

  return state;
};

export default newsReducer;

import { IFactory } from '../types';
import { IProductOverviewData } from '../../app/store/product-overview/types';
import { IProduct } from '../../entities/product/types';
import ProductFactory from '../product';
import SectionDataFacade from '../../facades/SectionDataFacade';
import ProductOverviewData from '../../entities/product/ProductOverviewData';
import Validator from '../../validators/Validator';

const ProductOverviewFactory: IFactory = class ProductOverviewFactory {
  static create(
    productOverviewData: IProductOverviewData
  ): IProductOverviewData {
    const fromTheSameSource: any = {};

    if (
      !Validator.isUndefined(productOverviewData.fromTheSameSource) &&
      Validator.isArray(productOverviewData.fromTheSameSource)
    ) {
      fromTheSameSource.products = productOverviewData.fromTheSameSource.map(
        (product: IProduct) => {
          return ProductFactory.create(product);
        }
      );
      fromTheSameSource.title = 'Od istog autora';

      switch (productOverviewData.product.category) {
        case 'Gift':
          fromTheSameSource.title = 'Iz iste kolekcije';
          break;
        case 'Film':
          fromTheSameSource.title = 'Od istog producenta';
          break;
        case 'Knjiga':
        case 'Strana knjiga':
        case 'Udžbenik':
        case 'Dečja knjiga':
        case 'Muzika':
          fromTheSameSource.title = 'Od istog autora';
          break;
        case 'Video igra':
          fromTheSameSource.title = 'Iz iste franšize';
          break;
        default:
          fromTheSameSource.title = 'Od istog autora';
      }
    }

    const pageSections = SectionDataFacade.generate(
      productOverviewData.pageSections
    );

    return new ProductOverviewData(
      ProductFactory.create(productOverviewData.product),
      fromTheSameSource,
      pageSections
    );
  }
};

export default ProductOverviewFactory;

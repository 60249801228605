import Validator from '../Validator';
import Ajv from 'ajv';
import { bookmakerSchema } from './bookmakerSchema';
import { IBookMakerMagazineState } from '../../app/store/bookmaker-magazine/types';

export default class BookmakerValidator extends Validator {
  public static validate(bookmakerPageData: IBookMakerMagazineState) {
    const ajv = new Ajv();
    return ajv.validate(bookmakerSchema, bookmakerPageData);
  }
}

/* eslint-disable @typescript-eslint/brace-style */

import { BenefitStatesEnum, IYandexBenefit } from '../cart/types';
import Benefit from './Benefit';

export default class YandexBenefit extends Benefit implements IYandexBenefit {
  state: BenefitStatesEnum;

  forToday: boolean;

  forTomorrow: boolean;

  forMonday: boolean;

  constructor(
    state: BenefitStatesEnum,
    forToday: boolean,
    forTomorrow: boolean,
    forMonday: boolean
  ) {
    super(state);
    this.state = state;
    this.forToday = forToday;
    this.forTomorrow = forTomorrow;
    this.forMonday = forMonday;
  }
}

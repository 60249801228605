import ActionTypes from '../../../constants/ActionTypes';
import { IFaqState, IFetchFaqDataAction, ISetFaqDataAction } from './types';

export function fetchFaqDataAction(): IFetchFaqDataAction {
  return {
    type: ActionTypes.FAQ.FETCH_FAQ_DATA
  };
}

export function setFaqDataAction(faqData: IFaqState): ISetFaqDataAction {
  return {
    type: ActionTypes.FAQ.SET_FAQ_DATA,
    payload: faqData
  };
}

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchForeignBookDataAction,
  IForeignBookState,
  ISetForeignBookDataAction
} from './types';

export function fetchForeignBookDataAction(): IFetchForeignBookDataAction {
  return {
    type: ActionTypes.FOREIGN_BOOK.FETCH_FOREIGN_BOOK_DATA
  };
}

export function setForeignBookDataAction(
  foreignBookData: IForeignBookState
): ISetForeignBookDataAction {
  return {
    type: ActionTypes.FOREIGN_BOOK.SET_FOREIGN_BOOK_DATA,
    payload: foreignBookData
  };
}

export function validateForeignBookDataAction(
  foreignBookData: IForeignBookState
) {
  return {
    type: ActionTypes.FOREIGN_BOOK.VALIDATE_FOREIGN_BOOK_DATA,
    payload: foreignBookData
  };
}

export function enrichForeignBookDataAction(foreignBookData: any) {
  return {
    type: ActionTypes.FOREIGN_BOOK.ENRICH_FOREIGN_BOOK_DATA,
    payload: foreignBookData
  };
}

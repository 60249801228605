import React, {
  forwardRef,
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import delfiLogoImage from '../../../assets/images/header/delfilogo-15.webp';
import './scss/style.scss';
import Button from '../../../components/button';
import SearchBarContainer from '../search-bar';
import UserPopup from '../../../components/user-popup';
import CartPopup from '../../../components/cart-popup';
import favoriteImageIcon from '../../../assets/images/header/favorite.svg';
import userImageIcon from '../../../assets/images/header/profile.svg';
import userLoginNewIcon from '../../../assets/images/header/user-login-blue.svg';
import cartImageIcon from '../../../assets/images/header/cart-blue.svg';
import { IUser } from '../../../../entities/user/types';
import RoundedCircleNumber from '../../../components/rounded-circle-number';
import { Link, useNavigate } from 'react-router-dom';
import { ICartState } from '../../../../app/store/cart/types';
import {
  IMenuItems,
  ITopMenuItem
} from '../../../../app/store/menu-items/types';
import { useMediaQuery } from 'react-responsive';
import downArrayImage from '../../../assets/images/down-arrow.svg';
import upArrayImage from '../../../assets/images/arrow-up.svg';
import { formatPrice } from '../../../../utilites/product';
import { ILoginRequest } from '../../../components/login-form/types';

interface IProps {
  numberOfWishProductItems: number;
  cart: ICartState;
  handleOnChangeQuantity: (productId: string, quantity: number) => void;
  handleOnRemoveFromItem: (productId: string) => void;
  handleLogin: (values: ILoginRequest) => void;
  handleLogout: () => void;
  handleOnClickShowDropdownMenu: () => void;
  handleOnClickShowUserDropDownMenu: (
    event: React.MouseEvent<HTMLElement>
  ) => void;
  handleOnClickShowCategoryMenu: () => void;
  handleOnClickShowCartPopup: (event: React.MouseEvent<HTMLElement>) => void;
  user: IUser | null;
  isChanged: boolean;
  showDropdownCategory: boolean;
  showLogoDropdownMenu: boolean;
  showUserPopup: boolean;
  showCartPopup: boolean;
  showCartAnimation: boolean;
  isShowSearchItems: boolean;
  setIsShowSearchItems: (isShowSearchItems: boolean) => void;
  isCategoryDropdownOpen: boolean;
  setIsCategoryDropdownOpen: (isCategoryDropdownOpen: boolean) => void;
  ui: any;
  menuItems: IMenuItems;
}

const DesktopHeader2 = forwardRef<any, IProps>(
  (
    {
      numberOfWishProductItems,
      cart,
      handleOnChangeQuantity,
      handleOnRemoveFromItem,
      handleLogin,
      handleLogout,
      handleOnClickShowDropdownMenu,
      handleOnClickShowUserDropDownMenu,
      handleOnClickShowCategoryMenu,
      handleOnClickShowCartPopup,
      user,
      isChanged,
      showDropdownCategory,
      showLogoDropdownMenu,
      showUserPopup,
      showCartPopup,
      showCartAnimation,
      isShowSearchItems,
      setIsShowSearchItems,
      isCategoryDropdownOpen,
      setIsCategoryDropdownOpen,
      ui,
      menuItems
    }: IProps,
    ref
  ): ReactElement => {
    const navigate = useNavigate();
    const {
      userPopupRef,
      cartPopupRef,
      categoryDropdownRef,
      searchWrapperRef,
      searchCategoryDropdownRef
      // @ts-ignore
    } = ref.current;
    const isLaptop = useMediaQuery({ query: '(max-width: 1600px)' });

    const [isCategoryDropdownHovered, setIsCategoryDropdownHovered] =
      useState(false);

    const handleOnCategoryMouseEnter = () => {
      setIsCategoryDropdownHovered(true);
    };

    const handleOnCategoryMouseLeave = () => {
      setIsCategoryDropdownHovered(false);
    };

    useEffect(() => {
      window.onpopstate = () => {
        setIsShowSearchItems(false);
      };
    });

    const searchBarRefs = useRef({
      searchWrapperRef,
      searchCategoryDropdownRef
    });

    const topMenuItems = menuItems.menuItems?.header?.topMenuItems;

    return (
      <div className="sticky-header sticky-top background-white-lilac">
        <div className="position-relative">
          <div className="row justify-content-center ms-0 me-0 gx-0 align-items-center">
            <div className="col-md-2">
              <div className="card border-0 bg-transparent nav-logo">
                <Link to="/">
                  <img
                    style={{ width: '264px' }}
                    className="img-fluid"
                    alt="delfi-logo"
                    src={delfiLogoImage}
                  />
                </Link>
              </div>
            </div>
            <div className="col-md-10 col-12">
              <div className="row justify-content-center align-items-center ms-0 me-0">
                <SearchBarContainer
                  isShowSearchItems={isShowSearchItems}
                  isCategoryDropdownOpen={isCategoryDropdownOpen}
                  setIsShowSearchItems={setIsShowSearchItems}
                  setIsCategoryDropdownOpen={setIsCategoryDropdownOpen}
                  ref={searchBarRefs}
                />
                <div className="col-4 col-lg-4 col-xl-3 p-0 text-end position-relative">
                  <div
                    className="ms-5 cursor-pointer d-inline position-relative"
                    onClick={() => navigate('/lista-zelja')}
                  >
                    <img
                      src={favoriteImageIcon}
                      alt="favorite"
                      className="mx-auto"
                    />
                    {numberOfWishProductItems ? (
                      <RoundedCircleNumber
                        numberOfItems={numberOfWishProductItems}
                        classes="rounded-circle-number-wrapper-desktop  background-orange"
                      />
                    ) : null}
                  </div>
                  <div
                    className="ms-5 cursor-pointer  d-inline position-relative"
                    onClick={handleOnClickShowUserDropDownMenu}
                  >
                    {user && user.username ? (
                      <img
                        src={userLoginNewIcon}
                        alt="korisnik"
                        className="mx-auto"
                      />
                    ) : (
                      <img
                        src={userImageIcon}
                        alt="korisnik"
                        className="mx-auto"
                      />
                    )}
                    {user &&
                    user.username !== '' &&
                    typeof user.isDelfiClub !== 'undefined' &&
                    !user.isDelfiClub ? (
                      <RoundedCircleNumber
                        numberOfItems={1}
                        classes="rounded-circle-number-wrapper-desktop background-torch-red color-white"
                      />
                    ) : null}
                  </div>
                  <div
                    id="shopping-cart"
                    className="ms-5 cursor-pointer d-inline position-relative"
                    onClick={handleOnClickShowCartPopup}
                  >
                    <img
                      src={cartImageIcon}
                      alt="korpa"
                      className={`mx-auto mt-minus-4 ${
                        showCartAnimation ? 'add-to-cart-animation' : ''
                      }`}
                    />
                    {cart.order.totalItemQuantity ? (
                      <RoundedCircleNumber
                        numberOfItems={cart.order.totalItemQuantity}
                        classes={`rounded-circle-number-wrapper-desktop background-orange ${
                          showCartAnimation ? 'add-to-cart-animation' : ''
                        }`}
                      />
                    ) : null}
                  </div>
                  {showUserPopup ? (
                    <UserPopup
                      handleLogin={handleLogin}
                      handleLogout={handleLogout}
                      handleOnClickShowUserPopup={
                        handleOnClickShowUserDropDownMenu
                      }
                      user={user}
                      ref={userPopupRef}
                      ui={ui}
                      isLaptop={isLaptop}
                    />
                  ) : null}
                  {showCartPopup ? (
                    <CartPopup
                      cart={cart}
                      handleOnChangeQuantity={handleOnChangeQuantity}
                      handleOnRemoveFromItem={handleOnRemoveFromItem}
                      handleOnClickShowCartPopup={handleOnClickShowCartPopup}
                      ref={cartPopupRef}
                      isLaptop={isLaptop}
                      isChanged={isChanged}
                    />
                  ) : null}
                </div>
              </div>
              <div className="row justify-content-center align-items-center ms-0 me-0">
                <div className="col-10 text-16">
                  <nav
                    className="
                            navbar
                            justify-content-center
                            justify-content-xxl-between
                            font-color-default
                            text-16
                            jost-semi-bold"
                  >
                    <a
                      ref={categoryDropdownRef}
                      className="navbar-brand text-16 cursor-pointer"
                      onClick={() => handleOnClickShowCategoryMenu()}
                    >
                      <span
                        className={`me-2 ${
                          showDropdownCategory
                            ? 'color-active-item'
                            : 'font-color-default d0'
                        }`}
                      >
                        Kategorije
                      </span>
                      {showDropdownCategory ? (
                        <img
                          alt="ikonica-gore"
                          src={upArrayImage}
                          width="10"
                          height="10"
                        />
                      ) : (
                        <img
                          alt="ikonica-dole"
                          src={downArrayImage}
                          width="10"
                          height="10"
                        />
                      )}
                    </a>
                    <span className="pt-3 pb-3 mt-2 border-end border-2" />
                    {topMenuItems && topMenuItems.length > 0 ? (
                      <>
                        {topMenuItems.map((item: ITopMenuItem) => {
                          if (item.label === 'BookTok') {
                            // todo: This is a quick fix based on Jelena Veljkovic ad-hoc request 01.08.2023. If this happens again or logic start becomes more complex, this will be refactored and moved to the Admin Panel side
                            return (
                              <Link
                                key={item.label}
                                className="marked-menu-item navbar-brand text-16 ms-3 ms-xxl-0"
                                to={item.link}
                              >
                                {item.label}
                              </Link>
                            );
                          }
                          return (
                            <Link
                              key={item.label}
                              className="navbar-brand text-16 font-color-default ms-3 ms-xxl-0"
                              to={item.link}
                            >
                              {item.label}
                            </Link>
                          );
                        })}
                      </>
                    ) : null}
                  </nav>
                </div>
                <div className="col-2"></div>
              </div>
            </div>
          </div>
          {showDropdownCategory && (
            <div className="row me-0 ms-0 justify-content-between text-start text-14 position-absolute category">
              <div className="col-2 p-5 offset-1 bg-white box-shadow-main-category-dropdown">
                <div
                  onMouseEnter={handleOnCategoryMouseEnter}
                  className="category-dropdown"
                >
                  <h6>
                    <span className="color-active-item text-14 jost-extra-bold">
                      KATEGORIJE
                    </span>
                    <span>
                      <MdKeyboardArrowRight
                        size="1.1em"
                        className="ms-3 mb-1 color-active-item"
                      />
                    </span>
                  </h6>
                  <hr />
                </div>
                <ul
                  className="list-style-none p-0 text-14 jost-extra-bold"
                  onMouseEnter={handleOnCategoryMouseLeave}
                >
                  <li className="mb-4 mt-3">
                    <a
                      className="cursor-pointer text-decoration-none font-color-default"
                      onClick={() => navigate('/gift')}
                    >
                      <span>GIFT</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      className="cursor-pointer text-decoration-none font-color-default"
                      onClick={() => navigate('/top-liste')}
                    >
                      <span>TOP LISTE</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <a
                      className="cursor-pointer text-decoration-none font-color-default"
                      onClick={() => navigate('/muzika')}
                    >
                      <span>MUZIKA</span>
                    </a>
                  </li>
                  <li className="mb-4">
                    <Link
                      className="text-decoration-none font-color-default"
                      to="lista-proizvoda?eBook=true"
                    >
                      <span>E-KNJIGE</span>
                    </Link>
                  </li>
                  <li className="mb-5">
                    <a
                      className="cursor-pointer text-decoration-none font-color-default"
                      onClick={() => navigate('/drustvene-igre')}
                    >
                      <span>DRUŠTVENE IGRE</span>
                    </a>
                  </li>
                  <li>
                    <hr />
                  </li>
                  {/*<li className="mb-4 mt-5">*/}
                  {/*  <Link*/}
                  {/*    className="text-decoration-none font-color-default"*/}
                  {/*    to="video_igre_i_konzole"*/}
                  {/*  >*/}
                  {/*    <span>VIDEO IGRE</span>*/}
                  {/*  </Link>*/}
                  {/*</li>*/}
                  <li className="mb-4">
                    <Link
                      className="text-decoration-none font-color-default"
                      to="akcije"
                    >
                      <span>AKCIJE</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="text-decoration-none font-color-default"
                      to="vesti"
                    >
                      <span>#DELFI KUTAK</span>
                    </Link>
                  </li>
                </ul>
              </div>
              {isCategoryDropdownHovered ? (
                <div
                  className="col d-flex p-3 bg-white box-shadow-category-dropdown-wrapper"
                  onMouseLeave={handleOnCategoryMouseLeave}
                >
                  <>
                    <div className="col">
                      <div>
                        <h6 className="text-14">KNJIGE</h6>
                        <ul className="footer-links text-14 jost-semi-bold text-muted">
                          <li className="mb-2 mt-3">
                            <Link
                              className="text-decoration-none"
                              to={`/Knjiga/zanr/${encodeURIComponent(
                                'Domaći pisci'
                              )}`}
                            >
                              Domaći Pisci
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Knjiga/zanr/${encodeURIComponent(
                                'Nagrađene knjige'
                              )}`}
                            >
                              Nagrađivane knjige
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Knjiga/zanr/Trileri"
                            >
                              Trileri
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Knjiga/zanr/${encodeURIComponent(
                                'Ljubavni romani'
                              )}`}
                            >
                              Ljubavni
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Knjiga/zanr/Drama"
                            >
                              Drame
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Knjiga/zanr/${encodeURIComponent(
                                'Popularna psihologija'
                              )}`}
                            >
                              Popularna psihologija
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Knjiga/zanr/StarWay"
                            >
                              StarWay
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Knjiga/zanr/${encodeURIComponent(
                                'Stripovi i grafičke novele'
                              )}`}
                            >
                              Stripovi i grafičke novele
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Knjiga/zanr/Publicistika"
                            >
                              Publicistika
                            </Link>
                          </li>
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?category=Knjiga&genre=Fantastika"*/}
                          {/*  >*/}
                          {/*    Fantastika*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?category=Knjiga&genre=Istorija"*/}
                          {/*  >*/}
                          {/*    Istorija*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?category=Knjiga&genre=Tinejdž"*/}
                          {/*  >*/}
                          {/*    Tinejdž*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?category=Knjiga&genre=Biznis i ekonomija"*/}
                          {/*  >*/}
                          {/*    Biznis i ekonomija*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?category=Knjiga&genre=Autobiografije i biografije"*/}
                          {/*  >*/}
                          {/*    Biografije*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          <li>
                            <Link
                              className="text-decoration-none"
                              to="/lista-proizvoda?category=Knjiga"
                            >
                              Vidi sve
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4">
                        <h6>KNJIGE ZA DECU</h6>
                        <ul className="footer-links text-14 jost-semi-bold text-muted">
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Knjiga/zanr/${encodeURIComponent(
                                'Knjige za decu'
                              )}?inPage=decja-knjiga&pgRating=0-3,0+`}
                            >
                              Najmlađi do 3 godine
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Knjiga/zanr/${encodeURIComponent(
                                'Knjige za decu'
                              )}?inPage=decja-knjiga&pgRating=3-6`}
                            >
                              3 do 6 godina
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col">
                      {/*<div>*/}
                      {/*  <h6 className="text-14">UDŽBENICI</h6>*/}
                      {/*  <ul className="footer-links text-14 jost-semi-bold text-muted">*/}
                      {/*    <li className="mb-2 mt-3">*/}
                      {/*      <a*/}
                      {/*        className="text-decoration-none"*/}
                      {/*        href="/lista-proizvoda?Udžbenici i priručnici&pgRating=6+"*/}
                      {/*      >*/}
                      {/*        Predškolski uzrast*/}
                      {/*      </a>*/}
                      {/*    </li>*/}
                      {/*    <li className="mb-2">*/}
                      {/*      <a*/}
                      {/*        className="text-decoration-none"*/}
                      {/*        href="/lista-proizvoda?Udžbenici i priručnici&pgRating=7+"*/}
                      {/*      >*/}
                      {/*        Osnovna škola*/}
                      {/*      </a>*/}
                      {/*    </li>*/}
                      {/*    <li>*/}
                      {/*      <a*/}
                      {/*        className="text-decoration-none"*/}
                      {/*        href="/lista-proizvoda?Udžbenici i priručnici&pgRating=12+"*/}
                      {/*      >*/}
                      {/*        Srednja škola*/}
                      {/*      </a>*/}
                      {/*    </li>*/}
                      {/*  </ul>*/}
                      {/*</div>*/}
                      <div>
                        {/*<h6 className="text-14">POGLEDAJTE JOŠ</h6>*/}
                        {/*<ul className="footer-links text-14 jost-semi-bold text-muted">*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Stripovi i grafičke+novele"*/}
                        {/*    >*/}
                        {/*      Stripovi*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Horor"*/}
                        {/*    >*/}
                        {/*      Horor*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Filozofija"*/}
                        {/*    >*/}
                        {/*      Filozofija*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Zdravlje_ss_cmma_ sport i lepota"*/}
                        {/*    >*/}
                        {/*      Zdravlje, sport i lepota*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Marketing i menadžment"*/}
                        {/*    >*/}
                        {/*      Marketing i menadžment*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Politika"*/}
                        {/*    >*/}
                        {/*      Politika*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Putopisi"*/}
                        {/*    >*/}
                        {/*      Putopisi*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga&genre=Religija i mitologija"*/}
                        {/*    >*/}
                        {/*      Religija i mitologija*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*  <li className="mb-2">*/}
                        {/*    <a*/}
                        {/*      className="text-decoration-none"*/}
                        {/*      href="/lista-proizvoda?category=Knjiga"*/}
                        {/*    >*/}
                        {/*      Vidi sve*/}
                        {/*    </a>*/}
                        {/*  </li>*/}
                        {/*</ul>*/}
                        <h6 className="text-14">GIFT</h6>
                        <ul className="footer-links text-14 jost-semi-bold text-muted">
                          <li className="mb-2 mt-3">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Društvene igre i igračke'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Društvene igre i igračke
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/Gift/zanr/StarWay"
                              className="text-decoration-none font-color-default"
                            >
                              StarWay
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Torbe_ss_cmma_ rančevi_ss_cmma_ neseseri'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Torbe, rančevi, neseseri
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/Gift/zanr/Aksesoari"
                              className="text-decoration-none font-color-default"
                            >
                              Aksesoari
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Kolekcionarski predmeti i replike'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Kolekcionarski predmeti i replike
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Šolje i čaše'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Šolje i čaše
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Držači za čaše'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Držači za knjige
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Lampice za knjige'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Lampice za knjige
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Kućna+dekoracija i ukrasi'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Kućna dekoracija i ukrasi
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Školski pribor'
                              )}`}
                              className="text-decoration-none font-color-default"
                            >
                              Školski pribor
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="/lista-proizvoda?category=Gift"
                              className="text-decoration-none font-color-default"
                            >
                              Vidi sve
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4">
                        <h6>DRUŠTVENE IGRE</h6>
                        <ul className="footer-links text-14 jost-semi-bold text-muted">
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Društvene igre - savremene'
                              )}`}
                            >
                              Društvene igre – savremene
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Društvene igre - klasične'
                              )}`}
                            >
                              Društvene igre – klasične
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Društvene igre za decu'
                              )}`}
                            >
                              Društvene igre za decu
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Gift/zanr/${encodeURIComponent(
                                'Društvene igre - dodatna oprema'
                              )}`}
                            >
                              Društvene igre – dodatna oprema
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <h6 className="text-14">STRANE KNJIGE</h6>
                        <ul className="footer-links text-14 jost-semi-bold text-muted">
                          <li className="mb-2 mt-3">
                            <Link
                              className="text-decoration-none"
                              to="/Strana knjiga/zanr/Manga"
                            >
                              Manga
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Strana knjiga/zanr/StarWay"
                            >
                              StarWay
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Strana knjiga/zanr/Fantasy"
                            >
                              Fantasy
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Strana knjiga/zanr/${encodeURIComponent(
                                'Young Adult'
                              )}`}
                            >
                              Young Adults
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Strana knjiga/zanr/${encodeURIComponent(
                                "Children's Books"
                              )}`}
                            >
                              Children`s Books
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Strana knjiga/zanr/Chicklit"
                            >
                              Chicklit
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Strana knjiga/zanr/${encodeURIComponent(
                                'Marketing & Management'
                              )}`}
                            >
                              Marketing&Management
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Strana knjiga/zanr/${encodeURIComponent(
                                'Popular Psychology'
                              )}`}
                            >
                              Popular Psychology
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Strana knjiga/zanr/Arts"
                            >
                              Art
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to={`/Strana knjiga/zanr/${encodeURIComponent(
                                'Computers & Internet'
                              )}`}
                            >
                              Computers & Internet
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/Strana knjiga/zanr/Classics"
                            >
                              Classics
                            </Link>
                          </li>
                          <li>
                            <Link
                              className="text-decoration-none"
                              to="/lista-proizvoda?category=Strana knjiga"
                            >
                              Vidi sve
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="mt-4">
                        <h6 className="text-14">POPULARNO</h6>
                        <ul className="footer-links text-14 jost-semi-bold text-muted">
                          <li className="mb-2">
                            <Link
                              to="/top-liste"
                              className="text-decoration-none font-color-default"
                            >
                              Top-liste
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/vesti"
                              className="text-decoration-none font-color-default"
                            >
                              # Delfi kutak
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              to="/akcije"
                              className="text-decoration-none font-color-default"
                            >
                              Akcije
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col">
                      <div>
                        <h6>POPULARNI AUTORI</h6>
                        <ul className="footer-links text-14 jost-semi-bold text-muted">
                          <li className="mb-2 mt-3">
                            <Link
                              className="text-decoration-none"
                              to="/autori/65280b32f2fbb3bb27e31f09-stefani-stal"
                            >
                              Stefani Štal
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/65280c62f2fbb3bb27e39805-inio-asano"
                            >
                              Inio Asano
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/652808b9f2fbb3bb27e23b2f-kolin-huver"
                            >
                              Kolin Huver
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/6528086af2fbb3bb27e21e8f-jelena-bacic-alimpic"
                            >
                              Jelena Bačić Alimpić
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/652807fbf2fbb3bb27e1f823-mirjana-bobic-mojsilovic"
                            >
                              Mirjana Bobić Mojsilović
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/65280823f2fbb3bb27e20593-goran-milasinovic"
                            >
                              Goran Milašinović
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/6528082cf2fbb3bb27e208fb-teri-pracet"
                            >
                              Teri Pračet
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/6528082df2fbb3bb27e20947-bil-brajson"
                            >
                              Bil Brajson
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/65280a8cf2fbb3bb27e2e1e9-fredrik-bakman"
                            >
                              Fredrik Bakman
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/65280827f2fbb3bb27e2071f-dzordz-r-r-martin"
                            >
                              Džordž R.R.Martin
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/652807f4f2fbb3bb27e1f53d-david-albahari"
                            >
                              David Albahari
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/652807fcf2fbb3bb27e1f8a9-dz-k-rouling"
                            >
                              Dž. K. Rouling
                            </Link>
                          </li>
                          <li className="mb-2">
                            <Link
                              className="text-decoration-none"
                              to="/autori/65280b2ef2fbb3bb27e31e19-dzejms-klir"
                            >
                              Džejms Klir
                            </Link>
                          </li>
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?author=6528080bf2fbb3bb27e1fd47&inPage=lista-autora"*/}
                          {/*  >*/}
                          {/*    Paolo Koeljo*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?author=6528082ff2fbb3bb27e209f3&inPage=lista-autora"*/}
                          {/*  >*/}
                          {/*    Vanja Bulić*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?author=65280a8cf2fbb3bb27e2e1e9&inPage=lista-autora"*/}
                          {/*  >*/}
                          {/*    Fredrik Bakman*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?author=65280815f2fbb3bb27e1ffe5&inPage=lista-autora"*/}
                          {/*  >*/}
                          {/*    Dušan Radović*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          {/*<li className="mb-2">*/}
                          {/*  <Link*/}
                          {/*    className="text-decoration-none"*/}
                          {/*    to="/lista-proizvoda?author=6528082ff2fbb3bb27e20a3f&inPage=lista-autora"*/}
                          {/*  >*/}
                          {/*    Trejsi Ševalije*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                        </ul>
                      </div>
                    </div>
                    {menuItems.prominentProduct &&
                    Object.keys(menuItems.prominentProduct).length > 0 ? (
                      <div className="col-3 background-gray height-menu-right-component p-2">
                        <Link
                          to={menuItems.prominentProduct.product.navigateTo}
                          className="text-decoration-none font-color-default"
                        >
                          <div className="row justify-content-center align-items-center text-center">
                            <div className="col-md-12 font-weight-700 text-transform text-5em">
                              <span className="text-uppercase">
                                {menuItems.prominentProduct.product.category}
                              </span>
                            </div>
                            <div className="col-md-12">
                              <img
                                src={menuItems.prominentProduct.image}
                                alt={menuItems.prominentProduct.product.title}
                                className="img-fluid shadow-img"
                              />
                            </div>
                            <div className="col-md-12 mt-2 text-14 jost-medium text-muted">
                              <span>
                                {menuItems.prominentProduct.product.publisher}
                              </span>
                            </div>
                            <div className="col-md-10 mt-4">
                              <h6 className="h6-mod1 text-18 max-lines-3 max-lines">
                                {menuItems.prominentProduct.product.title}
                              </h6>
                            </div>
                            <div className="col-md-12 mt-2 text-16 jost-semi-bold color-blue">
                              <span>
                                {formatPrice(
                                  menuItems.prominentProduct.product
                                    .displayPrices.fullPrice,
                                  2
                                )}
                                &nbsp;RSD
                              </span>
                            </div>
                            <div className="col-md-12 mt-4">
                              <Button
                                classes="w-75 text-14 jost-extra-bold"
                                styleType={{
                                  height: '60px',
                                  background: '#FBC920',
                                  border: 'none'
                                }}
                              >
                                KUPI SAD
                              </Button>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : null}
                  </>
                </div>
              ) : null}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default DesktopHeader2;

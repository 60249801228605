import { IFlatCarouselContent } from '../../../entities/section/types';
import { IFactory } from '../../types';
import FlatCarouselsContent from '../../../entities/section/flatcarousels/FlatCarouselsContent';
import { IProduct } from '../../../entities/product/types';
import ProductFactory from '../../product';
import { getAvailableProducts } from '../../../utilites/product';

const FlatSquareCarouselsContentFactory: IFactory = class FlatSquareCarouselsContentFactory {
  static create(content: IFlatCarouselContent) {
    return new FlatCarouselsContent(
      content._id,
      content.title,
      content.priceDisplayType,
      content.startAt,
      content.endAt,
      getAvailableProducts(content.products),
      content.isTopList,
      content.viewAllUrl
    );
  }
};

export default FlatSquareCarouselsContentFactory;

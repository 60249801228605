// export const getCookie = (name: string) => {
//   const re = new RegExp(name + '=([^;]+)');
//   const value = re.exec(document.cookie);
//   return value != null ? decodeURI(value[1]) : null;
// };

export const getCookie = (cname: string) => {
  try {
    let name = cname + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  } catch (e) {
    return '';
  }
  return '';
};

export const setCookie = (name: string, value: string, days?: number) => {
  let expires = '';

  if (days) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    expires = `expires=${date.toUTCString()}`;
  }

  document.cookie = `${name}=${value || ''}; ${expires}; path=/`;
};

export const removeCookie = (name: string) => {
  document.cookie = `${name}=;Path=/;Expires=Thu, 01 Jan 1970 00:00:01 GMT;"`;
};

export const getJsonFromCookie = (cookieName: string, parseTo: string) => {
  try {
    return JSON.parse(getCookie(cookieName) || parseTo);
  } catch (e) {
    removeCookie(cookieName);
    return null;
  }
};

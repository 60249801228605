import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import NewsFactory from '../../../factories/news/NewsFactory';
import NewsValidator from '../../../validators/news';
import {
  enrichNewsOverview,
  setNewsOverview,
  validateNewsOverview
} from './actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

const fetchNewsOverviewMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWS_OVERVIEW.FETCH_NEWS_OVERVIEW:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_LAST_NEWS}/${action.payload.newsId}`,
            null,
            null,
            MiddlewareEntities.NEWS_OVERVIEW,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.NEWS_OVERVIEW));
        break;
      case `${MiddlewareEntities.NEWS_OVERVIEW} ${ActionTypes.API_SUCCESS}`:
        if (action.payload.data) {
          next(validateNewsOverview(action.payload.data));
          return next(setLoader(false, MiddlewareEntities.NEWS_OVERVIEW));
        }
        window.location.href = '/404';
        break;
      case `${MiddlewareEntities.NEWS_OVERVIEW} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWS_OVERVIEW));

        if ((action.payload.data.status = HttpStatusCodes.HTTP_NOT_FOUND)) {
          return window.location.replace('/404');
        }

        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.NEWS_OVERVIEW,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

const validateNewsOverviewMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.NEWS_OVERVIEW.VALIDATE_NEWS_OVERVIEW)
    ) {
      if (NewsValidator.validate(action.payload.news)) {
        return next(enrichNewsOverview(action.payload.news));
      }
      window.location.replace('/404');
    }
  };

const enrichNewsOverviewMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.NEWS_OVERVIEW.ENRICH_NEWS_OVERVIEW) {
      next(setNewsOverview(NewsFactory.create(action.payload.news)));
    }
  };

export default [
  fetchNewsOverviewMiddleware,
  validateNewsOverviewMiddleware,
  enrichNewsOverviewMiddleware
];

export const productSchema: any = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    publisher: { type: ['string', 'null'] },
    status: { type: 'boolean' },
    isAvailable: { type: 'boolean' },
    title: { type: 'string' },
    category: { type: 'string' },
    images: {
      type: 'object',
      properties: {
        s: { type: 'string' },
        m: { type: 'string' },
        l: { type: 'string' },
        xl: { type: 'string' },
        xxl: { type: 'string' },
        fb: { type: 'string' }
      },
      required: ['s', 'm', 'l', 'xl', 'xxl', 'fb']
    },
    priceList: {
      type: 'object',
      properties: {
        fullPrice: { type: ['string', 'number'] },
        // discount: { type: ['string', 'number'] },
        // quantityDiscount: { type: ['string', 'number'] },
        // action: { type: ['string', 'number'] },
        eBookPrice: { type: ['string', 'number'] }
      },
      required: ['fullPrice']
    }
  },
  required: [
    '_id',
    'isAvailable',
    'status',
    'title',
    'category',
    'images',
    'priceList'
  ]
};

import ActionTypes from '../../../constants/ActionTypes';
import { ICookieConsentState, ISetCookieConsentAction } from './types';

const initialState: ICookieConsentState = {
  cookieConsent: null
};

const cookieConsentReducer = (
  state = initialState,
  action: ISetCookieConsentAction
): ICookieConsentState => {
  switch (action.type) {
    case ActionTypes.COOKIE_CONSENT.SET_COOKIE_CONSENT:
      return {
        ...state,
        cookieConsent: action.payload.cookieConsent
      };
  }

  return state;
};

export default cookieConsentReducer;

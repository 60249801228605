export const newsSchema: any = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    products: { type: 'array' },
    status: { type: 'boolean' },
    actions: { type: 'boolean' },
    content: { type: 'string' },
    img1: { type: 'string' },
    img2: { type: ['string', 'null'] },
    img3: { type: ['string', 'null'] },
    createdAt: { type: ['string', 'null'] },
    title: { type: 'string' },
    oldId: { type: 'number' }
  },
  required: ['_id', 'content', 'title', 'img1']
};

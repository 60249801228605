import Config from '../config';
import { IProduct } from '../entities/product/types';
import ProductFactory from '../factories/product';
import { IUser } from '../entities/user/types';
import Ajv from 'ajv';
import { productSchema } from '../validators/product/productSchema';
import { PriceDisplayTypes } from '../entities/section/types';

export const formatPrice = (
  value: number | string | Number,
  fixedTo: number
): string => {
  if (typeof value === 'string') {
    value = parseFloat(value);
  } else {
    value = Number(value);
  }

  return new Intl.NumberFormat('sr', {
    minimumFractionDigits: fixedTo,
    maximumFractionDigits: 2
  }).format(Number(value));
};

export const replaceDotWithUrl = (string: string): string => {
  if (string.startsWith('..')) {
    return string.replace(/\.\.\//g, `${Config.api.mediaUrl}/`);
  }

  return `${Config.api.mediaUrl}${string}`;
};

export const getCategoryTabs = (tabs: any[]) => {
  const categories: { id: string; name: string }[] = [];

  tabs.forEach((item: any) => {
    categories.push({ id: item.name, name: item.name });
  });

  return categories;
};

export const getAvailableProduct = (
  product: IProduct | null
): IProduct | null => {
  const ajv = new Ajv();
  if (
    product &&
    product.status &&
    product.isAvailable &&
    ajv.validate(productSchema, product)
  ) {
    return ProductFactory.create(product);
  }

  return null;
};

export const getAvailableProducts = (products: IProduct[]) => {
  const ajv = new Ajv();
  const availableProducts = products.filter(
    (product: IProduct) =>
      product.status &&
      product.isAvailable &&
      ajv.validate(productSchema, product)
  );

  return availableProducts.map((product: IProduct) => {
    return ProductFactory.create(product);
  });
};

export const isRenderPremiumPrice = (
  premiumPrice: Number,
  bestPrice: Number,
  user: IUser | null
): boolean => {
  if (user && user.isDelfiClub) {
    return false;
  }

  return premiumPrice > 0 && premiumPrice < bestPrice;
};

export const createNavigateToUrlWithPriceDisplayType = (
  navigateTo: string,
  priceDisplayType?: PriceDisplayTypes
) => {
  if (priceDisplayType === PriceDisplayTypes.eBook) {
    return `${navigateTo}?priceDisplayType=${priceDisplayType}`;
  }

  return navigateTo;
};

import React, { CSSProperties, FC, ReactElement, ReactNode } from 'react';

interface IProps {
  label?: string;
  icon?: ReactElement | ReactNode;
  iconDirection?: 'left' | 'right';
  componentClasses?: string;
  iconClasses?: string;
  textClasses?: string;
  styleType?: CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  handleOnClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ButtonWitIcon: FC<IProps> = ({
  label,
  icon,
  iconDirection = 'left',
  type = 'button',
  componentClasses,
  iconClasses,
  textClasses,
  styleType,
  disabled,
  handleOnClick
}: IProps) => (
  <button
    type={type}
    className={`${componentClasses} btn shadow-none`}
    style={styleType}
    disabled={disabled}
    onClick={handleOnClick && handleOnClick}
  >
    {icon && iconDirection === 'left' ? (
      <span className={iconClasses}>{icon}</span>
    ) : null}
    <span className={textClasses}>{label}</span>
    {icon && iconDirection === 'right' ? (
      <span className={iconClasses}>{icon}</span>
    ) : null}
  </button>
);

export default ButtonWitIcon;

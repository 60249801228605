import {
  ITabsCarouselContent,
  ITabsCarouselContentTabs,
  PriceDisplayTypes
} from '../types';

export default class TabsCarouselContent implements ITabsCarouselContent {
  _id: string;

  title: string;

  priceDisplayType: PriceDisplayTypes;

  type: string;

  tabs: ITabsCarouselContentTabs[];

  startAt: string | null;

  endAt: string | null;

  isTopList?: boolean;

  viewAllUrl?: string;

  constructor(
    _id: string,
    title: string,
    priceDisplayType: PriceDisplayTypes,
    type: string,
    tabs: ITabsCarouselContentTabs[],
    startAt: string | null,
    endAt: string | null,
    isTopList?: boolean,
    viewAllUrl?: string
  ) {
    this._id = _id;
    this.title = title;
    this.priceDisplayType = priceDisplayType;
    this.type = type;
    this.tabs = tabs;
    this.startAt = startAt;
    this.endAt = endAt;
    this.isTopList = isTopList;
    this.viewAllUrl = viewAllUrl;
  }
}

import ActionTypes from '../../../constants/ActionTypes';
import {
  IBookMakerMagazineState,
  IFetchBookMakerMagazineDataAction,
  ISetBookMakerMagazineAction
} from './types';

export function fetchBookMakerMagazineDataAction(): IFetchBookMakerMagazineDataAction {
  return {
    type: ActionTypes.BOOKMAKER_MAGAZINE.FETCH_BOOKMAKER_MAGAZINE_PAGE_DATA
  };
}

export function setBookMakerMagazineDataAction(
  bookMakerMagazineData: IBookMakerMagazineState
): ISetBookMakerMagazineAction {
  return {
    type: ActionTypes.BOOKMAKER_MAGAZINE.SET_BOOKMAKER_MAGAZINE_PAGE_DATA,
    payload: bookMakerMagazineData
  };
}

export function validateBookMakerMagazineDataAction(
  bookMakerMagazineData: IBookMakerMagazineState
) {
  return {
    type: ActionTypes.BOOKMAKER_MAGAZINE.VALIDATE_BOOKMAKER_MAGAZINE_PAGE_DATA,
    payload: bookMakerMagazineData
  };
}

export function enrichBookMakerMagazineDataAction(
  bookMakerMagazineData: IBookMakerMagazineState
) {
  return {
    type: ActionTypes.BOOKMAKER_MAGAZINE.ENRICH_BOOKMAKER_MAGAZINE_PAGE_DATA,
    payload: bookMakerMagazineData
  };
}

export const headerSchema: any = {
  type: 'object',
  properties: {
    _id: { type: 'string' },
    header: {
      type: 'object',
      properties: {
        topMenuItems: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              label: { type: 'string' },
              link: { type: 'string' }
            },
            required: ['label', 'link']
          }
        }
      },
      required: ['topMenuItems']
    }
  },
  required: ['_id', 'header']
};

import { IFactory } from '../../types';
import { ITabsCarouselContentTabs } from '../../../entities/section/types';
import { IProduct } from '../../../entities/product/types';
import ProductFactory from '../../product';
import TabCarouselContentTabs from '../../../entities/section/tab-carousel/TabCarouselContentTabs';
import { getAvailableProducts } from '../../../utilites/product';

const TabsCarouselTabsFactory: IFactory = class TabsCarouselTabsFactory {
  static create(tabs: ITabsCarouselContentTabs[]) {
    return tabs.map((tab: ITabsCarouselContentTabs) => {
      return new TabCarouselContentTabs(
        tab.name,
        tab.viewAllUrl,
        getAvailableProducts(tab.products)
      );
    });
  }
};

export default TabsCarouselTabsFactory;

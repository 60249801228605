/* eslint-disable @typescript-eslint/brace-style */
import { IProduct } from '../../product/types';
import { ITabsCarouselContentTabs } from '../types';

export default class TabCarouselContentTabs
  implements ITabsCarouselContentTabs
{
  name: string;

  viewAllUrl: string;

  products: IProduct[];

  constructor(name: string, viewAllUrl: string, products: IProduct[]) {
    this.name = name;
    this.viewAllUrl = viewAllUrl;
    this.products = products;
  }
}

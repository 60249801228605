import ActionTypes from '../../../constants/ActionTypes';
import { ISendResetPasswordReqAction, ISetResetPasswordAction } from './types';

export function sendResetPasswordAction(sendResetPasswordReq: { token: string, date: Date | string, password: string }): ISendResetPasswordReqAction {
  return {
    type: ActionTypes.RESET_USER_PASSWORD.SEND_RESET_USER_PASSWORD_REQUEST,
    payload: {
      sendResetPasswordReq
    }
  };
}

export function setResetPasswordAction(status: boolean): ISetResetPasswordAction {
  return {
    type: ActionTypes.RESET_USER_PASSWORD.SET_RESET_USER_PASSWORD,
    payload: {
      status
    }
  };
}

import React, { ReactChildren, ReactNode, ReactNodeArray } from 'react';
import { ICssProps } from '../../../types';

interface IProps {
  children: ReactChildren | ReactNode | ReactNodeArray | JSX.Element | string;
  ariaLabelName?: string;
  classes?: string;
  styleType?: ICssProps;
  type?: 'button' | 'submit' | 'reset';
  onClick?: (event?: any) => void;
}

const Button: React.FC<IProps> = ({
  children,
  classes,
  styleType,
  ariaLabelName,
  onClick,
  type = 'button'
}: IProps) => (
  <button
    aria-label={ariaLabelName}
    className={classes}
    style={styleType}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;

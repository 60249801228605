import ActionTypes from '../../../constants/ActionTypes';
import { ISetTextSlidersAction, ITextSlidersState } from './types';

const initialState: ITextSlidersState = {
  items: []
};

const textSlidersReducer = (
  state = initialState,
  action: ISetTextSlidersAction,
): ITextSlidersState => {
  switch (action.type) {
    case ActionTypes.TEXT_SLIDERS.SET_TEXT_SLIDERS:
      return {
        ...state,
        items: action.payload.items
      };
  }

  return state;
};

export default textSlidersReducer;

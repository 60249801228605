import { ICareerPageDataState, ISetCareerPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: ICareerPageDataState = {
  careerPage: null,
  careerPositions: []
};
const careerPageDataReducer = (
  state = initialState,
  action: ISetCareerPageDataAction
): ICareerPageDataState => {
  switch (action.type) {
    case ActionTypes.CAREER.SET_CAREER_PAGE_DATA:
      return {
        ...state,
        careerPage: action.payload.careerPage,
        careerPositions: action.payload.careerPositions
      };
  }
  return state;
};

export default careerPageDataReducer;

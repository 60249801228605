import { IFactory } from '../types';
import { IProduct } from '../../entities/product/types';
import ProductFactory from '../product';
import VisaMassBenefit from '../../entities/benefits/VisaMassBenefit';
import YandexBenefit from '../../entities/benefits/YandexBenefit';
import {
  BenefitsReportEnum,
  BenefitStatesEnum,
  IBenefitsReport
} from '../../entities/cart/types';
import Benefits from '../../constants/Benefits';
import VisaPremiumBenefitFactory from './VisaPremiumBenefitFactory';

const BenefitReportFactory: IFactory = class BenefitReportFactory {
  private static checkIfBenefitExists(
    benefitReport: IBenefitsReport,
    benefitName: BenefitsReportEnum
  ) {
    return typeof benefitReport[benefitName] !== 'undefined';
  }

  private static getFreeProducts(products: IProduct[]) {
    return products.map((product: IProduct) => {
      return ProductFactory.create(product);
    });
  }

  static create(benefitReport: any) {
    if (
      this.checkIfBenefitExists(benefitReport, BenefitsReportEnum.VISA_PREMIUM)
    ) {
      const visaPremiumBenefit = benefitReport[Benefits.VISA_PREMIUM];

      if (
        visaPremiumBenefit &&
        visaPremiumBenefit.state &&
        visaPremiumBenefit.state !== BenefitStatesEnum.killed &&
        visaPremiumBenefit.payload &&
        Object.keys(visaPremiumBenefit.payload).length > 0
      ) {
        benefitReport[Benefits.VISA_PREMIUM] =
          VisaPremiumBenefitFactory.create(visaPremiumBenefit);
      }
    }

    if (
      this.checkIfBenefitExists(benefitReport, BenefitsReportEnum.VISA_MASS)
    ) {
      const visaMassBenefit = benefitReport[Benefits.VISA_MASS];
      let products: IProduct[] = [];

      if (
        visaMassBenefit.freeProducts &&
        visaMassBenefit.freeProducts.length > 0
      ) {
        products = this.getFreeProducts(visaMassBenefit.freeProducts);
      }

      benefitReport[Benefits.VISA_MASS] = new VisaMassBenefit(
        visaMassBenefit.state,
        products
      );
    }

    if (this.checkIfBenefitExists(benefitReport, BenefitsReportEnum.YANDEX)) {
      const yandexBenefit = benefitReport[Benefits.YANDEX];

      benefitReport[Benefits.YANDEX] = new YandexBenefit(
        yandexBenefit.state,
        yandexBenefit.forToday,
        yandexBenefit.forTomorrow,
        yandexBenefit.forMonday
      );
    }

    return benefitReport;
  }
};

export default BenefitReportFactory;

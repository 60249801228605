import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  setNewsLetterUnsubscribeConfirmationMessageAction,
  setNewsLetterUnsubscribeMessageAction
} from './actions';
import { GenericMessages } from '../../../constants/GenericMessages';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { isTokenExpired } from '../../../utilites/token';
import { errorAction } from '../error-handling/actions';
import NotificationTypes from '../../../constants/NotificationTypes';
import { ErrorMessages } from '../../../constants/ErrorMessages';

const newsLetterUnsubscribeMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWSLETTER.UNSUBSCRIBE_NEWSLETTER:
        next(
          apiRequest(
            null,
            'POST',
            `${ApiUrls.NEWSLETTER_UNSUBSCRIBE}`,
            null,
            {
              email: action.payload.newsLetterUnsubscribeRequest.email,
              recaptcha: action.payload.newsLetterUnsubscribeRequest.recaptcha
            },
            MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE));
        break;
      case `${MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE} ${ActionTypes.API_SUCCESS}`:
        next(
          setNewsLetterUnsubscribeMessageAction(
            'Uskoro će na vašu email adresu stići link za odjavu.',
            HttpStatusCodes.OK
          )
        );
        next(setLoader(false, MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE));
        break;
      case `${MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE));
        if (
          action.payload.data &&
          action.payload.data.status === HttpStatusCodes.HTTP_BAD_REQUEST
        ) {
          return next(
            setNewsLetterUnsubscribeMessageAction(
              'Već ste se odjavili',
              HttpStatusCodes.HTTP_BAD_REQUEST
            )
          );
        }
        next(
          setNewsLetterUnsubscribeMessageAction(
            ErrorMessages.DEFAULT,
            HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR
          )
        );
        break;
    }
  };

const confirmNewsLetterUnsubscribeMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWSLETTER.CONFIRM_NEWSLETTER_UNSUBSCRIBE:
        if (!isTokenExpired(action.payload.date)) {
          next(
            apiRequest(
              null,
              'GET',
              `${ApiUrls.NEWSLETTER_CONFIRM_UNSUBSCRIBE}/${action.payload.token}`,
              null,
              null,
              MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE,
              {}
            )
          );
          return next(
            setLoader(true, MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE)
          );
        }
        next(
          setNewsLetterUnsubscribeConfirmationMessageAction(
            GenericMessages.TOKEN_EXPIRED
          )
        );
        break;
      case `${MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE} ${ActionTypes.API_SUCCESS}`:
        next(
          setNewsLetterUnsubscribeConfirmationMessageAction(
            'Uspešno ste se odjavili sa newslettera'
          )
        );
        next(setLoader(false, MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE));
        break;
      case `${MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE));
        if (
          action.payload.data &&
          action.payload.data.status === HttpStatusCodes.HTTP_BAD_REQUEST
        ) {
          return next(
            setNewsLetterUnsubscribeConfirmationMessageAction(
              GenericMessages.TOKEN_EXPIRED
            )
          );
        }
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.NEWSLETTER_UNSUBSCRIBE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export default [
  newsLetterUnsubscribeMiddleware,
  confirmNewsLetterUnsubscribeMiddleware
];

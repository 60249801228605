export const getFullYear = (date: Date | string) => {
  const newDate = new Date(date);
  return newDate.getFullYear();
};

export const getMonthName = (date: Date | string, locale: string) => {
  const newDate = new Date(date);
  const months = new Intl.DateTimeFormat(locale, { month: 'long' }).format;
  return months(newDate);
};

import { BsBoxSeam } from 'react-icons/bs';
import React, { FC } from 'react';
import DropdownMenu from '../../dropdown';
import { GrServices } from 'react-icons/gr';
import { GiBookPile } from 'react-icons/gi';
import { MdOutlineSupportAgent, MdOutlineSwitchAccount } from 'react-icons/md';
import { keyId } from '../../hooks/types';
import { Link, useNavigate } from 'react-router-dom';

interface IProps {
  classes?: string;
  handleOnClick: (event: React.MouseEvent<HTMLDivElement>, key: keyId) => void;
  showDropdown: boolean;
}

const ServiceMenu: FC<IProps> = ({
  handleOnClick,
  showDropdown,
  classes
}: IProps) => {
  const navigate = useNavigate();
  return (
    <>
      <div className={`col-12 background-gray p-4 ${classes}`}>
        <DropdownMenu
          id="services"
          handleOnClick={handleOnClick}
          showDropdown={showDropdown}
          label="DELFI SERVIS"
          borderClasses="border-dotted"
          textHeadlineClasses="text-11 jost-extra-bold"
          textClassesChildren="text-11 jost-semi-bold"
          icon={<GrServices size="1.2em" className="mt-minus-10" />}
        >
          {/*<li className="mb-3">*/}
          {/*  <Link to="/saradnja" className="text-decoration-none">*/}
          {/*    <span>Saradnja</span>*/}
          {/*  </Link>*/}
          {/*</li>*/}
          {/*<li className="mb-3">*/}
          {/*  /!*TODO promeniti na delfi_magazin cim srede stranicu*!/*/}
          {/*  <Link to="/" className="text-decoration-none">*/}
          {/*    <span>Bukmarker</span>*/}
          {/*  </Link>*/}
          {/*</li>*/}
          <li className="mb-3">
            <Link to="/delfi-premium" className="text-decoration-none">
              <span>Članstvo u Delfima</span>
            </Link>
          </li>
        </DropdownMenu>
        <div className="row align-items-center">
          <div className="col-auto pe-0">
            <GiBookPile size="1.2em" className="mt-minus-3" />
          </div>
          <div className="col-auto text-11 jost-semi-bold">
            {/*<Link*/}
            {/*  to="/knjizare"*/}
            {/*  className="text-decoration-none font-color-default"*/}
            {/*>*/}
            {/*  <span> O KNJIŽARAMA DELFI</span>*/}
            {/*</Link>*/}
            <Link
              to="/o_nama"
              className="text-decoration-none font-color-default"
            >
              <span>O nama</span>
            </Link>
          </div>
          <div className="border-dotted"></div>
        </div>
        <div className="row mt-2">
          <div className="col-auto pe-0">
            <MdOutlineSupportAgent size="1.2em" className="mt-minus-10" />
          </div>
          <div className="col-auto text-11 jost-semi-bold">
            <Link
              to="/sta1_pomoc"
              className="text-decoration-none cursor-pointer font-color-default"
            >
              <span>Kontakt i pomoć</span>
            </Link>
          </div>
          {/*<div className="border-dotted"></div>*/}
        </div>
        {/*<div className="row mt-2">*/}
        {/*  <div className="col-auto pe-0">*/}
        {/*    <MdOutlineSwitchAccount size="1.2em" className="mt-minus-10" />*/}
        {/*  </div>*/}
        {/*  <div className="col-auto text-11 jost-semi-bold">MOJ NALOG</div>*/}
        {/*</div>*/}
      </div>
      {/*<div className="col-12 background-orange p-3 mt-minus-10">*/}
      {/*  <div className="row align-items-center">*/}
      {/*    <div className="col-auto pe-0 ms-2">*/}
      {/*      <BsBoxSeam size="1.2em" className="mt-minus-5" />*/}
      {/*    </div>*/}
      {/*    <div className="col-auto text-11 jost-semi-bold">*/}
      {/*      STATUS PORUDŽBINE*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default ServiceMenu;

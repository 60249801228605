import { FC, useEffect } from 'react';
import { parseJwt } from '../../../utilites/jwt-token';
import { AppState } from '../../../app/store/rootReducers';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IUser } from '../../../entities/user/types';
import { getCookie } from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';
import { removeUserCookieAction } from '../../../app/store/user/actions';

interface IProps {
  user: IUser | null;
  removeUserCookieAction: () => void;
  children: JSX.Element;
}

const AuthVerifyContainer: FC<IProps> = ({
  user,
  removeUserCookieAction,
  children
}: IProps) => {
  useEffect((): any => {
    if (user) {
      const jwtCookie = getCookie(CookieNames.MEMBER);

      if (jwtCookie) {
        const jwt = parseJwt(jwtCookie);

        const interval = setInterval(() => {
          if (jwt.expiresIn <= new Date().getTime()) {
            clearInterval(interval);
            removeUserCookieAction();
          }
        }, 10000);
      }
    }
  }, []);

  return children;
};

const mapStateToProps = (state: AppState) => ({
  user: state.user.member.user
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ removeUserCookieAction }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthVerifyContainer);

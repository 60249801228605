import Validator from '../Validator';
import Ajv from 'ajv';
import { bookstoreSchema } from './bookstoreSchema';
import { IBookstore } from '../../entities/bookstore/types';

export default class BookstoreValidator extends Validator {
  public static validate(bookstore: IBookstore) {
    const ajv = new Ajv();
    return ajv.validate(bookstoreSchema, bookstore);
  }
}

export const sanitizeSearchParam = (searchParam: string) => {
  const SEARCH_PARAMS_MAX_LENGTH = 28; //Elastic search max searchParam length
  /**
   * Elastic search rules
   */

  let localSearchParam = searchParam;

  if (searchParam.length > SEARCH_PARAMS_MAX_LENGTH) {
    localSearchParam = searchParam.substring(0, SEARCH_PARAMS_MAX_LENGTH);
  }

  localSearchParam = localSearchParam.replace(/\s-\s/g, ' ');
  localSearchParam = localSearchParam.replace(/\s–\s/g, ' ');
  localSearchParam = localSearchParam.replace(/,\s/g, ' ');
  localSearchParam = localSearchParam.replace(/\s\(/g, ' ');
  localSearchParam = localSearchParam.replace(/\)\s/g, ' ');
  localSearchParam = localSearchParam.replace(/:\s/g, ' ');
  localSearchParam = localSearchParam.replace(/„/g, '');
  localSearchParam = localSearchParam.replace(/”/g, '');

  return localSearchParam;

  /**
   * End of rules
   */
};

import { IInfoIconsItem } from '../types';

export default class InfoIconsItem implements IInfoIconsItem {
  boldText: string;

  icon: string;

  regularText: string;

  url?: string | null;

  constructor(
    boldText: string,
    icon: string,
    regularText: string,
    url?: string | null
  ) {
    this.boldText = boldText;
    this.icon = icon;
    this.regularText = regularText;
    this.url = url;
  }
}

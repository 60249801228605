import { IFactory } from '../../types';
import { ITabsCarouselContent } from '../../../entities/section/types';
import TabsCarouselContent from '../../../entities/section/tab-carousel/TabsCarouselContent';
import TabsCarouselTabsFactory from './TabsCarouselTabsFactory';

const TabsCarouselContentFactory: IFactory = class SimpleCarouselContentFactory {
  static create(content: ITabsCarouselContent) {
    return new TabsCarouselContent(
      content._id,
      content.title,
      content.priceDisplayType,
      content.type,
      TabsCarouselTabsFactory.create(content.tabs),
      content.startAt,
      content.endAt,
      content.isTopList,
      content.viewAllUrl
    );
  }
};

export default TabsCarouselContentFactory;

import { IProduct } from '../product/types';
import { IProminentProduct } from '../../app/store/menu-items/types';

export default class ProminentProduct implements IProminentProduct {
  _id: string;

  type: string;

  image: string;

  product: IProduct;

  constructor(id: string, type: string, image: string, product: IProduct) {
    this._id = id;
    this.type = type;
    this.image = image;
    this.product = product;
  }
}

import ActionTypes from '../../../constants/ActionTypes';
import { IMenuItemsState, ISetMenuItemsAction } from './types';

const initialState: IMenuItemsState = {
  menuItems: {
    header: {
      topMenuItems: []
    }
  },
  prominentProduct: null
};

const menuItemsReducer = (
  state = initialState,
  action: ISetMenuItemsAction
): IMenuItemsState => {
  switch (action.type) {
    case ActionTypes.MENU_ITEMS.SET_MENU_ITEMS:
      return {
        ...state,
        menuItems: action.payload.menuItems,
        prominentProduct: action.payload.prominentProduct
      };
  }

  return state;
};

export default menuItemsReducer;

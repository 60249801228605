import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { createNotification, setNotification } from '../notification/actions';
import NotificationFactory from '../../../factories/notification/NotificationFactory';
import { generateErrorMessage } from '../../../utilites/error';
import { setForgotUserPasswordAction } from './actions';
import NotificationTypes from '../../../constants/NotificationTypes';

export const sendForgotPasswordMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.FORGOT_USER_PASSWORD.SEND_FORGOT_USER_PASSWORD_REQUEST:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.SEND_FORGOT_PASSWORD_EMAIL,
            null,
            action.payload.sendForgotPasswordReq,
            MiddlewareEntities.FORGOT_USER_PASSWORD,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.FORGOT_USER_PASSWORD));
        break;
      case `${MiddlewareEntities.FORGOT_USER_PASSWORD} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.FORGOT_USER_PASSWORD));
        next(setForgotUserPasswordAction(true));
        next(
          createNotification(
            MiddlewareEntities.FORGOT_USER_PASSWORD,
            NotificationTypes.SUCCESS,
            'Uspešno ste poslali podatke. Uskoro će Vam stići email'
          )
        );
        break;
      case `${MiddlewareEntities.FORGOT_USER_PASSWORD} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.FORGOT_USER_PASSWORD));
        next(setForgotUserPasswordAction(false));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.FORGOT_USER_PASSWORD,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );

        break;
    }
  };

export default [sendForgotPasswordMiddleware];

import {
  IConfirmNewsletterUnsubscribeAction,
  INewsletterUnsubscribeAction,
  INewsLetterUnsubscribeRequest,
  ISetNewsLetterUnsubscribeConfirmationMessageAction,
  ISetNewsLetterUnsubscribeMessageAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const confirmNewsletterUnsubscribeAction = (
  date: Date,
  token: string
): IConfirmNewsletterUnsubscribeAction => ({
  type: ActionTypes.NEWSLETTER.CONFIRM_NEWSLETTER_UNSUBSCRIBE,
  payload: {
    date,
    token
  }
});

export const setNewsLetterUnsubscribeConfirmationMessageAction = (
  message: string
): ISetNewsLetterUnsubscribeConfirmationMessageAction => ({
  type: ActionTypes.NEWSLETTER.SET_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION_MESSAGE,
  payload: {
    message
  }
});

export const setNewsLetterUnsubscribeMessageAction = (
  message: string,
  status: number
): ISetNewsLetterUnsubscribeMessageAction => ({
  type: ActionTypes.NEWSLETTER.SET_UNSUBSCRIBE_NEWSLETTER_MESSAGE,
  payload: {
    message,
    status
  }
});

export const unsubscribeNewsletterAction = (
  newsLetterUnsubscribeRequest: INewsLetterUnsubscribeRequest
): INewsletterUnsubscribeAction => ({
  type: ActionTypes.NEWSLETTER.UNSUBSCRIBE_NEWSLETTER,
  payload: { newsLetterUnsubscribeRequest }
});

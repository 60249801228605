import {
  IAddToCartAction,
  ICartChangeQuantityAction,
  ICartState,
  IEnrichCartItemsAction,
  IFetchCartItemsFromApiAction,
  IRemoveFromCartAction,
  ISetProductToCartAction,
  IRemoveAllItemsFromCartAction,
  ICartChangeCountryAndZipCodeAction,
  IBenefitOptions,
  ISetCartForPaymentAction,
  IResetCartItemsAction,
  IGtmQuantityChangeData,
  IsLastGetCartErrorAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';
import benefits from '../../../constants/Benefits';

export const addToCartAction = (productId: string): IAddToCartAction => ({
  type: ActionTypes.CART.ADD_TO_CART,
  payload: {
    productId
  }
});

export const removeFromCartAction = (
  productId: string
): IRemoveFromCartAction => ({
  type: ActionTypes.CART.REMOVE_FROM_CART,
  payload: {
    productId
  }
});

export const removeAllItemsFromCartAction = (
  preventRedirect?: boolean
): IRemoveAllItemsFromCartAction => ({
  type: ActionTypes.CART.REMOVE_ALL_ITEMS_FROM_CART,
  payload: {
    preventRedirect
  }
});

export const setProductToCartAction = (
  order: ICartState | any
): ISetProductToCartAction => ({
  type: ActionTypes.CART.SET_PRODUCT_TO_CART,
  payload: {
    order
  }
});

export const fetchCartItemsFromApiAction = (
  options?: IBenefitOptions,
  paymentType?: string,
  cartId?: number,
  cartForPayment?: boolean,
  preventGTM?: boolean,
  gtmQuantityChangeData?: IGtmQuantityChangeData,
  homeEmail?: string
): IFetchCartItemsFromApiAction => ({
  type: ActionTypes.CART.FETCH_CART_ITEMS_FROM_API,
  payload: {
    options,
    paymentType,
    cartId,
    cartForPayment,
    preventGTM,
    gtmQuantityChangeData,
    homeEmail
  }
});

export const resetCartItemsAction = (
  cartForPayment?: boolean
): IResetCartItemsAction => ({
  type: ActionTypes.CART.RESET_CART,
  payload: {
    cartForPayment
  }
});

export const enrichCartItemsAction = (
  order: ICartState,
  cartForPayment?: boolean,
  preventGTM?: boolean,
  gtmQuantityChangeData?: IGtmQuantityChangeData
): IEnrichCartItemsAction => ({
  type: ActionTypes.CART.ENRICH_CART,
  payload: {
    order,
    cartForPayment,
    preventGTM,
    gtmQuantityChangeData
  }
});

export const changeCartQuantityAction = (
  productId: string,
  quantity: number,
  options?: IBenefitOptions
): ICartChangeQuantityAction => ({
  type: ActionTypes.CART.CART_CHANGE_QUANTITY,
  payload: {
    productId,
    quantity,
    options
  }
});

export const changeCartCountryAndZipCodeAction = (
  countryOldId: number,
  zipCode: string
): ICartChangeCountryAndZipCodeAction => ({
  type: ActionTypes.CART.CART_CHANGE_COUNTRY_AND_ZIP_CODE,
  payload: {
    countryOldId,
    zipCode
  }
});

export const setCartForPaymentAction = (
  order: ICartState | any
): ISetCartForPaymentAction => ({
  type: ActionTypes.CART.SET_CART_FOR_PAYMENT,
  payload: {
    order
  }
});

export const setIsLastGetCartErrorAction = (
  isLastGetCartError: boolean
): IsLastGetCartErrorAction => ({
  type: ActionTypes.CART.SET_IS_LAST_GET_CART_ERROR,
  payload: isLastGetCartError
});

import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichStarWayDataAction,
  IFetchStarWayDataAction,
  ISetStarWayDataAction,
  IStarWayState,
  IValidateStarWayDataAction
} from './types';

export function fetchStarWayDataAction(): IFetchStarWayDataAction {
  return {
    type: ActionTypes.STARWAY_PAGE.FETCH_STARWAY_DATA
  };
}

export function setStarWayDataAction(
  starWayDataData: IStarWayState
): ISetStarWayDataAction {
  return {
    type: ActionTypes.STARWAY_PAGE.SET_STARWAY_DATA,
    payload: starWayDataData
  };
}

export function validateStarWayDataAction(
  starWayDataData: any
): IValidateStarWayDataAction {
  return {
    type: ActionTypes.STARWAY_PAGE.VALIDATE_STARWAY_DATA,
    payload: starWayDataData
  };
}

export function enrichStarWayDataAction(
  starWayDataData: any
): IEnrichStarWayDataAction {
  return {
    type: ActionTypes.STARWAY_PAGE.ENRICH_STARWAY_DATA,
    payload: starWayDataData
  };
}

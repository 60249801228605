import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import redirectMapCategory from '../../../utilites/redirectMapCategory';
import { has } from 'lodash';

export const fetchAuthorByOldIdMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.REDIRECT_PAGE.FETCH_AUTHOR_ID_BY_OLD_ID:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.FETCH_AUTHOR_ID_BY_OLD_ID}/${action.payload.oldId}`,
            null,
            null,
            MiddlewareEntities.REDIRECT_AUTHOR_BY_OLD_ID,
            {}
          )
        );
        break;
      case `${MiddlewareEntities.REDIRECT_AUTHOR_BY_OLD_ID} ${ActionTypes.API_SUCCESS}`:
        if (has(action, 'payload.data._id')) {
          return (window.location.href = `/lista-proizvoda?author=${action.payload.data._id}&inPage=lista-autora`);
        }
        window.location.href = '/404';
        break;
      case `${MiddlewareEntities.REDIRECT_AUTHOR_BY_OLD_ID} ${ActionTypes.API_ERROR}`:
        window.location.href = '/404';
        break;
    }
  };

export const fetchGenreByOldIdMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.REDIRECT_PAGE.FETCH_GENRE_NAME_BY_OLD_ID:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.FETCH_GENRE_NAME_BY_OLD_ID}/${action.payload.oldId}`,
            null,
            { category: action.payload.category },
            MiddlewareEntities.REDIRECT_GENRE_BY_OLD_ID,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.REDIRECT_GENRE_BY_OLD_ID));
        break;
      case `${MiddlewareEntities.REDIRECT_GENRE_BY_OLD_ID} ${ActionTypes.API_SUCCESS}`:
        let category = redirectMapCategory.get(
          action.payload.meta.data.category
        );

        if (!category) {
          category = '';
        }

        if (has(action, 'payload.data.genreName')) {
          return (window.location.href = `/lista-proizvoda?${
            category ? `category=${category}&` : ''
          }genre=${action.payload.data.genreName}`);
        }
        window.location.href = '/404';
        break;
      case `${MiddlewareEntities.REDIRECT_GENRE_BY_OLD_ID} ${ActionTypes.API_ERROR}`:
        window.location.href = '/404';
        break;
    }
  };

export default [fetchAuthorByOldIdMiddleware, fetchGenreByOldIdMiddleware];

import { IAdministrativeBanReport, ICart } from './types';
import { ICartOrderItem } from '../../app/store/cart/types';

export default class Cart implements ICart {
  orderItemsPriceReport: ICartOrderItem[];

  benefitsReport: any;

  availablePaymentTypes: string[];

  administrativeBanReport: IAdministrativeBanReport | null;

  deliveryService: string;

  allOrderItemsAmount: Number | string;

  pttPrice: number;

  totalAmount: number;

  amountUpToFreeShipping: Number | string;

  totalItemQuantity: number;

  discountPrice: Number | string;

  calculatedPremiumPrice: Number | string;

  fullItemsPrice: number;

  constructor(
    orderItemsPriceReport: ICartOrderItem[],
    benefitsReport: any,
    availablePaymentTypes: string[],
    administrativeBanReport: IAdministrativeBanReport | null,
    deliveryService: string,
    allOrderItemsAmount: Number | string,
    pttPrice: number,
    totalAmount: any,
    amountUpToFreeShipping: Number | string,
    totalItemQuantity: number,
    discountPrice: Number | string,
    calculatedPremiumPrice: Number | string,
    fullItemsPrice: number
  ) {
    this.orderItemsPriceReport = orderItemsPriceReport;
    this.benefitsReport = benefitsReport;
    this.availablePaymentTypes = availablePaymentTypes;
    this.administrativeBanReport = administrativeBanReport;
    this.deliveryService = deliveryService;
    this.allOrderItemsAmount = allOrderItemsAmount;
    this.pttPrice = pttPrice;
    this.totalAmount = totalAmount;
    this.amountUpToFreeShipping = amountUpToFreeShipping;
    this.totalItemQuantity = totalItemQuantity;
    this.discountPrice = discountPrice;
    this.calculatedPremiumPrice = calculatedPremiumPrice;
    this.fullItemsPrice = fullItemsPrice;
  }

  public validatePrice() {
    let blockFlag = false;
    let orderValidationUserMessage =
      'Došlo je do greške prilikom kreiranja ordera.';

    if (
      this.totalAmount &&
      !isNaN(this.totalAmount) &&
      Number(this.totalAmount) > 0
    ) {
      return {
        blockFlag,
        orderValidationUserMessage: 'success'
      };
    }

    this.orderItemsPriceReport.map((orderItemPriceReport: ICartOrderItem) => {
      if (
        orderItemPriceReport.actionPriceReport &&
        orderItemPriceReport.actionPriceReport.blockFlag &&
        orderItemPriceReport.actionPriceReport.blockMessage
      ) {
        blockFlag = true;
        orderValidationUserMessage =
          orderItemPriceReport.actionPriceReport.blockMessage;
      }
    });

    return {
      blockFlag,
      orderValidationUserMessage
    };
  }
}

export default new Map([
  ['knjige', 'Knjiga'],
  ['knjiga', 'Knjiga'],
  ['Knjige', 'Knjiga'],
  ['Knjiga', 'Knjiga'],
  ['strane_knjige', 'Strana knjiga'],
  ['strane knjige', 'Strana knjiga'],
  ['Strana knjiga', 'Strana knjiga'],
  ['gift', 'Gift'],
  ['film', 'Film'],
  ['filmovi', 'Film'],
  ['muzika', 'Muzika']
]);

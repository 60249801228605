import { IFetchWsPayRedirectParamsAction, ISetWsPayRedirectParamsAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const fetchWsPayRedirectParamsAction = (secret: any): IFetchWsPayRedirectParamsAction => ({
  type: ActionTypes.WSPAY.FETCH_WSPAY_REDIRECT_PARAMS_FROM_API,
  payload: secret
});

export const setWsPayRedirectParamsAction = (redirectParams: any[]): ISetWsPayRedirectParamsAction => ({
  type: ActionTypes.WSPAY.SET_WSPAY_REDIRECT_PARAMS,
  payload: {
    redirectParams
  }
});

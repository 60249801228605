import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichSectionDataAction,
  IFetchSectionPageDataAction,
  IFetchSectionPageGenresAction,
  ISetSectionDataAction,
  ISetSectionPageGenresAction,
  IValidateSectionDataAction
} from './types';

export function fetchSectionDataAction(
  id: string,
  type: string
): IFetchSectionPageDataAction {
  return {
    type: ActionTypes.SECTION_PAGE.FETCH_SECTION_PAGE_DATA,
    payload: {
      id,
      type
    }
  };
}

export function fetchSectionGenresAction(): IFetchSectionPageGenresAction {
  return {
    type: ActionTypes.SECTION_PAGE.FETCH_SECTION_PAGE_GENRES
  };
}

export function setSectionPageDataAction(content?: any): ISetSectionDataAction {
  return {
    type: ActionTypes.SECTION_PAGE.SET_SECTION_PAGE_DATA,
    payload: {
      content
    }
  };
}

export function setSectionPageGenresAction(
  genres: any[]
): ISetSectionPageGenresAction {
  return {
    type: ActionTypes.SECTION_PAGE.SET_SECTION_PAGE_GENRES,
    payload: {
      genres
    }
  };
}

export function validateSectionPageDataAction(
  type: string,
  content: any
): IValidateSectionDataAction {
  return {
    type: ActionTypes.SECTION_PAGE.VALIDATE_SECTION_PAGE_DATA,
    payload: {
      type,
      content
    }
  };
}

export function enrichSectionPageDataAction(
  content?: any
): IEnrichSectionDataAction {
  return {
    type: ActionTypes.SECTION_PAGE.ENRICH_SECTION_PAGE_DATA,
    payload: {
      content
    }
  };
}

import { IProduct } from '../../../../../entities/product/types';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import Authors from '../../../../components/authors';

interface IProps {
  product: IProduct;
  setIsShowSearchItems: (isShowSearchItems: boolean) => void;
}

const ProductByNavId: FC<IProps> = ({
  product,
  setIsShowSearchItems
}: IProps) => {
  return (
    <div className="bg-white container box-shadow-container">
      <div className="row justify-content-center p-3">
        <div className="col-12 text-start">
          <Link
            to={product.navigateTo}
            onClick={() => setIsShowSearchItems(false)}
            className="text-decoration-none font-color-default"
          >
            <div className="row">
              <div className="d-flex flex-column h-100">
                <div key={product.id} className="row align-items-center pt-2">
                  <div className="col-auto">
                    <img
                      src={product.images.m}
                      alt={product.title + '_img'}
                      className="float-left"
                      style={{
                        height: '95px',
                        width: '70px'
                      }}
                    />
                  </div>
                  <div className="col text-start overflow-hidden item-text-title">
                    <div className="jost-semi-bold text-16 line-height-20">
                      <p className="mb-0">{product.title}</p>
                      <div className="max-lines max-lines-2 max-height-40">
                        <Authors authors={product.authors} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-2 border-bottom mt-2 mb-2" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ProductByNavId;

import React, {
  forwardRef,
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState
} from 'react';
import { VscChromeClose } from 'react-icons/vsc';
import delfiLogoImage from '../../../assets/images/delfi-logo-mobile-15.svg';
import callServiceIcon from '../../../assets/images/call-service-icon.svg';
import hamburgerMenu from '../../../assets/images/hamburger-manu.svg';
import './style.scss';
import useNavItemsOpen from '../hooks/useNavItemsOpen';
import SearchBarContainer from '../search-bar';
import DropdownMenu from '../dropdown';
import ServiceMenu from './service-menu';
import BottomBar from './bottom-bar';
import favoriteImageIcon from '../../../assets/images/header/favorite.svg';
import userImageIcon from '../../../assets/images/header/profile.svg';
import userBlueLoginIcon from '../../../assets/images/header/user-login-blue.svg';
import cartImageWhiteIcon from '../../../assets/images/header/cart-white.svg';
import cartImageBlueIcon from '../../../assets/images/header/cart-blue.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import RoundedCircleNumber from '../../../components/rounded-circle-number';
import { ICartState } from '../../../../app/store/cart/types';
import CartPopup from '../../../components/cart-popup';
import { IUser } from '../../../../entities/user/types';
import { textSliderMobileSettings } from '../../../components/sliders/carousel/settings';
import UserPopup from '../../../components/user-popup';
import { keyId } from '../hooks/types';
import TextSlider from '../../../components/sliders/text-slider';
import { ITextSlider } from '../../../components/sliders/text-slider/types';
import { ILoginRequest } from '../../../components/login-form/types';
import Genres from '../../../components/genres';
import { useDispatch, useStore } from 'react-redux';
import { fetchFiltersDataAction } from '../../../../app/store/filters-data/actions';
import { AiOutlineHome } from 'react-icons/ai';

interface IProps {
  textSliders: ITextSlider[];
  numberOfWishProductItems: number;
  cart: ICartState;
  isChanged: boolean;
  user: IUser | null;
  handleOnChangeQuantity: (productId: string, quantity: number) => void;
  handleOnRemoveFromItem: (productId: string) => void;
  handleOnClickShowCartPopup: (event: React.MouseEvent<HTMLElement>) => void;
  handleOnClickShowUserPopup: (event: React.MouseEvent<HTMLElement>) => void;
  handleLogin: (values: ILoginRequest) => void;
  handleLogout: () => void;
  showCartPopup: boolean;
  showUserPopup: boolean;
  showCartAnimation: boolean;
  isShowSearchItems: boolean;
  setIsShowSearchItems: (isShowSearchItems: boolean) => void;
  ui: any;
}

const MobileHeader = forwardRef<any, IProps>(
  (
    {
      textSliders,
      numberOfWishProductItems,
      cart,
      user,
      isChanged,
      handleOnChangeQuantity,
      handleOnRemoveFromItem,
      handleOnClickShowCartPopup,
      handleOnClickShowUserPopup,
      handleLogin,
      handleLogout,
      showCartPopup,
      showUserPopup,
      showCartAnimation,
      isShowSearchItems,
      setIsShowSearchItems,
      ui
    }: IProps,
    ref
  ): ReactElement => {
    const [menuOpen, setMenuOpen] = useState(false);

    const { navItemsOpen, handleOnToggleNavDropdown } = useNavItemsOpen();

    const [menuActiveTab, setMenuActiveTab] = useState('kategorije');
    const menuRef: MutableRefObject<any> = useRef(null);

    const store = useStore();
    const state: any = store.getState();
    const dispatch = useDispatch();

    // @ts-ignore
    const { cartPopupRef, userPopupRef, searchWrapperRef } = ref.current;

    const location = useLocation();

    const navigate = useNavigate();

    const mapPages = {
      BASKET: 'basket',
      PROFILE: 'profile',
      FAVORITES: 'favorites',
      HOMEPAGE: 'homepage'
    };

    const searchBarRefs = useRef({
      searchWrapperRef
    });

    const getPathname = () => {
      const pathName = location.pathname;

      if (pathName.includes('kupovina')) {
        return mapPages.BASKET;
      }

      if (pathName.includes('profile')) {
        return mapPages.PROFILE;
      }

      if (pathName.includes('lista-zelja')) {
        return mapPages.FAVORITES;
      }

      if (pathName === '/') {
        return mapPages.HOMEPAGE;
      }
      return '';
    };

    const [activePage, setActivePage] = useState(getPathname());

    useEffect(() => {
      setActivePage(getPathname());
      return setMenuOpen(false);
    }, [location]);

    useEffect(() => {
      if (!location.pathname.includes('lista-proizvoda')) {
        dispatch(fetchFiltersDataAction());
      }
    }, []);

    const handleOnToggleMenu = (event: any) => {
      event.stopPropagation();
      setMenuOpen(!menuOpen);
      setIsShowSearchItems(false);
    };

    const handleOnClickToggleNavDropdown = (
      event: React.MouseEvent<HTMLDivElement>,
      id: keyId
    ) => {
      event.stopPropagation();
      handleOnToggleNavDropdown(id);
    };

    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
    };

    useEffect(() => {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    });

    const isActivePopup = () => {
      return showCartPopup || showUserPopup;
    };

    const isActiveIcon = (pageName: string) => {
      return activePage === pageName && !isActivePopup();
    };

    const removeScrollWhilePopupOpen = () => {
      if (isActivePopup()) {
        return document.body.classList.add('popup-open');
      }
      document.body.classList.remove('popup-open');
    };

    removeScrollWhilePopupOpen();

    return (
      <header className="text-center">
        {Array.isArray(textSliders) && textSliders.length > 0 ? (
          <div className="row justify-content-center text-center ms-0 me-0 gx-0 p-2 bg-header-slider">
            <div className="col-sm-auto col-md-12 text-11 carousel-slider-mobile jost-semi-bold">
              <TextSlider
                textSliders={textSliders}
                settings={textSliderMobileSettings}
              />
            </div>
          </div>
        ) : null}
        <div ref={menuRef}>
          <div className="p-3 background-delfi justify-content-center">
            <div className="row  ms-0 me-0 gx-0 align-items-center bg-header-menu">
              <div className="col text-start">
                <span
                  className="cursor-pointer"
                  onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                    handleOnToggleMenu(event)
                  }
                >
                  {menuOpen ? (
                    <VscChromeClose size="3em" color="white" id="123" />
                  ) : (
                    <img height="33px" alt="menu" src={hamburgerMenu} />
                  )}
                </span>
              </div>
              <div className="col-4">
                <div className="card border-0 bg-transparent">
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      navigate('/');
                      setMenuOpen(false);
                    }}
                  >
                    <img height="60px" alt="delfi-logo" src={delfiLogoImage} />
                  </div>
                </div>
              </div>
              <div className="col-4 text-end">
                <a
                  className="text-decoration-none font-color-default jost-extra-bold text-14"
                  href="tel:0117155042"
                >
                  <img
                    height="55px"
                    alt="call-service-icon"
                    src={callServiceIcon}
                  />
                </a>
              </div>
            </div>
            <div className="row ms-0 me-0 gx-0 text-center mt-3 mb-2">
              <SearchBarContainer
                ref={searchBarRefs}
                isShowSearchItems={isShowSearchItems}
                setIsShowSearchItems={setIsShowSearchItems}
              />
            </div>
          </div>
          {menuOpen ? (
            <>
              <div className="row me-0 ms-0 justify-content-between text-start text-14 mb-4">
                <div className="col-12 background-zircon p-4">
                  {menuActiveTab === 'kategorije' ? (
                    <Genres genres={state.filtersData} isRenderForMobileMenu />
                  ) : null}
                  {menuActiveTab === 'kolekcije' ? (
                    <>
                      <DropdownMenu
                        id="genres"
                        handleOnClick={handleOnClickToggleNavDropdown}
                        showDropdown={navItemsOpen.genres}
                        label="ŽANROVI"
                        borderClasses="border-bottom border-2"
                        textHeadlineClasses="text-14 jost-extra-bold"
                        textClassesChildren="text-14 jost-semi-bold"
                      >
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Akcioni"
                            className="text-decoration-none"
                          >
                            <span>Akcioni</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Autobiografije"
                            className="text-decoration-none"
                          >
                            <span>Autobiografije</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Avanturistički"
                            className="text-decoration-none"
                          >
                            <span>Avanturistički</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Bojanke"
                            className="text-decoration-none"
                          >
                            <span>Bojanke</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Bojanke za odrasle"
                            className="text-decoration-none"
                          >
                            <span>Bojanke za odrasle</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Čiklit"
                            className="text-decoration-none"
                          >
                            <span>Čiklit</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Domaći autori"
                            className="text-decoration-none"
                          >
                            <span>Domaći autori</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Drama"
                            className="text-decoration-none"
                          >
                            <span>Drama</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Duh i telo"
                            className="text-decoration-none"
                          >
                            <span>Duh i telo</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=E-knjige"
                            className="text-decoration-none"
                          >
                            <span>E-knjige</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Edukativni"
                            className="text-decoration-none"
                          >
                            <span>Edukativni</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Domaći autori"
                            className="text-decoration-none"
                          >
                            <span>Domaći autori</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Enciklopedija"
                            className="text-decoration-none"
                          >
                            <span>Enciklopedija</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Epska fantastika"
                            className="text-decoration-none"
                          >
                            <span>Epska fantastika</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Erotski"
                            className="text-decoration-none"
                          >
                            <span>Erotski</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Fantastika"
                            className="text-decoration-none"
                          >
                            <span>Fantastika</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Filmovane knjige"
                            className="text-decoration-none"
                          >
                            <span>Filmovane knjige</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Filozofija"
                            className="text-decoration-none"
                          >
                            <span>Filozofija</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Horor"
                            className="text-decoration-none"
                          >
                            <span>Horor</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/lista-proizvoda?genre=Interaktivna knjiga"
                            className="text-decoration-none"
                          >
                            <span>Interaktivna knjiga</span>
                          </Link>
                        </li>
                      </DropdownMenu>
                      <DropdownMenu
                        id="recommend"
                        handleOnClick={handleOnClickToggleNavDropdown}
                        showDropdown={navItemsOpen.recommend}
                        label="PREPORUČUJEMO"
                        borderClasses="border-bottom border-2"
                        textHeadlineClasses="text-14 jost-extra-bold"
                        textClassesChildren="text-14 jost-semi-bold"
                      >
                        <li className="mb-3">
                          <span>Preporučujemo</span>
                        </li>
                      </DropdownMenu>
                      <DropdownMenu
                        id="popularGenres"
                        handleOnClick={handleOnClickToggleNavDropdown}
                        showDropdown={navItemsOpen.popularGenres}
                        label="POPULARNI ŽANROVI"
                        borderClasses="border-bottom border-2"
                        textHeadlineClasses="text-14 jost-extra-bold"
                        textClassesChildren="text-14 jost-semi-bold"
                      >
                        <li className="mb-3">
                          <span>Popularni žanrovi</span>
                        </li>
                      </DropdownMenu>
                      <DropdownMenu
                        id="customerFavorites"
                        handleOnClick={handleOnClickToggleNavDropdown}
                        showDropdown={navItemsOpen.customerFavorites}
                        label="FAVORITI KUPACA"
                        borderClasses="border-bottom border-2"
                        textHeadlineClasses="text-14 jost-extra-bold"
                        textClassesChildren="text-14 jost-semi-bold"
                      >
                        <li className="mb-3">
                          <span>Favoriti kupaca</span>
                        </li>
                      </DropdownMenu>
                      <DropdownMenu
                        id="musics"
                        handleOnClick={handleOnClickToggleNavDropdown}
                        showDropdown={navItemsOpen.musics}
                        label="MUZIKA"
                        borderClasses="border-bottom border-2"
                        textHeadlineClasses="text-14 jost-extra-bold"
                        textClassesChildren="text-14 jost-semi-bold"
                      >
                        <li className="mb-3">
                          <Link to="/muzika" className="text-decoration-none">
                            <span>Muzika</span>
                          </Link>
                        </li>
                      </DropdownMenu>
                      {/*<DropdownMenu*/}
                      {/*  id="videoGames"*/}
                      {/*  handleOnClick={handleOnClickToggleNavDropdown}*/}
                      {/*  showDropdown={navItemsOpen.videoGames}*/}
                      {/*  label="VIDEO IGRE"*/}
                      {/*  borderClasses="border-bottom border-2"*/}
                      {/*  textHeadlineClasses="text-14 jost-extra-bold"*/}
                      {/*  textClassesChildren="text-14 jost-semi-bold"*/}
                      {/*>*/}
                      {/*  <li className="mb-3">*/}
                      {/*    <a*/}
                      {/*      href="/lista-proizvoda?category=Video Igre"*/}
                      {/*      className="text-decoration-none"*/}
                      {/*    >*/}
                      {/*      <span>Video Igre</span>*/}
                      {/*    </a>*/}
                      {/*  </li>*/}
                      {/*</DropdownMenu>*/}
                      {/*<DropdownMenu*/}
                      {/*  id="classBook"*/}
                      {/*  handleOnClick={handleOnClickToggleNavDropdown}*/}
                      {/*  showDropdown={navItemsOpen.classBook}*/}
                      {/*  label="UDŽBENICI"*/}
                      {/*  borderClasses="border-bottom border-2"*/}
                      {/*  textHeadlineClasses="text-14 jost-extra-bold"*/}
                      {/*  textClassesChildren="text-14 jost-semi-bold"*/}
                      {/*>*/}
                      {/*  <li className="mb-3">*/}
                      {/*    <a*/}
                      {/*      href="/lista-proizvoda?genre=Udžbenici"*/}
                      {/*      className="text-decoration-none"*/}
                      {/*    >*/}
                      {/*      <span>Udžbenici</span>*/}
                      {/*    </a>*/}
                      {/*  </li>*/}
                      {/*</DropdownMenu>*/}
                      <DropdownMenu
                        id="eBooks"
                        handleOnClick={handleOnClickToggleNavDropdown}
                        showDropdown={navItemsOpen.eBooks}
                        label="E KNJIGE"
                        textHeadlineClasses="text-14 jost-extra-bold"
                        textClassesChildren="text-14 jost-semi-bold"
                      >
                        <li className="mb-3">
                          <span>E Knjige</span>
                        </li>
                      </DropdownMenu>
                    </>
                  ) : null}
                </div>
                <ServiceMenu
                  handleOnClick={handleOnClickToggleNavDropdown}
                  showDropdown={navItemsOpen.services}
                  classes="mt-minus-10"
                />
                <BottomBar />
              </div>
            </>
          ) : null}
          <div className="row justify-content-center text-center">
            <div className="sticky-bottom bg-white">
              <div className="navigation d-flex position-relative justify-content-center align-items-center">
                <ul className="d-flex p-0">
                  <li
                    className={isActiveIcon(mapPages.HOMEPAGE) ? 'active' : ''}
                  >
                    <a
                      className="cursor-pointer"
                      onClick={() =>
                        activePage !== mapPages.HOMEPAGE ? navigate('/') : false
                      }
                    >
                      <div
                        className={`icon ${
                          isActiveIcon(mapPages.HOMEPAGE)
                            ? 'rounded-circle max-height-50 max-width-50 d-flex flex-column justify-content-center'
                            : ''
                        }`}
                      >
                        <AiOutlineHome
                          size="25"
                          className="mx-auto"
                          fill={
                            isActiveIcon(mapPages.HOMEPAGE) ? '#fff' : '#000'
                          }
                        />
                      </div>
                      <span className="iconText text-11 jost-semi-bold">
                        Početna
                      </span>
                    </a>
                  </li>
                  <li
                    className={isActiveIcon(mapPages.FAVORITES) ? 'active' : ''}
                  >
                    <a
                      className="cursor-pointer"
                      onClick={() =>
                        activePage !== mapPages.FAVORITES
                          ? navigate('/lista-zelja')
                          : false
                      }
                    >
                      <div
                        className={`icon ${
                          isActiveIcon(mapPages.FAVORITES)
                            ? 'rounded-circle max-height-50 max-width-50 d-flex flex-column justify-content-center'
                            : ''
                        }`}
                      >
                        <img
                          src={favoriteImageIcon}
                          alt="favorite"
                          className={`mx-auto ${
                            isActiveIcon(mapPages.FAVORITES)
                              ? 'icon-contrast-filter'
                              : ''
                          }`}
                        />
                        {numberOfWishProductItems ? (
                          <RoundedCircleNumber
                            numberOfItems={numberOfWishProductItems}
                            classes={`line-height-16  background-orange font-color-default ${
                              activePage === 'favorites' &&
                              !showUserPopup &&
                              !showCartPopup
                                ? 'rounded-circle-number-wrapper-mobile-active '
                                : 'rounded-circle-number-wrapper-mobile'
                            }`}
                          />
                        ) : null}
                      </div>
                      <span className="iconText text-11 jost-semi-bold">
                        Lista želja
                      </span>
                    </a>
                  </li>
                  <li
                    className={
                      activePage === mapPages.PROFILE
                        ? 'active'
                        : showUserPopup
                        ? 'active'
                        : ''
                    }
                  >
                    <a
                      className="cursor-pointer"
                      onClick={handleOnClickShowUserPopup}
                    >
                      <div
                        className={`icon ${
                          isActiveIcon(mapPages.PROFILE)
                            ? 'rounded-circle max-height-50 max-width-50 d-flex flex-column justify-content-center'
                            : showUserPopup
                            ? 'rounded-circle max-height-50 max-width-50 d-flex flex-column justify-content-center'
                            : ''
                        }`}
                      >
                        {user && user.username ? (
                          <img
                            src={userBlueLoginIcon}
                            alt="korisnik"
                            className={`mx-auto ${
                              isActiveIcon(mapPages.PROFILE)
                                ? 'icon-contrast-filter'
                                : showUserPopup
                                ? 'icon-contrast-filter'
                                : ''
                            }`}
                          />
                        ) : (
                          <img
                            src={userImageIcon}
                            alt="korisnik"
                            className={`mx-auto ${
                              isActiveIcon(mapPages.PROFILE)
                                ? 'icon-contrast-filter'
                                : showUserPopup
                                ? 'icon-contrast-filter'
                                : ''
                            }`}
                          />
                        )}
                        {user &&
                        user.username !== '' &&
                        typeof user.isDelfiClub !== 'undefined' &&
                        !user.isDelfiClub ? (
                          <RoundedCircleNumber
                            numberOfItems={1}
                            classes={`line-height-16 background-torch-red color-white ${
                              activePage === 'profile'
                                ? 'rounded-circle-number-profile-mobile-active'
                                : 'rounded-circle-number-wrapper-mobile'
                            }`}
                          />
                        ) : null}
                      </div>
                      <span className="iconText text-11 jost-semi-bold">
                        Moj profil
                      </span>
                    </a>
                  </li>
                  <li
                    className={
                      isActiveIcon(mapPages.BASKET)
                        ? 'active'
                        : showCartPopup
                        ? 'active'
                        : ''
                    }
                  >
                    <a
                      className="cursor-pointer position-relative"
                      onClick={handleOnClickShowCartPopup}
                    >
                      <div
                        className={`icon ${
                          isActiveIcon(mapPages.BASKET)
                            ? 'rounded-circle max-height-50 max-width-50 d-flex flex-column justify-content-center'
                            : showCartPopup
                            ? 'rounded-circle max-height-50 max-width-50 d-flex flex-column justify-content-center'
                            : ''
                        }`}
                      >
                        <img
                          src={
                            isActiveIcon(mapPages.BASKET)
                              ? cartImageWhiteIcon
                              : showCartPopup
                              ? cartImageWhiteIcon
                              : cartImageBlueIcon
                          }
                          alt="korpa"
                          className={`mx-auto ${
                            showCartAnimation ? 'add-to-cart-animation' : ''
                          }`}
                        />
                        {cart.order.totalItemQuantity > 0 ? (
                          <RoundedCircleNumber
                            numberOfItems={cart.order.totalItemQuantity}
                            classes={`line-height-16  background-orange font-color-default ${
                              showCartAnimation ? 'add-to-cart-animation' : ''
                            } ${
                              showCartPopup ||
                              (activePage === mapPages.BASKET && !showUserPopup)
                                ? 'rounded-circle-number-wrapper-mobile-active'
                                : 'rounded-circle-number-wrapper-mobile'
                            }`}
                          />
                        ) : null}
                      </div>
                      <span className="iconText text-11 jost-semi-bold">
                        Korpa
                      </span>
                    </a>
                  </li>
                  {getPathname() === '' &&
                  !showCartPopup &&
                  !showUserPopup ? null : (
                    <div className="indicator" />
                  )}
                </ul>
                {showCartPopup ? (
                  <CartPopup
                    cart={cart}
                    handleOnClickShowCartPopup={handleOnClickShowCartPopup}
                    handleOnRemoveFromItem={handleOnRemoveFromItem}
                    handleOnChangeQuantity={handleOnChangeQuantity}
                    ref={cartPopupRef}
                    isChanged={isChanged}
                    isMobile
                  />
                ) : null}
                {showUserPopup ? (
                  <UserPopup
                    user={user}
                    handleLogin={handleLogin}
                    handleLogout={handleLogout}
                    handleOnClickShowUserPopup={handleOnClickShowUserPopup}
                    ref={userPopupRef}
                    ui={ui}
                    isMobile
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
);

export default MobileHeader;

import { IAdministrativeBanState, ISetAdministrativeDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IAdministrativeBanState = {
  data: []
};
const administrativeBanDataReducer = (
  state = initialState,
  action: ISetAdministrativeDataAction
): IAdministrativeBanState => {
  switch (action.type) {
    case ActionTypes.ADMINISTRATIVE_BAN.SET_ADMINISTRATIVE_BAN_DATA:
      return {
        ...state,
        data: action.payload.data
      };
  }
  return state;
};

export default administrativeBanDataReducer;

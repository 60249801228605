import React, { FC, ReactElement, ReactNode } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';

interface IProps {
  title: string;
  url: string;
  icon?: ReactElement | ReactNode | null;
  iconDirection?: string;
}

const SingleDropdownItem: FC<IProps> = ({
  title,
  url,
  icon,
  iconDirection = 'left'
}: IProps): JSX.Element => {
  return (
    <div className="row mt-4 cursor-pointer align-items-center">
      <div className="col-10">
        <div className="row align-items-center">
          <div className="col">
            <h4 className="text-14">
              <Link
                className="text-decoration-none font-color-default"
                to={url}
              >
                {icon && iconDirection === 'left' ? icon : null}
                {title}
                {icon && iconDirection === 'right' ? icon : null}
                <MdKeyboardArrowRight size="1.5em" className="mt-minus-2" />
              </Link>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleDropdownItem;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichActionsDataAction,
  setActionsDataAction,
  validateActionsDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchActionsDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.ACTIONS_PAGE.FETCH_ACTIONS_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_ACTIONS_PAGE,
            { ...action.params },
            null,
            MiddlewareEntities.ACTIONS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.ACTIONS));
        break;
      case `${MiddlewareEntities.ACTIONS} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.ACTIONS));
        next(validateActionsDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.ACTIONS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.ACTIONS));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.ACTIONS,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );

        break;
    }
  };

export const validateActionsDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.ACTIONS_PAGE.VALIDATE_ACTIONS_PAGE_DATA)
    ) {
      next(
        enrichActionsDataAction({
          sections: SectionDataFacade.generate(action.payload.sections),
          metaDescription: action.payload.metaDescription
        })
      );
    }
  };

export const enrichActionsDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.ACTIONS_PAGE.ENRICH_ACTIONS_PAGE_DATA) {
      next(setActionsDataAction(action.payload));
    }
  };

export default [
  fetchActionsDataMiddleware,
  validateActionsDataMiddleware,
  enrichActionsDataMiddleware
];

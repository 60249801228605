import React, { FC } from 'react';
import { IProduct } from '../../../../../entities/product/types';
import Button from '../../../../components/button';
import Authors from '../../../../components/authors';
import { Link } from 'react-router-dom';

interface IProps {
  product: IProduct;

  setIsShowSearchItems: (showSearchItems: boolean) => void;

  isMobile?: boolean;
}

const ProductOfTheWeek: FC<IProps> = ({
  product,
  setIsShowSearchItems,
  isMobile
}: IProps) => {
  return (
    <>
      <div className="background-gray p-2 h-search-action-section">
        <div className="row justify-content-center text-center mb-2">
          <div className="col-md-12">
            <div className="prominent-img-container cursor-pointer">
              <Link
                to={product.navigateTo}
                onClick={() => setIsShowSearchItems(false)}
              >
                <img
                  className="mx-auto img-fluid"
                  alt={product.title + '_img'}
                  src={product.images.l}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="row text-start justify-content-center text-center mt-3">
          <div className="col-md-10">
            <Link to={product.navigateTo} className="text-decoration-none">
              <h6 className="h6-mod1">{product.title}</h6>
            </Link>
            <div className="max-lines max-lines-2 max-height-40 mt-1">
              <Authors authors={product.authors} />
            </div>
          </div>
        </div>
        <div className="row text-start justify-content-center text-center mt-4">
          <div className="col-md-10">
            <Link
              to={product.navigateTo}
              onClick={() => setIsShowSearchItems(false)}
            >
              <Button
                classes={`
            btn
            btn-lg
            jost-extra-bold
            text-14
            background-orange
            btn-height-60
            rounded-0 ${isMobile && 'w-75'}`}
                type="button"
              >
                SAZNAJ VIŠE
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductOfTheWeek;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchFiltersDataAction,
  IFiltersData,
  ISetFiltersDataAction
} from './types';

export function fetchFiltersDataAction(): IFetchFiltersDataAction {
  return {
    type: ActionTypes.FILTERS_DATA.FETCH_FILTERS_DATA
  };
}

export function setFiltersDataAction(
  filtersData: IFiltersData
): ISetFiltersDataAction {
  return {
    type: ActionTypes.FILTERS_DATA.SET_FILTERS_DATA,
    payload: {
      filtersData
    }
  };
}

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchStaticPageDataAction,
  ISetStaticPageDataAction,
  IStaticPageDataState
} from './types';

export function requestFetchStaticPageDataAction(
  staticPageName: string
): IFetchStaticPageDataAction {
  return {
    type: ActionTypes.STATIC_PAGE.FETCH_STATIC_PAGE_DATA,
    payload: staticPageName
  };
}

export function setStaticPageDataAction(
  staticPageData: IStaticPageDataState
): ISetStaticPageDataAction {
  return {
    type: ActionTypes.STATIC_PAGE.SET_STATIC_PAGE_DATA,
    payload: staticPageData
  };
}

import { IFetchBookstoresAction, ISetBookstoresAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { IBookstore } from '../../../entities/bookstore/types';

export const fetchBookstoresAction = (): IFetchBookstoresAction => ({
  type: ActionTypes.BOOKSTORES_PAGE.FETCH_BOOKSTORES_PAGE
});

export const validateBookstoresAction = (bookstores: IBookstore[]) => ({
  type: ActionTypes.BOOKSTORES_PAGE.VALIDATE_BOOKSTORES_PAGE,
  payload: {
    bookstores
  }
});

export const enrichBookstoresAction = (bookstores: IBookstore[]) => ({
  type: ActionTypes.BOOKSTORES_PAGE.ENRICH_BOOKSTORES_PAGE,
  payload: {
    bookstores
  }
});

export const setBookstoresAction = (
  bookstores: any[]
): ISetBookstoresAction => ({
  type: ActionTypes.BOOKSTORES_PAGE.SET_BOOKSTORES_PAGE,
  payload: {
    bookstores
  }
});

import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { setFaqDataAction } from './actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchFaqDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.FAQ.FETCH_FAQ_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_FAQS,
            null,
            null,
            MiddlewareEntities.FAQ,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.FAQ));
        break;
      case `${MiddlewareEntities.FAQ} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.FAQ));
        next(setFaqDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.FAQ} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.FAQ));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.FAQ,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );

        break;
    }
  };

export default [fetchFaqDataMiddleware];

import { IFactory } from '../types';
import { IPublisher } from '../../entities/publisher/types';
import Publisher from '../../entities/publisher/Publisher';

const PublisherFactory: IFactory = class GenreFactory {
  static create(publisher: IPublisher) {
    return new Publisher(
      publisher._id,
      publisher.publisherName,
      publisher.status,
      publisher.description,
      publisher.shortDescription,
      publisher.metaTitle,
      publisher.metaDescription
    );
  }
};

export default PublisherFactory;

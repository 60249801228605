import {
  IAvailableNotificationState,
  ISetAvailableNotificationDataAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IAvailableNotificationState = {
  status: '',
  message: ''
};
const availableNotificationReducer = (
  state = initialState,
  action: ISetAvailableNotificationDataAction
): IAvailableNotificationState => {
  switch (action.type) {
    case ActionTypes.AVAILABLE_NOTIFICATION.SET_AVAILABLE_NOTIFICATION:
      return {
        ...state,
        status: action.payload.status,
        message: action.payload.message
      };
  }
  return state;
};

export default availableNotificationReducer;

import React from 'react';
import NextArrow from '../arrows/NextArrow';
import PrevArrow from '../arrows/PrevArrow';
import { Settings } from 'react-slick';
import BookstoreOverviewStyle from '../../../../config/bookstore-overview-style';

export const defaultSettings: Settings = {
  slidesToScroll: 1,
  slidesToShow: 6,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 5000,
  nextArrow: <NextArrow styleType={{ right: '-5%' }} />,
  prevArrow: <PrevArrow styleType={{ left: '-5%' }} />,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: true,
        dots: false
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false
      }
    }
  ]
};

export const homePageSettings: Settings = Object.assign({}, defaultSettings, {
  speed: 500,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: true,
        dots: false
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false
      }
    }
    // {
    //   breakpoint: 390,
    //   settings: {
    //     slidesToShow: 1.5,
    //     slidesToScroll: 1,
    //     autoplay: false,
    //     autoplaySpeed: 1000,
    //     infinite: false,
    //     arrows: false
    //   }
    // }
  ]
});

export const flatSquareCarouselSettings: Settings = Object.assign(
  {},
  defaultSettings,
  {
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      }
      // {
      //   breakpoint: 390,
      //   settings: {
      //     slidesToShow: 1.5,
      //     slidesToScroll: 1,
      //     autoplay: false,
      //     autoplaySpeed: 1000,
      //     infinite: false,
      //     arrows: false
      //   }
      // }
    ]
  }
);

export const bookStoreOverviewSettings: Settings = Object.assign(
  {},
  defaultSettings,
  {
    nextArrow: (
      <NextArrow
        styleType={{ ...BookstoreOverviewStyle, ...{ right: '-1%' } }}
      />
    ),
    prevArrow: (
      <PrevArrow
        styleType={{ ...BookstoreOverviewStyle, ...{ left: '-1%' } }}
      />
    ),
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false
        }
      }
    ]
  }
);

export const productOverViewSettings: Settings = Object.assign(
  {},
  defaultSettings,
  {
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          infinite: false,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          infinite: false,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          infinite: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 2,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      }
    ]
  }
);

export const sameComponentSettings: Settings = Object.assign(
  {},
  defaultSettings,
  {
    speed: 500,
    slidesToShow: 5,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      }
    ]
  }
);

export const textSliderSettings: Settings = Object.assign({}, defaultSettings, {
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  infinite: true,
  responsive: []
});

export const faqSettings: Settings = Object.assign({}, defaultSettings, {
  speed: 500,
  autoplaySpeed: 1000,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false,
        dots: true
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 2,
        autoplay: false,
        autoplaySpeed: 1000,
        infinite: false,
        arrows: false
      }
    }
  ]
});

export const delfiCollaborationSettings: Settings = Object.assign(
  {},
  defaultSettings,
  {
    speed: 500,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 2,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      }
    ]
  }
);

export const textSliderMobileSettings: Settings = Object.assign(
  {},
  defaultSettings,
  {
    speed: 2000,
    slidesToShow: 1,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          arrows: false
        }
      }
    ]
  }
);

export const simpleCarouselsLargeSettings: Settings = Object.assign(
  {},
  defaultSettings,
  {
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: true,
          dots: false
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 1000,
          infinite: false,
          arrows: false
        }
      }
    ]
  }
);

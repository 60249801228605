import { IFactory } from '../types';
import { IVisaPremiumBenefitPayloadCart } from '../../entities/cart/types';
import VisaPremiumBenefitPayloadCart from '../../entities/benefits/VisaPremiumBenefitPayloadCarts';
import { IProduct } from '../../entities/product/types';
import ProductFactory from '../product';
import Config from '../../config';
const VisaPremiumBenefitPayloadCartFactory: IFactory = class VisaPremiumBenefitPayloadCartFactory {
  static create(visaPremiumBenefitPayloadCart: IVisaPremiumBenefitPayloadCart) {
    const products = visaPremiumBenefitPayloadCart.cartProducts.map(
      (product: IProduct) => {
        return ProductFactory.create(product);
      }
    );
    return new VisaPremiumBenefitPayloadCart(
      visaPremiumBenefitPayloadCart.cartId,
      `${Config.api.mediaUrl}${visaPremiumBenefitPayloadCart.cartImageUrl}`,
      products,
      visaPremiumBenefitPayloadCart.cartStatus,
      visaPremiumBenefitPayloadCart.cartTitle,
      visaPremiumBenefitPayloadCart.startAt,
      visaPremiumBenefitPayloadCart.endAt
    );
  }
};

export default VisaPremiumBenefitPayloadCartFactory;

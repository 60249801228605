import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../../constants/ActionTypes';
import { apiRequest } from '../../api/actions';
import ApiUrls from '../../../../constants/ApiUrls';
import { setHomepageTopCarouselAction } from './actions';
import { setLoader } from '../../ui/actions';
import MiddlewareEntities from '../../../../constants/MiddlewareEntities';
import Config from '../../../../config';

const homepageTopCarouselMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.HOMEPAGE_TOP_CAROUSEL.FETCH_HOMEPAGE_TOP_CAROUSEL:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.HOMEPAGE_TOP_CAROUSEL,
            null,
            null,
            MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL));
        break;
      case `${MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL} ${ActionTypes.API_SUCCESS}`:
        action.payload.data.slides.map(
          (slide: { img: string; img_mobile: string; thumbnail: string }) => {
            slide.img = Config.api.mediaUrl + slide.img;
            slide.img_mobile = Config.api.mediaUrl + slide.img_mobile;
            slide.thumbnail = Config.api.mediaUrl + slide.thumbnail;
            return slide;
          }
        );
        next(setHomepageTopCarouselAction(action.payload.data.slides));
        next(setLoader(false, MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL));
        break;
      case `${MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.HOMEPAGE_TOP_CAROUSEL));
        break;
    }
  };

export default homepageTopCarouselMiddleware;

import { IProductOverviewData } from '../../app/store/product-overview/types';
import { IProduct } from './types';
import { ISection } from '../section/types';

export default class ProductOverviewData implements IProductOverviewData {
  product: IProduct;

  fromTheSameSource: IProduct[];

  pageSections: ISection[];

  constructor(
    product: IProduct,
    fromTheSameSource: IProduct[],
    pageSections: ISection[]
  ) {
    this.product = product;
    this.fromTheSameSource = fromTheSameSource;
    this.pageSections = pageSections;
  }
}

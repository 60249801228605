import ActionTypes from '../../../constants/ActionTypes';
import { ISetChangeCartToggleAction, IToggleChangeCartState } from './types';

const initialState: IToggleChangeCartState = {
  isChanged: false
};

const toggleChangeCartReducer = (
  state = initialState,
  action: ISetChangeCartToggleAction
): IToggleChangeCartState => {
  switch (action.type) {
    case ActionTypes.TOGGLE_CHANGE_CART.SET_TOGGLE_CHANGE_CART:
      return {
        ...state,
        isChanged: action.payload
      };
  }

  return state;
};

export default toggleChangeCartReducer;

import {
  IChangeProfileUserAction,
  IDeleteProfileUserAction,
  IEnrichProfileActivityAction,
  IFetchProfileActivityAction,
  IFetchProfileOrderHistoryAction,
  IFetchProfileUserAction,
  IProfileActivityHistory,
  ISetProfileActivityAction,
  ISetProfileOrderHistoryAction,
  ISetProfileUserAction,
  IUpdateProfileUserAction,
  IUpdateProfileUserData,
  IValidateProfileActivityAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { IMember } from '../../../entities/profile/types';

export const fetchProfileUserAction = (): IFetchProfileUserAction => ({
  type: ActionTypes.PROFILE_PAGE.FETCH_PROFILE_USER
});

export const setProfileUserAction = (user: IMember): ISetProfileUserAction => ({
  type: ActionTypes.PROFILE_PAGE.SET_PROFILE_USER,
  payload: {
    user
  }
});

export const deleteProfileUserAction = (): IDeleteProfileUserAction => ({
  type: ActionTypes.PROFILE_PAGE.DELETE_PROFILE_USER
});

export const fetchProfileActivityAction = (): IFetchProfileActivityAction => ({
  type: ActionTypes.PROFILE_PAGE.FETCH_PROFILE_ACTIVITY
});

export const validateProfileActivityAction = (
  activityHistory: any[]
): IValidateProfileActivityAction => ({
  type: ActionTypes.PROFILE_PAGE.VALIDATE_PROFILE_ACTIVITY,
  payload: {
    activityHistory
  }
});

export const enrichProfileActivityAction = (
  activityHistory: any[]
): IEnrichProfileActivityAction => ({
  type: ActionTypes.PROFILE_PAGE.ENRICH_PROFILE_ACTIVITY,
  payload: {
    activityHistory
  }
});

export const setProfileActivityAction = (
  activityHistory: any[]
): ISetProfileActivityAction => ({
  type: ActionTypes.PROFILE_PAGE.SET_PROFILE_ACTIVITY,
  payload: {
    activityHistory
  }
});

export const fetchProfileOrderHistoryAction =
  (): IFetchProfileOrderHistoryAction => ({
    type: ActionTypes.PROFILE_PAGE.FETCH_PROFILE_ORDER_HISTORY
  });

export const validateProfileOrderHistoryAction = (orderHistory: any[]) => {
  return {
    type: ActionTypes.PROFILE_PAGE.VALIDATE_PROFILE_ORDER_HISTORY,
    payload: {
      orderHistory
    }
  };
};

export const enrichProfileOrderHistoryAction = (orderHistory: any[]) => {
  return {
    type: ActionTypes.PROFILE_PAGE.ENRICH_PROFILE_ORDER_HISTORY,
    payload: {
      orderHistory
    }
  };
};

export const setProfileOrderHistoryAction = (
  orders: any
): ISetProfileOrderHistoryAction => ({
  type: ActionTypes.PROFILE_PAGE.SET_PROFILE_ORDER_HISTORY,
  payload: {
    orders
  }
});

export const updateProfileUserAction = (
  newUserProfileData: IUpdateProfileUserData
): IUpdateProfileUserAction => ({
  type: ActionTypes.PROFILE_PAGE.PROFILE_USER_UPDATE,
  payload: {
    newUserProfileData
  }
});

export const changeProfileUserPasswordAction = (changePasswordData: {
  password: string;
  newPassword: string;
}): IChangeProfileUserAction => ({
  type: ActionTypes.PROFILE_PAGE.PROFILE_USER_PASSWORD_CHANGE,
  payload: {
    changePasswordData
  }
});

export const joinToDelfiPremiumAction = () => ({
  type: ActionTypes.PROFILE_PAGE.JOIN_TO_DELFI_PREMIUM
});

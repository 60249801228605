import ActionTypes from '../../../constants/ActionTypes';
import {
  INotification,
  IRemoveNotificationAction,
  ISetNotificationAction
} from './types';

export const createNotification = (
  entity: string,
  type: string,
  message?: string,
  data?: any
) => ({
  type: `${entity} ${ActionTypes.NOTIFICATION.CREATE_NOTIFICATION}`,
  payload: { data, meta: { entity, type }, message }
});

export const setNotification = (
  notification: INotification,
  entity: string
): ISetNotificationAction => ({
  type: `${entity} ${ActionTypes.NOTIFICATION.SET_NOTIFICATION}`,
  payload: { notification },
  meta: { entity }
});

export const removeNotification = (
  notificationId: string | number
): IRemoveNotificationAction => ({
  type: ActionTypes.NOTIFICATION.REMOVE_NOTIFICATION,
  payload: { notificationId }
});

export const removeAllNotifications = () => ({
  type: ActionTypes.NOTIFICATION.REMOVE_ALL_NOTIFICATIONS
});

export const createErrorNotification = (
  errorResponse: any,
  entity: string
) => ({
  type: ActionTypes.NOTIFICATION.CREATE_ERROR_NOTIFICATION,
  payload: { errorResponse },
  meta: { entity }
});

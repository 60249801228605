import {
  IFetchHomepageTopCarouselAction,
  ISetHomepageTopCarouselAction
} from './types';
import ActionTypes from '../../../../constants/ActionTypes';

export const fetchHomepageTopCarouselAction = (): IFetchHomepageTopCarouselAction => ({
  type: ActionTypes.HOMEPAGE_TOP_CAROUSEL.FETCH_HOMEPAGE_TOP_CAROUSEL,
});

export const setHomepageTopCarouselAction = (slides: any[]): ISetHomepageTopCarouselAction => ({
  type: ActionTypes.HOMEPAGE_TOP_CAROUSEL.SET_HOMEPAGE_TOP_CAROUSEL,
  payload: {
    slides
  }
});

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { isTokenExpired } from '../../../utilites/token';
import { setNewsLetterConfirmationMessageAction } from './actions';
import { GenericMessages } from '../../../constants/GenericMessages';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';

const newsLetterConfirmationMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWSLETTER.CONFIRM_NEWSLETTER:
        if (!isTokenExpired(action.payload.date)) {
          next(
            apiRequest(
              null,
              'GET',
              `${ApiUrls.NEWSLETTER_CONFIRM}/${action.payload.token}`,
              null,
              null,
              MiddlewareEntities.NEWSLETTER,
              {}
            )
          );
          return next(setLoader(true, MiddlewareEntities.NEWSLETTER));
        }
        next(
          setNewsLetterConfirmationMessageAction(GenericMessages.TOKEN_EXPIRED)
        );
        break;
      case `${MiddlewareEntities.NEWSLETTER} ${ActionTypes.API_SUCCESS}`:
        next(
          setNewsLetterConfirmationMessageAction(
            'Uspešno ste se prijavili na naš newsletter'
          )
        );
        next(setLoader(false, MiddlewareEntities.NEWSLETTER));
        break;
      case `${MiddlewareEntities.NEWSLETTER} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWSLETTER));
        if (
          action.payload.data.data &&
          action.payload.data.data.status === HttpStatusCodes.HTTP_BAD_REQUEST
        ) {
          next(
            setNewsLetterConfirmationMessageAction(
              GenericMessages.TOKEN_EXPIRED
            )
          );
        }
        break;
    }
  };

export default newsLetterConfirmationMiddleware;

import { INotification } from '../../app/store/notification/types';

export default class Notification implements INotification {
  id: number;

  type: string;

  message: string;

  cssClass: string;

  constructor(id: number, type: string, message: string, cssClass: string) {
    this.id = id;
    this.type = type;
    this.message = message;
    this.cssClass = cssClass;
  }
}

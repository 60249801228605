import {
  IPremiumActionsPageState,
  ISetPremiumActionsPageDataAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IPremiumActionsPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const premiumActionsPageDataReducer = (
  state = initialState,
  action: ISetPremiumActionsPageDataAction
): IPremiumActionsPageState => {
  switch (action.type) {
    case ActionTypes.PREMIUM_ACTIONS_PAGE.SET_PREMIUM_ACTIONS_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default premiumActionsPageDataReducer;

import ActionTypes from '../../../constants/ActionTypes';
import { INotificationState } from './types';

const initialState: INotificationState = [];

export default (state = initialState, action: any): INotificationState => {
  if (action.type.includes(ActionTypes.NOTIFICATION.SET_NOTIFICATION)) {
    return [...state, action.payload.notification];
  }

  if (action.type.includes(ActionTypes.NOTIFICATION.REMOVE_NOTIFICATION)) {
    return state.filter(
      (notification) => notification.id !== action.payload.notificationId
    );
  }

  if (action.type === ActionTypes.NOTIFICATION.REMOVE_ALL_NOTIFICATIONS) {
    return [];
  }

  return state;
};

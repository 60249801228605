import ActionTypes from '../../../constants/ActionTypes';
import { IProduct } from '../../../entities/product/types';
import {
  IEnrichProductListProductsAction,
  IFetchProductListPageProductsAction,
  ISetProductListProductsAction,
  IValidateProductListProductsAction
} from './types';

export function fetchProductListProductsAction(): IFetchProductListPageProductsAction {
  return {
    type: ActionTypes.PRODUCTS_LIST_PAGE.FETCH_PRODUCTS_LIST_PAGE_PRODUCTS
  };
}

export function setProductListPageProductsAction(
  products: IProduct[],
  totalRecords: number
): ISetProductListProductsAction {
  return {
    type: ActionTypes.PRODUCTS_LIST_PAGE.SET_PRODUCTS_LIST_PAGE_PRODUCTS,
    payload: {
      products,
      totalRecords
    }
  };
}

export function validateProductListPageProductsAction(
  products: IProduct[],
  totalRecords: number
): IValidateProductListProductsAction {
  return {
    type: ActionTypes.PRODUCTS_LIST_PAGE.VALIDATE_PRODUCTS_LIST_PAGE_PRODUCTS,
    payload: {
      products,
      totalRecords
    }
  };
}

export function enrichProductListPageProductsAction(
  products: IProduct[],
  totalRecords: number
): IEnrichProductListProductsAction {
  return {
    type: ActionTypes.PRODUCTS_LIST_PAGE.ENRICH_PRODUCTS_LIST_PAGE_PRODUCTS,
    payload: {
      products,
      totalRecords
    }
  };
}

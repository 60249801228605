import ActionTypes from '../../../constants/ActionTypes';
import { ISetLoaderAction } from './types';

export const setLoader = (isVisible: boolean, entity: string): ISetLoaderAction => ({
  type: ActionTypes.SET_LOADER,
  payload: isVisible,
  meta: {
    entity,
  },
});

import {
  ILastViewedProductsState,
  ISetLastViewedProductsAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: ILastViewedProductsState = {
  products: []
};
const lastViewedProductsReducer = (
  state = initialState,
  action: ISetLastViewedProductsAction
): ILastViewedProductsState => {
  switch (action.type) {
    case ActionTypes.LAST_VIEWED.SET_LAST_VIEWED_PRODUCTS:
      return {
        ...state,
        products: action.payload.products
      };
  }
  return state;
};

export default lastViewedProductsReducer;

import { combineReducers } from 'redux';
import uiReducer from './ui/reducers';
import searchItemsReducer from './search-items/reducers';
import textSlidersReducer from './text-sliders/reducers';
import homePageReducer from './homepage/reducers';
import homepageCarouselReducer from './carousels/homepage-top-carousel/reducers';
import productPageReducer from './product-overview/reducers';
import userReducer from './user/reducers';
import bookstoresReducer from './bookstores/reducers';
import bookstoreReducer from './bookstore-overview/reducers';
import profileReducer from './profile/reducers';
import commentsReducer from './comments/reducers';
import newsReducer from './news/reducers';
import newsOverviewReducer from './news-overview/reducers';
import countriesReducer from './countries/reducers';
import municipalitiesReducer from './municipalities/reducers';
import wishListReducer from './wishlist/reducers';
import userConfirmationReducer from './confirm-registration/reducers';
import cartReducer from './cart/reducers';
import wspayReducer from './wspay/reducers';
import askAboutProductReducer from './ask-about-product/reducers';
import kidsBookPageReducer from './kids-book/reducers';
import foreignBookDataReducer from './foreign-book/reducers';
import schoolBookDataReducer from './school-book/reducers';
import actionsDataReducer from './actions/reducers';
import checkoutDataReducer from './checkout/reducers';
import administrativeBanDataReducer from './administrative-ban/reducers';
import notificationReducer from './notification/reducers';
import bookMakerMagazinePageDataReducer from './bookmaker-magazine/reducers';
import careerPageDataReducer from './delfi-career/reducers';
import advancedSearchPageDataReducer from './advanced_search/reducers';
import searchParamsReducer from './searchParams/reducers';
import faqDataReducer from './faq/reducers';
import productListPageReducer from './products-list/reducers';
import newsletterReducer from './newsletter/reducers';
import newsLetterConfirmationReducer from './confirm-newsletter/reducers';
import sectionPageReducer from './section/reducers';
import forgotPasswordReducer from './forgot-user-password/reducers';
import resetPasswordReducer from './reset-user-password/reducers';
import edenBooksReducer from './eden_books/reducers';
import orderStatusDataReducer from './order-status/reducers';
import availableBookstoresReducer from './bookstore-availability/reducers';
import starWayDataReducer from './starway/reducers';
import bookPageDataReducer from './book/reducers';
import gamePageDataReducer from './game/reducers';
import giftPageDataReducer from './gift/reducers';
import musicPageDataReducer from './music/reducers';
import lastViewedProductsReducer from './last-viewed/reducers';
import staticPageDataReducer from './static-page/reducers';
import compliantPageDataReducer from './complaint/reducers';
import productByNavIdReducer from './product-by-nav-id/reducers';
import menuItemsReducer from './menu-items/reducers';
import availableNotificationReducer from './sent-available-notification/reducers';
import actionsListReducer from './actions-list/reducers';
import booktokPageDataReducer from './booktok/reducers';
import topListsPageDataReducer from './toplists/reducers';
import filmPageDataReducer from './film/reducers';
import premiumActionsPageDataReducer from './premium-actions/reducers';
import toggleChangeCartReducer from './toggle-add-to-cart/reducers';
import filtersDataReducer from './filters-data/reducers';
import newsletterUnsubscribeReducer from './newsletter-unsubscribe/reducers';
import cookieConsentReducer from './cookie-consent/reducers';
import boardGamesPageDataReducer from './board-games/reducers';
import russianBooksPageDataReducer from './russian-books/reducers';
import mangePageDataReducer from './mange/reducers';
import magyarPageDataReducer from './magyar/reducers';
import authorPageDataReducer from './author-page/reducers';
import genrePageDataReducer from './genre-page/reducers';
import publisherPageDataReducer from './publisher-page/reducers';

const rootReducers = combineReducers({
  ui: uiReducer,
  searchItems: searchItemsReducer,
  textSliders: textSlidersReducer,
  homePage: homePageReducer,
  homepageCarousel: homepageCarouselReducer,
  productPage: productPageReducer,
  user: userReducer,
  bookstores: bookstoresReducer,
  bookstore: bookstoreReducer,
  profileData: profileReducer,
  comments: commentsReducer,
  news: newsReducer,
  newsOverview: newsOverviewReducer,
  countries: countriesReducer,
  municipalities: municipalitiesReducer,
  wishlist: wishListReducer,
  userConfirmationMessage: userConfirmationReducer,
  cart: cartReducer,
  wspay: wspayReducer,
  askAboutProductStatusMessage: askAboutProductReducer,
  kidsBookPageData: kidsBookPageReducer,
  foreignBookData: foreignBookDataReducer,
  schoolBookData: schoolBookDataReducer,
  actionsData: actionsDataReducer,
  checkoutData: checkoutDataReducer,
  administrativeBanData: administrativeBanDataReducer,
  notifications: notificationReducer,
  bookmakerPageData: bookMakerMagazinePageDataReducer,
  careerPageData: careerPageDataReducer,
  advancedSearchPageData: advancedSearchPageDataReducer,
  searchParams: searchParamsReducer,
  faqData: faqDataReducer,
  productListPageData: productListPageReducer,
  newsLetterData: newsletterReducer,
  newsLetterConfirmationData: newsLetterConfirmationReducer,
  sectionPageData: sectionPageReducer,
  forgotPasswordData: forgotPasswordReducer,
  resetPasswordData: resetPasswordReducer,
  edenBooksData: edenBooksReducer,
  orderStatusData: orderStatusDataReducer,
  availableBookstores: availableBookstoresReducer,
  starWayData: starWayDataReducer,
  bookPageData: bookPageDataReducer,
  gamePageData: gamePageDataReducer,
  giftPageData: giftPageDataReducer,
  musicPageData: musicPageDataReducer,
  lastViewedProducts: lastViewedProductsReducer,
  staticPageData: staticPageDataReducer,
  compliantPageData: compliantPageDataReducer,
  productByNavId: productByNavIdReducer,
  menuItems: menuItemsReducer,
  availableNotificationData: availableNotificationReducer,
  actionsListData: actionsListReducer,
  booktokPageData: booktokPageDataReducer,
  topListsPageData: topListsPageDataReducer,
  filmPageData: filmPageDataReducer,
  boardGamesPageData: boardGamesPageDataReducer,
  russianBooksPageData: russianBooksPageDataReducer,
  premiumActionsPageData: premiumActionsPageDataReducer,
  toggleChangeCartData: toggleChangeCartReducer,
  filtersData: filtersDataReducer,
  newsletterUnsubscribeData: newsletterUnsubscribeReducer,
  cookieConsentData: cookieConsentReducer,
  mangePageData: mangePageDataReducer,
  magyarPageData: magyarPageDataReducer,
  authorPageData: authorPageDataReducer,
  genrePageData: genrePageDataReducer,
  publisherPageData: publisherPageDataReducer
});

export type AppState = ReturnType<typeof rootReducers>;

export default rootReducers;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { isTokenExpired } from '../../../utilites/token';
import { setUserConfirmationMessageAction } from './actions';
import { GenericMessages } from '../../../constants/GenericMessages';
const userConfirmationMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.CONFIRM_USER_REGISTRATION:
        if (!isTokenExpired(action.payload.date)) {
          next(
            apiRequest(
              null,
              'POST',
              ApiUrls.USER_CONFIRM_REGISTRATION,
              null,
              { token: action.payload.token },
              MiddlewareEntities.USER_CONFIRMATION_REGISTRATION_MESSAGE,
              {}
            )
          );
          return next(
            setLoader(
              true,
              MiddlewareEntities.USER_CONFIRMATION_REGISTRATION_MESSAGE
            )
          );
        }
        next(
          setUserConfirmationMessageAction(
            GenericMessages.TOKEN_EXPIRED,
            'error'
          )
        );
        break;
      case `${MiddlewareEntities.USER_CONFIRMATION_REGISTRATION_MESSAGE} ${ActionTypes.API_SUCCESS}`:
        next(
          setUserConfirmationMessageAction(
            'Uspešno ste potvrdili registraciju',
            'success'
          )
        );
        next(
          setLoader(
            false,
            MiddlewareEntities.USER_CONFIRMATION_REGISTRATION_MESSAGE
          )
        );
        break;

      case `${MiddlewareEntities.USER_CONFIRMATION_REGISTRATION_MESSAGE} ${ActionTypes.API_ERROR}`:
        next(
          setLoader(
            false,
            MiddlewareEntities.USER_CONFIRMATION_REGISTRATION_MESSAGE
          )
        );
        next(
          setUserConfirmationMessageAction(
            GenericMessages.TOKEN_EXPIRED,
            'error'
          )
        );
        break;
    }
  };

export default userConfirmationMiddleware;

import React, {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useEffect,
  useState
} from 'react';
import './scss/style.scss';
import { TiMinus } from 'react-icons/ti';
import { TiPlus } from 'react-icons/ti';
import { FaCheck, FaRegTrashAlt } from 'react-icons/fa';
import ButtonWitIcon from '../button-with-icon';
import Authors from '../authors';
import ProductPrice from '../product-price';
import { Link, useLocation } from 'react-router-dom';
import { formatPrice } from '../../../utilites/product';
import { useDispatch, useStore } from 'react-redux';
import { TransitionSteps } from '../../../constants/TransitionSteps';
import { setToggleChangeCartAction } from '../../../app/store/toggle-add-to-cart/actions';

interface IProps {
  cart: any;
  handleOnChangeQuantity: (productId: string, quantity: number) => void;
  handleOnRemoveFromItem: (productId: string) => void;
  handleOnClickShowCartPopup: (event: React.MouseEvent<HTMLElement>) => void;
  isChanged: boolean;
  styleType?: CSSProperties;
  classes?: string;
  isMobile?: boolean;
  isLaptop?: boolean;
}

const CartPopup = forwardRef<HTMLDivElement, IProps>(
  (
    {
      cart,
      handleOnChangeQuantity,
      handleOnRemoveFromItem,
      handleOnClickShowCartPopup,
      isChanged,
      styleType,
      classes,
      isMobile,
      isLaptop
    }: IProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    let orderValidationData = null;
    const location = useLocation();
    const store = useStore();
    const state: any = store.getState();
    const dispatch = useDispatch();

    const handleOnChangeQuantityLocal = (
      productId: string,
      quantity: number
    ) => {
      if (!isChanged) {
        handleOnChangeQuantity(productId, quantity);
        dispatch(setToggleChangeCartAction(true));

        setTimeout(() => {
          dispatch(setToggleChangeCartAction(false));
        }, 2000);
      }
    };

    const [isDisabledChangeBasket, setIsDisabledChangeBasket] = useState(false);

    useEffect(() => {
      if (
        location.pathname === '/kupovina' &&
        state.checkoutData &&
        state.checkoutData.checkoutData &&
        state.checkoutData.checkoutData.steps &&
        (state.checkoutData.checkoutData.steps.current.name ===
          TransitionSteps.PAYMENT_OPTION_STATE ||
          state.checkoutData.checkoutData.steps.current.name ===
            TransitionSteps.CONFIRM_ORDER_STATE)
      ) {
        return setIsDisabledChangeBasket(true);
      }
    }, [location.pathname]);

    if (Object.keys(cart.order).length > 0) {
      orderValidationData = cart.order.validatePrice();
    }

    // usePreventOutsideScroll(ref);

    return Object.keys(cart.order).length > 0 &&
      cart.order.orderItemsPriceReport.length ? (
      <div
        className={`${
          isMobile ? 'mobile-cart-callout' : 'cart-callout'
        } p-2 bg-white position-absolute ${classes}`}
        style={styleType}
        ref={ref}
      >
        <div
          className={`cart-callout-items ${
            isMobile ? 'cart-callout-mobile-items' : ''
          }`}
        >
          {cart.order.orderItemsPriceReport.map((item: any, index: number) => {
            let copyDisplayPrices = { ...item.product.displayPrices };
            copyDisplayPrices.bestPrice = item.finalPrice;
            return (
              <div
                key={index}
                className="row justify-content-start align-items-center gx-0 cart-callout-items"
              >
                <div className="card border-0">
                  <div className={`card-body ${isLaptop ? 'p-2' : ''}`}>
                    <div className="row align-items-center">
                      <div className="col-auto text-start ps-0 pe-0">
                        <Link
                          to={item.product.navigateTo}
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleOnClickShowCartPopup(event)
                          }
                        >
                          <img
                            src={item.product.images.m}
                            className="img-fluid"
                            alt={`${item.product.title}_img`}
                          />
                        </Link>
                      </div>
                      <div className="col text-start">
                        <Link
                          to={item.product.navigateTo}
                          className="text-decoration-none"
                          onClick={(event: any) =>
                            handleOnClickShowCartPopup(event)
                          }
                        >
                          <h2
                            className={`card-text h2-mod6 ${
                              isLaptop ? 'text-14' : 'text-16'
                            } jost-semi-bold max-lines max-lines-3`}
                          >
                            {item.product.title}
                          </h2>
                        </Link>
                        <div className="mt-1">
                          <Authors
                            authors={item.product.authors}
                            classes={`${isLaptop ? 'text-14' : ''}`}
                          />
                        </div>
                        <Link
                          to={item.product.navigateTo}
                          className="text-decoration-none"
                          onClick={(event: React.MouseEvent<HTMLElement>) =>
                            handleOnClickShowCartPopup(event)
                          }
                        >
                          <ProductPrice
                            displayPrices={copyDisplayPrices}
                            productType={item.product.productType}
                            isOnAction={item.product.isActionOnFly}
                            isNotRenderIsPremium
                            isOnCart
                          />
                        </Link>
                      </div>
                      <div className="col-auto">
                        <button
                          className="border-0 bg-transparent pe-0 ps-0"
                          disabled={isDisabledChangeBasket || isChanged}
                          onClick={() =>
                            handleOnChangeQuantityLocal(
                              item.product.id,
                              --item.quantity
                            )
                          }
                        >
                          <TiMinus size="1em" className="mt-minus-4" />
                        </button>
                        <span className="ms-2 text-14 jost-extra-bold">
                          {item.quantity}
                        </span>
                        <button
                          className="ms-2 border-0 bg-transparent pe-0 ps-0"
                          disabled={isDisabledChangeBasket || isChanged}
                          onClick={() =>
                            handleOnChangeQuantityLocal(
                              item.product.id,
                              ++item.quantity
                            )
                          }
                        >
                          <TiPlus size="1em" className="mt-minus-4" />
                        </button>
                        <button
                          className="ms-3 border-0 bg-transparent pe-0 ps-0"
                          disabled={isDisabledChangeBasket || isChanged}
                          onClick={() =>
                            handleOnRemoveFromItem(item.product.id)
                          }
                        >
                          <FaRegTrashAlt size="1em" className="mt-minus-3" />
                        </button>
                      </div>
                    </div>
                    <div
                      className={`border-bottom border-2 ${
                        isLaptop ? 'mt-1' : 'mt-3'
                      }`}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {orderValidationData && !orderValidationData.blockFlag ? (
          <div
            className={`row align-items-center gx-0 ${
              isLaptop ? 'p-2' : 'p-3'
            }`}
          >
            <div className="col-6 text-start">
              <span className="text-uppercase text-14 jost-extra-bold">
                Ukupno:
              </span>
            </div>
            <div className="col-6 float-end text-end">
              <span className="text-14 jost-extra-bold">
                {formatPrice(cart.order.allOrderItemsAmount, 2)} RSD
              </span>
            </div>
          </div>
        ) : null}
        {orderValidationData && orderValidationData.blockFlag && (
          <div className="row justify-content-center text-center p-3">
            <div className="col-md-12">
              <h6>{orderValidationData.orderValidationUserMessage}</h6>
            </div>
          </div>
        )}
        {orderValidationData && (
          <div
            className={`row align-items-center justify-content-center text-center ${
              isLaptop ? 'ps-3 pe-3' : 'p-3'
            }`}
          >
            <div className="col-md-12">
              <Link
                to="/kupovina"
                onClick={(event: React.MouseEvent<HTMLElement>) =>
                  handleOnClickShowCartPopup(event)
                }
                state={{
                  navigateToFirstStep: TransitionSteps.INITIAL_STATE
                }}
              >
                <ButtonWitIcon
                  label="MOJA KORPA"
                  iconDirection="right"
                  icon={<FaCheck size="1.4em" className="mt-minus-4" />}
                  componentClasses="background-orange justify-content-center text-center w-100 p-3"
                  iconClasses="ms-3"
                  textClasses="text-14 jost-extra-bold text-uppercase"
                />
              </Link>
            </div>
          </div>
        )}
        {cart.order.amountUpToFreeShipping &&
        cart.order.amountUpToFreeShipping > 0 ? (
          <div
            className={`row justify-content-center align-items-center text-center gx-1 ${
              isLaptop ? 'mt-3' : 'mt-2'
            }`}
          >
            <div className="background-delfi color-white p-2">
              <div className="mt-2">
                <span className="text-16 jost-semi-bold">
                  JOŠ SAMO {cart.order.amountUpToFreeShipping} RSD DO BESPLATNE
                  ISPORUKE!
                </span>
                <p className="text-12 jost-medium">
                  Besplatna dostava za sve porudžbine iznad 3.000 rsd na
                  teritoriji Srbije
                  {/*Besplatna dostava za porudžbine na teritoriji Srbije*/}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            className={`row justify-content-center align-items-center text-center gx-1 ${
              isLaptop ? 'mt-3' : 'mt-2'
            }`}
          >
            <div className="background-delfi color-white p-2">
              <div className="mt-2">
                <p className="text-16 jost-medium">
                  Besplatna isporuka na teritoriji republike Srbije
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    ) : (
      <div
        ref={ref}
        className={`${
          isMobile
            ? 'mobile-cart-callout mobile-cart-callout-ml-8'
            : 'cart-callout'
        } p-2 bg-white position-absolute ${classes}`}
      >
        <div className="row justify-content-center text-center align-items-center gx-0 p-3">
          <div className="col-md-12">
            <h6>Vaša korpa je prazna</h6>
          </div>
        </div>
      </div>
    );
  }
);

export default CartPopup;

import ActionTypes from '../../../constants/ActionTypes';
import { ICountriesState, ISetCountriesAction } from './types';

const initialState: ICountriesState = {
  items: []
};

const countriesReducer = (
  state = initialState,
  action: ISetCountriesAction
): ICountriesState => {
  switch (action.type) {
    case ActionTypes.COUNTRIES.SET_COUNTRIES:
      return {
        ...state,
        items: action.payload.countries
      };
  }

  return state;
};

export default countriesReducer;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchGiftPageDataAction,
  IGiftPageState,
  ISetGiftPageDataAction
} from './types';

export function fetchGiftPageDataAction(): IFetchGiftPageDataAction {
  return {
    type: ActionTypes.GIFT_PAGE.FETCH_GIFT_PAGE_DATA
  };
}

export function setGiftPageDataAction(
  giftPageData: IGiftPageState
): ISetGiftPageDataAction {
  return {
    type: ActionTypes.GIFT_PAGE.SET_GIFT_PAGE_DATA,
    payload: giftPageData
  };
}

export function validateGiftPageDataAction(giftPageData: IGiftPageState) {
  return {
    type: ActionTypes.GIFT_PAGE.VALIDATE_GIFT_PAGE_DATA,
    payload: giftPageData
  };
}

export function enrichGiftPageDataAction(giftPageData: any) {
  return {
    type: ActionTypes.GIFT_PAGE.ENRICH_GIFT_PAGE_DATA,
    payload: giftPageData
  };
}

import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichProductByNavIdAction,
  IFetchProductByNavIdAction,
  IResetProductByNavIdAction,
  ISetProductByNavIdAction,
  IValidateProductByNavIdAction
} from './types';
import { IProduct } from '../../../entities/product/types';

export function fetchProductByNavIdAction(
  navId: string
): IFetchProductByNavIdAction {
  return {
    type: ActionTypes.PRODUCT_BY_NAV_ID.FETCH_PRODUCT_BY_NAV_ID,
    payload: {
      navId
    }
  };
}

export function validateProductByNavIdAction(
  product: IProduct
): IValidateProductByNavIdAction {
  return {
    type: ActionTypes.PRODUCT_BY_NAV_ID.VALIDATE_PRODUCT_BY_NAV_ID,
    payload: product
  };
}

export function enrichProductByNavIdAction(
  product: IProduct
): IEnrichProductByNavIdAction {
  return {
    type: ActionTypes.PRODUCT_BY_NAV_ID.ENRICH_PRODUCT_BY_NAV_ID,
    payload: product
  };
}

export function setProductByNavIdAction(
  product: IProduct | null
): ISetProductByNavIdAction {
  return {
    type: ActionTypes.PRODUCT_BY_NAV_ID.SET_PRODUCT_BY_NAV_ID,
    payload: product
  };
}

export function resetProductByNavIdAction(): IResetProductByNavIdAction {
  return {
    type: ActionTypes.PRODUCT_BY_NAV_ID.RESET_PRODUCT_BY_NAV_ID,
    payload: null
  };
}

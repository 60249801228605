import {
  IAction,
  IDisplayPrices,
  IProduct,
  ISticker
} from '../entities/product/types';
import Validator from '../validators/Validator';
import { IUser } from '../entities/user/types';
import ProductActions from '../constants/ProductActions';
import store from '../app/store';
import { ProductTypes } from '../constants/ProductTypes';
import { Discounts } from '../constants/Discounts';

export default class PriceRulesFacade {
  private static instance: PriceRulesFacade;

  // @ts-ignore
  private product: IProduct;

  // @ts-ignore
  private user: IUser | null;

  public static getInstance(): PriceRulesFacade {
    if (!this.instance) {
      this.instance = new PriceRulesFacade();
    }

    return this.instance;
  }

  public init(product: IProduct) {
    this.product = product;
    this.user = store.getState().user.member.user;
  }

  private isDelfiClubUser() {
    return this.user && this.user.isDelfiClub;
  }

  // private isExclusivelyPremiumAction(exclusivelyPremium: boolean) {
  //   return this.isDelfiClubUser() && exclusivelyPremium;
  // }

  public getAction(): IAction | undefined {
    if (
      !Validator.isUndefined(this.product.actions) &&
      Validator.isArray(this.product.actions)
    ) {
      const todayDate = new Date();
      const action = this.product.actions.find((action: IAction) => {
        if (action.exclusivelyPremium && !this.isDelfiClubUser()) {
          return false;
        }

        return (
          action.raw.status &&
          action.startAt &&
          action.endAt &&
          todayDate >= new Date(action.startAt) &&
          todayDate <= new Date(action.endAt)
        );
      });

      if (action) {
        return action;
      }
    }
  }

  private getPriceFromActionByUserType(action: IAction): Number {
    switch (action.actionType) {
      case ProductActions.FIXED_PRICE:
      case ProductActions.ADDITIONAL_DISCOUNT:
      case ProductActions.QUANTITY_DISCOUNT2:
      case ProductActions.FIXED_DISCOUNT:
        if (this.isDelfiClubUser() && action.priceQuantityPremium) {
          return action.priceQuantityPremium;
        }

        if (this.isDelfiClubUser() && action.priceRegularPremium) {
          return action.priceRegularPremium;
        }

        if (!action.exclusivelyPremium && action.priceQuantityStandard) {
          return action.priceQuantityStandard;
        }

        if (!action.exclusivelyPremium && action.priceRegularStandard) {
          return action.priceRegularStandard;
        }

        return -1;

      case ProductActions.EXPONENTIAL_DISCOUNT:
        if (Array.isArray(action.levels) && action.levels.length > 0) {
          const levelPrice = action.levels[action.levels.length - 1].levelPrice;
          if (this.isDelfiClubUser() && action.exclusivelyPremium) {
            return levelPrice;
          }

          if (!action.exclusivelyPremium) {
            return levelPrice;
          }
        }
        return -1;
    }

    return -1;
  }

  public getBestPrice() {
    const displayPrices: IDisplayPrices = {
      fullPrice: this.product.priceList.fullPrice,
      ebookPrice: this.product.priceList.eBookPrice,
      collectionPrice: this.product.priceList.collectionFullPrice,
      premiumPrice: 0,
      bestPrice: 0
    };

    if (this.product.productType === ProductTypes.COLLECTION) {
      displayPrices.fullPrice = this.product.priceList.fullPrice;
      return displayPrices;
    }

    const action = this.getAction();

    if (action) {
      displayPrices.bestPrice = this.getPriceFromActionByUserType(action);

      //add for new premium price change logic
      // now we use premiumPrice for not logged users
      displayPrices.premiumPrice = this.getPriceFromActionByUserType(action);
      return displayPrices;
    }

    if (!action) {
      if (this.isDelfiClubUser()) {
        if (
          this.checkIsPropertyPriceIsValid(
            Discounts.QUANTITY_DISCOUNT_PREMIUM_PRICE
          )
        ) {
          //@ts-ignore
          displayPrices.bestPrice =
            this.product.priceList[Discounts.QUANTITY_DISCOUNT_PREMIUM_PRICE];
          //@ts-ignore
          //add for new premium price change logic
          // now we use premiumPrice for not logged users
          displayPrices.premiumPrice =
            this.product.priceList[Discounts.QUANTITY_DISCOUNT_PREMIUM_PRICE];

          return displayPrices;
        }

        if (
          this.checkIsPropertyPriceIsValid(
            Discounts.REGULAR_DISCOUNT_PREMIUM_PRICE
          )
        ) {
          //@ts-ignore
          displayPrices.bestPrice =
            this.product.priceList[Discounts.REGULAR_DISCOUNT_PREMIUM_PRICE];
          //@ts-ignore
          //add for new premium price change logic
          // now we use premiumPrice for not logged users
          displayPrices.premiumPrice =
            this.product.priceList[Discounts.REGULAR_DISCOUNT_PREMIUM_PRICE];

          return displayPrices;
        }
      }

      if (
        this.checkIsPropertyPriceIsValid(
          Discounts.QUANTITY_DISCOUNT_PREMIUM_PRICE
        )
      ) {
        //@ts-ignore
        displayPrices.premiumPrice =
          this.product.priceList[Discounts.QUANTITY_DISCOUNT_PREMIUM_PRICE];
      } else if (
        this.checkIsPropertyPriceIsValid(
          Discounts.REGULAR_DISCOUNT_PREMIUM_PRICE
        )
      ) {
        //@ts-ignore
        displayPrices.premiumPrice =
          this.product.priceList[Discounts.REGULAR_DISCOUNT_PREMIUM_PRICE];
      }

      if (this.checkIsPropertyPriceIsValid(Discounts.QUANTITY_DISCOUNT_PRICE)) {
        //@ts-ignore
        displayPrices.bestPrice =
          this.product.priceList[Discounts.QUANTITY_DISCOUNT_PRICE];

        return displayPrices;
      }

      if (this.checkIsPropertyPriceIsValid(Discounts.REGULAR_DISCOUNT_PRICE)) {
        //@ts-ignore
        displayPrices.bestPrice =
          this.product.priceList[Discounts.REGULAR_DISCOUNT_PRICE];

        return displayPrices;
      }
    }

    return displayPrices;
  }

  public checkIsPropertyPriceIsValid(propertyName: string): boolean {
    return !!(
      this.product.priceList[propertyName] &&
      Number(this.product.priceList[propertyName]) > 0
    );
  }

  private getRegularPercentageDiscounts(stickers: ISticker[]) {
    if (
      this.checkIsPropertyPriceIsValid(
        Discounts.REGULAR_DISCOUNT_PREMIUM_PERCENTAGE
      ) &&
      this.isDelfiClubUser()
    ) {
      stickers.push({
        type: 'regular',
        title: `${this.product.priceList.regularDiscountPremiumPercentage}%`,
        color: this.mapStickerTypeToColor('regular'),
        popup: {
          title: 'Popust za Premium članove',
          description: 'Popust za članove Delfi Premium programa lojalnosti'
        }
      });

      return stickers;
    }

    if (
      this.checkIsPropertyPriceIsValid(Discounts.REGULAR_DISCOUNT_PERCENTAGE)
    ) {
      stickers.push({
        type: 'regular',
        title: `${this.product.priceList.regularDiscountPercentage}%`,
        color: this.mapStickerTypeToColor('regular'),
        popup: {
          title: 'Popust za Premium članove',
          description: 'Popust za članove Delfi Premium programa lojalnosti'
        }
      });
    }

    return stickers;
  }

  private getDiscounts(stickers: ISticker[]) {
    this.getRegularPercentageDiscounts(stickers);

    if (
      this.checkIsPropertyPriceIsValid('quantityDiscountPremiumPercentage') &&
      this.isDelfiClubUser()
    ) {
      stickers.push({
        type: 'quantity',
        title: `${this.product.priceList.quantityDiscountPremiumPercentage}% za ${this.product.priceList.quantityDiscountPremiumLimit}`,
        color: this.mapStickerTypeToColor('quantity'),
        popup: {
          title: 'Količinski popust',
          description: `Premium količinski popust za ${this.product.priceList.quantityDiscountPremiumLimit} ili više artikala`
        }
      });

      return stickers;
    }

    if (
      this.checkIsPropertyPriceIsValid(Discounts.QUANTITY_DISCOUNT_PERCENTAGE)
    ) {
      stickers.push({
        type: 'quantity',
        title: `${
          this.product.priceList[Discounts.QUANTITY_DISCOUNT_PERCENTAGE]
        }% za ${this.product.priceList.quantityDiscountLimit}`,
        color: this.mapStickerTypeToColor('quantity'),
        popup: {
          title: 'Količinski popust',
          description: `količinski popust za ${this.product.priceList.quantityDiscountLimit} ili više knjiga`
        }
      });
    }

    return stickers;
  }

  private mapStickerTypeToColor(type: string) {
    const colorMandy = '#D84A00';
    const colorBlueRibbon = '#005E9E';
    switch (type) {
      case 'regular':
      case ProductActions.FIXED_DISCOUNT:
      case ProductActions.ADDITIONAL_DISCOUNT:
        return colorMandy;
      case 'quantity':
      case ProductActions.QUANTITY_DISCOUNT2:
        return colorBlueRibbon;
      case ProductActions.EXPONENTIAL_DISCOUNT:
      case ProductActions.FIXED_PRICE:
        return 'orange';
      default:
        return colorMandy;
    }
  }

  public getStickers() {
    let stickers: ISticker[] = [];

    const action = this.getAction();

    if (action) {
      if (action.actionType === ProductActions.FIXED_PRICE) {
        stickers.push({
          type: ProductActions.FIXED_PRICE,
          title: `${action.raw.fixedPriceCount} za ${action.raw.fixedPrice}`,
          color: this.mapStickerTypeToColor(ProductActions.FIXED_PRICE),
          popup: {
            title: action.actionTitle,
            description: action.raw.description
          },
          infoUrl: action.infoUrl
        });

        return stickers;
      }
      if (action.actionType === ProductActions.FIXED_DISCOUNT) {
        stickers.push({
          type: ProductActions.FIXED_DISCOUNT,
          title: `${action.raw.fixedDiscount}%`,
          color: this.mapStickerTypeToColor(ProductActions.FIXED_DISCOUNT),
          popup: {
            title: action.actionTitle,
            description: action.raw.description
          },
          infoUrl: action.infoUrl
        });

        return stickers;
      }
      if (
        action.actionType === ProductActions.EXPONENTIAL_DISCOUNT &&
        Array.isArray(action.levels) &&
        action.levels.length > 0
      ) {
        const sticks = this.getRegularPercentageDiscounts(stickers);

        sticks.push({
          type: ProductActions.EXPONENTIAL_DISCOUNT,
          title: `${
            action.levels[action.levels.length - 1].levelPercentage
          }% za ${action.levels.length}`,
          color: this.mapStickerTypeToColor(
            ProductActions.EXPONENTIAL_DISCOUNT
          ),
          popup: {
            title: action.actionTitle,
            description: action.raw.description
          },
          infoUrl: action.infoUrl
        });

        return sticks;
      }

      if (action.actionType === ProductActions.ADDITIONAL_DISCOUNT) {
        const sticks = this.getDiscounts(stickers);

        sticks.push({
          type: ProductActions.ADDITIONAL_DISCOUNT,
          title: `${action.raw.additionalDiscount}%`,
          color: this.mapStickerTypeToColor(ProductActions.ADDITIONAL_DISCOUNT),
          popup: {
            title: action.actionTitle,
            description: action.raw.description
          },
          infoUrl: action.infoUrl
        });

        return sticks;
      }

      if (action.actionType === ProductActions.QUANTITY_DISCOUNT2) {
        const sticks = this.getRegularPercentageDiscounts(stickers);

        sticks.push({
          type: ProductActions.QUANTITY_DISCOUNT2,
          title: `${action.raw.quantityDiscount2}% za ${action.raw.quantityDiscount2Limit}`,
          color: this.mapStickerTypeToColor(ProductActions.QUANTITY_DISCOUNT2),
          popup: {
            title: action.actionTitle,
            description: action.raw.description
          },
          infoUrl: action.infoUrl
        });

        return sticks;
      }
    }

    if (!action) {
      return this.getDiscounts(stickers);
    }

    return stickers;
  }
}

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchSchoolBookDataAction,
  ISchoolBookState,
  ISetSchoolBookDataAction
} from './types';

export function fetchSchoolBookDataAction(): IFetchSchoolBookDataAction {
  return {
    type: ActionTypes.SCHOOL_BOOK.FETCH_SCHOOL_BOOK_DATA
  };
}

export function setSchoolBookDataAction(
  schoolBookDataData: ISchoolBookState
): ISetSchoolBookDataAction {
  return {
    type: ActionTypes.SCHOOL_BOOK.SET_SCHOOL_BOOK_DATA,
    payload: schoolBookDataData
  };
}

export function validateSchoolBookDataAction(schoolBookData: any) {
  return {
    type: ActionTypes.SCHOOL_BOOK.VALIDATE_SCHOOL_BOOK_DATA,
    payload: schoolBookData
  };
}

export function enrichSchoolBookDataAction(schoolBookData: any) {
  return {
    type: ActionTypes.SCHOOL_BOOK.ENRICH_SCHOOL_BOOK_DATA,
    payload: schoolBookData
  };
}

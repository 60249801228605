import React, { ReactElement } from 'react';
import './scss/style.scss';

interface IProps {
  isOverlay?: boolean;
  color?: string;
  classes?: string;
}

const Spinner: React.FC<IProps> = (props: IProps): ReactElement => {
  const { isOverlay, color, classes } = props;
  return (
    <div className={classes}>
      {isOverlay ? <div className="custom-spinner" /> : null}
      <div
        className={`spinner-border ${color ? color : 'text-primary'}`}
        role="status"
      />
    </div>
  );
};

export default Spinner;

export const isTokenExpired = (date: Date) => {

  if (date) {
    const oneDayDate = new Date(Date.now() - 3600 * 1000 * 24);
    const newDate = new Date(date);
    return newDate.getTime() <= oneDayDate.getTime();
  }

  return true;
};

import { IFactory } from '../../types';
import {
  ITopListsContent,
  ITwinBannersContent
} from '../../../entities/section/types';
import TwinBannersContent from '../../../entities/section/twin-banners/TwinBannersContent';
import TwinBannerFactory from './TwinBannerFactory';

const TwinBannersContentFactory: IFactory = class TwinBannersContentFactory {
  static create(content: ITwinBannersContent) {
    return new TwinBannersContent(
      content._id,
      content.title,
      TwinBannerFactory.create(content.banner1),
      TwinBannerFactory.create(content.banner2),
      content.startAt,
      content.endAt
    );
  }
};

export default TwinBannersContentFactory;

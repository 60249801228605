import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { setStaticPageDataAction } from './actions';

const staticPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.STATIC_PAGE.FETCH_STATIC_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_STATIC_PAGE_DATA}/${action.payload}`,
            null,
            null,
            MiddlewareEntities.STATIC_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.STATIC_PAGE));
        break;
      case `${MiddlewareEntities.STATIC_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.STATIC_PAGE));
        next(setStaticPageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.STATIC_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.STATIC_PAGE));
        window.location.href = '/404';
        break;
    }
  };

export default [staticPageDataMiddleware];

import Validator from '../Validator';
import Ajv from 'ajv';
import { productOverviewSchema } from './productOverviewSchema';

export default class ProductValidator extends Validator {
  public static validate(productData: any) {
    const ajv = new Ajv();
    return ajv.validate(productOverviewSchema, productData);
  }
}

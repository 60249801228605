import { IFetchBookstoreAction, ISetBookstoreAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { IBookstore } from '../../../entities/bookstore/types';

export const fetchBookstoreAction = (
  withOldId: string
): IFetchBookstoreAction => ({
  type: ActionTypes.BOOKSTORE_OVERVIEW_PAGE.FETCH_BOOKSTORE_OVERVIEW_PAGE,
  payload: {
    withOldId
  }
});

export const validateBookstoreAction = (bookstore: IBookstore) => ({
  type: ActionTypes.BOOKSTORE_OVERVIEW_PAGE.VALIDATE_BOOKSTORE_OVERVIEW_PAGE,
  payload: {
    bookstore
  }
});

export const enrichBookstoreAction = (bookstore: IBookstore) => ({
  type: ActionTypes.BOOKSTORE_OVERVIEW_PAGE.ENRICH_BOOKSTORE_OVERVIEW_PAGE,
  payload: {
    bookstore
  }
});

export const setBookstoreAction = (
  bookstore: IBookstore
): ISetBookstoreAction => ({
  type: ActionTypes.BOOKSTORE_OVERVIEW_PAGE.SET_BOOKSTORE_OVERVIEW_PAGE,
  payload: {
    bookstore
  }
});

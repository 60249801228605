import ProfileActivity from '../../entities/profile/ProfileActivity';
import { IFactory } from '../types';
import { IProfileActivityHistory } from '../../app/store/profile/types';
import ProductFactory from '../product';

const ProfileActivityFactory: IFactory = class ProfileActivityFactory {
  static create(profileActivity: IProfileActivityHistory) {
    return new ProfileActivity(
      profileActivity._id,
      profileActivity.comment,
      profileActivity.comments,
      profileActivity.createdAt,
      profileActivity.email,
      profileActivity.ipAddress,
      profileActivity.memberId,
      profileActivity.memberName,
      profileActivity.oldId,
      ProductFactory.create(profileActivity.product),
      profileActivity.productRank,
      profileActivity.productTitle,
      profileActivity.status,
      profileActivity.title
    );
  }
};

export default ProfileActivityFactory;

import { ISetStaticPageDataAction, IStaticPageDataState } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IStaticPageDataState = {
  staticPage: {
    content: '',
    id: 0,
    description: '',
    meta_description: '',
    short_description: '',
    title: ''
  }
};
const staticPageDataReducer = (
  state = initialState,
  action: ISetStaticPageDataAction
): IStaticPageDataState => {
  switch (action.type) {
    case ActionTypes.STATIC_PAGE.SET_STATIC_PAGE_DATA:
      return {
        ...state,
        staticPage: action.payload.staticPage
      };
  }
  return state;
};

export default staticPageDataReducer;

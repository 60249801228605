import { ITwinBanner } from '../types';

export default class TwinBanner implements ITwinBanner {
  _id: string;

  img: string;

  link: string;

  constructor(id: string, img: string, link: string) {
    this._id = id;
    this.img = img;
    this.link = link;
  }
}

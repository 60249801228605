import ActionTypes from '../../../constants/ActionTypes';
import {
  GtmQuantityChangeProductData,
  ISendGtmEventAddProductAction,
  ISendGtmEventBeginCheckoutAction,
  ISendGtmEventRemoveProductAction
} from './types';
import { ICartState } from '../cart/types';

export const sendGtmEventAddProductAction = (
  gtmQuantityChangeProductData: GtmQuantityChangeProductData
): ISendGtmEventAddProductAction => ({
  type: ActionTypes.GTM.SEND_EVENT_ADD_PRODUCT,
  payload: {
    gtmQuantityChangeProductData
  }
});

export const sendGtmEventRemoveProductAction = (
  gtmQuantityChangeProductData: GtmQuantityChangeProductData
): ISendGtmEventRemoveProductAction => ({
  type: ActionTypes.GTM.SEND_EVENT_REMOVE_PRODUCT,
  payload: {
    gtmQuantityChangeProductData
  }
});

export const sendGtmEventBeginCheckoutAction = (
  cartState: ICartState
): ISendGtmEventBeginCheckoutAction => ({
  type: ActionTypes.GTM.SEND_EVENT_BEGIN_CHECKOUT,
  payload: {
    cartState
  }
});
import {
  IRussianBooksPageState,
  ISetRussianBooksPageDataAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IRussianBooksPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const russianBooksPageDataReducer = (
  state = initialState,
  action: ISetRussianBooksPageDataAction
): IRussianBooksPageState => {
  switch (action.type) {
    case ActionTypes.RUSSIAN_BOOKS_PAGE.SET_RUSSIAN_BOOKS_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default russianBooksPageDataReducer;

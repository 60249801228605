/* eslint-disable @typescript-eslint/brace-style */

import {
  IThreePartCarouselContent,
  IThreePartCarouselItem,
  PriceDisplayTypes
} from '../types';

export default class ThreePartCarouselContent
  implements IThreePartCarouselContent
{
  _id: string;

  title: string;

  priceDisplayType: PriceDisplayTypes;

  slide1: IThreePartCarouselItem;

  slide2: IThreePartCarouselItem;

  slide3: IThreePartCarouselItem;

  startAt: string | null;

  endAt: string | null;

  constructor(
    id: string,
    title: string,
    priceDisplayType: PriceDisplayTypes,
    slide1: IThreePartCarouselItem,
    slide2: IThreePartCarouselItem,
    slide3: IThreePartCarouselItem,
    startAt: string | null,
    endAt: string | null
  ) {
    this._id = id;
    this.title = title;
    this.priceDisplayType = priceDisplayType;
    this.slide1 = slide1;
    this.slide2 = slide2;
    this.slide3 = slide3;
    this.startAt = startAt;
    this.endAt = endAt;
  }
}

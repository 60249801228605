import { Link } from 'react-router-dom';
import CarouselSlider from '../carousel';
import React, { FC } from 'react';
import { ITextSlider } from './types';
import { Settings } from 'react-slick';
import './scss/style.scss';

interface IProps {
  textSliders: ITextSlider[];
  settings: Settings;
}

const TextSlider: FC<IProps> = ({ textSliders, settings }: IProps) => {
  return (
    <div className="text-slider-wrapper">
      <CarouselSlider settings={settings}>
        {textSliders.map((item: any, index: number) => (
          <Link to={item.link} className="text-decoration-none" key={item.id}>
            <p className="line-height-20 h6-mod1 text-14 d-inline">
              {item.text}
            </p>
          </Link>
        ))}
      </CarouselSlider>
    </div>
  );
};

export default TextSlider;

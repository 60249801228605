import {
  IBookstore,
  IBookstoreAddress,
  ILocation,
  IPrepareWorkingHoursLabels,
  IWorkingHours
} from './types';

export default class Bookstore implements IBookstore {
  _id: string;

  oldId: number;

  stockStatus: boolean;

  status: boolean;

  imgGallery: string[];

  img: string;

  location: ILocation;

  workingHours: IWorkingHours;

  identAks: string;

  embedMap: string;

  email: string;

  tel: string;

  address: IBookstoreAddress;

  mp: string;

  bookstoreName: string;

  workingHoursLabels: IPrepareWorkingHoursLabels;

  navigateTo: string;

  constructor(
    id: string,
    oldId: number,
    stockStatus: boolean,
    status: boolean,
    imgGallery: string[],
    img: string,
    location: ILocation,
    workingHours: IWorkingHours,
    identAks: string,
    embedMap: string,
    email: string,
    tel: string,
    address: IBookstoreAddress,
    mp: string,
    bookstoreName: string,
    workingHoursLabels: IPrepareWorkingHoursLabels,
    navigateTo: string
  ) {
    this._id = id;
    this.oldId = oldId;
    this.stockStatus = stockStatus;
    this.status = status;
    this.imgGallery = imgGallery;
    this.img = img;
    this.location = location;
    this.workingHours = workingHours;
    this.identAks = identAks;
    this.embedMap = embedMap;
    this.email = email;
    this.tel = tel;
    this.address = address;
    this.mp = mp;
    this.bookstoreName = bookstoreName;
    this.workingHoursLabels = workingHoursLabels;
    this.navigateTo = navigateTo;
  }
}

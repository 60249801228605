export const logoWidgetCarouselsSchema: any = {
  $id: 'logowidgetcarousels',
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        title: { type: 'string' },
        status: { type: 'boolean' },
        items: {
          type: 'array',
          minItems: 1,
          items: {
            type: 'object',
            properties: {
              url: { type: 'string' },
              img: { type: 'string' },
              altImg: { type: 'string' }
            },
            required: ['url', 'img', 'altImg']
          }
        }
      },
      required: ['_id', 'title', 'items']
    }
  },
  required: ['type', 'content']
};

import { IFactory } from '../types';
import News from '../../entities/news/News';
import { replaceDotWithUrl } from '../../utilites/product';
import { formatDateWithDots } from '../../utilites';
import Config from '../../config';
import noImageBookstore from '../../views/assets/images/no-images/no-image-bookstore.png';

const NewsFactory: IFactory = class NewsFactory {
  private static addMediaUrlToImage(imageSrc: string | null | undefined) {
    return imageSrc ? `${Config.api.mediaUrl}${imageSrc}` : noImageBookstore;
  }

  private static addMediaUrlToImageGallery(imageGallery?: string[] | null) {
    if (imageGallery) {
      return imageGallery.map((imageSrc: string) => {
        return (imageSrc = this.addMediaUrlToImage(imageSrc));
      });
    }

    return [];
  }

  public static create(news: News) {
    return new News(
      news._id,
      news.actions,
      news.authors,
      news.products,
      news.content,
      replaceDotWithUrl(news.img1),
      news.img2 ? replaceDotWithUrl(news.img2) : '',
      news.img3 ? replaceDotWithUrl(news.img3) : '',
      news.createdAt ? formatDateWithDots(new Date(news.createdAt)) : '',
      formatDateWithDots(new Date(news.dateRangeStart)),
      formatDateWithDots(new Date(news.dateRangeEnd)),
      news.rb,
      news.status,
      news.title,
      news.oldId,
      this.addMediaUrlToImageGallery(news.imgGallery),
      news.metaTitle,
      news.metaDescription
    );
  }
};

export default NewsFactory;

import React, {
  CSSProperties,
  FC,
  ReactChildren,
  ReactElement,
  ReactNode
} from 'react';
import RoundedCircleNumber from '../rounded-circle-number';
import './scss/style.scss';

interface IProps {
  icon: ReactElement | ReactNode;
  label?: string;
  iconDirection?: 'left' | 'right';
  classes?: string;
  styleType?: CSSProperties;
  children?: ReactElement | ReactNode | ReactChildren;
  roundedIcon?: boolean;
  handleOnClick?: () => void;
}

const Tab: FC<IProps> = ({
  icon,
  label,
  iconDirection = 'left',
  classes,
  styleType,
  children,
  roundedIcon
}: IProps) => {
  return (
    <div
      className={`${classes} align-items-center p-3 cursor-pointer d-flex`}
      style={styleType}
    >
      <div className="col-auto">
        {iconDirection === 'left' ? icon : null}
        {label && (
          <div
            className={`${
              iconDirection === 'left' ? 'ms-3' : 'me-2'
            } jost-extra-bold text-14 text-uppercase d-inline position-relative`}
          >
            {label}
            {roundedIcon && (
              <RoundedCircleNumber
                numberOfItems={1}
                classes="rounded-profile-notification background-torch-red color-white"
              />
            )}
          </div>
        )}
        {iconDirection === 'right' ? icon : null}
      </div>
      {children}
    </div>
  );
};

export default Tab;

import ActionTypes from '../../../constants/ActionTypes';
import {
  ISetNewsLetterUnsubscribeConfirmationMessageAction,
  ISetNewsLetterUnsubscribeMessageAction,
  IUnsubscribeNewsletterState
} from './types';

const initialState: IUnsubscribeNewsletterState = {
  unsubscribeMessage: '',
  confirmUnsubscribeMessage: '',
  status: 0
};

const newsletterUnsubscribeReducer = (
  state = initialState,
  action:
    | ISetNewsLetterUnsubscribeMessageAction
    | ISetNewsLetterUnsubscribeConfirmationMessageAction
): IUnsubscribeNewsletterState => {
  switch (action.type) {
    case ActionTypes.NEWSLETTER.SET_UNSUBSCRIBE_NEWSLETTER_MESSAGE:
      return {
        ...state,
        unsubscribeMessage: action.payload.message,
        status: (action as ISetNewsLetterUnsubscribeMessageAction).payload
          .status
      };
    case ActionTypes.NEWSLETTER.SET_NEWSLETTER_UNSUBSCRIBE_CONFIRMATION_MESSAGE:
      return {
        ...state,
        confirmUnsubscribeMessage: action.payload.message
      };
  }

  return state;
};

export default newsletterUnsubscribeReducer;

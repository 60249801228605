import { ISectionPageState } from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { AnyAction } from 'redux';

const initialState: ISectionPageState = {
  content: null
};
const sectionPageReducer = (
  state = initialState,
  action: AnyAction
): ISectionPageState => {
  switch (action.type) {
    case ActionTypes.SECTION_PAGE.SET_SECTION_PAGE_DATA:
      return {
        content: {
          data: action.payload.content.content
        }
      };
  }
  return state;
};

export default sectionPageReducer;

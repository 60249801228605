import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichMagyarPageDataAction,
  IFetchMagyarPageDataAction,
  IMagyarPageState,
  ISetMagyarPageDataAction,
  IValidateMagyarPageDataAction
} from './types';

export function fetchMagyarPageDataAction(): IFetchMagyarPageDataAction {
  return {
    type: ActionTypes.MAGYAR_PAGE.FETCH_MAGYAR_PAGE_DATA
  };
}

export function setMagyarPageDataAction(
  magyarPageData: IMagyarPageState
): ISetMagyarPageDataAction {
  return {
    type: ActionTypes.MAGYAR_PAGE.SET_MAGYAR_PAGE_DATA,
    payload: magyarPageData
  };
}

export function validateMagyarPageDataAction(
  magyarPageData: IMagyarPageState
): IValidateMagyarPageDataAction {
  return {
    type: ActionTypes.MAGYAR_PAGE.VALIDATE_MAGYAR_PAGE_DATA,
    payload: magyarPageData
  };
}

export function enrichMagyarPageDataAction(
  magyarPageData: any
): IEnrichMagyarPageDataAction {
  return {
    type: ActionTypes.MAGYAR_PAGE.ENRICH_MAGYAR_PAGE_DATA,
    payload: magyarPageData
  };
}

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import CookieNames from '../../../constants/CookieNames';
import {
  enrichWishlistAction,
  saveWishlistRequestAction,
  setToWishListAction,
  setWishlistFromApiAction,
  validateWishListAction
} from './actions';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import ProductFactory from '../../../factories/product';
import { IProduct } from '../../../entities/product/types';
import WishListProductValidator from '../../../validators/product/WishListProductValidator';
import { getItem, setItem } from '../../../utilites/localStorage';

const wishListCookieMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.WISHLIST.ADD_TO_WISHLIST:
        let productIds = getItem(CookieNames.WISHLIST);

        if (!productIds) {
          setItem(CookieNames.WISHLIST, [action.payload.productId]);

          next(setToWishListAction([action.payload.productId]));
          return next(saveWishlistRequestAction());
        }

        if (
          Array.isArray(productIds) &&
          !productIds.includes(action.payload.productId)
        ) {
          productIds.push(action.payload.productId);

          setItem(CookieNames.WISHLIST, productIds);
          next(setToWishListAction(productIds));
          return next(saveWishlistRequestAction());
        }

        if (
          Array.isArray(productIds) &&
          productIds.includes(action.payload.productId)
        ) {
          productIds = productIds.filter(
            (id: string) => id !== action.payload.productId
          );

          setItem(CookieNames.WISHLIST, productIds);
          next(setToWishListAction(productIds));
          next(saveWishlistRequestAction());
        }

        break;

      case ActionTypes.WISHLIST.FETCH_WISHLIST_FROM_COOKIE:
        const productsId = getItem(CookieNames.WISHLIST);

        if (productsId) {
          next(setToWishListAction(productsId));
        }
        break;

      case ActionTypes.WISHLIST.SET_INITIAL_WISHLIST:
        setItem(CookieNames.WISHLIST, action.payload);
        break;

      case ActionTypes.WISHLIST.REMOVE_FROM_WISHLIST:
        const prIds = getItem(CookieNames.WISHLIST);
        const filterProductIds = prIds.filter(
          (productId: string) => productId !== action.payload.productId
        );

        if (prIds) {
          setItem(CookieNames.WISHLIST, filterProductIds);
          next(setToWishListAction(filterProductIds));
          next(saveWishlistRequestAction());
        }
        break;
    }
  };

const fetchWishListMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.WISHLIST.FETCH_WISHLIST_REQUEST:
        next(
          apiRequest(
            null,
            'POST',
            ApiUrls.WISHLIST,
            null,
            { browserWishlist: getItem(CookieNames.WISHLIST) },
            MiddlewareEntities.WISHLIST,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.WISHLIST));
        break;
      case `${MiddlewareEntities.WISHLIST} ${ActionTypes.API_SUCCESS}`:
        next(validateWishListAction(action.payload.data.wishListProducts.data));
        next(setLoader(false, MiddlewareEntities.WISHLIST));
        break;
    }
  };

const validateWishListMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.WISHLIST.VALIDATE_WISHLIST)) {
      const productsFromApi = action.payload.products;
      if (productsFromApi) {
        const products = productsFromApi.filter((product: IProduct) => {
          if (WishListProductValidator.validate(product)) {
            return product;
          }
        });

        return next(enrichWishlistAction(products));
      }

      return next(enrichWishlistAction([]));
    }
  };

const enrichWishListMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.WISHLIST.ENRICH_WISHLIST) {
      const prIds = action.payload.products.map((product: any) => {
        return product._id;
      });

      setItem(CookieNames.WISHLIST, prIds);
      next(setToWishListAction(prIds));

      const products = action.payload.products.map((product: IProduct) => {
        return ProductFactory.create(product);
      });
      next(setWishlistFromApiAction(products));
    }
  };

const saveWishListMiddleware: Middleware =
  (store) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.WISHLIST.SAVE_TO_WISHLIST_REQUEST:
        if (store.getState().user.member.user) {
          next(
            apiRequest(
              null,
              'PATCH',
              ApiUrls.SAVE_TO_WISHLIST_REQUEST,
              null,
              { browserWishlist: getItem(CookieNames.WISHLIST) },
              MiddlewareEntities.SAVE_TO_WISHLIST,
              {}
            )
          );
        }
        break;
    }
  };

export default [
  wishListCookieMiddleware,
  fetchWishListMiddleware,
  validateWishListMiddleware,
  enrichWishListMiddleware,
  saveWishListMiddleware
];

import { IFactory } from '../types';
import { ISection } from '../../entities/section/types';
import SectionContent from '../../entities/section/SectionContent';

const SectionFactory: IFactory = class SectionContentFactory {
  static create(section: ISection) {
    return new SectionContent(section.type, section.content);
  }
};

export default SectionFactory;

/* eslint-disable @typescript-eslint/brace-style */
import { IWidePromoBannersContent } from '../types';

export default class WidePromoBannersContent
  implements IWidePromoBannersContent
{
  _id: string;

  buttonLabel: string;

  description: string;

  img: string;

  mobileImg: string;

  title: string;

  url: string;

  titleColor: string;

  descriptionColor: string;

  startAt: string | null;

  endAt: string | null;

  constructor(
    id: string,
    buttonLabel: string,
    description: string,
    img: string,
    mobileImg: string,
    title: string,
    url: string,
    titleColor: string,
    descriptionColor: string,
    startAt: string | null,
    endAt: string | null
  ) {
    this._id = id;
    this.buttonLabel = buttonLabel;
    this.description = description;
    this.img = img;
    this.mobileImg = mobileImg;
    this.title = title;
    this.url = url;
    this.titleColor = titleColor;
    this.descriptionColor = descriptionColor;
    this.startAt = startAt;
    this.endAt = endAt;
  }
}

import ActionTypes from '../../../constants/ActionTypes';
import { INewsState, ISetNewsOverviewAction } from './types';

const initialState: INewsState = {
  data: null
};

const newsOverviewReducer = (
  state = initialState,
  action: ISetNewsOverviewAction
): INewsState => {
  switch (action.type) {
    case ActionTypes.NEWS_OVERVIEW.SET_NEWS_OVERVIEW:
      return {
        ...state,
        data: action.payload.news
      };
  }

  return state;
};

export default newsOverviewReducer;

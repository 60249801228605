import { ISetStarWayDataAction, IStarWayState } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IStarWayState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const starWayDataReducer = (
  state = initialState,
  action: ISetStarWayDataAction
): IStarWayState => {
  switch (action.type) {
    case ActionTypes.STARWAY_PAGE.SET_STARWAY_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default starWayDataReducer;

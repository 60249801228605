import React, { FC, ReactElement } from 'react';
import BreadCrumbItem from './BreadCrumbItem';

interface IProps {
  items: any[];
}

const Breadcrumbs: FC<IProps> = ({ items }: IProps): ReactElement => {
  return (
    <nav aria-label="breadcrumb" className="text-14 jost-semi-bold">
      <ol className="breadcrumb justify-content-center text-center">
        {items.map((item: any, index) => {
          return <BreadCrumbItem key={index} item={item} />;
        })}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;

import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchKidsBookDataAction,
  IKidsBookState,
  ISetKidsBookAction
} from './types';

export function requestFetchKidsBookDataAction(): IFetchKidsBookDataAction {
  return {
    type: ActionTypes.KIDS_BOOK.FETCH_KIDS_BOOK_DATA
  };
}

export function setKidsBookAction(
  kidsBookData: IKidsBookState
): ISetKidsBookAction {
  return {
    type: ActionTypes.KIDS_BOOK.SET_KIDS_BOOK_DATA,
    payload: kidsBookData
  };
}

export function validateKidsBookDataAction(kidsBookData: any) {
  return {
    type: ActionTypes.KIDS_BOOK.VALIDATE_KIDS_BOOK_DATA,
    payload: kidsBookData
  };
}

export function enrichKidsBookDataAction(kidsBookData: any) {
  return {
    type: ActionTypes.KIDS_BOOK.ENRICH_KIDS_BOOK_DATA,
    payload: kidsBookData
  };
}

import { FC } from 'react';
import { formatPrice } from '../../../utilites/product';

interface IProps {
  fullPrice: Number | string;
  classes?: string;
}

const FullPrice: FC<IProps> = ({ fullPrice, classes }: IProps) => {
  return (
    <span
      className={`card-price jost-semi-bold text-16 d-block font-color-default ${classes}`}
    >
      {formatPrice(fullPrice, 2)} RSD
    </span>
  );
};

export default FullPrice;

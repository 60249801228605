import React, { FC } from 'react';
import { ProductTypes } from '../../../constants/ProductTypes';
import DiscountPrice from './DiscountPrice';
import FullPrice from './FullPrice';
import { IDisplayPrices } from '../../../entities/product/types';
import { PriceDisplayTypes } from '../../../entities/section/types';
import CollectionPrice from './CollectionPrice';

interface IProps {
  productType: string;
  displayPrices: IDisplayPrices;
  priceDisplayType?: PriceDisplayTypes;
  isNotRenderIsPremium?: boolean;
  isOnCart?: boolean;
  isOnAction?: boolean;
  priceWrapperClasses?: string;
  fullPriceClasses?: string;
  bestPriceClasses?: string;
}

const ProductPrice: FC<IProps> = ({
  productType,
  displayPrices,
  priceDisplayType,
  isNotRenderIsPremium,
  isOnCart,
  isOnAction,
  priceWrapperClasses,
  fullPriceClasses,
  bestPriceClasses
}: IProps) => {
  return (
    <p className={`card-text line-height-18 ${priceWrapperClasses}`}>
      {(() => {
        if (productType === ProductTypes.COLLECTION) {
          return (
            <CollectionPrice
              fullPrice={displayPrices.fullPrice}
              collectionPrice={displayPrices.collectionPrice}
            />
          );
        }

        if (
          priceDisplayType === PriceDisplayTypes.eBook &&
          displayPrices.ebookPrice
        ) {
          return (
            // <DiscountPrice
            //   fullPrice={displayPrices.fullPrice}
            //   bestPrice={displayPrices.ebookPrice}
            // />

            <FullPrice fullPrice={displayPrices.ebookPrice} />
          );
        }

        if (
          isOnCart &&
          displayPrices.bestPrice &&
          displayPrices.bestPrice > 0 &&
          displayPrices.fullPrice > displayPrices.bestPrice
        ) {
          return (
            <>
              <DiscountPrice
                fullPrice={displayPrices.fullPrice}
                bestPrice={displayPrices.bestPrice}
                productType={productType}
                isOnCart={isOnCart}
              />
            </>
          );
        }

        if (
          displayPrices.premiumPrice &&
          displayPrices.premiumPrice > 0 &&
          displayPrices.fullPrice > displayPrices.premiumPrice
        ) {
          return (
            <>
              <span className="d-block mb-2">
                {!isOnAction && !isNotRenderIsPremium ? (
                  <>
                    <span className="text-decoration-none jost-semi-bold text-9 text-uppercase color-blue">
                      Premium cena i do:
                    </span>
                  </>
                ) : !isNotRenderIsPremium ? (
                  <span className="text-9 jost-semi-bold text-uppercase">
                    Akcijska cena i do:
                  </span>
                ) : null}
              </span>
              <DiscountPrice
                fullPrice={displayPrices.fullPrice}
                bestPrice={displayPrices.premiumPrice}
                productType={productType}
              />
            </>
          );
        }

        return <FullPrice fullPrice={displayPrices.fullPrice} />;
      })()}
    </p>
  );
};

export default ProductPrice;

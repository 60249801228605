import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchBooktokPageDataAction,
  IValidateBooktokPageDataAction,
  IEnrichBooktokPageDataAction,
  ISetBooktokPageDataAction,
  IBooktokPageState
} from './types';

export function fetchBooktokPageDataAction(): IFetchBooktokPageDataAction {
  return {
    type: ActionTypes.BOOKTOK_PAGE.FETCH_BOOKTOK_PAGE_DATA
  };
}

export function setBooktokPageDataAction(
  booktokPageData: IBooktokPageState
): ISetBooktokPageDataAction {
  return {
    type: ActionTypes.BOOKTOK_PAGE.SET_BOOKTOK_PAGE_DATA,
    payload: booktokPageData
  };
}

export function validateBooktokPageDataAction(
  booktokPageData: IBooktokPageState
): IValidateBooktokPageDataAction {
  return {
    type: ActionTypes.BOOKTOK_PAGE.VALIDATE_BOOKTOK_PAGE_DATA,
    payload: booktokPageData
  };
}

export function enrichBooktokPageDataAction(
  booktokPageData: any
): IEnrichBooktokPageDataAction {
  return {
    type: ActionTypes.BOOKTOK_PAGE.ENRICH_BOOKTOK_PAGE_DATA,
    payload: booktokPageData
  };
}

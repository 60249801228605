import ActionTypes from '../../../constants/ActionTypes';
import { IFiltersData, ISetFiltersDataAction } from './types';

const initialState: IFiltersData = {
  genresByCategories: [],
  booksForKids: [],
  publishers: []
};
const filtersDataReducer = (
  state = initialState,
  action: ISetFiltersDataAction
): IFiltersData => {
  switch (action.type) {
    case ActionTypes.FILTERS_DATA.SET_FILTERS_DATA:
      return {
        ...state,
        genresByCategories: action.payload.filtersData.genresByCategories,
        booksForKids: action.payload.filtersData.booksForKids,
        publishers: action.payload.filtersData.publishers
      };
  }
  return state;
};

export default filtersDataReducer;

import { IFactory } from '../../types';
import WidePromoBannersContent from '../../../entities/section/wide-promo-banners/WidePromoBannersContent';
import { IWidePromoBannersContent } from '../../../entities/section/types';
import { replaceDotWithUrl } from '../../../utilites/product';

const WidePromoBannersContentFactory: IFactory = class TwinBannersContentFactory {
  static create(widePromoBannersContent: IWidePromoBannersContent) {
    return new WidePromoBannersContent(
      widePromoBannersContent._id,
      widePromoBannersContent.buttonLabel,
      widePromoBannersContent.description,
      replaceDotWithUrl(widePromoBannersContent.img),
      replaceDotWithUrl(widePromoBannersContent.mobileImg),
      widePromoBannersContent.title,
      widePromoBannersContent.url,
      widePromoBannersContent.titleColor,
      widePromoBannersContent.descriptionColor,
      widePromoBannersContent.startAt,
      widePromoBannersContent.endAt
    );
  }
};

export default WidePromoBannersContentFactory;

import { IFactory } from '../types';
import { IGenre } from '../../entities/genre/types';
import Genre from '../../entities/genre/Genre';

const GenreFactory: IFactory = class GenreFactory {
  static create(genre: IGenre) {
    return new Genre(
      genre._id,
      genre.oldId,
      genre.genreName,
      genre.status,
      genre.description,
      genre.shortDescription,
      genre.metaTitle,
      genre.metaDescription
    );
  }
};

export default GenreFactory;

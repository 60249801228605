import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichFilmPageDataAction,
  setFilmPageDataAction,
  validateFilmPageDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchFilmPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.FILM_PAGE.FETCH_FILM_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_FILM_PAGE_DATA,
            { ...action.params },
            null,
            MiddlewareEntities.FILM_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.FILM_PAGE));
        break;
      case `${MiddlewareEntities.FILM_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.FILM_PAGE));
        next(validateFilmPageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.FILM_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.FILM_PAGE));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.FILM_PAGE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateFilmPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.FILM_PAGE.VALIDATE_FILM_PAGE_DATA)) {
      next(
        enrichFilmPageDataAction({
          sections: SectionDataFacade.generate(action.payload.sections),
          metaDescription: action.payload.metaDescription
        })
      );
    }
  };

export const enrichFilmPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.FILM_PAGE.ENRICH_FILM_PAGE_DATA) {
      next(setFilmPageDataAction(action.payload));
    }
  };

export default [
  fetchFilmPageDataMiddleware,
  validateFilmPageDataMiddleware,
  enrichFilmPageDataMiddleware
];

import { IFactory } from '../../types';
import { IThreePartCarouselContent } from '../../../entities/section/types';
import ThreePartCarouselsContent from '../../../entities/section/three-part-carousels/ThreePartCarouselsContent';
import ThreePartCarouselsFactory from './ThreePartCarouselsFactory';

const ThreePartCarouselsContentFactory: IFactory = class ThreePartCarouselsContentFactory {
  static create(content: IThreePartCarouselContent) {
    return new ThreePartCarouselsContent(
      content._id,
      content.title,
      content.priceDisplayType,
      ThreePartCarouselsFactory.create(content.slide1),
      ThreePartCarouselsFactory.create(content.slide2),
      ThreePartCarouselsFactory.create(content.slide3),
      content.startAt,
      content.endAt
    );
  }
};

export default ThreePartCarouselsContentFactory;

import { Dispatch } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { ICreateFiltersActions, ISetSearchParamsActions } from './types';

export function createSearchParamsAction(params: any): ICreateFiltersActions {
  return {
    type: ActionTypes.SEARCH_PARAMS.CREATE_SEARCH_PARAMS,
    payload: params
  };
}

export function setSearchParamsAction(params: any): ISetSearchParamsActions {
  return {
    type: ActionTypes.SEARCH_PARAMS.SET_SEARCH_PARAMS,
    payload: {
      items: params
    }
  };
}

export const clearSearchParamsAction = () => (dispatch: Dispatch) => {
  return dispatch({
    type: ActionTypes.SEARCH_PARAMS.CLEAR_SEARCH_PARAMS
  });
};

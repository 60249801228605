import Validator from '../Validator';
import Ajv from 'ajv';
import { productSchema } from './productSchema';

export default class WishListProductValidator extends Validator {
  public static validate(productData: any) {
    const ajv = new Ajv();
    return ajv.validate(productSchema, productData);
  }
}

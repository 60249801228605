import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichSectionPageDataAction,
  setSectionPageDataAction,
  validateSectionPageDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';

export const fetchSectionProductsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.SECTION_PAGE.FETCH_SECTION_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_SECTION_PRODUCTS}/${action.payload.type}/element/${action.payload.id}`,
            null,
            { sectionType: action.payload.type },
            MiddlewareEntities.SECTION_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.SECTION_PAGE));
        break;
      case `${MiddlewareEntities.SECTION_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.SECTION_PAGE));
        next(
          validateSectionPageDataAction(
            action.payload.meta.data.sectionType,
            action.payload.data
          )
        );
        break;
      case `${MiddlewareEntities.SECTION_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.SECTION_PAGE));
        window.location.href = '/404';
        break;
    }
  };

export const validateSectionPageMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.SECTION_PAGE.VALIDATE_SECTION_PAGE_DATA)
    ) {
      const section = {
        type: action.payload.type,
        content: action.payload.content
      };

      next(
        enrichSectionPageDataAction(SectionDataFacade.getValidateItem(section))
      );
    }
  };

export const enrichSectionPageMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.SECTION_PAGE.ENRICH_SECTION_PAGE_DATA) {
      const content = SectionDataFacade.generateSectionItem(
        action.payload.content
      );
      next(setSectionPageDataAction(content));
    }
  };

export default [
  fetchSectionProductsMiddleware,
  validateSectionPageMiddleware,
  enrichSectionPageMiddleware
];

import ActionTypes from '../../../constants/ActionTypes';
import { ISetWishListAction, ISetWishListApiAction, IWishListApiState, IWishListCookieState } from './types';
import { AnyAction, combineReducers } from 'redux';
import { IProduct } from '../../../entities/product/types';

const initialWishListCookieState: IWishListCookieState = {
  productIds: []
};

const initialWishListApiState: IWishListApiState = {
  products: []
};

const wishListCookieReducer = (
  state = initialWishListCookieState,
  action: ISetWishListAction
): IWishListCookieState => {
  switch (action.type) {
    case ActionTypes.WISHLIST.SET_WISHLIST:
      return {
        ...state,
        productIds: action.payload.productIds
      };
  }

  return state;
};

const wishListApiReducer = (state = initialWishListApiState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.WISHLIST.SET_WISHLIST_FROM_API:
      return {
        ...state,
        products: action.payload.products
      };
    case ActionTypes.WISHLIST.REMOVE_FROM_WISHLIST:
      return {
        ...state,
        products: state.products.filter( (product:IProduct) => product.id !== action.payload.productId)
      };
  }

  return state;
};

const wishListReducer = combineReducers({
  productIds: wishListCookieReducer,
  products: wishListApiReducer,
});

export default wishListReducer;

import { ISection } from './types';

export default class SectionContent implements ISection {
  type: string;

  content: any;

  constructor(type: string, content: any) {
    this.type = type;
    this.content = content;
  }
}

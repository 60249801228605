import ActionTypes from '../../../constants/ActionTypes';
import {
  IFetchFilmPageDataAction,
  IFilmPageState,
  ISetFilmPageDataAction
} from './types';

export function fetchFilmPageDataAction(): IFetchFilmPageDataAction {
  return {
    type: ActionTypes.FILM_PAGE.FETCH_FILM_PAGE_DATA
  };
}

export function setFilmPageDataAction(
  filmPageData: IFilmPageState
): ISetFilmPageDataAction {
  return {
    type: ActionTypes.FILM_PAGE.SET_FILM_PAGE_DATA,
    payload: filmPageData
  };
}

export function validateFilmPageDataAction(filmPageData: IFilmPageState) {
  return {
    type: ActionTypes.FILM_PAGE.VALIDATE_FILM_PAGE_DATA,
    payload: filmPageData
  };
}

export function enrichFilmPageDataAction(filmPageData: any) {
  return {
    type: ActionTypes.FILM_PAGE.ENRICH_FILM_PAGE_DATA,
    payload: filmPageData
  };
}

import {
  IAddCommentAction,
  IAddCommentBody,
  IFetchAllCommentsAction,
  ISetAddCommentStatusAction,
  ISetCommentsAction
} from './types';
import ActionTypes from '../../../constants/ActionTypes';
import { ITopComment } from '../../../entities/product/types';

export function fetchAllCommentsAction(
  productId: string | number
): IFetchAllCommentsAction {
  return {
    type: ActionTypes.COMMENTS.FETCH_ALL_COMMENTS,
    payload: {
      productId
    }
  };
}

export function setCommentsAction(comments: ITopComment[]): ISetCommentsAction {
  return {
    type: ActionTypes.COMMENTS.SET_ALL_COMMENTS,
    payload: {
      comments
    }
  };
}

export function addCommentAction(
  productId: string | number,
  addCommentBody: IAddCommentBody
): IAddCommentAction {
  return {
    type: ActionTypes.COMMENTS.ADD_COMMENT,
    payload: {
      productId,
      addCommentBody
    }
  };
}

export function setAddCommentStatusAction(
  status: string
): ISetAddCommentStatusAction {
  return {
    type: ActionTypes.COMMENTS.SET_ADD_COMMENT_STATUS,
    payload: {
      status
    }
  };
}

import ActionTypes from '../../../constants/ActionTypes';
import { IFetchNewsOverviewAction, ISetNewsOverviewAction } from './types';
import News from '../../../entities/news/News';

export const fetchNewsOverview = (
  newsId: string
): IFetchNewsOverviewAction => ({
  type: ActionTypes.NEWS_OVERVIEW.FETCH_NEWS_OVERVIEW,
  payload: {
    newsId
  }
});

export const setNewsOverview = (news: News): ISetNewsOverviewAction => ({
  type: ActionTypes.NEWS_OVERVIEW.SET_NEWS_OVERVIEW,
  payload: {
    news
  }
});

export const validateNewsOverview = (news: News) => ({
  type: ActionTypes.NEWS_OVERVIEW.VALIDATE_NEWS_OVERVIEW,
  payload: {
    news
  }
});

export const enrichNewsOverview = (news: News) => ({
  type: ActionTypes.NEWS_OVERVIEW.ENRICH_NEWS_OVERVIEW,
  payload: {
    news
  }
});

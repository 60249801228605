import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import { setFiltersDataAction } from './actions';

export const fetchFiltersDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.FILTERS_DATA.FETCH_FILTERS_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.FETCH_FILTERS_DATA,
            null,
            null,
            MiddlewareEntities.FILTERS_DATA,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.FILTERS_DATA));
        break;
      case `${MiddlewareEntities.FILTERS_DATA} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.FILTERS_DATA));
        next(setFiltersDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.FILTERS_DATA} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.FILTERS_DATA));
        break;
    }
  };

export default [fetchFiltersDataMiddleware];

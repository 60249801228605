import { IProfileActivityHistory } from '../../app/store/profile/types';
import { IProduct } from '../product/types';

export default class ProfileActivity implements IProfileActivityHistory {
  _id: string;

  comment: string;

  comments: any[];

  createdAt: Date;

  email: string;

  ipAddress: string;

  memberId: number;

  memberName: string;

  oldId: number;

  product: IProduct;

  productRank: number;

  productTitle: string;

  status: boolean;

  title: string;

  constructor(
    id: string,
    comment: string,
    comments: any[],
    createdAt: Date,
    email: string,
    ipAddress: string,
    memberId: number,
    memberName: string,
    oldId: number,
    product: IProduct,
    productRank: number,
    productTitle: string,
    status: boolean,
    title: string
  ) {
    this._id = id;
    this.comment = comment;
    this.comments = comments;
    this.createdAt = createdAt;
    this.email = email;
    this.ipAddress = ipAddress;
    this.memberId = memberId;
    this.memberName = memberName;
    this.oldId = oldId;
    this.product = product;
    this.productRank = productRank;
    this.productTitle = productTitle;
    this.status = status;
    this.title = title;
  }
}

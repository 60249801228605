import apiMiddleware from '../../api/middleware';
import errorHandlingMiddleware from '../../error-handling/middleware';
import { notificationMiddleware } from '../../notification/middleware';
import cacheMiddleware from '../../cache/middleware';

export const coreProviderMiddleware = [
  apiMiddleware,
  errorHandlingMiddleware,
  cacheMiddleware,
  notificationMiddleware
];

import { IThreeCollectionCarouselsContent } from '../../../entities/section/types';
import { IFactory } from '../../types';
import ThreeCollectionCarouselsContent from '../../../entities/section/threecollectioncarousels/ThreeCollectionCarouselsContent';
import ThreeCollectionCarouselsGroupFactory from './ThreeCollectionCarouselsGroupFactory';

const ThreeCollectionCarouselsContentFactory: IFactory = class ThreeCollectionCarouselsContentFactory {
  static create(
    content: IThreeCollectionCarouselsContent
  ): ThreeCollectionCarouselsContent {
    return new ThreeCollectionCarouselsContent(
      content._id,
      content.title,
      content.priceDisplayType,
      content.startAt,
      content.endAt,
      ThreeCollectionCarouselsGroupFactory.create(content.group1),
      ThreeCollectionCarouselsGroupFactory.create(content.group2),
      ThreeCollectionCarouselsGroupFactory.create(content.group3)
    );
  }
};

export default ThreeCollectionCarouselsContentFactory;

import { IFetchTextSlidersAction, ISetTextSlidersAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

export const fetchTextSliders = (): IFetchTextSlidersAction => ({
  type: ActionTypes.TEXT_SLIDERS.FETCH_TEXT_SLIDERS,
});

export const setTextSliderItems = (items: any[]): ISetTextSlidersAction => ({
  type: ActionTypes.TEXT_SLIDERS.SET_TEXT_SLIDERS,
  payload: {
    items
  }
});

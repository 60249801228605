import { ILogoWidgetCarousel, ILogoWidgetCarouselItem } from '../types';

export class LogoWidgetCarouselItem implements ILogoWidgetCarouselItem {
  url: string;

  img: string;

  altImg: string;

  constructor(url: string, img: string, altImg: string) {
    this.url = url;
    this.img = img;
    this.altImg = altImg;
  }
}

import { IFaqState, ISetFaqDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IFaqState = {
  data: []
};
const faqDataReducer = (
  state = initialState,
  action: ISetFaqDataAction
): IFaqState => {
  switch (action.type) {
    case ActionTypes.FAQ.SET_FAQ_DATA:
      return {
        ...state,
        data: action.payload.data
      };
  }
  return state;
};

export default faqDataReducer;

import { IFactory } from '../../types';
import {
  IInfoIconsContent,
  IInfoIconsItem
} from '../../../entities/section/types';
import InfoIconsItemFactory from './InfoIconsItemFactory';
import InfoIconsContent from '../../../entities/section/info-icons/InfoIconsContent';

const InfoIconsContentFactory: IFactory = class InfoIconsContentFactory {
  static create(infoIconsContent: IInfoIconsContent) {
    const items: IInfoIconsItem[] = infoIconsContent.items.map(
      (item: IInfoIconsItem) => {
        return InfoIconsItemFactory.create(item);
      }
    );

    return new InfoIconsContent(
      infoIconsContent._id,
      infoIconsContent.title,
      infoIconsContent.startAt,
      infoIconsContent.endAt,
      items
    );
  }
};

export default InfoIconsContentFactory;

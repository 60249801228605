import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import {
  enrichBookstoresAction,
  setBookstoresAction,
  validateBookstoresAction
} from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import BookstoreFactory from '../../../factories/bookstore/BookstoreFactory';
import { IBookstore } from '../../../entities/bookstore/types';
import BookstoresValidator from '../../../validators/bookstores';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import { setNotification } from '../notification/actions';
import NotificationFactory from '../../../factories/notification/NotificationFactory';
import { generateErrorMessage } from '../../../utilites/error';
import NotificationTypes from '../../../constants/NotificationTypes';

const fetchBookstoresMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.BOOKSTORES_PAGE.FETCH_BOOKSTORES_PAGE:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.BOOKSTORE_PAGE,
            null,
            null,
            MiddlewareEntities.BOOKSTORES,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.BOOKSTORES));
        break;
      case `${MiddlewareEntities.BOOKSTORES} ${ActionTypes.API_SUCCESS}`:
        next(validateBookstoresAction(action.payload.data));
        next(setLoader(false, MiddlewareEntities.BOOKSTORES));
        break;
      case `${MiddlewareEntities.BOOKSTORES} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.BOOKSTORES));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.BOOKSTORES,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

const validateBookstoresMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(ActionTypes.BOOKSTORES_PAGE.VALIDATE_BOOKSTORES_PAGE)
    ) {
      if (BookstoresValidator.validate(action.payload.bookstores)) {
        return next(enrichBookstoresAction(action.payload.bookstores));
      }
    }
  };

const enrichBookstoresMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.BOOKSTORES_PAGE.ENRICH_BOOKSTORES_PAGE) {
      const bookstores = action.payload.bookstores.map(
        (bookstore: IBookstore) => {
          return BookstoreFactory.create(bookstore);
        }
      );
      next(setBookstoresAction(bookstores));
    }
  };

export default [
  fetchBookstoresMiddleware,
  validateBookstoresMiddleware,
  enrichBookstoresMiddleware
];

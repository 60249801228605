import ActionTypes from '../../../constants/ActionTypes';
import {
  IEnrichGenrePageDataAction,
  IFetchGenrePageDataAction,
  IGenrePageDataState,
  ISetGenrePageDataAction,
  IValidateGenrePageDataAction
} from './types';

export function fetchGenrePageDataAction(
  genreName: string,
  category: string
): IFetchGenrePageDataAction {
  return {
    type: ActionTypes.GENRE_PAGE.FETCH_GENRE_PAGE_DATA,
    payload: { genreName, category }
  };
}

export function setGenrePageDataAction(
  genrePageData: IGenrePageDataState
): ISetGenrePageDataAction {
  return {
    type: ActionTypes.GENRE_PAGE.SET_GENRE_PAGE_DATA,
    payload: genrePageData
  };
}

export function validateGenrePageDataAction(
  genrePageData: IGenrePageDataState
): IValidateGenrePageDataAction {
  return {
    type: ActionTypes.GENRE_PAGE.VALIDATE_GENRE_PAGE_DATA,
    payload: genrePageData
  };
}

export function enrichGenrePageDataAction(
  genrePageData: IGenrePageDataState
): IEnrichGenrePageDataAction {
  return {
    type: ActionTypes.GENRE_PAGE.ENRICH_GENRE_PAGE_DATA,
    payload: genrePageData
  };
}

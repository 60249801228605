import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichBoardGamesPageDataAction,
  setBoardGamesPageDataAction,
  validateBoardGamesPageDataAction
} from './actions';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchBoardGamesPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.BOARD_GAMES_PAGE.FETCH_BOARD_GAMES_PAGE_DATA:
        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_BOARD_GAMES_PAGE_DATA,
            { ...action.params },
            null,
            MiddlewareEntities.BOARD_GAMES_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.BOARD_GAMES_PAGE));
        break;
      case `${MiddlewareEntities.BOARD_GAMES_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.BOARD_GAMES_PAGE));
        next(validateBoardGamesPageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.BOARD_GAMES_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.BOARD_GAMES_PAGE));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.BOARD_GAMES_PAGE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateBoardGamesPageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.BOARD_GAMES_PAGE.VALIDATE_BOARD_GAMES_PAGE_DATA
      )
    ) {
      next(
        enrichBoardGamesPageDataAction({
          sections: SectionDataFacade.generate(action.payload.sections),
          metaDescription: action.payload.metaDescription
        })
      );
    }
  };

export const enrichBoardGamesPageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type === ActionTypes.BOARD_GAMES_PAGE.ENRICH_BOARD_GAMES_PAGE_DATA
    ) {
      next(setBoardGamesPageDataAction(action.payload));
    }
  };

export default [
  fetchBoardGamesPageDataMiddleware,
  validateBoardGamesPageDataMiddleware,
  enrichBoardGamesPageDataMiddleware
];

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import {
  enrichSearchItemsAction,
  setInitSearchItems,
  setSearchItems,
  validateSearchItemsAction
} from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import ProductFactory from '../../../factories/product';
import Validator from '../../../validators/Validator';
import { setProductByNavIdAction } from '../product-by-nav-id/actions';
import { IProduct } from '../../../entities/product/types';
import { productSchema } from '../../../validators/product/productSchema';
import Ajv from 'ajv';
import { ISearchItemsState } from './types';

const fetchSearchItemsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.SEARCH_ITEMS.FETCH_ITEMS_BY_KEY:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.SEARCH_ITEMS}/${action.payload.category}/${action.payload.queryString}`,
            action.payload.key,
            null,
            MiddlewareEntities.SEARCH_ITEMS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.SEARCH_ITEMS));
        break;
      case `${MiddlewareEntities.SEARCH_ITEMS} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.SEARCH_ITEMS));
        const searchResponse = action.payload.data;
        next(validateSearchItemsAction(searchResponse));
        break;
      case `${MiddlewareEntities.SEARCH_ITEMS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.SEARCH_ITEMS));
        break;
      case ActionTypes.SEARCH_ITEMS.SET_INIT_SEARCH_ITEMS:
        next(setInitSearchItems());
        break;
    }
  };

export const validateSearchItemsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.SEARCH_ITEMS.VALIDATE_SEARCH_ITEMS) {
      const searchResponse = action.payload;

      if (
        !Validator.isUndefined(searchResponse) &&
        searchResponse.results &&
        searchResponse.results.length > 0
      ) {
        const results: ISearchItemsState = {
          totalNumberOfResults: searchResponse.totalNumberOfResults,
          prominentProduct: null,
          results: []
        };

        const ajv = new Ajv();

        results.results = searchResponse.results.filter((product: IProduct) => {
          if (ajv.validate(productSchema, product)) {
            return product;
          }
        });

        if (
          typeof searchResponse?.prominentProduct !== 'undefined' &&
          ajv.validate(productSchema, searchResponse?.prominentProduct)
        ) {
          results.prominentProduct = ProductFactory.create(
            searchResponse.prominentProduct
          );
        }
        next(setProductByNavIdAction(null));
        return next(enrichSearchItemsAction(results));
      }

      next(setInitSearchItems());
    }
  };

export const enrichSearchItemsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.SEARCH_ITEMS.ENRICH_SEARCH_ITEMS) {
      const searchResponse = action.payload;
      searchResponse.results = action.payload.results.map((product: IProduct) =>
        ProductFactory.create(product)
      );

      next(setSearchItems(searchResponse));
    }
  };

export default [
  fetchSearchItemsMiddleware,
  validateSearchItemsMiddleware,
  enrichSearchItemsMiddleware
];

import { IGiftPageState, ISetGiftPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IGiftPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const giftPageDataReducer = (
  state = initialState,
  action: ISetGiftPageDataAction
): IGiftPageState => {
  switch (action.type) {
    case ActionTypes.GIFT_PAGE.SET_GIFT_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default giftPageDataReducer;

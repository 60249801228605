import { AnyAction, Dispatch, Middleware } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import {
  enrichNews,
  enrichNewsSectionsAction,
  setNews,
  setNewsSectionsAction,
  validateNews
} from './actions';
import { setLoader } from '../ui/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import NewsFactory from '../../../factories/news/NewsFactory';
import NewsValidator from '../../../validators/news';
import News from '../../../entities/news/News';
import SectionDataFacade from '../../../facades/SectionDataFacade';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

const fetchNewsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWS.FETCH_LAST_NEWS:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_LAST_NEWS}`,
            { skip: action.payload.skip, limit: action.payload.limit },
            { isOverrideNews: action.payload.isOverrideNews },
            MiddlewareEntities.NEWS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.NEWS));
        break;
      case `${MiddlewareEntities.NEWS} ${ActionTypes.API_SUCCESS}`:
        next(
          validateNews(
            action.payload.data.data,
            action.payload.meta.data.isOverrideNews
          )
        );
        next(setLoader(false, MiddlewareEntities.NEWS));
        break;
      case `${MiddlewareEntities.NEWS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.NEWS));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.NEWS,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

const validateNewsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.NEWS.VALIDATE_NEWS)) {
      const news = action.payload.news.filter((item: News) => {
        if (NewsValidator.validate(item)) {
          return item;
        }
      });

      next(enrichNews(news, action.payload.isOverrideNews));
    }
  };

const enrichNewsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.NEWS.ENRICH_NEWS) {
      const news = action.payload.news.map((item: News) => {
        return NewsFactory.create(item);
      });

      next(setNews(news, action.payload.isOverrideNews));
    }
  };

const fetchNewsSectionMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.NEWS.FETCH_NEWS_SECTIONS:
        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_NEWS_SECTIONS}`,
            null,
            null,
            MiddlewareEntities.NEWS_SECTION,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.NEWS_SECTION));
        break;
      case `${MiddlewareEntities.NEWS_SECTION} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.NEWS_SECTION));
        next(enrichNewsSectionsAction(action.payload.data));
        break;
    }
  };

const enrichNewsSectionMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.NEWS.ENRICH_NEWS_SECTIONS) {
      next(
        setNewsSectionsAction(
          SectionDataFacade.generate(action.payload.sections.sections),
          action.payload.metaDescription
        )
      );
    }
  };

export default [
  fetchNewsMiddleware,
  validateNewsMiddleware,
  enrichNewsMiddleware,
  fetchNewsSectionMiddleware,
  enrichNewsSectionMiddleware
];

export const tabCarouselsSchema: any = {
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        tabs: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              name: { type: 'string' },
              viewAllUrl: { type: ['string', 'null'] },
              products: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    _id: { type: 'string' },
                    publisher: { type: ['string', 'null'] },
                    authors: {
                      type: ['array', 'string']
                    },
                    images: {
                      type: 'object',
                      properties: {
                        s: { type: 'string' },
                        m: { type: 'string' },
                        l: { type: 'string' },
                        xl: { type: 'string' },
                        xxl: { type: 'string' },
                        fb: { type: 'string' }
                      },
                      required: ['s', 'm', 'l', 'xl', 'xxl', 'fb']
                    },
                    priceList: {
                      type: 'object',
                      properties: {
                        fullPrice: { type: ['string', 'number'] },
                        eBookPrice: { type: ['string', 'number'] }
                      }
                    },
                    status: { type: 'boolean' },
                    title: { type: 'string' },
                    category: { type: 'string' }
                  },
                  required: [
                    '_id',
                    'status',
                    'title',
                    'category',
                    'images',
                    'priceList'
                  ]
                }
              }
            },
            required: ['name', 'products']
          }
        }
      },
      required: ['_id', 'tabs']
    }
  },
  required: ['type', 'content']
};

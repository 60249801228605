import { IFlatCarouselContent } from '../../../entities/section/types';
import { IFactory } from '../../types';
import FlatCarouselsContent from '../../../entities/section/flatcarousels/FlatCarouselsContent';
import { getAvailableProducts } from '../../../utilites/product';

const FlatCarouselsContentFactory: IFactory = class FlatCarouselsContentFactory {
  static create(content: IFlatCarouselContent) {
    return new FlatCarouselsContent(
      content._id,
      content.title,
      content.priceDisplayType,
      content.startAt,
      content.endAt,
      getAvailableProducts(content.products),
      content.isTopList,
      content.viewAllUrl
    );
  }
};

export default FlatCarouselsContentFactory;

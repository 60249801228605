import ActionTypes from '../../../constants/ActionTypes';
import { IWsPayState, ISetWsPayRedirectParamsAction } from './types';

const initialState: IWsPayState = {
  redirectParams: null
};

const wspayReducer = (
  state = initialState,
  action: ISetWsPayRedirectParamsAction
): IWsPayState => {
  switch (action.type) {
    case ActionTypes.WSPAY.SET_WSPAY_REDIRECT_PARAMS:
      return {
        ...state,
        redirectParams: action.payload.redirectParams
      };
  }

  return state;
};

export default wspayReducer;

import { IActionsListState, ISetActionsListPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IActionsListState = {
  actions: [],
  products: [],
  totalRecords: 0
};
const actionsListPageDataReducer = (
  state = initialState,
  action: ISetActionsListPageDataAction
): IActionsListState => {
  switch (action.type) {
    case ActionTypes.ACTIONS_LIST_PAGE.SET_ACTIONS_LIST_PAGE_DATA:
      return {
        ...state,
        actions: action.payload.actions,
        products: action.payload.products,
        totalRecords: action.payload.totalRecords
      };
  }
  return state;
};

export default actionsListPageDataReducer;

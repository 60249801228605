import { IFactory } from '../../types';
import { ITwinBanner } from '../../../entities/section/types';
import TwinBanner from '../../../entities/section/twin-banners/TwinBanner';
import { replaceDotWithUrl } from '../../../utilites/product';

const TwinBannerFactory: IFactory = class TwinBannerFactory {
  static create(twinBanner: ITwinBanner) {
    return new TwinBanner(
      twinBanner._id,
      replaceDotWithUrl(twinBanner.img),
      twinBanner.link
    );
  }
};

export default TwinBannerFactory;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichProductListPageProductsAction,
  setProductListPageProductsAction,
  validateProductListPageProductsAction
} from './actions';
import { IProduct } from '../../../entities/product/types';
import ProductFactory from '../../../factories/product';
import Ajv from 'ajv';
import { productSchema } from '../../../validators/product/productSchema';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';

export const fetchProductListProductsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.PRODUCTS_LIST_PAGE.FETCH_PRODUCTS_LIST_PAGE_PRODUCTS:
        const storeSearchParams = { ...store.getState().searchParams.items };

        if (storeSearchParams.page > 1) {
          storeSearchParams.skip =
            Number(storeSearchParams.limit) * Number(storeSearchParams.page) -
            Number(storeSearchParams.limit);
        }

        if (storeSearchParams.page === 1) {
          storeSearchParams.skip = 0;
        }

        if (storeSearchParams.category === 'all') {
          delete storeSearchParams.category;
        }

        if (storeSearchParams.page) {
          delete storeSearchParams.page;
        }

        if (storeSearchParams.inPage) {
          delete storeSearchParams.inPage;
        }

        const encodedObj: any = {};
        for (const key in storeSearchParams) {
          encodedObj[key] = storeSearchParams[key];
          if (typeof encodedObj[key] === 'string') {
            encodedObj[key] = encodedObj[key].replace(/_ss_cmma_/g, '%2C');
          }
        }

        next(
          apiRequest(
            null,
            'GET',
            ApiUrls.GET_PRODUCT_LIST_PRODUCTS,
            encodedObj,
            null,
            MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS));
        break;
      case `${MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS));
        next(
          validateProductListPageProductsAction(
            action.payload.data,
            action.payload.data.recordsTotal
          )
        );
        break;
      case `${MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS));
        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.PRODUCTS_LIST_PAGE_PRODUCTS,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateProductListProductsMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type.includes(
        ActionTypes.PRODUCTS_LIST_PAGE.VALIDATE_PRODUCTS_LIST_PAGE_PRODUCTS
      )
    ) {
      const ajv = new Ajv();
      const products = action.payload.products.data.filter(
        (product: IProduct) => {
          if (ajv.validate(productSchema, product)) {
            return product;
          }
        }
      );

      next(
        enrichProductListPageProductsAction(
          products,
          action.payload.totalRecords
        )
      );
    }
  };

export const enrichProductListPageProductsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (
      action.type ===
      ActionTypes.PRODUCTS_LIST_PAGE.ENRICH_PRODUCTS_LIST_PAGE_PRODUCTS
    ) {
      const products = action.payload.products.map((product: IProduct) =>
        ProductFactory.create(product)
      );

      next(
        setProductListPageProductsAction(products, action.payload.totalRecords)
      );
    }
  };

export default [
  fetchProductListProductsMiddleware,
  validateProductListProductsMiddleware,
  enrichProductListPageProductsMiddleware
];

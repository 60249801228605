/* eslint-disable @typescript-eslint/brace-style */

import {
  IVisaPremiumBenefitPayload,
  IVisaPremiumBenefitPayloadCart
} from '../cart/types';

export default class VisaPremiumBenefitPayload
  implements IVisaPremiumBenefitPayload
{
  bannerUrl: string;

  benefitAmountLimit: number;

  benefitAvailableContent: string;

  benefitCarts: IVisaPremiumBenefitPayloadCart[];

  benefitUnavailableContent: string;

  benefitSubtext: string;

  constructor(
    bannerUrl: string,
    benefitAmountLimit: number,
    benefitAvailableContent: string,
    benefitCarts: IVisaPremiumBenefitPayloadCart[],
    benefitUnavailableContent: string,
    benefitSubtext: string
  ) {
    this.bannerUrl = bannerUrl;
    this.benefitAmountLimit = benefitAmountLimit;
    this.benefitAvailableContent = benefitAvailableContent;
    this.benefitCarts = benefitCarts;
    this.benefitUnavailableContent = benefitUnavailableContent;
    this.benefitSubtext = benefitSubtext;
  }
}

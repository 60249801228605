import { IMusicPageState, ISetMusicPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IMusicPageState = {
  data: {
    sections: [],
    metaDescription: ''
  }
};
const musicPageDataReducer = (
  state = initialState,
  action: ISetMusicPageDataAction
): IMusicPageState => {
  switch (action.type) {
    case ActionTypes.MUSIC_PAGE.SET_MUSIC_PAGE_DATA:
      return {
        ...state,
        data: action.payload
      };
  }
  return state;
};

export default musicPageDataReducer;

export const threePartCarouselsSchema: any = {
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        title: { type: 'string' },
        slide1: {
          type: 'object',
          properties: {
            description: { type: 'string' },
            img: { type: 'string' },
            prominentText: { type: 'string' },
          },
          required: ['description', 'img', 'prominentText']
        },
          slide2: {
            type: 'object',
            properties: {
              description: { type: 'string' },
              img: { type: 'string' },
              prominentText: { type: 'string' },
            },
            required: ['description', 'img', 'prominentText']
          },
          slide3: {
            type: 'object',
            properties: {
              description: { type: 'string' },
              img: { type: 'string' },
              prominentText: { type: 'string' },
            },
            required: ['description', 'img', 'prominentText']
          }
        },
        required: ['_id', 'title', 'slide1', 'slide2', 'slide3']
      }
    },
    required: ['type', 'content']
  };


import { ILogoWidgetCarousel, ILogoWidgetCarouselItem } from '../types';

export default class LogoWidgetCarousel implements ILogoWidgetCarousel {
  _id: string;

  title: string;

  status: boolean;

  items: ILogoWidgetCarouselItem[];

  constructor(
    _id: string,
    title: string,
    status: boolean,
    items: ILogoWidgetCarouselItem[]
  ) {
    this._id = _id;
    this.title = title;
    this.status = status;
    this.items = items;
  }
}

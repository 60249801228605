import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import ApiUrls from '../../../constants/ApiUrls';
import { apiRequest } from '../api/actions';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  enrichGenrePageDataAction,
  setGenrePageDataAction,
  validateGenrePageDataAction
} from './actions';
import { errorAction } from '../error-handling/actions';
import { HttpStatusCodes } from '../../../constants/HttpStatusCodes';
import NotificationTypes from '../../../constants/NotificationTypes';
import Ajv from 'ajv';
import { IProduct } from '../../../entities/product/types';
import { productSchema } from '../../../validators/product/productSchema';
import ProductFactory from '../../../factories/product';
import GenreFactory from '../../../factories/genre/GenreFactory';

export const fetchGenrePageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);
    switch (action.type) {
      case ActionTypes.GENRE_PAGE.FETCH_GENRE_PAGE_DATA:
        const storeSearchParams = { ...store.getState().searchParams.items };

        if (storeSearchParams.page > 1) {
          storeSearchParams.skip =
            Number(storeSearchParams.limit) * Number(storeSearchParams.page) -
            Number(storeSearchParams.limit);
        }

        if (storeSearchParams.page === 1) {
          storeSearchParams.skip = 0;
        }

        if (storeSearchParams.page) {
          delete storeSearchParams.page;
        }

        if (storeSearchParams.inPage) {
          delete storeSearchParams.inPage;
        }

        const encodedObj: any = {};
        for (const key in storeSearchParams) {
          encodedObj[key] = storeSearchParams[key];
          if (typeof encodedObj[key] === 'string') {
            encodedObj[key] = encodedObj[key].replace(/_ss_cmma_/g, '%2C');
          }
        }

        const encodedGenreName = action.payload.genreName.replace(
          /_ss_cmma_/g,
          '%2C'
        );

        next(
          apiRequest(
            null,
            'GET',
            `${ApiUrls.GET_GENRE_PAGE_DATA}/${action.payload.category}/${encodedGenreName}`,
            encodedObj,
            null,
            MiddlewareEntities.GENRE_PAGE,
            {}
          )
        );
        next(setLoader(true, MiddlewareEntities.GENRE_PAGE));
        break;
      case `${MiddlewareEntities.GENRE_PAGE} ${ActionTypes.API_SUCCESS}`:
        next(setLoader(false, MiddlewareEntities.GENRE_PAGE));
        next(validateGenrePageDataAction(action.payload.data));
        break;
      case `${MiddlewareEntities.GENRE_PAGE} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.GENRE_PAGE));

        if (
          action.payload.data &&
          action.payload.data.status &&
          action.payload.data.status === HttpStatusCodes.HTTP_NOT_FOUND
        ) {
          return (window.location.href = '/404');
        }

        next(
          errorAction(
            action.payload.data.data,
            MiddlewareEntities.GENRE_PAGE,
            NotificationTypes.DELAY_NOTIFICATION_DISMISS,
            action.payload.data
              ? action.payload.data.status
              : HttpStatusCodes.HTTP_INTERNAL_SERVER_ERROR,
            action.payload.data && action.payload.data.code
              ? action.payload.data.code
              : undefined
          )
        );
        break;
    }
  };

export const validateGenrePageDataMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type.includes(ActionTypes.GENRE_PAGE.VALIDATE_GENRE_PAGE_DATA)) {
      const ajv = new Ajv();
      const validProducts = action.payload.products.filter(
        (product: IProduct) => ajv.validate(productSchema, product)
      );

      next(
        enrichGenrePageDataAction({
          genre: action.payload.genre,
          recordsTotal: action.payload.recordsTotal,
          products: validProducts
        })
      );
    }
  };

export const enrichGenrePageDataMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.GENRE_PAGE.ENRICH_GENRE_PAGE_DATA) {
      const products = action.payload.products.map((product: IProduct) => {
        return ProductFactory.create(product);
      });
      next(
        setGenrePageDataAction({
          genre: GenreFactory.create(action.payload.genre),
          recordsTotal: action.payload.recordsTotal,
          products
        })
      );
    }
  };

export default [
  fetchGenrePageDataMiddleware,
  validateGenrePageDataMiddleware,
  enrichGenrePageDataMiddleware
];

import { IComplaintPageDataState, ISetComplaintPageDataAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState: IComplaintPageDataState = {
  compliantPage: null
};
const compliantPageDataReducer = (
  state = initialState,
  action: ISetComplaintPageDataAction
): IComplaintPageDataState => {
  switch (action.type) {
    case ActionTypes.COMPLAINT_PAGE.SET_COMPLAINT_PAGE_DATA:
      return {
        ...state,
        compliantPage: action.payload.compliantPage
      };
  }
  return state;
};

export default compliantPageDataReducer;

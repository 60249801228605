export const infoIconsSchema: any = {
  $id: 'infoIconsSchema',
  type: 'object',
  properties: {
    type: { type: 'string' },
    content: {
      type: 'object',
      properties: {
        _id: { type: 'string' },
        title: { type: 'string' },
        startAt: { type: ['string', 'null'] },
        endAt: { type: ['string', 'null'] },
        items: {
          type: 'array',
          minItems: 1,
          items: {
            type: 'object',
            properties: {
              boldText: { type: 'string' },
              icon: { type: 'string' },
              regularText: { type: 'string' },
              url: { type: ['null', 'string'] }
            },
            required: ['boldText', 'icon', 'regularText']
          }
        }
      },
      required: ['_id', 'title', 'items']
    }
  },
  required: ['type', 'content']
};

import { ISetResetPasswordAction } from './types';
import ActionTypes from '../../../constants/ActionTypes';

const initialState = {
    status: false,
};
const resetPasswordReducer = (
    state = initialState,
    action: ISetResetPasswordAction
) => {
    switch (action.type) {
        case ActionTypes.RESET_USER_PASSWORD.SET_RESET_USER_PASSWORD:
            return {
                ...state,
                status: action.payload.status
            };
    }
    return state;
};

export default resetPasswordReducer;

import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import ActionTypes from '../../../constants/ActionTypes';
import { apiRequest } from '../api/actions';
import ApiUrls from '../../../constants/ApiUrls';
import MiddlewareEntities from '../../../constants/MiddlewareEntities';
import { setLoader } from '../ui/actions';
import {
  getJsonFromCookie,
  removeCookie,
  setCookie
} from '../../../utilites/cookies';
import CookieNames from '../../../constants/CookieNames';
import {
  changeCartQuantityAction,
  enrichCartItemsAction,
  fetchCartItemsFromApiAction,
  removeFromCartAction,
  resetCartItemsAction,
  setCartForPaymentAction,
  setIsLastGetCartErrorAction,
  setProductToCartAction
} from './actions';
import _, { has } from 'lodash';
import CartItemFactory from '../../../factories/cart/CartFactory';
import DefaultCountryData from '../../../constants/DefaultCountryData';
import { Checkout } from '../../../constants/Checkout';
import { ErrorCodes } from '../../../constants/ErrorCodes';
import CookieExpiredByDays from '../../../constants/CookieExpiredByDays';
import { createNotification } from '../notification/actions';
import NotificationTypes from '../../../constants/NotificationTypes';
import { PaymentTypes } from '../../../constants/PaymentTypes';
import { getDataLayerDataBasedOnCartChange } from '../../../utilites/gtm';
import {
  sendGtmEventAddProductAction,
  sendGtmEventRemoveProductAction
} from '../gtm/actions';
import { IGtmQuantityChangeData } from './types';
import { GtmQuantityChangeProductData } from '../gtm/types';

const cartMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.CART.ADD_TO_CART:
        const cartCookie = getJsonFromCookie(CookieNames.CART, '{}');

        if (!cartCookie || !has(cartCookie, 'orderItems')) {
          setCookie(
            CookieNames.CART,
            JSON.stringify({
              countryOldId: DefaultCountryData.COUNTRY_OLD_ID,
              zipCode: DefaultCountryData.ZIPCODE,
              orderItems: [{ productId: action.payload.productId, quantity: 1 }]
            }),
            CookieExpiredByDays.DELFI_ORDER
          );
          return next(changeCartQuantityAction(action.payload.productId, 1));
        }

        const orderItems = cartCookie.orderItems;

        let orderItem = orderItems.find(
          (item: { productId: string; quantity: number }) =>
            item.productId === action.payload.productId
        );

        if (!orderItem) {
          orderItems.push({ productId: action.payload.productId, quantity: 1 });
          setCookie(
            CookieNames.CART,
            JSON.stringify({
              countryOldId: DefaultCountryData.COUNTRY_OLD_ID,
              zipCode: DefaultCountryData.ZIPCODE,
              orderItems
            }),
            CookieExpiredByDays.DELFI_ORDER
          );
          return next(changeCartQuantityAction(action.payload.productId, 1));
        }

        if (orderItem) {
          next(
            changeCartQuantityAction(
              action.payload.productId,
              ++orderItem.quantity
            )
          );
        }
        break;
      case ActionTypes.CART.REMOVE_FROM_CART:
        break;
    }
  };

const changeOrderItemQuantityMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.CART.CART_CHANGE_QUANTITY) {
      const gtmQuantityChangeData: IGtmQuantityChangeData = {
        productId: undefined,
        direction: 0,
        quantity: 0
      };
      const cartCookie = getJsonFromCookie(CookieNames.CART, '{}');

      if (has(cartCookie, 'orderItems')) {
        let orderItems = cartCookie.orderItems;

        let orderItem = orderItems.find(
          (item: { productId: string; quantity: number }) =>
            item.productId === action.payload.productId
        );

        if (orderItem && action.payload.quantity > 0) {
          if (orderItem.quantity !== action.payload.quantity) {
            gtmQuantityChangeData.productId = action.payload.productId;
            gtmQuantityChangeData.direction =
              orderItem.quantity < action.payload.quantity ? 1 : -1;
            gtmQuantityChangeData.quantity = 1;
          }

          orderItem.quantity = action.payload.quantity;
          setCookie(
            CookieNames.CART,
            JSON.stringify({
              countryOldId: cartCookie.countryOldId
                ? cartCookie.countryOldId
                : DefaultCountryData.COUNTRY_OLD_ID,
              zipCode: cartCookie.zipCode
                ? cartCookie.zipCode
                : DefaultCountryData.ZIPCODE,
              orderItems
            }),
            CookieExpiredByDays.DELFI_ORDER
          );

          return next(
            fetchCartItemsFromApiAction(
              action.payload.options,
              undefined,
              undefined,
              undefined,
              undefined,
              gtmQuantityChangeData
            )
          );
        }
      }

      if (!action.payload.quantity) {
        next(removeFromCartAction(action.payload.productId));
      }
    }
  };

const changeCartCountryAndCityMiddleware: Middleware =
  () => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.CART.CART_CHANGE_COUNTRY_AND_ZIP_CODE) {
      const cartCookie = getJsonFromCookie(CookieNames.CART, '{}');

      if (cartCookie && Object.keys(cartCookie).length > 0) {
        setCookie(
          CookieNames.CART,
          JSON.stringify({
            countryOldId: action.payload.countryOldId,
            zipCode: action.payload.zipCode,
            orderItems: cartCookie.orderItems
          }),
          CookieExpiredByDays.DELFI_ORDER
        );
      }
    }
  };

const removeItemFromCartMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.CART.REMOVE_FROM_CART) {
      const cartCookie = getJsonFromCookie(CookieNames.CART, '{}');
      let orderItems = cartCookie.orderItems;

      const removedOrderItem = orderItems.find(
        (item: { productId: string; quantity: number }) =>
          item.productId === action.payload.productId
      );

      if (removedOrderItem) {
        const gtmQuantityChangeData: IGtmQuantityChangeData = {
          productId: removedOrderItem.productId,
          direction: -1,
          quantity: removedOrderItem.quantity
        };
        orderItems = orderItems.filter(
          (item: { productId: string; quantity: number }) =>
            item.productId !== action.payload.productId
        );

        setCookie(
          CookieNames.CART,
          JSON.stringify({
            countryOldId: cartCookie.countryOldId
              ? cartCookie.countryOldId
              : DefaultCountryData.COUNTRY_OLD_ID,
            zipCode: cartCookie.zipCode
              ? cartCookie.zipCode
              : DefaultCountryData.ZIPCODE,
            orderItems
          }),
          CookieExpiredByDays.DELFI_ORDER
        );

        store.dispatch(
          fetchCartItemsFromApiAction(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            gtmQuantityChangeData
          )
        );
      }
    }

    if (action.type === ActionTypes.CART.REMOVE_ALL_ITEMS_FROM_CART) {
      removeCookie(CookieNames.CART);
      next(
        setProductToCartAction({
          allOrderItemsAmount: 0,
          orderItemsPriceReport: [],
          pttPrice: 0,
          totalAmount: 0,
          amountUpToFreeShipping: 0,
          totalItemQuantity: 0,
          discountPrice: 0,
          validatePrice: () => null
        })
      );

      if (!action.payload.preventRedirect) {
        window.location.href = '/';
      }
    }
  };

const fetchCartItemsFromApiMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    switch (action.type) {
      case ActionTypes.CART.FETCH_CART_ITEMS_FROM_API:
        let paymentTypeQuery = '';
        let cartIdQuery = '';
        let cartCookie: any = null;
        let homeEmailQuery = '';

        cartCookie = getJsonFromCookie(CookieNames.CART, '{}');

        if (cartCookie && has(cartCookie, 'orderItems')) {
          const orderItems = cartCookie.orderItems;
          paymentTypeQuery = action.payload.paymentType
            ? `?paymentType=${action.payload.paymentType}`
            : '';

          homeEmailQuery = action.payload.homeEmail
            ? `&homeEmail=${action.payload.homeEmail}`
            : '';

          if (
            action.payload.paymentType ===
              PaymentTypes.PAYMENT_TYPE_VISA_PREMIUM_CREDIT_CARD &&
            action.payload.cartId
          ) {
            cartIdQuery = `&visaPremiumBenefitCartId=${action.payload.cartId}`;
          }

          if (orderItems.length <= 0) {
            const legacyState = store.getState();
            if (
              _.has(legacyState, 'cart.order.orderItemsPriceReport[0].product')
            ) {
              const removedProductGtmData: GtmQuantityChangeProductData = {
                product:
                  store.getState().cart.order.orderItemsPriceReport[0].product,
                quantity:
                  store.getState().cart.order.orderItemsPriceReport[0].quantity
              };
              next(sendGtmEventRemoveProductAction(removedProductGtmData));
            }
            next(
              setProductToCartAction({
                allOrderItemsAmount: 0,
                orderItemsPriceReport: [],
                pttPrice: 0,
                totalAmount: 0,
                amountUpToFreeShipping: 0,
                totalItemQuantity: 0,
                discountPrice: 0,
                validatePrice: () => null
              })
            );
          }
        }

        next(
          apiRequest(
            null,
            'POST',
            `${ApiUrls.GET_CART_ITEMS}${paymentTypeQuery}${cartIdQuery}${homeEmailQuery}`,
            null,
            action.payload.options,
            MiddlewareEntities.FETCH_CART_ITEMS,
            {},
            {
              cartForPayment: action.payload.cartForPayment,
              paymentType: action.payload.paymentType,
              cartId: action.payload.cartId,
              preventGTM: action.payload.preventGTM,
              gtmQuantityChangeData: action.payload.gtmQuantityChangeData
            }
          )
        );
        next(setLoader(true, MiddlewareEntities.FETCH_CART_ITEMS));
        next(setIsLastGetCartErrorAction(true));
        break;
      case `${MiddlewareEntities.FETCH_CART_ITEMS} ${ActionTypes.API_SUCCESS}`:
        next(setIsLastGetCartErrorAction(false));
        next(setLoader(false, MiddlewareEntities.FETCH_CART_ITEMS));
        if (
          action.payload.data &&
          Object.keys(action.payload.data).length > 0
        ) {
          next(
            enrichCartItemsAction(
              action.payload.data,
              action.payload.meta.metaData.cartForPayment,
              action.payload.meta.metaData.preventGTM,
              action.payload.meta.metaData.gtmQuantityChangeData
            )
          );
        }
        break;
      case `${MiddlewareEntities.FETCH_CART_ITEMS} ${ActionTypes.API_ERROR}`:
        next(setLoader(false, MiddlewareEntities.FETCH_CART_ITEMS));
        next(setIsLastGetCartErrorAction(true));

        if (has(action, 'payload.data.data.metadata.meta')) {
          if (
            action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.EMPTY_CART_ITEMS
          ) {
            return next(resetCartItemsAction(true));
          }

          if (
            action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.CART_ITEM_IS_NOT_VALID
          ) {
            try {
              if (action.payload.data.config.data) {
                return store.dispatch(
                  fetchCartItemsFromApiAction(
                    JSON.parse(action.payload.data.config.data),
                    action.payload.data.config.metaData.paymentType,
                    action.payload.data.config.metaData.cartForPayment
                  )
                );
              }
            } catch (e) {}

            return store.dispatch(fetchCartItemsFromApiAction());
          }

          if (
            action.payload.data.data.metadata.meta.errorCode ===
            ErrorCodes.ADMINISTRATIVE_BAN_NOT_AVAILABLE
          ) {
            next(
              createNotification(
                MiddlewareEntities.FETCH_CART_ITEMS,
                NotificationTypes.ERROR,
                undefined,
                action.payload.data.data
              )
            );
            if (action.payload.data.config.data) {
              return store.dispatch(
                fetchCartItemsFromApiAction(
                  JSON.parse(action.payload.data.config.data),
                  undefined,
                  undefined,
                  true
                )
              );
            }

            return store.dispatch(fetchCartItemsFromApiAction());
          }
        }
        break;
    }
  };

const enrichCartItemsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.CART.ENRICH_CART) {
      const order = CartItemFactory.create(action.payload.order);
      // old order
      const oldCart = { ...store.getState().cart.order };
      next(setProductToCartAction(order));
      const newCart = store.getState().cart.order;

      // Check if moment applicable
      if (!action.payload.preventGTM) {
        const gtmQuantityChangeData = action.payload.gtmQuantityChangeData
          ? action.payload.gtmQuantityChangeData
          : undefined;
        // console.log('Non-initial load. Do GTM part of work...');
        const dataLayerProductData = getDataLayerDataBasedOnCartChange(
          oldCart,
          newCart,
          gtmQuantityChangeData
        );
        if (dataLayerProductData.addedProduct) {
          // console.log('next action sendGtm ADD called');
          next(sendGtmEventAddProductAction(dataLayerProductData.addedProduct));
        }
        if (dataLayerProductData.removedProduct) {
          // console.log('next action sendGtm REMOVE called');
          next(
            sendGtmEventRemoveProductAction(dataLayerProductData.removedProduct)
          );
        }
      }

      if (action.payload.cartForPayment) {
        next(setCartForPaymentAction(order));
      }
    }
  };

const resetCartItemsMiddleware: Middleware =
  (store: MiddlewareAPI) => (next: Dispatch) => (action: AnyAction) => {
    next(action);

    if (action.type === ActionTypes.CART.RESET_CART) {
      const emptyCartState = {
        allOrderItemsAmount: 0,
        benefitsReport: {},
        administrativeBanReport: null,
        deliveryService: Checkout.DELIVERY_SERVICE_DEFAULT,
        orderItemsPriceReport: [],
        pttPrice: 0,
        totalAmount: 0,
        amountUpToFreeShipping: 0,
        totalItemQuantity: 0,
        discountPrice: 0,
        calculatedPremiumPrice: 0,
        validatePrice: () => null
      };
      next(setProductToCartAction(emptyCartState));

      if (action.payload.cartForPayment) {
        next(setCartForPaymentAction(emptyCartState));
      }
    }
  };

export default [
  cartMiddleware,
  changeOrderItemQuantityMiddleware,
  changeCartCountryAndCityMiddleware,
  removeItemFromCartMiddleware,
  fetchCartItemsFromApiMiddleware,
  enrichCartItemsMiddleware,
  resetCartItemsMiddleware
];
